@charset "UTF-8";
/*! 
 * front
 * (C)Mynavi Corporation
 */
/*! ============================================================
 * File        : Foundation/
 * DESCRIPTION : 定数・変数や関数、Mixin
 * AUTHOR      : 
 * -------------------------------------------------------------
 * (C)Mynavi Corporation
 * ========================================================== */
/** ============================================================
 * File        : Foundation/_define.scss
 * DESCRIPTION : 定数・基本情報
 * AUTHOR      : 
 * -------------------------------------------------------------
 * (C)Mynavi Corporation
 * ========================================================== */
/** ========================================
 * 定数
 * ====================================== */
/** --------------------
 * カラー
 * ------------------ */
/** --------------------
 * フォントサイズ
 * ------------------ */
/** --------------------
 * 幅
 * ------------------ */
/** ========================================
 * MIXIN
 * ====================================== */
/** --------------------
 * 横幅有りのブロック
 * ------------------ */
/** ========================================
 * 関数
 * ====================================== */
/** --------------------
 * フォントサイズのrem変換
 * ------------------ */
@import url(http://fonts.googleapis.com/earlyaccess/notosansjp.css);
@import url(../../node_modules/jquery-ui/themes/base/all.css);
@keyframes show {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/*! ============================================================
 * File        : /common/scss/Foundation/reset
 * DESCRIPTION : 共通のリセット
 * AUTHOR      : 
 * -------------------------------------------------------------
 * (C)Mynavi Corporation
 * ========================================================== */
/*! ============================================================
 * File        : Foundation/_reset.scss
 * DESCRIPTION : スタイルの初期化
 * AUTHOR      : 
 * -------------------------------------------------------------
 * (C)Mynavi Corporation
 * ========================================================== */
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
     License: none (public domain)
     update 2016 koizumi
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video,
main {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-style: normal;
  vertical-align: baseline;
  /*aタグとかをタップしたときに色が変わるのを防ぐ*/
  -webkit-tap-highlight-color: rgba(253, 153, 153, 0);
  /*回転したときなど文字の大きさを調整*/
  -webkit-text-size-adjust: 100%; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section,
main {
  display: block; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed; }

em {
  font-weight: bold; }

input, textarea, button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

input:focus, textarea:focus, button:focus {
  outline: 0; }

/*! ============================================================
 * File        : Layout/
 * DESCRIPTION : 基本のレイアウト
 * AUTHOR      : 
 * -------------------------------------------------------------
 * (C)Mynavi Corporation
 * ========================================================== */
/*! ============================================================
 * File        : Layout/_base.scss
 * DESCRIPTION : 基本設定
 * AUTHOR      : 
 * -------------------------------------------------------------
 * (C)Mynavi Corporation
 * ========================================================== */
*,
* ::before,
* ::after {
  box-sizing: border-box; }

body, textarea, input, button {
  color: #333;
  font-size: 0.875rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
  font-family: "Noto Sans JP", "メイリオ",Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif; }

/** --------------------
 * フォント
 * ------------------ */
.l--defFont {
  font-family: "Noto Sans JP", "メイリオ",Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif; }

.l--numFont {
  font-family: "Roboto", "Arial" ,sans-serif; }

p {
  word-break: normal !important;
  word-wrap: break-word; }

img {
  width: 100%;
  vertical-align: bottom; }

a {
  color: #333;
  text-decoration: none; }

.width__block {
  width: 1024px;
  margin: 0 auto; }

/*! ============================================================
 * File        : Object/
 * DESCRIPTION : 要素のスタイル
 * AUTHOR      : 
 * -------------------------------------------------------------
 * (C)Mynavi Corporation
 * ========================================================== */
/** ------------------------------
 * Component
 * DESCRIPTION : コンポーネント
 * ---------------------------- */
/*! 
 * 各要素（繰り返して使うもの）
 */
/** ========================================
 * @only
 * 出し分け
 * ====================================== */
.c--sp__only {
  display: none !important; }

.c--dsp--no {
  display: none; }

.c--dsp--block {
  display: block !important; }

.c--dsp--inlineBlock {
  display: inline-block; }

@media screen and (max-width: 767px) {
  .c--pc__only {
    display: none !important; }
  .c--sp__only {
    display: block !important; }
  .c--sp__inlineBlock {
    display: inline-block !important; } }

@media screen and (max-width: 767px) {
  .c--singleTxt {
    margin: 20px 20px 0 !important; } }

/** ========================================
 * @color
 * 文字色
 * ====================================== */
.c--color--p80 {
  color: #e92d82 !important; }

.c--color--red {
  color: #f84361 !important; }

.c--color--lb80 {
  color: #00b0ff !important; }

.c--color--b {
  color: #333 !important; }

.c--color--g70 {
  color: #828288 !important; }

.c--marker--y {
  background: linear-gradient(transparent 70%, #fff79d 70%); }

@media screen and (min-width: 768px) {
  /** ========================================
   * @hover
   * hover時アクション
   * ====================================== */
  a:hover {
    cursor: pointer; }
  .c--no__pointer {
    pointer-events: none; }
  button:hover {
    cursor: pointer; }
  button:disabled:hover {
    opacity: 1;
    cursor: default; }
  .c--hover__fade {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    overflow: hidden;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color; }
    .c--hover__fade:hover, .c--hover__fade:focus, .c--hover__fade:active {
      background-color: #2098D1;
      color: #fff; } }

@media screen and (max-width: 767px) {
  input,
  textarea,
  select,
  option {
    font-size: 1rem !important; }
  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0.2); } }

/** ========================================
 * @Headings
 * 見出し
 * ====================================== */
.c--primary__heading {
  color: #e92d82;
  font-size: 2rem;
  font-weight: bold;
  text-align: center; }
  .c--primary__heading + p {
    margin: 23px 0 0;
    font-size: 1rem;
    text-align: center; }
  .c--primary__heading--bk {
    color: #333; }

.c--secondary__heading {
  font-size: 1.75rem;
  font-weight: bold; }

.c--third__heading {
  font-size: 1.625rem;
  font-weight: bold; }

@media screen and (max-width: 767px) {
  .c--primary__heading {
    font-size: 1.5rem;
    padding: 0 20px; }
    .c--primary__heading + p {
      margin: 18px 0 0;
      font-size: 0.875rem;
      line-height: 1.7; }
  .c--secondary__heading {
    font-size: 1.25rem; } }

/** ========================================
 * @TABLE
 * テーブル
 * ====================================== */
.c--tableHeading {
  font-size: 1rem;
  font-weight: bold; }
  .c--tableHeading + .c--primaryTable {
    margin: 15px 0 0; }

.c--primaryTable {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse; }
  .c--primaryTable--fixed {
    table-layout: fixed; }
  .c--primaryTable th {
    padding: 10px 20px;
    border-top: 1px solid #dadadb;
    border-bottom: 1px solid #dadadb;
    background: #fff0f5;
    color: #333;
    text-align: left;
    vertical-align: middle; }
    .c--primaryTable th .c--require {
      display: flex;
      justify-content: space-between;
      align-items: flex-start; }
      .c--primaryTable th .c--require:after {
        content: "必須";
        min-width: 26px;
        margin: 3px 0 0 5px;
        color: #fff;
        font-weight: 400;
        font-size: 0.625rem;
        background: #f84361;
        padding: 3px;
        line-height: 1;
        display: inline-block; }
    .c--primaryTable th .c--required {
      background-color: #B00000;
      padding: 0.1rem 0.5rem;
      white-space: nowrap;
      float: right;
      color: #fff;
      font-size: 0.625rem;
      text-align: center; }
    .c--primaryTable th .c--noneTxt {
      font-weight: normal;
      font-size: .75rem; }
  .c--primaryTable.c--colType th {
    width: 15%;
    font-size: 0.875rem; }
  .c--primaryTable td {
    background: #fff;
    border-top: 1px solid #dadadb;
    border-bottom: 1px solid #dadadb;
    padding: 10px 20px;
    vertical-align: middle; }
    .c--primaryTable td.c--brno {
      border-right: none; }
    .c--primaryTable td.c--blno {
      border-left: none; }
    .c--primaryTable td.c--btno {
      border-top: none; }
    .c--primaryTable td.c--bbno {
      border-bottom: none; }
    .c--primaryTable td.c--guide {
      padding: 5px 20px;
      background: #f7f7f7;
      border-top: none; }
  .c--primaryTable--btm__editbtn {
    text-align: right;
    margin-top: 20px; }
    .c--primaryTable--btm__editbtn button {
      margin: 0; }
  .c--primaryTable + .c--tableHeading {
    margin: 35px 0 0; }
  .c--primaryTable td.p--inputArea--col2 input + span {
    margin: 0 5px 0 15px; }
  .c--primaryTable--clr__lpk th {
    background: #FFF7F6; }
  .c--primaryTable--thHigh th {
    padding: 24px 20px; }

.c--innerTable {
  margin: 10px 0; }
  .c--innerTable th {
    background: #F5F5F5; }
  .c--innerTable th, .c--innerTable td {
    padding: 3px 10px;
    border: 1px solid #D9D9DB; }

@media screen and (max-width: 767px) {
  .c--tableHeading {
    padding: 10px 20px;
    background: #F1F6FA; }
    .c--tableHeading + .c--primaryTable {
      margin: 0; }
  .c--primaryTable th, .c--primaryTable td {
    display: block;
    width: 100%;
    border: none; }
  .c--primaryTable th {
    padding: 5px 20px; }
    .c--primaryTable th .c--require {
      display: block; }
  .c--primaryTable + .c--tableHeading {
    margin: 15px 0 0; }
  .c--primaryTable td.p--inputArea--col2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 20px; }
    .c--primaryTable td.p--inputArea--col2 input + a {
      margin: 10px 0 0; }
  .c--primaryTable--thHigh th {
    padding: 5px 20px; }
  .c--innerTable th, .c--innerTable td {
    display: table-cell;
    border: 1px solid #D9D9DB; } }

/** ========================================
 * @
 * フォーム要素のスタイル
 * ====================================== */
input {
  width: auto;
  height: 45px;
  padding: 5px 10px;
  border: 2px solid #dadadb;
  border-radius: 4px; }
  input:disabled:hover {
    cursor: auto; }
  input[type=checkbox]:disabled ~ .c--control__txt {
    color: #bebec1; }
  input[type=radio]:disabled ~ .c--control__txt {
    color: #bebec1; }

.c--control__txt a {
  display: inline-block;
  margin: 0 5px 0 0;
  color: #e92d82;
  text-decoration: underline; }

textarea {
  padding: 5px 10px;
  border: 2px solid #dadadb;
  border-radius: 4px; }

.stretchTextarea {
  min-height: 45px; }

select {
  border: 2px solid #dadadb;
  background: #fff;
  outline: none; }
  select:focus {
    background: #fff; }

::-webkit-input-placeholder {
  color: #ccc; }

:-ms-input-placeholder {
  color: #ccc; }

::placeholder {
  color: #ccc; }

.c--input__name {
  width: 160px; }

.c--text__cntWrap {
  display: flex;
  align-items: center; }

.c--text__cntBlock {
  display: flex;
  align-items: center; }
  .c--text__cntBlock .c--cntBlock__info {
    margin: 0 0 0 10px;
    min-width: calc(20% - 10px);
    font-size: 0.75rem; }

input[type="text"].is--over,
input[type="password"].is--over,
input[type="number"].is--over,
input[type="radio"].is--over,
select.is--over,
textarea.is--over {
  border: 2px solid #f84361; }

.c--text__print.is--over {
  color: #f84361; }

@media screen and (max-width: 767px) {
  .c--input__name {
    width: 48%; }
    .c--input__name + .c--input__name {
      margin: 0 0 0 10px; }
    .c--input__name input {
      width: 100%; }
    .c--input__name + .c--arrow--circle {
      margin: 5px 0 0; }
  .c--errorTxt {
    font-size: 0.8125rem; }
  .c--text__cntWrap {
    display: block; } }

.c--control {
  display: inline-block;
  position: relative;
  margin: 5px 15px 5px 0;
  padding-left: 26px;
  cursor: pointer;
  min-width: 100px;
  vertical-align: middle;
  /*チェックボックス、またはラジオボタンだけ*/ }
  .c--control.checkbox__only, .c--control.radio__only {
    min-width: 20px;
    padding-left: 20px;
    margin-bottom: 0; }
  .c--control input {
    position: absolute;
    z-index: -1;
    opacity: 0; }
  .c--control.c--control--l {
    margin-right: 20px; }

.c--control__indicator {
  position: absolute;
  top: 50%;
  left: 0;
  height: 18px;
  width: 18px;
  background: #fff;
  border: 1px solid #a2a2a7;
  border-radius: 2px;
  transform: translateY(-50%); }
  .c--control--radio .c--control__indicator {
    border-radius: 50%; }
  .c--control:hover input[type="checkbox"] ~ .c--control__indicator,
  .c--control input[type="checkbox"]:focus ~ .c--control__indicator,
  .c--control:hover input[type="radio"] ~ .c--control__indicator,
  .c--control input[type="radio"]:focus ~ .c--control__indicator {
    background: #f0f0f0; }
  .c--control input[type="checkbox"]:checked ~ .c--control__indicator {
    background: #00b0ff; }
  .c--control:hover input[type="checkbox"]:not([disabled]):checked ~ .c--control__indicator,
  .c--control input[type="checkbox"]:checked:focus ~ .c--control__indicator,
  .c--control:hover input[type="radio"]:not([disabled]):checked ~ .c--control__indicator,
  .c--control input[type="radio"]:checked:focus ~ .c--control__indicator {
    background: #0084bf; }
  .c--control input[type="checkbox"]:disabled ~ .c--control__indicator,
  .c--control input[type="radio"]:disabled ~ .c--control__indicator {
    background: #dadadb;
    pointer-events: none; }
  .c--control__indicator:after {
    content: '';
    position: absolute;
    display: none; }
    .c--control input[type="checkbox"]:checked ~ .c--control__indicator:after {
      display: block; }
    .c--control--checkbox .c--control__indicator:after {
      top: 50%;
      left: 50%;
      width: 7px;
      height: 10px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: translate(-50%, -70%) rotate(45deg); }
    .c--control--checkbox input[type="checkbox"]:disabled ~ .c--control__indicator:after {
      border-color: #61616a; }
    .c--control--radio .c--control__indicator:after {
      top: 50%;
      left: 50%;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background: #fff;
      transform: translate(-50%, -50%); }
    .c--control--radio input[type="checkbox"]:disabled ~ .c--control__indicator:after {
      background: #61616a; }
  .c--control input:checked ~ .c--control__indicator {
    background: #00b0ff;
    border: none; }
    .c--control input:checked ~ .c--control__indicator:after {
      display: block; }

.c--select {
  position: relative;
  display: inline-block;
  width: 100%; }
  .c--select__only {
    margin-bottom: 0; }
  .c--select.w400 {
    width: 400px; }
  .c--select.w300 {
    width: 300px; }
  .c--select.w200 {
    width: 200px; }
  .c--select.w100 {
    width: 100px; }
  .c--select select {
    display: inline-block;
    width: 100%;
    height: 45px;
    cursor: pointer;
    padding: 10px 30px 10px 10px;
    outline: 0;
    border: 2px solid #dadadb;
    border-radius: 0;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    line-height: 1.2;
    font-size: 0.875rem;
    border-radius: 6px; }
    .c--select select::-ms-expand {
      display: none; }
    .c--select select:disabled {
      opacity: .5;
      pointer-events: none; }
  .c--select__multiple {
    height: auto !important; }
  .c--select__multiple--row5 {
    height: 133px !important; }

.c--select__arrow {
  position: absolute;
  top: 50%;
  right: 15px;
  width: 8px;
  height: 8px;
  border: solid #828288;
  border-width: 0 2px 2px 0;
  transform: translateY(-50%) rotate(45deg);
  pointer-events: none; }
  .c--select select:hover ~ .c--select__arrow .c--select select:focus ~ .c--select__arrow {
    border-top-color: #333; }
  .c--select select:disabled ~ .c--select__arrow {
    border-top-color: #dadadb; }

.c--select__tcArea {
  position: relative;
  display: inline-block;
  width: auto;
  height: 37px;
  margin: 0;
  border: 2px solid #dadadb;
  border-radius: 4px;
  background: #fff;
  text-align: center;
  cursor: pointer; }
  .c--select__tcArea .c--tc__label {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 7px 30px 7px 10px;
    color: #e92d82;
    font-size: 0.8125rem;
    font-weight: bold;
    z-index: 1; }
  .c--select__tcArea .c--tc__select {
    position: relative;
    display: block;
    width: 100%;
    padding: 8px 30px 8px 10px;
    border: none;
    opacity: 0;
    appearance: none;
    cursor: pointer;
    z-index: 2;
    /* IE10以上で矢印を消す */ }
    .c--select__tcArea .c--tc__select:focus {
      z-index: -1;
      opacity: 1; }
    .c--select__tcArea .c--tc__select::-ms-expand {
      display: none; }

.c--select__arrow--pk {
  position: absolute;
  width: 18px;
  height: 100%;
  top: 0;
  right: 0;
  border-left: 1px solid #f0f0f0; }
  .c--select__arrow--pk i {
    position: absolute;
    top: 50%;
    right: 6px;
    width: 6px;
    height: 6px;
    border: solid #e92d82;
    border-width: 0 2px 2px 0;
    -webkit-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg);
    pointer-events: none; }

/** ========================================
 * @
 * フォームの枠色を変更するクラス
 * ====================================== */
/** ====================
 * 一括変換
 * ==================== */
.c--input--bgLb input,
.c--input--bgLb select,
.c--input--bgLb textarea {
  background: #e8f0fe; }

.c--input--required input,
.c--input--required textarea,
.c--input--required select,
.c--input--required .c--control__indicator {
  border: 2px solid #00b0ff; }

.c--input--error input,
.c--input--error textarea {
  background: #FFD6E4;
  color: #BDBDC1; }

.c--input--error .c--control__indicator {
  background: #FFD6E4; }

.c--input--error select {
  background: #FFD6E4; }
  .c--input--error select.no__select {
    color: #BDBDC1; }
    .c--input--error select.no__select option:not(:first-child) {
      color: #333; }

.c--input--required2 {
  color: #f84361; }
  .c--input--required2 .c--control__indicator {
    border: solid 1px #f84361;
    outline: solid 3px #fb9dab; }

.c--input--disabled {
  color: #c1c1c1; }
  .c--input--disabled:hover {
    cursor: default; }

/** ====================
 * 個々変換
 * ==================== */
input.c--input--bgLb,
select.c--input--bgLb,
textarea.c--input--bgLb {
  background: #e8f0fe; }

input.c--input--required,
textarea.c--input--required,
select.c--input--required {
  border: 2px solid #00b0ff; }

.c--control__indicator.c--input--required {
  border-color: #00b0ff; }

input.c--input--error,
textarea.c--input--error {
  background: #FFD6E4;
  color: #BDBDC1; }

.c--control__indicator.c--input--error {
  background: #FFD6E4; }

select.c--input--error {
  background: #FFD6E4; }
  select.c--input--error.no__select {
    color: #BDBDC1; }
    select.c--input--error.no__select option:not(:first-child) {
      color: #333; }

@media screen and (max-width: 767px) {
  .c--inputSp__per--100 td {
    padding: 0; }
    .c--inputSp__per--100 td .c--control {
      width: 100%;
      padding: 12px 0 12px 49px;
      margin: 0; }
      .c--inputSp__per--100 td .c--control__indicator {
        left: 20px;
        height: 16px;
        width: 16px; }
      .c--inputSp__per--100 td .c--control + .c--control {
        border-top: 1px solid #dadadb; }
    .c--inputSp__per--100 td .p--strong__wrap {
      padding: 0; }
  .c--select__multiple--row5 {
    height: 45px !important; } }

/** ========================================
 * セレクトテーブル(先頭にチェックボックスがあるテーブル)
 * ====================================== */
.selectTable__w__per--5 {
  width: 5% !important; }

.selectTable__w__per--10 {
  width: 10% !important; }

.selectTable__w__per--15 {
  width: 15% !important; }

.selectTable__w__per--20 {
  width: 20% !important; }

.selectTable__w__per--25 {
  width: 25% !important; }

.selectTable__w__per--30 {
  width: 30% !important; }

.selectTable__w__per--35 {
  width: 35% !important; }

.selectTable__w__per--40 {
  width: 40% !important; }

.selectTable__w__per--45 {
  width: 45% !important; }

.selectTable__w__per--50 {
  width: 50% !important; }

.selectTable__w__per--55 {
  width: 55% !important; }

.selectTable__w__per--60 {
  width: 60% !important; }

.selectTable__w__per--65 {
  width: 65% !important; }

.selectTable__w__per--70 {
  width: 70% !important; }

.selectTable__w__per--75 {
  width: 75% !important; }

.selectTable__w__per--80 {
  width: 80% !important; }

.selectTable__w__per--85 {
  width: 85% !important; }

.selectTable__w__per--90 {
  width: 90% !important; }

.selectTable__w__per--95 {
  width: 95% !important; }

.selectTable__w__per--100 {
  width: 100% !important; }

/** ========================================
 * エラー
 * ====================================== */
.c--error__list {
  margin: 0 0 20px;
  color: #f84361; }
  .c--error__list li {
    color: #f84361; }
    .c--error__list li:before {
      content: "・ "; }

.c--error__txt {
  color: #f84361;
  margin-top: 10px; }

/** ========================================
 * パスワード強度
 * ====================================== */
.p--pass_strength {
  display: flex;
  align-items: center;
  margin: 7px 0 0;
  font-size: 0.75rem; }
  .p--pass_strength span {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 0 0 7px;
    background: #dadadb; }
    .p--pass_strength span.active:nth-child(1) {
      background: #f84361; }
    .p--pass_strength span.active:nth-child(2) {
      background: #f84361; }
    .p--pass_strength span.active:nth-child(3) {
      background: #f84361; }
  .p--pass_strength i {
    margin-left: .5em; }

/** ========================================
 * @
 * jQuery UI Datepicker
 * ====================================== */
input[type=text].c--ui__date {
  padding-right: 40px;
  background: url(../images/icon_calendar.png) right 7px center no-repeat !important;
  background-size: 23px !important;
  font-size: .9rem; }
  input[type=text].c--ui__date:focus {
    background-color: #e2f8ff !important; }
  input[type=text].c--ui__date.error {
    background: url(../images/icon_calendar.png) right 7px center no-repeat #fcdcdb !important; }

.ui-widget-header {
  border: none !important;
  background: none !important; }

.ui-state-default {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 30px !important;
  height: 30px !important; }

.ui-datepicker-week-end span[title=日曜日],
.ui--sunday .ui-state-default,
.ui--sunday,
.ui--holiday {
  color: #f84361 !important; }

.ui-datepicker-week-end span[title=土曜日],
.ui--saturday .ui-state-default,
.ui--saturday {
  color: #00abeb !important; }

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
  border: none !important;
  background: none !important;
  color: inherit !important; }

.ui-widget-content .ui-datepicker-today span.ui-state-default,
.ui-widget-content .ui-datepicker-today a.ui-state-default {
  background: #ccc !important;
  border-radius: 50%;
  color: #fff !important; }

.ui-datepicker .ui-state-active,
.ui-datepicker .ui-widget-content .ui-state-active {
  border: none !important;
  border-radius: 50%;
  background: #e92d82 !important;
  color: #fff !important; }

.ui-datepicker .ui-datepicker-title {
  display: flex;
  justify-content: center;
  align-content: center; }

select.ui-datepicker-month {
  border: none !important; }

.ui-datepicker th {
  font-weight: normal !important; }

.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year {
  width: 40% !important;
  font-weight: bold; }

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
  display: inline-block; }

.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
  position: absolute;
  background: transparent;
  top: 50%;
  width: 11px;
  height: 11px;
  margin-top: -4px !important;
  margin-left: -6px !important;
  border: solid #333;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg); }

.ui-datepicker .ui-datepicker-next span {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.ui-datepicker .ui-datepicker-next {
  right: 1px !important;
  top: 1px !important; }

.ui-datepicker .ui-datepicker-prev {
  left: 1px !important;
  top: 1px !important; }

/** ========================================
 * @
 * パスワード
 * ====================================== */
/** ========================================
 * パスワードの目
 * ====================================== */
.hideShowPassword-toggle {
  background-color: transparent;
  background-image: url(../images/wink.png);
  /* fallback */
  background-image: url(../images/wink.svg), none;
  background-position: 0 center;
  background-repeat: no-repeat;
  border: 2px solid transparent;
  border-radius: 0.25em;
  cursor: pointer;
  font-size: 100%;
  height: 44px;
  margin: 0;
  max-height: 100%;
  padding: 0;
  overflow: 'hidden';
  text-indent: -999em;
  width: 46px;
  -moz-appearance: none;
  -webkit-appearance: none; }

.hideShowPassword-toggle-hide {
  background-position: -44px center; }

.hideShowPassword-toggle:hover,
.hideShowPassword-toggle:focus {
  outline: transparent; }

::-ms-clear {
  display: none; }

::-ms-reveal {
  display: none; }

/** ========================================
 * @
 * // コンテンツ
 * ====================================== */
main {
  min-width: 1200px;
  min-height: calc(100vh - 103px - 64px);
  background: #fff7f6; }

.c--contents {
  background: #fff7f6;
  padding: 25px 0; }
  .c--contents--noBg {
    background: #fff;
    padding: 25px 0; }
  .c--contents__inner {
    width: 1200px;
    margin: 0 auto; }
  .c--contents--lastYear {
    background: #ececec; }
  .c--contents--nextYear {
    background: #ffd; }

@media screen and (max-width: 767px) {
  main {
    min-width: auto;
    min-height: calc(100vh - 450px); }
  .c--contents {
    padding-bottom: 0; }
  .c--contents__inner {
    width: 100%; } }

/** ========================================
 * @
 * // 線関係
 * ====================================== */
.c--dot__line {
  height: 3px;
  position: relative; }

.c--dot__line::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(to right, #dadadb, #dadadb 3px, transparent 3px, transparent 12px);
  background-size: 12px 3px;
  background-repeat: repeat-x; }

/** ========================================
 * @
 * // 矢印
 * ====================================== */
.c--arrow {
  position: relative; }
  .c--arrow:before {
    content: "";
    position: absolute;
    top: 49%;
    width: 7px;
    height: 7px;
    border: solid #e92d82;
    border-width: 0 2px 2px 0;
    z-index: 10; }
  .c--arrow--up:before {
    transform: translateY(-50%) rotate(-135deg);
    top: 50%; }
  .c--arrow--down:before {
    transform: translateY(-50%) rotate(45deg); }
  .c--arrow--right:before {
    transform: translateY(-50%) rotate(-45deg); }
  .c--arrow--left:before {
    transform: translateY(-50%) rotate(135deg);
    top: 50%; }
  .c--arrow--circle {
    position: relative;
    display: inline-block;
    padding: 0 0 0 20px; }
    .c--arrow--circle:before {
      content: "";
      position: absolute;
      display: inline-block;
      top: 50%;
      left: 0;
      width: 16px;
      height: 16px;
      background-size: contain;
      transform: translateY(-50%);
      background: url(../images/arrow_circle_r.png) 50%/16px no-repeat; }
    .c--arrow--circle--down:before {
      transform: translateY(-50%) rotate(90deg); }
  .c--arrow--p__left {
    padding: 0 0 0 14px; }
    .c--arrow--p__left:before {
      left: 0; }
  .c--arrow--p__right {
    padding: 0 14px 0 0; }
    .c--arrow--p__right:before {
      right: 1px; }
  .c--arrow__box:before {
    top: auto;
    right: 10px;
    bottom: 4px; }

/** ========================================
 * @
 * // 編集アイコン付きリンク
 * ====================================== */
.c--link__edit {
  position: relative;
  padding: 0 0 0 22px;
  font-size: 0.8125rem;
  font-weight: normal; }
  .c--link__edit:before {
    content: "";
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 0;
    width: 16px;
    height: 16px;
    background-size: contain;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: url(../images/icon_pencil.png) 50%/16px no-repeat; }

/** ========================================
 * @
 * // ホバーでシャドウが付く
 * ====================================== */
.c--hoverShadow__item {
  display: inline-block;
  background: #fff; }

.c--hoverShadow--border .c--hoverShadow__item {
  border: 1px solid #dadadb; }

.c--hoverShadow--shadow .c--hoverShadow__item {
  box-shadow: 0 2px 2px rgba(97, 97, 106, 0.2), 0 1px 5px rgba(97, 97, 106, 0.1); }

@media screen and (min-width: 768px) {
  /** ========================================
  * @
  * // ホバーでシャドウが付く
  * ====================================== */
  .c--hoverShadow__item:hover, .p--schoolCards .p--item:hover {
    cursor: pointer;
    opacity: 1;
    box-shadow: 4px 4px 8px rgba(97, 97, 106, 0.2), 1px 1px 20px rgba(97, 97, 106, 0.2);
    transition: all .25s;
    z-index: 5; }
  .c--hoverShadow--border .c--hoverShadow__item:hover, .c--hoverShadow--border .p--schoolCards .p--item:hover, .p--schoolCards .c--hoverShadow--border .p--item:hover {
    border-color: transparent; }
  .c--hoverShadow--shadow .c--hoverShadow__item:hover, .c--hoverShadow--shadow .p--schoolCards .p--item:hover, .p--schoolCards .c--hoverShadow--shadow .p--item:hover {
    box-shadow: 0 4px 8px rgba(97, 97, 106, 0.2), 0 1px 20px rgba(97, 97, 106, 0.2); } }

/** ========================================
 * @
 * // 要素の装飾
 * ====================================== */
.c--boxWht {
  background: #fff; }

.c--boxlp {
  background: #fff0f5; }

.c--boxBdg {
  border: 1px solid #dadadb; }
  .c--boxBdg--pc {
    border: 1px solid #dadadb; }

@media screen and (max-width: 767px) {
  .c--boxBdg--pc {
    border: none; }
  .c--boxBdg--sp {
    border: 1px solid #dadadb; }
  .c--boxBdg--spNolr {
    border-right: none;
    border-left: none; } }

.c--boxShadow {
  box-shadow: 0 2px 2px rgba(97, 97, 106, 0.2), 0 1px 5px rgba(97, 97, 106, 0.1); }

.c--bdt--p {
  border-top: 2px solid #e92d82; }

.c--bdt--lb {
  border-top: 2px solid #40c4ff; }

.c--bdt--g {
  border-top: 1px solid #dadadb; }

/** ========================================
 * @
 * // 数字バッジ
 * ====================================== */
.c--notice__num {
  display: inline-block;
  min-width: 20px;
  height: 20px;
  margin-left: 5px;
  padding: 0 5px;
  border-radius: 100px;
  background: #f84361;
  color: #fff;
  font-size: .6rem;
  font-weight: 400;
  text-align: center;
  line-height: 18px; }

/** ========================================
 * @
 * // 角丸長方形
 * ====================================== */
.c--oval--pk {
  display: inline-block;
  border: 1px solid #e92d82;
  border-radius: 100px;
  color: #e92d82; }

.c--oval--m {
  padding: 4px 13px;
  font-size: .7rem;
  text-align: center; }

@media screen and (max-width: 767px) {
  .c--oval--m {
    padding: 2px; } }

/** ========================================
 * @
 * // 画像付きリスト
 * ====================================== */
.c--card {
  display: flex;
  width: 100%; }
  .c--card--col3 .c--card__item {
    width: calc((100% - 48px) / 3); }
    .c--card--col3 .c--card__item:nth-child(3n) {
      margin: 0; }
  .c--card--col4 .c--card__item {
    width: calc((100% - 72px) / 4); }
    .c--card--col4 .c--card__item:nth-child(4n) {
      margin: 0; }
  .c--card a {
    display: flex;
    flex-direction: column;
    height: 100%; }
  .c--card__item {
    display: block;
    margin: 0 24px 0 0;
    padding: 0 0 20px;
    white-space: normal;
    border-bottom: 1px solid #f0f0f0; }
    .c--card__item a:hover {
      color: #e92d82; }
      .c--card__item a:hover .c--item__txt {
        color: #333; }
    .c--card__item.c--arrow__box:before {
      right: 5px; }
    .c--card__item .c--item__img {
      position: relative; }
      .c--card__item .c--item__img img {
        height: 171px; }
      .c--card__item .c--item__img .c--list__tag {
        position: absolute;
        left: 4px;
        top: 4px; }
    .c--card__item .c--item__ttl {
      flex-grow: 1;
      padding: 15px 15px 0;
      font-size: 1rem;
      font-weight: bold; }
      .c--card__item .c--item__ttl .c--item__catchcopy {
        overflow: hidden;
        width: 100%;
        font-size: 0.75rem;
        font-weight: normal;
        text-overflow: ellipsis;
        white-space: nowrap; }
    .c--card__item .c--item__txt {
      display: flex;
      flex-wrap: wrap;
      margin: 12px 0 0;
      padding: 0 15px;
      font-size: 0.75rem;
      font-weight: normal; }
      .c--card__item .c--item__txt span {
        display: inline-block;
        width: 50%; }
        .c--card__item .c--item__txt span:nth-child(n + 3) {
          margin: 5px 0 0; }
        .c--card__item .c--item__txt span i {
          color: #828288; }

.c--list__tag {
  display: inline-block;
  padding: 3px 7px;
  border: 1px solid #e92d82;
  border-radius: 100px;
  background: #fff;
  color: #e92d82;
  font-size: 0.75rem; }

@media screen and (max-width: 767px) {
  .c--list__tag {
    padding: 1px 7px; }
  .c--card__item {
    padding: 0 0 25px; }
    .c--card__item .c--item__img .c--list__tag {
      position: relative;
      left: 0;
      top: 0;
      margin: 7px 0 0; }
    .c--card__item .c--item__ttl {
      padding: 7px 0 0;
      font-size: 0.75rem; }
  .c--card--spCol1 {
    display: block; }
    .c--card--spCol1 .c--card__item {
      width: 100%; }
  .c--card--spCol2 {
    flex-wrap: wrap; }
    .c--card--spCol2 .c--card__item {
      width: calc(50% - 8px);
      margin: 0 16px 0 0; }
      .c--card--spCol2 .c--card__item:nth-child(even) {
        margin-right: 0; }
      .c--card--spCol2 .c--card__item:nth-child(n + 3) {
        margin-top: 17px; }
      .c--card--spCol2 .c--card__item .c--item__img .c--list__tag {
        margin: 8px 0 0; }
      .c--card--spCol2 .c--card__item .c--item__img img {
        height: 103px; }
  .c--card--scrl {
    overflow: hidden;
    overflow-x: auto;
    padding: 0 0 10px 20px; }
    .c--card--scrl .c--card__item {
      min-width: 187px; }
      .c--card--scrl .c--card__item .c--item__img img {
        height: 120px; }
      .c--card--scrl .c--card__item .c--item__ttl {
        padding: 18px 12px 0; }
      .c--card--scrl .c--card__item .c--item__txt {
        display: block; }
        .c--card--scrl .c--card__item .c--item__txt span {
          width: 100%; }
          .c--card--scrl .c--card__item .c--item__txt span:not(:first-child) {
            margin: 6px 0 0; } }

/** ========================================
 * @
 * // 背景グレーの画像が乗ったアイコン
 * ====================================== */
.p--btn {
  position: relative;
  display: inline-block;
  text-align: center;
  font-size: 1rem;
  font-weight: bold; }
  .p--btn .p--btn__icon {
    position: relative; }
    .p--btn .p--btn__icon:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      content: "";
      display: block;
      background-size: contain; }
    .p--btn .p--btn__icon:before {
      content: "";
      background-color: #f5f5f5;
      border-radius: 50%;
      display: block;
      margin: 0 auto 7px; }

/** ========================================
 * @
 * // トグル関係
 * ====================================== */
@media screen and (max-width: 767px) {
  .js--tglSwitch--sp {
    position: relative; }
    .js--tglSwitch--sp:after {
      content: "";
      position: absolute;
      top: 48%;
      right: 17px;
      width: 7px;
      height: 7px;
      border: solid #e92d82;
      border-width: 0 2px 2px 0;
      z-index: 10;
      transform: translateY(-50%) rotate(45deg); }
    .js--tglSwitch--sp.c--tglSwitch--open:after {
      transform: translateY(-50%) rotate(-135deg); }
  .c--spNoTgl:before {
    background: none !important; } }

/** ========================================
 * @
 * // 画像
 * ====================================== */
.c--cover {
  object-fit: cover;
  font-family: "object-fit: cover;"; }

.c--contain {
  object-fit: contain;
  font-family: 'object-fit: contain;'; }

/** ========================================
 * @c--impNotices
 * お知らせ関係
 * ====================================== */
.c--impNotices {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #f84361;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2), 0 1px 5px rgba(0, 0, 0, 0.1); }

.c--impNotices__heading {
  position: relative;
  width: 134px;
  padding: 0 0 0 32px;
  color: #fff;
  font-size: .8rem; }
  .c--impNotices__heading:before {
    content: "";
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 12px;
    width: 16px;
    height: 16px;
    background-size: contain;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: url(../images/icon_imp.png) 50%/16px no-repeat; }

.c--notices__list {
  width: 100%;
  background: #fff; }
  .c--notices__list .c--list__item--new .c--item__txt:after {
    content: 'NEW';
    display: inline-block;
    color: #f84361;
    font-weight: bold; }
  .c--notices__list .c--list__item:not(:first-child) {
    border-top: 1px solid #f0f0f0; }
  .c--notices__list .c--list__item a, .c--notices__list .c--list__item div {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 25px 11px 20px;
    font-size: 0.8125rem; }
    .c--notices__list .c--list__item a:hover, .c--notices__list .c--list__item div:hover {
      color: #e92d82; }
    .c--notices__list .c--list__item a .c--oval--m, .c--notices__list .c--list__item div .c--oval--m {
      margin: 0 13px 0 0;
      width: 120px; }
    .c--notices__list .c--list__item a.c--arrow--p__right:before, .c--notices__list .c--list__item div.c--arrow--p__right:before {
      right: 10px; }
  .c--notices__list .c--list__item .c--item__txt {
    width: calc(100% - 120px); }

@media screen and (max-width: 767px) {
  .c--impNotices {
    display: block;
    border-radius: unset; }
    .c--impNotices__heading {
      width: calc(100% - 20px);
      padding: 5px 34px;
      margin: 0 10px; }
  .c--notices__list .c--list__item a, .c--notices__list .c--list__item div {
    display: flex;
    align-items: flex-start;
    padding: 7px 20px 11px;
    font-size: 0.75rem; }
    .c--notices__list .c--list__item a .c--oval--m, .c--notices__list .c--list__item div .c--oval--m {
      width: 104px;
      margin: 8px 8px 0 0; }
  .c--notices__list .c--list__item .c--item__txt {
    width: calc(100% - 112px); } }

/** ========================================
 * @c--recommended
 * オススメPR
 * ====================================== */
.c--recommended {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #e92d82;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2), 0 1px 5px rgba(0, 0, 0, 0.1); }

.c--recommended__heading {
  position: relative;
  width: 134px;
  padding: 0 0 0 10px;
  color: #fff;
  font-size: .8rem; }

@media screen and (max-width: 767px) {
  .c--recommended {
    display: block;
    border-radius: unset; }
    .c--recommended__heading {
      width: 100%;
      padding: 5px 20px; }
    .c--recommended .c--notices__list .c--list__item .c--item__txt {
      width: 100%; } }

/** ========================================
 * @
 * // お知らせポップアップ
 * ====================================== */
.c--message {
  transform: translateX(-50%);
  position: fixed;
  top: 90px;
  left: 50%;
  min-width: 300px;
  padding: 30px 10px 22px;
  border-radius: 10px;
  background-color: #ffe4ed;
  background-image: url(../images/point_border_top.png), url(../images/point_border_bottom.png);
  background-position: center top, center bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: auto, auto;
  box-shadow: 1px 0 20px rgba(97, 97, 106, 0.2), 4px 0px 8px rgba(97, 97, 106, 0.2);
  text-align: center;
  z-index: 1000; }
  .c--message__heading {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e92d82;
    font-size: 1.25rem;
    font-weight: bold; }
    .c--message__heading:before {
      content: "";
      display: inline-block;
      width: 50px;
      height: 43px;
      background: url(../images/point_icon.png) 50% no-repeat;
      background-size: 50px; }
  .c--message__txt {
    margin: 10px 0 15px;
    font-size: 0.8125rem;
    line-height: 1.7; }
  .c--message__atten {
    margin: 10px 0 0;
    font-size: 0.625rem;
    line-height: 1.6; }
  .c--message .c--btn--m {
    padding: 11px 20px; }

.c--contents__flow {
  display: flex;
  justify-content: center;
  margin-bottom: 40px; }
  .c--contents__flow .c--flow__item {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 200px;
    position: relative; }
    .c--contents__flow .c--flow__item .c--item__num {
      color: #333;
      display: inline-block;
      width: 27px;
      height: 27px;
      border-radius: 50%;
      border: 1px solid #dadadb;
      background: #fff;
      text-align: center;
      line-height: 27px;
      vertical-align: middle; }
    .c--contents__flow .c--flow__item .c--item__txt {
      width: 100%;
      margin-top: 5px;
      text-align: center; }
    .c--contents__flow .c--flow__item .c--item__num {
      position: relative; }
    .c--contents__flow .c--flow__item--active .c--item__num {
      color: #fff;
      background: #e92d82;
      border: 1px solid #e92d82; }
    .c--contents__flow .c--flow__item + .c--flow__item .c--item__num::before {
      content: "";
      width: 173px;
      height: 4px;
      background: #eceff0;
      position: absolute;
      top: 50%;
      right: 26px;
      transform: translateY(-50%); }
    .c--contents__flow .c--flow__item--active + .c--flow__item.c--flow__item--active .c--item__num::before {
      background: #e92d82; }

@media screen and (max-width: 768px) {
  .c--contents__flow .c--flow__item {
    width: calc(100vw / 6); }
  .c--contents__flow .c--flow__item + .c--flow__item .c--item__num::before {
    width: calc(100vw / 6 - 27px); } }

@media screen and (max-width: 480px) {
  .c--contents__flow .c--flow__item .c--item__txt {
    display: none; } }

/*必須ラベルアイコン*/
.c--require__label {
  min-width: 26px;
  margin: 0 5px;
  color: #fff;
  font-weight: 400;
  font-size: 0.625rem;
  background: #f84361;
  padding: 3px;
  line-height: 1;
  display: inline-block; }

.c--col__btn2 .c--btn + .c--btn {
  margin-left: 10px; }

.c--col__btn--230 .c--btn {
  width: 230px; }
  .c--col__btn--230 .c--btn + .c--btn {
    margin-left: 10px; }

@media screen and (max-width: 768px) {
  .c--col__btn--230 .c--btn {
    width: 150px;
    padding: 18px 10px; } }

/** ========================================
 * @
 * 左側にメニューのある２カラム
 * ====================================== */
.c--contHeading--gr {
  padding: 8px 20px;
  border-radius: 4px 4px 0 0;
  background: #828288;
  color: #fff;
  font-size: 1rem;
  font-weight: bold; }

.c--contHeading--gr.c--contHeading--form {
  margin: 50px 0 0; }

div + .c--contHeading--gr {
  margin: 20px 0 0; }

.c--menuBlock {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 40px 0 0; }
  .c--menuBlock .c--menu__side {
    width: 283px;
    box-shadow: 2px 2px 2px rgba(97, 97, 106, 0.2), 1px 1px 5px rgba(97, 97, 106, 0.1); }
    .c--menuBlock .c--menu__side--noShadow {
      box-shadow: none; }
      .c--menuBlock .c--menu__side--noShadow .c--side__inner {
        box-shadow: 2px 2px 2px rgba(97, 97, 106, 0.2), 1px 1px 5px rgba(97, 97, 106, 0.1); }
        .c--menuBlock .c--menu__side--noShadow .c--side__inner + .c--side__inner {
          margin: 20px 0 0; }
  .c--menuBlock .c--menu__main {
    width: 894px; }
  .c--menuBlock .c--menu__setting {
    padding: 15px;
    border-top: 1px solid #f0f0f0;
    border-radius: 0 0 4px 4px;
    background: #fff; }
    .c--menuBlock .c--menu__setting li:not(:first-child) {
      margin: 10px 0 0; }
  .c--menuBlock--col1 {
    display: block; }
    .c--menuBlock--col1 .c--menu__main {
      width: 100%;
      margin: 0; }
  .c--menuBlock .c--point__main {
    padding: 23px 20px;
    background: #fff; }
  .c--menuBlock .c--point__txt {
    text-align: center; }
    .c--menuBlock .c--point__txt i {
      display: inline-block;
      margin: 0 3px;
      color: #e92d82;
      font-size: 2rem;
      font-weight: bold; }
  .c--menuBlock .c--point__link {
    margin: 20px 0 0; }
  .c--menuBlock .c--search__main {
    padding: 16px 20px;
    background: #fff;
    border-radius: 0 0 4px 4px; }
    .c--menuBlock .c--search__main--nopd {
      padding: 0; }
    .c--menuBlock .c--search__main .c--main__item {
      padding: 16px 20px; }
      .c--menuBlock .c--search__main .c--main__item:not(:first-child) {
        border-top: 1px solid #dadadb; }
  .c--menuBlock .c--link__item {
    border-top: 1px solid #f0f0f0; }
    .c--menuBlock .c--link__item a {
      position: relative;
      display: inline-block;
      width: 100%;
      padding: 17px 20px 17px 42px;
      background: #fff;
      line-height: 1;
      cursor: pointer; }
      .c--menuBlock .c--link__item a.c--arrow--p__right:before {
        right: 20px; }
      .c--menuBlock .c--link__item a:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        background-size: contain !important;
        background-repeat: no-repeat; }
    .c--menuBlock .c--link__item--fav a:after {
      background-image: url(../images/icon_fav.png); }
    .c--menuBlock .c--link__item--comparison a:after {
      background-image: url(../images/icon_graph.png); }
    .c--menuBlock .c--link__item--msg a:after {
      background-image: url(../images/icon_msg.png); }
    .c--menuBlock .c--link__item--scout a:after {
      background-image: url(../images/icon_scout.png); }
    .c--menuBlock .c--link__item--history a:after {
      background-image: url(../images/icon_history.png); }
    .c--menuBlock .c--link__item--setting a:after {
      background-image: url(../images/icon_setting.png); }
  .c--menuBlock .c--link__item--active a {
    background: #e92d82;
    color: #fff; }
    .c--menuBlock .c--link__item--active a.c--arrow:before {
      border-color: #fff; }
  .c--menuBlock .c--link__item--active .c--notice__num {
    background: #fff;
    color: #f84361; }
  .c--menuBlock .c--link__item--active.c--link__item--fav a:after {
    background-image: url(../images/icon_fav-w.png); }
  .c--menuBlock .c--link__item--active.c--link__item--comparison a:after {
    background-image: url(../images/icon_graph-w.png); }
  .c--menuBlock .c--link__item--active.c--link__item--msg a:after {
    background-image: url(../images/icon_msg-w.png); }
  .c--menuBlock .c--link__item--active.c--link__item--scout a:after {
    background-image: url(../images/icon_scout-w.png); }
  .c--menuBlock .c--link__item--active.c--link__item--history a:after {
    background-image: url(../images/icon_history-w.png); }
  .c--menuBlock .c--link__item--active.c--link__item--setting a:after {
    background-image: url(../images/icon_setting-w.png); }

@media screen and (max-width: 767px) {
  .c--contHeading--gr {
    padding: 5px 20px;
    border-radius: 0;
    font-size: 0.8125rem; }
  .c--contHeading--gr.c--contHeading--form {
    margin: 0; }
  .c--menuBlock {
    display: block;
    margin: 20px 0 0; }
    .c--menuBlock .c--menu__side {
      width: 100%; }
      .c--menuBlock .c--menu__side:not(.c--pc__only) {
        margin: 25px 0; }
    .c--menuBlock .c--menu__main {
      width: 100%;
      margin: 0; }
  .c--fav__setting {
    background: #f0f0f0; }
    .c--fav__setting .c--setting {
      padding: 0 20px; }
      .c--fav__setting .c--setting li:not(:first-child) {
        margin: 10px 0 0; }
      .c--fav__setting .c--setting li a {
        font-size: 1rem;
        font-weight: bold; } }

/** ========================================
 * @
 * // マップ付き都道府県チェックボックスエリア
 * ====================================== */
.c--areaWrap .c--control {
  width: 100%;
  margin: 0;
  padding: 5px 0 5px 26px; }
  .c--areaWrap .c--control:hover, .c--areaWrap .c--control:focus {
    color: #e92d82; }

.c--areaWrap .c--main__map {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  height: 680px;
  padding: 0;
  background-size: 100%;
  background-image: url(../images/map.png);
  background-repeat: no-repeat;
  background-position: top; }

.c--areaWrap .c--area {
  display: flex;
  flex-direction: column;
  width: calc(25% - 18px); }
  .c--areaWrap .c--area--col3 {
    position: relative;
    width: calc(50% - 12px); }
    .c--areaWrap .c--area--col3 .c--area__item {
      position: absolute;
      width: calc(50% - 12px); }
      .c--areaWrap .c--area--col3 .c--area__item--pref04 {
        left: 0;
        top: 0; }
      .c--areaWrap .c--area--col3 .c--area__item--pref05 {
        right: 0;
        bottom: 36px; }
        .c--areaWrap .c--area--col3 .c--area__item--pref05.c--area__item--details__pref05 {
          bottom: 24px; }
      .c--areaWrap .c--area--col3 .c--area__item--pref06 {
        left: 0;
        bottom: 0; }
  .c--areaWrap .c--area--justEnd {
    justify-content: flex-end; }
    .c--areaWrap .c--area--justEnd .c--area__item {
      margin: 0 0 36px !important; }
  .c--areaWrap .c--area--between {
    justify-content: space-between; }
  .c--areaWrap .c--area:last-child .c--area__item:last-child {
    margin: 45px 0 0 !important; }
  .c--areaWrap .c--area__item {
    width: 100%;
    padding: 0;
    border: 1px solid #dadadb;
    border-radius: 4px;
    background: #fff; }
    .c--areaWrap .c--area__item:nth-child(n + 2) {
      margin: 25px 0 0; }
    .c--areaWrap .c--area__item .c--control__indicator {
      width: 16px;
      height: 16px; }
  .c--areaWrap .c--area .c--item__heading {
    width: 100%;
    font-size: 1rem; }
    .c--areaWrap .c--area .c--item__heading .c--control {
      width: 100%;
      margin: 0;
      padding: 13px 20px 13px 46px; }
    .c--areaWrap .c--area .c--item__heading .c--control__indicator {
      width: 16px;
      height: 16px;
      left: 16px; }
  .c--areaWrap .c--area .c--item__body {
    display: flex;
    flex-wrap: wrap;
    margin: 0 7px;
    padding: 9px 4px 12px;
    border-top: 1px solid #f0f0f0;
    font-size: 0.8125rem; }
    .c--areaWrap .c--area .c--item__body li {
      width: 50%;
      padding: 0 0 0 8px; }
      .c--areaWrap .c--area .c--item__body li:nth-child(n + 3) {
        margin: 7px 0 0; }
    .c--areaWrap .c--area .c--item__body .c--hoverShadow__item {
      border-radius: 4px; }

@media screen and (max-width: 767px) {
  .c--areaWrap .c--control input {
    font-size: 0.8125rem; }
  .c--areaWrap .c--hoverShadow__item:hover, .c--areaWrap .p--schoolCards .p--item:hover, .p--schoolCards .c--areaWrap .p--item:hover {
    box-shadow: none; }
  .c--areaWrap .c--area__item--pref01 .c--item__heading:after, .c--areaWrap .c--area__item--pref01 .c--item__heading:before {
    display: none; }
  .c--areaWrap .c--main__map {
    flex-direction: column;
    height: auto;
    background: none; }
  .c--areaWrap .c--area {
    width: 100%;
    display: block; }
    .c--areaWrap .c--area--col3 {
      width: 100%; }
      .c--areaWrap .c--area--col3 .c--area__item {
        position: unset;
        width: 100%; }
    .c--areaWrap .c--area__item {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: unset; }
      .c--areaWrap .c--area__item:nth-child(n+2) {
        margin: 0; }
    .c--areaWrap .c--area:first-child .c--area__item:first-child {
      border-top: 1px solid #dadadb; }
    .c--areaWrap .c--area--justEnd .c--area__item,
    .c--areaWrap .c--area:last-child .c--area__item:last-child {
      margin: 0 !important; }
    .c--areaWrap .c--area .c--item__heading {
      padding: 11px 20px;
      font-size: 0.875rem; }
      .c--areaWrap .c--area .c--item__heading .c--control {
        padding: 0 0 0 26px;
        width: auto; }
        .c--areaWrap .c--area .c--item__heading .c--control__indicator {
          left: 0; }
    .c--areaWrap .c--area .c--item__heading {
      position: relative; }
      .c--areaWrap .c--area .c--item__heading:before {
        content: "";
        position: absolute;
        top: 0;
        right: 45px;
        width: 1px;
        height: 100%;
        background: #dadadb; }
      .c--areaWrap .c--area .c--item__heading:after {
        right: 17px; }
    .c--areaWrap .c--area .c--item__body {
      padding: 0 0 0 38px;
      border-top: none;
      border-top: 1px solid #dadadb; }
      .c--areaWrap .c--area .c--item__body li {
        width: 100%;
        margin: 0 !important;
        padding: 8px 0; }
        .c--areaWrap .c--area .c--item__body li:nth-child(n + 2) {
          border-top: 1px solid #dadadb; }
      .c--areaWrap .c--area .c--item__body .c--hoverShadow__item {
        border-radius: unset; } }

/** ========================================
 * @
 * 楕円形タグ
 * ====================================== */
.c--ovalTag {
  display: inline-block;
  width: 128px;
  background: #fff;
  margin: 0 5px 5px 0;
  padding: 1px 0;
  border: 1px solid;
  border-radius: 100px;
  font-size: 0.75rem;
  text-align: center; }
  .c--ovalTag--gr {
    border-color: #2CB200;
    color: #2CB200; }
  .c--ovalTag--pl {
    border-color: #9979DF;
    color: #9979DF; }
  .c--ovalTag--bl {
    border-color: #40C4FF;
    color: #40C4FF; }
  .c--ovalTag--og {
    border-color: #FF7F00;
    color: #FF7F00; }
  .c--ovalTag--pk {
    border-color: #E4267E;
    color: #E4267E; }
  .c--ovalTag--yl {
    border-color: #ffcc00;
    color: #D3A900; }
    .c--ovalTag--yl.c--ovalTag--fill {
      border-color: #FFF79D;
      background: #FFF79D;
      color: #333; }
  .c--ovalTag--gy {
    border-color: #828288;
    color: #828288; }
    .c--ovalTag--gy.c--ovalTag--fill {
      border-color: #c5c5c6;
      background: #c5c5c6;
      color: #808084; }
  .c--ovalTag--w__auto {
    width: auto;
    padding: 4px 15px; }
  .c--ovalTag--sml {
    width: 80px;
    font-size: 0.625rem; }

.c--taggedBlock .c--boxHeading {
  margin-right: 10px; }

@media screen and (max-width: 767px) {
  .c--ovalTag--w__auto {
    margin: 4px;
    padding: 1px 8px;
    font-size: .625rem; } }

.c--textCol1 {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap; }

.c--link__pdf {
  position: relative;
  display: inline-block;
  margin: 10px 0 0;
  padding: 0 0 0 26px;
  color: #e92d82;
  font-size: 0.8125rem;
  font-weight: normal; }
  .c--link__pdf:before {
    content: "";
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 0;
    width: 18px;
    height: 23px;
    background-size: contain;
    transform: translateY(-50%);
    background: url(../images/icon_pdf.png) no-repeat; }

.c--tgl__block--pk .c--tgl__head {
  padding: 10px 15px;
  background: #FFEFF4;
  font-weight: bold; }
  .c--tgl__block--pk .c--tgl__head.c--arrow--p__right:before {
    right: 17px;
    width: 6px;
    height: 6px; }
  .c--tgl__block--pk .c--tgl__head.c--arrow.p--tgl--open:before {
    transform: translateY(-50%) rotate(-135deg); }

.c--tgl__head:hover {
  cursor: pointer; }

/** ========================================
 * jQuery UI autocompleteの上書き
 * @SENI-001
 * 2020.07.06
 * ====================================== */
.ui-autocomplete .ui-state-active,
.ui-autocomplete .ui-widget-content .ui-state-active {
  border: none !important;
  background: #ccc !important; }

/*! 
 * ボタン
 */
/** ========================================
 * ボタン
 * ====================================== */
/*ボタン基本*/
.c--btn {
  display: inline-block;
  padding: 18px 22px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
  color: #555;
  text-align: center;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1); }
  .c--btnArea--col3 .c--btn + .c--btn {
    margin: 0 0 0 10px; }
  .c--btnArea__formBox .c--btn--back {
    min-width: 86px !important; }
  .c--btnArea__formBox .c--btn--next {
    width: 296px !important;
    margin: 0 0 0 10px; }
  .c--btn--input {
    display: inline-block;
    width: calc(100% - 360px);
    height: 45px;
    padding: 14px 20px;
    border: none;
    background: #40c4ff;
    color: #fff;
    font-size: .875rem;
    font-weight: bold;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1); }
  .c--btn--noTxtShadow {
    text-shadow: none; }
  .c--btn--w {
    border: 1px solid #dadadb; }
  .c--btn--g {
    background: #828288;
    border: 1px solid #828288;
    color: #fff; }
    .c--btn--g.c--btn--cancel {
      padding: 7px 9px;
      font-size: 0.625rem;
      font-weight: normal; }
      .c--btn--g.c--btn--canceled {
        padding: 7px 9px;
        background: #dadadb;
        border-color: #dadadb;
        color: #828288;
        font-size: 0.625rem;
        font-weight: normal; }
  .c--btn--pk {
    background: #ff80ab;
    border: 1px solid #ff80ab;
    color: #fff; }
  .c--btn--lb {
    background: #40c4ff;
    border: 1px solid #40c4ff;
    color: #fff; }
  .c--btn--disabled {
    border-color: #f0f0f0;
    background: #f0f0f0;
    color: #bebec1;
    pointer-events: none; }
    .c--btn--disabled .c--arrow:before {
      border-color: #bebec1; }
  .c--btn--xs {
    padding: 7px;
    font-size: 0.8125rem; }
  .c--btn--s {
    padding: 6px 20px; }
  .c--btn--m {
    padding: 11px 9px;
    font-size: 0.8125rem; }
  .c--btn--l {
    padding: 25px 39px;
    font-size: 1.25rem; }
  .c--btn--xl {
    padding: 25px 77px;
    font-size: 1.25rem; }
  .c--btn--dlIcon:after {
    content: "";
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-left: 5px;
    background: url(../images/icon_save_pdf.png) 50% no-repeat; }
  .c--btn--line {
    padding: 18px 22px 18px 5px;
    background: #00b900;
    color: #fff; }
    .c--btn--line i {
      position: relative;
      display: inline-block;
      padding: 0 0 0 45px; }
      .c--btn--line i:before {
        content: "";
        position: absolute;
        display: inline-block;
        top: 50%;
        left: 0;
        width: 40px;
        height: 40px;
        background-size: contain;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        background: url(../images/icon_line.png) 50%/40px no-repeat; }
  .c--btn--searchIcon {
    line-height: 16px; }
    .c--btn--searchIcon:after {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-left: 5px;
      background: url(../images/icon_search.png) 50% no-repeat;
      vertical-align: top; }
  .c--btn--fav {
    position: relative;
    display: block;
    width: 70px;
    height: 70px; }
    .c--btn--fav:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 20px;
      height: 20px;
      background-size: contain !important;
      background-repeat: no-repeat;
      background-image: url(../images/icon_star_off.png); }
    .c--btn--fav--active {
      color: #e92d82; }
      .c--btn--fav--active:before {
        background-image: url(../images/icon_star_on.png); }
  .c--btn--top {
    width: 168px; }
  .c--btn--login, .c--btn--pswd {
    width: 300px; }
  .c--btn--trash {
    display: flex;
    align-items: center;
    padding: 9px 10px 9px 15px; }
    .c--btn--trash:before {
      content: "";
      display: inline-block;
      width: 14px;
      height: 18px;
      margin-right: 5px;
      background: url(../images/icon_trash_w.png) 50% no-repeat;
      background-size: 14px; }
  .c--btn--print {
    display: flex;
    align-items: center;
    padding: 9px 10px 9px 15px; }
    .c--btn--print:before {
      content: "";
      display: inline-block;
      width: 14px;
      height: 18px;
      margin-right: 5px;
      background: url(../images/icon_print.png) 50% no-repeat;
      background-size: 14px; }

@media screen and (min-width: 768px) {
  .c--btn--pk:hover {
    background: #e92d82;
    border: 1px solid #e92d82; }
  .c--btn--lb:hover {
    background: #00b0ff;
    border: 1px solid #00b0ff; }
  .c--btn--disabled:hover {
    cursor: auto; } }

@media screen and (max-width: 767px) {
  .c--btnArea {
    padding-right: 20px;
    padding-left: 20px; }
    .c--btnArea--col3 .c--btn + .c--btn {
      margin: 10px 0 0 0; }
    .c--btnArea__formBox .c--btn--back {
      min-width: 96px !important;
      width: 96px !important; }
    .c--btnArea__formBox .c--btn--next {
      min-width: calc(100% - 110px) !important;
      width: calc(100% - 110px) !important;
      margin: 0 0 0 10px; }
  .c--btn--fav {
    width: 56px;
    height: 56px; }
  .c--btn--top, .c--btn--login, .c--btn--pswd {
    width: 100%; }
  .c--btn--xl {
    font-size: 1rem;
    padding: 20px 34px; } }

@media screen and (max-width: 320px) {
  .c--btn {
    padding: 18px 15px;
    font-size: .7rem; } }

/*! 
 * ヘッダー
 */
/** ========================================
 * @HEADER
 * ヘッダー
 * ====================================== */
header {
  min-width: 1200px; }

.c--mainHeader {
  position: relative;
  display: block;
  box-shadow: 0 2px 8px rgba(97, 97, 106, 0.1), 0 1px 10px rgba(97, 97, 106, 0.1); }

.c--mainHeader__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  min-height: 103px;
  margin: 0 auto;
  background: #fff; }

.c--header__logo {
  margin: 0 0 5px; }
  .c--header__logo p {
    margin: 0 0 14px;
    padding: 0 0 0 1px;
    color: #7f7f7f;
    font-size: .69rem;
    letter-spacing: .1rem; }
  .c--header__logo img {
    width: 300px; }

.c--subHeader {
  display: none; }

@media screen and (max-width: 767px) {
  header {
    min-width: auto; }
  .c--mainHeader {
    box-shadow: none; }
    .c--mainHeader__inner {
      width: 100%;
      min-height: 60px; }
  .c--header__logo p {
    display: none; }
  .c--header__logo img {
    width: 42.5vw;
    margin: 0 0 0 19px; }
  .c--subHeader {
    display: block;
    box-shadow: 0 8px 3px -3px rgba(97, 97, 106, 0.1) inset; }
    .c--subHeader__head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 44px;
      padding: 0 20px;
      font-size: 0.75rem;
      line-height: 1; }
      .c--subHeader__head .c--head__item {
        display: flex;
        align-items: center;
        height: 100%; }
        .c--subHeader__head .c--head__item .c--item__inner {
          display: flex;
          align-items: center;
          height: 100%; }
          .c--subHeader__head .c--head__item .c--item__inner--myqr {
            margin: 0 0 0 10px;
            padding: 0 0 0 10px;
            border-left: 1px solid #dadadb; }
        .c--subHeader__head .c--head__item .c--item__txt {
          color: #828288;
          font-size: 0.625rem; }
        .c--subHeader__head .c--head__item .c--item__icon {
          margin: 0 0 0 5px; }
          .c--subHeader__head .c--head__item .c--item__icon a {
            display: flex;
            width: 24px;
            height: 24px;
            border: 1px solid #dadadb;
            border-radius: 50%;
            align-items: center;
            justify-content: center; }
          .c--subHeader__head .c--head__item .c--item__icon img {
            width: 14px;
            height: 14px; }
        .c--subHeader__head .c--head__item .c--item__point {
          margin: 0 0 0 5px; }
          .c--subHeader__head .c--head__item .c--item__point i {
            color: #e92d82;
            font-size: 1rem;
            font-weight: bold; }
    .c--subHeader__mypageList {
      display: flex;
      border-top: 1px solid #dadadb; }
      .c--subHeader__mypageList li {
        width: 20%; }
        .c--subHeader__mypageList li a {
          display: inline-block;
          width: 100%;
          text-align: center;
          color: #828288;
          font-size: 0.625rem; }
      .c--subHeader__mypageList .c--list__item {
        border-top: 1px solid #f0f0f0; }
        .c--subHeader__mypageList .c--list__item .c--item {
          display: block;
          width: 100%;
          height: 100%;
          padding: 13px 0;
          background: #fff;
          line-height: 1; }
          .c--subHeader__mypageList .c--list__item .c--item__icon span {
            position: relative;
            display: inline-block; }
            .c--subHeader__mypageList .c--list__item .c--item__icon span:before {
              content: "";
              display: block;
              width: 16px;
              height: 16px;
              margin: 0 auto 8px;
              background-size: contain !important;
              background-repeat: no-repeat; }
            .c--subHeader__mypageList .c--list__item .c--item__icon span .c--notice__num {
              position: absolute;
              right: -22px;
              top: -9px;
              height: 18px;
              border: 1px solid #fff;
              line-height: 16px; }
          .c--subHeader__mypageList .c--list__item .c--item__icon--fav span:before {
            background-image: url(../images/icon_fav.png); }
          .c--subHeader__mypageList .c--list__item .c--item__icon--comparison span:before {
            background-image: url(../images/icon_graph.png); }
          .c--subHeader__mypageList .c--list__item .c--item__icon--msg span:before {
            background-image: url(../images/icon_msg.png); }
          .c--subHeader__mypageList .c--list__item .c--item__icon--scout span:before {
            background-image: url(../images/icon_scout.png); }
          .c--subHeader__mypageList .c--list__item .c--item__icon--history span:before {
            background-image: url(../images/icon_history.png); }
        .c--subHeader__mypageList .c--list__item--active .c--item {
          background: #e92d82;
          color: #fff; }
          .c--subHeader__mypageList .c--list__item--active .c--item__icon span .c--notice__num {
            background: #fff;
            border: 1px solid #e92d82 !important;
            color: #f84361; }
          .c--subHeader__mypageList .c--list__item--active .c--item__icon--fav span:before {
            background-image: url(../images/icon_fav-w.png); }
          .c--subHeader__mypageList .c--list__item--active .c--item__icon--comparison span:before {
            background-image: url(../images/icon_graph-w.png); }
          .c--subHeader__mypageList .c--list__item--active .c--item__icon--msg span:before {
            background-image: url(../images/icon_msg-w.png); }
          .c--subHeader__mypageList .c--list__item--active .c--item__icon--scout span:before {
            background-image: url(../images/icon_scout-w.png); }
          .c--subHeader__mypageList .c--list__item--active .c--item__icon--history span:before {
            background-image: url(../images/icon_history-w.png); }
    .c--subHeader__defList {
      display: flex;
      padding: 4px 0; }
      .c--subHeader__defList .c--list__item {
        width: calc(100% / 3); }
        .c--subHeader__defList .c--list__item:not(:first-child) {
          border-left: 1px solid #dadadb; }
        .c--subHeader__defList .c--list__item .c--item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 9px 0;
          font-size: 0.625rem; }
          .c--subHeader__defList .c--list__item .c--item__icon {
            display: inline-block; }
            .c--subHeader__defList .c--list__item .c--item__icon span {
              display: flex;
              align-items: center; }
              .c--subHeader__defList .c--list__item .c--item__icon span:before {
                content: "";
                display: inline-block;
                width: 17px;
                height: 17px;
                margin: 0 7px 0 0;
                background-size: contain !important; }
            .c--subHeader__defList .c--list__item .c--item__icon--msg span:before {
              background: url(../images/icon_msg.png) 50% no-repeat; }
            .c--subHeader__defList .c--list__item .c--item__icon--scout span:before {
              background: url(../images/icon_scout.png) 50% no-repeat; }
            .c--subHeader__defList .c--list__item .c--item__icon--fav span:before {
              background: url(../images/icon_fav.png) 50% no-repeat; } }

/*! 
 * フッター
 */
/** ========================================
 * @FOOTER
 * フッター
 * ====================================== */
.c--footer {
  min-width: 1200px;
  background: #fff; }
  .c--footer--nolink .c--pageTop__box {
    min-width: 1200px;
    margin: 0 auto; }
  .c--footerInfo {
    padding: 77px 0 0;
    text-align: center; }
    .c--footerInfo__link {
      display: flex;
      justify-content: center;
      margin: 43px 0 0; }
      .c--footerInfo__link li {
        border-left: 1px solid #dadadb;
        padding: 0 16px; }
        .c--footerInfo__link li:first-child {
          border-left: none; }
  .c--footerLogo {
    width: 300px;
    margin: 0 auto; }
  .c--footerSns {
    display: flex;
    justify-content: center;
    margin: 32px 0 0; }
    .c--footerSns__item {
      width: 32px; }
      .c--footerSns__item:not(:first-child) {
        margin: 0 0 0 15px; }
  .c--footer__txt {
    margin: 25px 0 0;
    color: #828288;
    font-size: .72rem; }
  .c--footerNav {
    position: relative;
    width: 1200px;
    margin: 40px auto 0; }
    .c--footerNav__ttl {
      margin: 37px 0 0;
      color: #00b0ff;
      font-size: .92rem;
      letter-spacing: 1px; }
    .c--footerNav__link {
      display: flex;
      justify-content: space-between;
      margin: 18px 0 0; }
      .c--footerNav__link .c--link__wrap {
        width: calc(25% - 20px); }
        .c--footerNav__link .c--link__wrap dt {
          padding: 0 0 4px 0;
          border-bottom: 1px solid #f0f0f0; }
        .c--footerNav__link .c--link__wrap dd {
          margin: 10px 0 0; }
          .c--footerNav__link .c--link__wrap dd + dt {
            margin: 33px 0 0; }
      .c--footerNav__link .c--link__list {
        font-size: .75rem; }
        .c--footerNav__link .c--link__list li {
          margin: 6px 0 0; }
          .c--footerNav__link .c--link__list li a {
            padding: 0 0 0 14px; }
  .c--footer__simple .c--subFooter {
    margin: 0; }

.c--pageTop {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .c--pageTop dl {
    flex-grow: 1; }
  .c--pageTop__box {
    position: relative;
    display: flex;
    justify-content: flex-end; }

.c--box__linkArea {
  position: fixed;
  right: 28px;
  bottom: 18vh;
  opacity: 0;
  visibility: hidden;
  z-index: 1000; }
  .c--box__linkArea .c--linkArea__btn {
    content: "";
    display: inline-block;
    width: 50px;
    height: 50px;
    background: url(../images/page_top.png) 50%/50px no-repeat;
    vertical-align: bottom;
    box-shadow: 4px 0 8px rgba(97, 97, 106, 0.2), 1px 0 20px rgba(97, 97, 106, 0.2); }

.c--privacy__logo {
  width: 50px;
  margin: 0 0 2px 0; }

.c--subFooter {
  margin: 58px 0 0;
  background: #61616a;
  font-size: .68rem; }
  .c--subFooter__inner {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 11px 0; }
  .c--subFooter .c--subFooter__list {
    display: flex; }
    .c--subFooter .c--subFooter__list a {
      color: #fff;
      letter-spacing: 1px; }
    .c--subFooter .c--subFooter__list li {
      margin: 0 0 0 23px; }

.c--copyrightText {
  color: #fff; }

/** ========================================
 * @FOOTER
 * 2020.04.24 フッター追加
 * ====================================== */
.c--footerInfo + .c--dot__line {
  margin: 40px 0 0; }

#FooterService {
  position: relative;
  width: 1200px;
  margin: 37px auto 0; }
  #FooterService .FooterTitle {
    margin: 37px 0 0;
    color: #00b0ff;
    font-size: .92rem;
    letter-spacing: 1px; }
  #FooterServiceCont {
    display: flex;
    justify-content: space-between;
    margin: 18px 0 0; }
    #FooterServiceCont .c--link__wrap {
      width: calc(20% - 20px); }
    #FooterServiceCont dl dt {
      padding: 0 0 4px;
      border-bottom: 1px solid #f0f0f0; }
    #FooterServiceCont dl dd {
      margin: 6px 0 0;
      font-size: .75rem; }
      #FooterServiceCont dl dd a {
        position: relative;
        padding: 0 0 0 14px; }
        #FooterServiceCont dl dd a:before {
          content: "";
          position: absolute;
          top: 48%;
          left: 0;
          width: 7px;
          height: 7px;
          border: solid #e92d82;
          border-width: 0 2px 2px 0;
          z-index: 10;
          transform: translateY(-50%) rotate(-45deg); }
  #FooterService #privacy {
    width: 50px;
    margin: 0 0 2px; }

@media screen and (max-width: 767px) {
  .c--footer {
    min-width: auto; }
    .c--footer .c--footer__txt,
    .c--footer .c--dot__line,
    .c--footer .c--footerNav__link dl,
    .c--footer .c--footerNav__ttl,
    .c--footer .c--subFooter__list,
    .c--footer #FooterServiceHead,
    .c--footer #FooterService dl {
      display: none; }
    .c--footer--nolink .c--pageTop__box {
      min-width: auto;
      width: calc(100% - 30px);
      margin: 13px 10px 0 20px; }
  .c--footerNav,
  #FooterService {
    width: 100%;
    margin: 13px auto 0; }
  .c--subFooter__inner {
    width: 100%;
    padding: 12px 18px; }
  .c--footerInfo {
    padding: 97px 0 0; }
  .c--footerSns {
    margin: 23px 0 0; }
  .c--footerNav__link,
  #FooterServiceCont {
    margin: 0 10px 0 20px; }
    .c--footerNav__link .c--link__wrap,
    #FooterServiceCont .c--link__wrap {
      width: 100%; }
  .c--footerLogo {
    width: 160px; }
  .c--footerInfo__link {
    flex-wrap: wrap;
    margin: 23px 0 0; }
    .c--footerInfo__link li {
      width: 50%;
      padding: 10px;
      font-size: .75rem; }
      .c--footerInfo__link li:nth-child(1), .c--footerInfo__link li:nth-child(2) {
        padding-top: 16px; }
      .c--footerInfo__link li:nth-child(5), .c--footerInfo__link li:nth-child(6) {
        padding-bottom: 16px; }
      .c--footerInfo__link li:not(:nth-child(even)) {
        border: none; }
  .c--pageTop__box {
    justify-content: flex-start; }
  .c--subFooter {
    margin: 23px 0 0; } }

@media screen and (max-width: 480px) {
  .c--box__linkArea {
    top: -15px;
    right: -5px; }
  .c--footerNav__link {
    margin: 0 10px 0 20px; }
  .c--linkArea__btn {
    width: 50px;
    height: 50px;
    background-size: 50px; } }

/*!
 * ナビ
 */
/** ========================================
 * @GLOBAL NAVI
 * グロナビ
 * ====================================== */
/** --------------------
 * 
 * ------------------ */
.c--gnavi {
  display: flex; }
  .c--gnavi__main {
    display: flex;
    align-items: flex-end;
    height: 100%;
    margin: 0 26px 0 0; }
    .c--gnavi__main .c--main__item {
      display: inline-block;
      padding: 16px 0; }
      .c--gnavi__main .c--main__item:hover {
        padding: 14px 0;
        color: #e92d82;
        border-bottom: 2px solid #e92d82;
        cursor: pointer; }
      .c--gnavi__main .c--main__item.c--arrow:hover:before {
        top: calc(48% - 1px); }
      .c--gnavi__main .c--main__item .c--item__btn {
        display: inline-block;
        padding: 0 36px 0 24px;
        border-left: 1px solid #dadadb; }
        .c--gnavi__main .c--main__item .c--item__btn:before {
          right: 23px; }
        .c--gnavi__main .c--main__item .c--item__btn--noChild {
          padding: 0 30px; }
  .c--gnavi__child {
    position: absolute;
    top: 103px;
    left: 0;
    display: none;
    width: 100%;
    margin: 0;
    padding: 0;
    background: #fff;
    box-shadow: inset 2px 10px 8px -8px rgba(97, 97, 106, 0.1), inset 0 11px 10px -10px rgba(97, 97, 106, 0.1), 2px 10px 8px -8px rgba(97, 97, 106, 0.01), 1px 11px 10px -10px rgba(97, 97, 106, 0.1);
    z-index: 15; }
    .c--gnavi__child--imgOnly {
      background: #fff; }
    .c--gnavi__child--linkOnly {
      background: #fafafa; }
    .c--gnavi__child .c--child__inner {
      display: flex;
      width: 1248px;
      min-height: 217px;
      margin: 0 auto; }
    .c--gnavi__child .c--arrow--circle:hover {
      color: #e92d82; }
      .c--gnavi__child .c--arrow--circle:hover i {
        border-bottom: 1px solid #e92d82; }
  .c--gnavi .c--child__item {
    width: 25%;
    padding: 24px 0 20px 24px; }
    .c--gnavi .c--child__item--img .c--item__img {
      margin: 0 30px 0 0;
      width: 242px; }
      .c--gnavi .c--child__item--img .c--item__img img {
        height: 140px; }
    .c--gnavi .c--child__item--img .c--arrow--circle {
      margin: 10px 0 0; }
    .c--gnavi .c--child__item--img a {
      display: inline-block;
      width: 100%;
      height: 100%;
      font-weight: bold; }
    .c--gnavi .c--child__item--link p {
      line-height: 1;
      color: #e92d82;
      font-weight: bold; }
      .c--gnavi .c--child__item--link p + ul {
        margin: 10px 0 0; }
    .c--gnavi .c--child__item--link ul {
      margin: 18px 0 0; }
      .c--gnavi .c--child__item--link ul li:not(:first-child) {
        margin: 16px 0 0; }
      .c--gnavi .c--child__item--link ul li .c--arrow--circle:before {
        transform: none;
        top: .2rem; }
  .c--gnavi__sub {
    display: flex; }
    .c--gnavi__sub a {
      display: inline-block;
      width: 82px;
      padding: 25px 0 20px;
      color: #fff;
      text-align: center;
      text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1); }
      .c--gnavi__sub a:before {
        background-size: contain;
        content: "";
        display: block;
        margin: 0 auto 13px;
        width: 24px; }
  .c--gnavi .c--sub__item--regist a {
    background: #40c4ff; }
    .c--gnavi .c--sub__item--regist a:before {
      height: 24px;
      background: url(../images/header_icon_regist.png) 50% no-repeat;
      background-size: 17px; }
    .c--gnavi .c--sub__item--regist a:hover {
      background: #00b0ff; }
  .c--gnavi .c--sub__item--login {
    margin: 0 0 0 1px; }
    .c--gnavi .c--sub__item--login a {
      background: #ff80ab; }
      .c--gnavi .c--sub__item--login a:before {
        height: 24px;
        background: url(../images/header_icon_login.png) 50% no-repeat;
        background-size: 20px; }
      .c--gnavi .c--sub__item--login a:hover {
        background: #e92d82; }
  .c--gnavi .c--sub__item--spBtn {
    display: none; }
  .c--gnavi--login {
    align-self: stretch; }
    .c--gnavi--login .c--gnavi__main {
      margin: 0; }
      .c--gnavi--login .c--gnavi__main .c--main__item .c--item__btn {
        border-right: 1px solid #dadadb;
        border-left: none; }
      .c--gnavi--login .c--gnavi__main .c--main__item a span:last-child {
        border-right: 1px solid #dadadb; }
      .c--gnavi--login .c--gnavi__main .c--main__item:first-child .c--item__btn {
        border-left: 1px solid #dadadb; }
    .c--gnavi--login .c--menu__mainInner {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      height: 100%; }

.c--login {
  display: flex;
  align-items: center;
  margin: 0 12px 0 0; }
  .c--login a:hover {
    color: #e92d82; }
  .c--login__list {
    display: flex;
    border: 1px solid;
    border-color: transparent #dadadb #dadadb #dadadb;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; }
  .c--login__item:not(:first-child) {
    border-left: 1px solid #dadadb; }
  .c--login .c--item {
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    padding: 6px 14px; }
    .c--login .c--item__icon span {
      display: flex;
      align-items: center; }
      .c--login .c--item__icon span:before {
        content: "";
        display: inline-block;
        width: 17px;
        height: 17px;
        margin: 0 7px 0 0;
        background-size: contain !important; }
    .c--login .c--item__icon--msg span:before {
      background: url(../images/icon_msg.png) 50% no-repeat; }
    .c--login .c--item__icon--scout span:before {
      background: url(../images/icon_scout.png) 50% no-repeat; }
    .c--login .c--item__icon--fav span:before {
      background: url(../images/icon_fav.png) 50% no-repeat; }

.c--login__user {
  padding: 8px 14px;
  font-size: .75rem; }

@media screen and (max-width: 767px) {
  .c--gnavi__sub a {
    width: 60px;
    padding: 10px 0 7px;
    border-left: 1px solid #dadadb;
    font-size: .6rem; }
    .c--gnavi__sub a:before {
      margin: 0 auto 4px; }
    .c--gnavi__sub a.c--menu__trigger {
      color: #333; }
  .c--gnavi .c--sub__item--login {
    margin: 0; }
  .c--gnavi .c--sub__item--spBtn {
    position: relative;
    display: block; }
  .c--gnavi--login {
    align-items: center;
    flex-direction: row; }
    .c--gnavi--login .c--gnavi__sub {
      height: 60px; }
  .c--gnavi__main {
    display: block;
    width: 100%;
    margin: 0; }
    .c--gnavi__main .c--main__item {
      width: 100%;
      padding: 0;
      border-top: 1px solid #f0f0f0; }
      .c--gnavi__main .c--main__item .c--item__btn {
        position: relative;
        width: 100%;
        padding: 14px 20px;
        border: none !important; }
        .c--gnavi__main .c--main__item .c--item__btn:not(.c--item__btn--noChild):after {
          content: "";
          position: absolute;
          top: 0;
          right: 50px;
          width: 1px;
          height: 100%;
          background: #dadadb; }
        .c--gnavi__main .c--main__item .c--item__btn.c--gnavi__childTgl:before {
          transform: translateY(-50%) rotate(-45deg); }
        .c--gnavi__main .c--main__item .c--item__btn--noChild {
          position: relative;
          padding: 0; }
          .c--gnavi__main .c--main__item .c--item__btn--noChild:before {
            content: "";
            position: absolute;
            top: 48%;
            right: 23px;
            width: 7px;
            height: 7px;
            border: solid #e92d82;
            border-width: 0 2px 2px 0;
            z-index: 10;
            transform: translateY(-50%) rotate(-45deg); }
          .c--gnavi__main .c--main__item .c--item__btn--noChild a {
            display: inline-block;
            width: 100%;
            padding: 14px 20px; }
      .c--gnavi__main .c--main__item--btm {
        padding: 18px 22px;
        background: #f5f5f5; }
        .c--gnavi__main .c--main__item--btm > a {
          width: 100%;
          padding: 5px 25px; }
  .c--gnavi__btn--sp {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 15px 20px;
    background: #f5f5f5; }
  .c--gnavi .c--menu__main {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    border-top: 1px solid #dadadb;
    background: #fff;
    box-shadow: 0 8px 3px -3px rgba(97, 97, 106, 0.1), 0 8px 3px -3px rgba(97, 97, 106, 0.1);
    z-index: 15; }
    .c--gnavi .c--menu__main.c--menu--open {
      display: block; }
  .c--gnavi .c--menu__trigger {
    height: 100%;
    display: inline-block;
    transition: all .4s;
    box-sizing: border-box;
    text-shadow: none; }
    .c--gnavi .c--menu__trigger span {
      position: absolute;
      left: 29%;
      display: inline-block;
      width: 45%;
      height: 2px;
      box-sizing: border-box;
      background-color: #e92d82;
      transition: all .4s; }
      .c--gnavi .c--menu__trigger span:first-of-type {
        top: 13px; }
      .c--gnavi .c--menu__trigger span:nth-of-type(2) {
        top: 22px; }
      .c--gnavi .c--menu__trigger span:nth-of-type(3) {
        top: 30px; }
      .c--gnavi .c--menu__trigger span.c--menu__trigger--open, .c--gnavi .c--menu__trigger span.c--menu__trigger--close {
        top: 38px;
        left: 0;
        width: 100%;
        height: auto;
        background: none; }
      .c--gnavi .c--menu__trigger span.c--menu__trigger--open {
        display: none; }
    .c--gnavi .c--menu__trigger.c--menu--open span:nth-of-type(1) {
      top: 10px;
      transform: translateY(10px) rotate(-45deg); }
    .c--gnavi .c--menu__trigger.c--menu--open span:nth-of-type(2) {
      top: 22px;
      opacity: 0;
      left: 50%;
      animation: active-menu .8s forwards; }
    .c--gnavi .c--menu__trigger.c--menu--open span:nth-of-type(3) {
      transform: translateY(-10px) rotate(45deg); }
    .c--gnavi .c--menu__trigger.c--menu--open span:nth-of-type(4) {
      display: none; }
    .c--gnavi .c--menu__trigger.c--menu--open span:nth-of-type(5) {
      display: block;
      color: #e92d82; }
  @-webkit-keyframes active-menu {
    100% {
      height: 0; } }
  @keyframes active-menu {
    100% {
      height: 0; } }
  .c--gnavi__child {
    position: relative;
    top: auto;
    left: auto;
    box-shadow: unset;
    margin: 0 20px;
    border-top: 1px solid #f0f0f0;
    background: #fff; }
    .c--gnavi__child .c--child__inner {
      display: block;
      width: 100%;
      min-height: auto;
      padding: 7px 12px 17px; }
  .c--gnavi__main .c--main__item:hover {
    padding: 0;
    border-bottom: none;
    color: #333; }
  .c--gnavi__main .c--main__item .c--item__btn.c--childTgl--open:before {
    transform: translateY(-50%) rotate(-135deg); }
  .c--gnavi .c--child__item {
    width: 100%;
    padding: 0; }
    .c--gnavi .c--child__item--img {
      width: 100%;
      margin: 10px 0 0;
      padding: 0;
      box-shadow: unset;
      background: transparent; }
      .c--gnavi .c--child__item--img a {
        font-weight: normal; }
      .c--gnavi .c--child__item--img img {
        display: none; }
      .c--gnavi .c--child__item--img .c--arrow--circle {
        margin: 0; }
    .c--gnavi .c--child__item--link {
      box-shadow: unset;
      background: transparent; }
      .c--gnavi .c--child__item--link p {
        display: none; }
        .c--gnavi .c--child__item--link p + ul {
          margin: 0; }
      .c--gnavi .c--child__item--link ul {
        margin: 0; }
        .c--gnavi .c--child__item--link ul li {
          margin: 10px 0 0 !important; }
  .c--login {
    display: block;
    width: 100%;
    margin: 0;
    padding: 10px 20px 0;
    background: #f5f5f5; }
    .c--login .c--item {
      position: relative;
      display: block;
      padding: 22px 5px;
      text-align: center; }
      .c--login .c--item:before {
        content: "";
        position: absolute;
        top: 48%;
        width: 7px;
        height: 7px;
        border: solid #e92d82;
        border-width: 0 2px 2px 0;
        z-index: 10;
        top: auto;
        right: 10px;
        bottom: 4px;
        transform: translateY(-50%) rotate(-45deg); }
      .c--login .c--item__icon span {
        position: relative;
        display: inline-block; }
        .c--login .c--item__icon span:before {
          display: block;
          width: 25px;
          height: 25px;
          margin: 0 auto; }
      .c--login .c--item__icon--msg span:before {
        background: url(../images/icon_msg_sp.png) 50% no-repeat; }
      .c--login .c--item__icon--scout span:before {
        background: url(../images/icon_scout_sp.png) 50% no-repeat; }
      .c--login .c--item__icon--fav span:before {
        background: url(../images/icon_fav_sp.png) 50% no-repeat; }
      .c--login .c--item .c--notice__num {
        position: absolute;
        top: -8px;
        right: -19px;
        border: 1px solid #fff; }
    .c--login__list {
      width: 100%;
      background: #fff;
      border-top-color: #dadadb; }
      .c--login__list .c--login__item {
        flex: 1 1 calc(100% / 3); }
  .c--gnavi--login .c--gnavi__main .c--main__item:first-child .c--item__btn {
    border: none; } }

@media screen and (max-width: 320px) {
  .c--gnavi__sub a {
    width: 50px; } }

/*! 
 * パンくず
 */
/** ========================================
 * @
 * パンくず
 * ====================================== */
.c--breadcrumbs {
  background: #fafafa;
  padding: 3px 0; }
  .c--breadcrumbs__inner {
    width: 1200px;
    margin: 0 auto; }
  .c--breadcrumbs li {
    display: inline-block;
    font-size: 0.75rem; }
    .c--breadcrumbs li + li {
      position: relative; }
      .c--breadcrumbs li + li:before {
        content: "";
        position: absolute;
        top: 55%;
        left: 5px;
        width: 6px;
        height: 6px;
        border: solid #828288;
        border-width: 0 2px 2px 0;
        z-index: 10;
        transform: translateY(-50%) rotate(-45deg); }
    .c--breadcrumbs li:not(:first-child) {
      padding: 0 0 0 20px; }
    .c--breadcrumbs li a {
      display: inline-block;
      color: #e92d82;
      text-decoration: underline; }

@media screen and (max-width: 767px) {
  .c--breadcrumbs {
    padding: 0;
    overflow: hidden; }
    .c--breadcrumbs__inner {
      width: 100%;
      box-shadow: 0 8px 3px -3px rgba(97, 97, 106, 0.1) inset;
      white-space: nowrap;
      overflow: hidden;
      overflow-x: scroll; }
    .c--breadcrumbs li {
      padding: 0 !important; }
      .c--breadcrumbs li > a,
      .c--breadcrumbs li > span {
        padding: 7px 14px !important; }
      .c--breadcrumbs li > a {
        color: #bebec1;
        text-decoration: none; }
      .c--breadcrumbs li.c--current {
        color: #dadadb; }
      .c--breadcrumbs li + li:before {
        width: 30px;
        height: 30px;
        left: -30px;
        top: 50%;
        border-color: #dadadb;
        border-width: 0 1px 1px 0; }
    .c--breadcrumbs .c--home {
      position: relative;
      z-index: 11; }
      .c--breadcrumbs .c--home:before {
        content: "";
        display: inline-block;
        width: 10px;
        height: 12px;
        background: url(../images/icon_home.png) 50% no-repeat;
        background-size: contain; }
      .c--breadcrumbs .c--home i {
        display: none; } }

/*! 
 * モーダル
 */
/**************************\
  Basic Modal Styles
\**************************/
/** ========================================
 * モーダル
 * ====================================== */
body.fixed {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0; }

.c--modal__container {
  position: relative;
  max-width: 1200px;
  box-sizing: border-box;
  min-width: 1200px; }

.c--modal__content {
  position: relative;
  max-height: 80vh;
  overflow: hidden;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.8);
  line-height: 1.5; }

.c--modal__close {
  position: absolute;
  right: -20px;
  top: -20px;
  padding: 0;
  border: 0;
  background: transparent; }
  .c--modal__close:after {
    content: "";
    display: block;
    width: 40px;
    height: 40px;
    background: url(../images/icon_close.png) 50% no-repeat;
    background-size: 100%; }

.c--modal__main {
  margin: 0 0 40px; }

.c--modal__overlay {
  z-index: 10005;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto; }

@media screen and (min-width: 767px) and (max-width: 1220px) {
  .c--modal__container {
    position: absolute;
    left: 0; } }

@media screen and (max-width: 767px) {
  .c--modal__container {
    min-width: auto;
    width: 95%; } }

/**************************\
  Demo Animation Style
\**************************/
.c--micromodal-slide {
  display: none; }

.c--micromodal-slide.is-open {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0; }

@media screen and (max-width: 767px) {
  .c--modal__close {
    right: -9px;
    top: -9px; } }

/** ========================================
 * モーダル 追加
 * ====================================== */
.c--modal__title {
  color: #e92d82;
  font-size: 2rem;
  font-weight: bold;
  text-align: center; }

.c--modal__btnArea {
  position: absolute;
  bottom: 0;
  border-top: 1px solid #dadadb;
  width: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 135px; }
  .c--modal__btnArea .c--btnArea__result {
    margin: 0 38px 0 0;
    font-size: 1rem; }
    .c--modal__btnArea .c--btnArea__result span {
      display: inline-block;
      margin: 0 3px;
      font-size: 1.5rem;
      font-weight: bold; }
  .c--modal__btnArea .c--btn + .c--btn {
    margin: 0 0 0 8px; }

.c--modal__fixBtnBlock {
  padding: 0; }
  .c--modal__fixBtnBlock .c--modal__mainArea {
    overflow-y: auto;
    max-height: calc(80vh - 135px);
    margin: 0 0 135px;
    padding: 30px 30px 0 30px; }

.c--modal--area .c--modal__mainArea {
  padding: 25px 48px 0 !important; }

.c--modal--area .c--modal__main {
  margin: 22px 0 0; }

.c--modal--area .ps__rail-y {
  top: 0 !important; }

.c--modal--area .ps__thumb-y,
.c--modal--area .ps__rail-y:hover > .ps__thumb-y,
.c--modal--area .ps__rail-y:focus > .ps__thumb-y,
.c--modal--area .ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #e92d82; }

.c--modal--details .c--modal__mainArea {
  padding: 25px 38px 0 !important; }

.c--modal--details .c--ttl__bg--dot {
  content: "";
  padding: 8px 16px;
  background: url(../images/bg-hdg.png) 50%;
  font-size: 0.875rem;
  font-weight: 700; }
  .c--modal--details .c--ttl__bg--dot:not(:first-child) {
    margin: 24px 0 0; }

.c--modal--details .c--modal__main {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 34px 0 0;
  padding: 0; }
  .c--modal--details .c--modal__main .c--main__map {
    width: calc(100% - 250px);
    height: 536px; }
    .c--modal--details .c--modal__main .c--main__map .c--area__item:nth-child(n+2) {
      margin: 17px 0 0; }
    .c--modal--details .c--modal__main .c--main__map .c--area__item .c--control__indicator {
      width: 13px;
      height: 13px; }
    .c--modal--details .c--modal__main .c--main__map .c--area__item .c--item__heading {
      font-size: 0.75rem; }
      .c--modal--details .c--modal__main .c--main__map .c--area__item .c--item__heading .c--control {
        padding: 10px 20px 10px 36px; }
    .c--modal--details .c--modal__main .c--main__map .c--area__item .c--item__body {
      font-size: 0.625rem; }
      .c--modal--details .c--modal__main .c--main__map .c--area__item .c--item__body li:nth-child(n+3) {
        margin: 0; }
  .c--modal--details .c--modal__main .c--main__date {
    width: 230px;
    margin: 0 0 0 20px; }
    .c--modal--details .c--modal__main .c--main__date .c--date__checkList {
      margin: 16px 0 0; }
      .c--modal--details .c--modal__main .c--main__date .c--date__checkList .c--checkList__item {
        border-bottom: 1px solid #dadadb; }
        .c--modal--details .c--modal__main .c--main__date .c--date__checkList .c--checkList__item:first-child {
          border-top: 1px solid #dadadb; }
        .c--modal--details .c--modal__main .c--main__date .c--date__checkList .c--checkList__item .c--control {
          padding: 10px 0 10px 34px; }
          .c--modal--details .c--modal__main .c--main__date .c--date__checkList .c--checkList__item .c--control__indicator {
            left: 8px; }
    .c--modal--details .c--modal__main .c--main__date .c--date__inputArea {
      margin: 17px 0 0; }
      .c--modal--details .c--modal__main .c--main__date .c--date__inputArea .c--input__item:not(:first-child) {
        margin: 10px 0 0; }
      .c--modal--details .c--modal__main .c--main__date .c--date__inputArea .c--input__item input {
        width: 150px;
        height: 30px;
        margin: 0 15px 0 0;
        border: 1px solid #a2a2a7; }

@media screen and (max-width: 767px) {
  .c--modal__title {
    font-size: 1.5rem; }
  .c--modal--area .ps__rail-y {
    top: 41px !important; }
  .c--modal--area .c--modal__content {
    max-height: 80vh; }
  .c--modal--area .c--modal__mainArea {
    max-height: unset;
    height: 100%;
    margin: 0;
    padding: 5px 10px 0 !important; }
  .c--modal--area .c--modal__title {
    height: 36px; }
  .c--modal--area .c--modal__main {
    max-height: calc(80vh - 122px);
    margin: 0 0 81px;
    overflow: hidden; }
  .c--modal--area .c--modal__btnArea {
    height: 81px;
    padding: 0 10px;
    border-top: none; }
    .c--modal--area .c--modal__btnArea .c--btnArea__result {
      margin: 0;
      padding: 0 10px 0 0;
      font-size: 0.75rem;
      text-align: center; }
      .c--modal--area .c--modal__btnArea .c--btnArea__result span {
        font-size: 1.25rem;
        line-height: 1; }
    .c--modal--area .c--modal__btnArea .c--btn--xl {
      width: 179px; }
    .c--modal--area .c--modal__btnArea .c--btn--l {
      width: 75px;
      margin: 0 0 0 8px;
      padding: 14px 12px;
      font-size: 0.875rem; }
  .c--modal--details .c--ttl__bg--dot {
    margin: 10px 0 0 !important; }
  .c--modal--details .c--modal__main {
    flex-direction: column; }
    .c--modal--details .c--modal__main .c--main__map {
      width: 100%;
      height: auto;
      margin: 10px 0 0; }
      .c--modal--details .c--modal__main .c--main__map .c--area__item:nth-child(n+2) {
        margin: 0; }
      .c--modal--details .c--modal__main .c--main__map .c--area__item .c--control__indicator {
        width: 16px;
        height: 16px; }
      .c--modal--details .c--modal__main .c--main__map .c--area__item .c--item__heading {
        font-size: 0.875rem; }
        .c--modal--details .c--modal__main .c--main__map .c--area__item .c--item__heading .c--control {
          padding: 0 0 0 26px; }
      .c--modal--details .c--modal__main .c--main__map .c--area__item .c--item__body {
        display: block;
        font-size: 0.8125rem; }
    .c--modal--details .c--modal__main .c--main__date {
      width: 100%;
      margin: 0; }
      .c--modal--details .c--modal__main .c--main__date .c--date__checkList {
        margin: 10px 0 0; }
        .c--modal--details .c--modal__main .c--main__date .c--date__checkList .c--checkList__item {
          padding: 11px 20px; }
          .c--modal--details .c--modal__main .c--main__date .c--date__checkList .c--checkList__item .c--control {
            padding: 0 0 0 26px; }
            .c--modal--details .c--modal__main .c--main__date .c--date__checkList .c--checkList__item .c--control__indicator {
              left: 0; }
      .c--modal--details .c--modal__main .c--main__date .c--date__inputArea {
        margin: 10px 0 0;
        padding: 0 0 0 20px; }
        .c--modal--details .c--modal__main .c--main__date .c--date__inputArea .c--input__item {
          width: 100%; }
          .c--modal--details .c--modal__main .c--main__date .c--date__inputArea .c--input__item:not(:first-child) {
            margin: 10px 0 0; }
          .c--modal--details .c--modal__main .c--main__date .c--date__inputArea .c--input__item input {
            width: 70%;
            height: 30px;
            margin: 0 10px 0 0; }
  .c--modal--terms .c--modal__content {
    padding: 10px; }
    .c--modal--terms .c--modal__content .c--modal__mainArea {
      overflow-y: auto;
      max-height: calc(80vh - 20px); } }

@media screen and (max-width: 320px) {
  .c--modal--area .c--modal__btnArea .c--btnArea__result {
    font-size: 0.5625rem; }
    .c--modal--area .c--modal__btnArea .c--btnArea__result span {
      font-size: 1rem; }
  .c--modal--area .c--modal__btnArea .c--btn--xl {
    width: 160px;
    padding: 20px 0px; } }

/*! 
 * 細かな動作
 */
/** ========================================
 * セルが開くTable
 * ====================================== */
.c--accordionTable tr {
  overflow: hidden; }

.c--accordionTable th,
.c--accordionTable td {
  overflow: hidden; }

.c--accordionTable__head span {
  position: relative;
  display: block; }
  .c--accordionTable__head span::after {
    content: "";
    position: absolute;
    top: 47%;
    right: 10px;
    width: 6px;
    height: 6px;
    border: solid #333;
    border-width: 0 2px 2px 0;
    -webkit-transform: translateY(-50%) rotate(45deg);
    -ms-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg); }

.c--accordionTable__head.is-close span::after {
  -webkit-transform: translateY(-50%) rotate(-135deg);
  -ms-transform: translateY(-50%) rotate(-135deg);
  transform: translateY(-50%) rotate(-135deg); }

.c--accordionTable__row td {
  transition: all .5s ease-out;
  height: auto;
  line-height: 1.6; }

.c--accordionTable__row.is-close td {
  height: 0;
  padding: 0;
  line-height: 0; }

/** ------------------------------
 * Project
 * DESCRIPTION : 共通化しないページごとのもの
 * ---------------------------- */
/*! 
 * トップページ
 */
/** ========================================
 * 重要なお知らせ
 * ====================================== */
.p--top .c--notices__list {
  width: calc(100% - 134px); }

@media screen and (max-width: 767px) {
  .p--top .c--notices__list {
    width: 100%; } }

/** ========================================
 * @p--searchArea
 * 検索エリア
 * ====================================== */
.p--searchArea__bg {
  width: 1200px;
  height: 500px;
  margin: 0 auto;
  overflow: hidden; }
  .p--searchArea__bg img {
    width: auto;
    height: 100%; }

@media screen and (max-width: 1200px) {
  .p--searchArea__bg {
    max-width: 100%; } }

@media screen and (max-width: 767px) {
  .p--searchArea__bg {
    display: none; } }

.p--searchArea {
  position: relative;
  margin: 25px 0 0; }
  .p--searchArea__inner {
    position: absolute;
    top: 37px;
    left: 80px; }
  .p--searchArea__tab {
    display: flex; }

.p--tab {
  min-width: 266px;
  background: #fff;
  text-align: center; }
  .p--tab:not(:first-child) {
    margin: 0 0 0 8px; }
  .p--tab__txt {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 1rem;
    font-weight: bold; }
  .p--tab--hospital {
    height: 78px;
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(97, 97, 106, 0.2), 0 1px 5px rgba(97, 97, 106, 0.1); }
  .p--tab--intern {
    height: 78px;
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(97, 97, 106, 0.2), 0 1px 5px rgba(97, 97, 106, 0.1); }
  .p--tab--active {
    height: 86px;
    padding: 6px 0 18px;
    border-top: 4px solid #e92d82;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: 0 0 2px -2px rgba(97, 97, 106, 0.2), 0 0 5px -5px rgba(97, 97, 106, 0.1); }
    .p--tab--active p {
      color: #e92d82; }
    .p--tab--active span {
      display: inline-block;
      margin: 5px 0 0;
      color: #333;
      font-size: .75rem; }
      .p--tab--active span i {
        color: #e92d82;
        font-weight: bold; }

.p--searchArea__main {
  padding: 24px 40px;
  background: #fff;
  box-shadow: 0 2px 2px rgba(97, 97, 106, 0.2), 0 1px 5px rgba(97, 97, 106, 0.1); }

.p--main__btnArea {
  position: relative;
  display: flex;
  justify-content: space-between;
  border-radius: 4px; }
  .p--main__btnArea .p--btn {
    width: 222px;
    padding: 15px 0; }
    .p--main__btnArea .p--btn .p--btn__icon {
      position: relative; }
      .p--main__btnArea .p--btn .p--btn__icon:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        content: "";
        display: block;
        width: 24px;
        margin: 2px auto 8px;
        background-size: contain; }
      .p--main__btnArea .p--btn .p--btn__icon:before {
        width: 48px;
        height: 48px;
        background-size: 48px; }
      .p--main__btnArea .p--btn .p--btn__icon--area:after {
        height: 24px;
        background: url(../images/icon_aria.png) 50% no-repeat;
        background-size: 18px; }
      .p--main__btnArea .p--btn .p--btn__icon--ds:after {
        height: 24px;
        background: url(../images/icon_ds.png) 50% no-repeat;
        background-size: 18px; }

.p--main__kwArea {
  margin: 23px 0 0; }
  .p--main__kwArea .p--kw__ttl {
    font-size: 1rem;
    font-weight: bold; }
  .p--main__kwArea .p--kw__input {
    margin: 6px 0 0; }
    .p--main__kwArea .p--kw__input input {
      width: 355px;
      margin: 0 5px 0 0; }
  .p--main__kwArea .p--tag {
    margin: 10px 0 0; }
    .p--main__kwArea .p--tag span {
      display: inline-block;
      border-radius: 4px;
      background: #f5f5f5;
      margin: 0 6px 0 0; }
      .p--main__kwArea .p--tag span a {
        display: inline-block;
        padding: 7px 8px;
        color: #e92d82;
        font-size: 0.75rem; }
  .p--main__kwArea .p--other {
    margin: 19px 0 0;
    font-size: 0.75rem; }
    .p--main__kwArea .p--other a:not(:first-child) {
      margin: 0 0 0 10px; }

.p--sliderArea + .c--recommended {
  margin: 60px 0 0; }

@media screen and (max-width: 767px) {
  .p--top__main {
    display: flex;
    flex-direction: column; }
    .p--top__main .p--sliderArea {
      order: 1; }
    .p--top__main .c--recommended {
      order: 2; }
  .p--sliderArea + .c--recommended {
    margin-top: 30px; }
  .p--searchArea {
    order: 3;
    margin: 24px 0 0; }
    .p--searchArea__inner {
      position: relative;
      top: auto;
      left: auto; }
    .p--searchArea__tab {
      justify-content: space-between;
      padding: 0 11px 0 10px; }
  .p--tab {
    min-width: auto;
    width: calc(50% - 4px); }
    .p--tab__txt {
      font-size: 0.875rem;
      line-height: 1; }
    .p--tab--hospital {
      height: 74px; }
      .p--tab--hospital span {
        font-size: 0.625rem; }
        .p--tab--hospital span i {
          margin: 0 2px;
          font-size: 0.75rem; }
    .p--tab--intern {
      height: 66px; }
  .p--searchArea__main {
    padding: 24px 0 0; }
    .p--searchArea__main .c--hoverShadow__item {
      border-radius: 4px; }
  .p--main__btnArea {
    padding: 0 20px; }
    .p--main__btnArea .p--btn {
      width: calc(50% - 4px); }
  .p--main__kwArea .p--kw {
    padding: 0 20px; }
    .p--main__kwArea .p--kw__input input {
      width: calc(100% - 96px); }
    .p--main__kwArea .p--kw__input .c--btn--input {
      width: 96px; }
  .p--main__kwArea .c--btn__input {
    width: 96px; }
  .p--main__kwArea .p--tag {
    padding: 0 20px;
    margin: 15px 0 0; }
  .p--main__kwArea .p--other {
    margin: 15px 0 0; }
    .p--main__kwArea .p--other a.c--arrow--circle {
      display: block;
      width: 100%;
      margin: 0 !important;
      padding: 8px 30px 8px 20px;
      border-top: 1px solid #f0f0f0; }
      .p--main__kwArea .p--other a.c--arrow--circle:before {
        top: 48%;
        right: 20px;
        left: unset;
        width: 7px;
        height: 7px;
        border: solid #e92d82;
        border-width: 0 2px 2px 0;
        z-index: 10;
        -webkit-transform: translateY(-50%) rotate(-45deg);
        transform: translateY(-50%) rotate(-45deg);
        background: none; } }

/** ========================================
 * @p--sliderArea
 * スライダー
 * ====================================== */
.p--sliderArea {
  margin: 25px 0 0; }
  .p--sliderArea .p--slider__pc {
    display: flex; }
    .p--sliderArea .p--slider__pc a {
      width: 282px; }
      .p--sliderArea .p--slider__pc a:not(:first-child) {
        margin: 0 0 0 8px; }
  .p--sliderArea .swiper-custom-parent {
    position: relative;
    width: 1200px;
    margin: 0 auto; }
  .p--sliderArea .swiper-container {
    width: 1152px; }
  .p--sliderArea .swiper-button-prev,
  .p--sliderArea .swiper-button-next {
    background: #ff80ab;
    height: 100%;
    top: 0;
    margin: 0;
    width: 25px; }
    .p--sliderArea .swiper-button-prev:hover,
    .p--sliderArea .swiper-button-next:hover {
      background: #e92d82; }
    .p--sliderArea .swiper-button-prev:focus,
    .p--sliderArea .swiper-button-next:focus {
      outline: none; }
  .p--sliderArea .swiper-button-prev {
    left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
  .p--sliderArea .swiper-button-next {
    right: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
  .p--sliderArea .swiper-button-prev:after,
  .p--sliderArea .swiper-button-next:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 6px;
    height: 6px;
    border: solid #fff;
    border-width: 0 2px 2px 0; }
  .p--sliderArea .swiper-button-prev:after {
    left: calc(50% + 2px);
    transform: translate3d(-50%, -50%, 0) rotate(135deg); }
  .p--sliderArea .swiper-button-next:after {
    right: calc(50% - 6px);
    transform: translate3d(-50%, -50%, 0) rotate(-45deg); }
  .p--sliderArea .swiper-pagination {
    width: 100%;
    margin: 3px 0 0; }
  .p--sliderArea .swiper-pagination-bullet {
    border: 2px solid #333 !important;
    background: #fff !important;
    opacity: 1 !important;
    margin: 0 2px; }
  .p--sliderArea .swiper-pagination-bullet-active {
    background: #ff80ab !important; }

@media screen and (max-width: 767px) {
  .p--sliderArea .swiper-custom-parent,
  .p--sliderArea .swiper-container {
    width: 100%; }
  .p--sliderArea .swiper-button-prev,
  .p--sliderArea .swiper-button-next {
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 50%; }
  .p--sliderArea .swiper-button-prev {
    order: 1; }
    .p--sliderArea .swiper-button-prev:after {
      left: calc(50% + 1px); }
  .p--sliderArea .swiper-button-next {
    order: 3; }
    .p--sliderArea .swiper-button-next:after {
      right: calc(50% - 5px); }
  .p--sliderArea .swiper-pagination {
    display: flex;
    position: relative;
    order: 2;
    width: auto;
    margin: 0 6px; }
  .p--sliderArea .p--top__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px 0 0; } }

/** ========================================
 * @p--search__jobFair
 * 合説を探す
 * ====================================== */
.p--search__jobFair {
  padding: 35px 0 60px; }

.p--jobFair__area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 25px 0 0;
  padding: 0 25px;
  background: #dadadb;
  font-size: 0.875rem; }

.p--area {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  margin: 0 0 0 40px; }
  .p--area__heading {
    position: relative;
    padding: 0 0 0 21px; }
    .p--area__heading:before {
      content: "";
      position: absolute;
      display: inline-block;
      top: 50%;
      left: 0;
      width: 16px;
      height: 16px;
      background-size: contain;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      background: url(../images/icon_jobFair.png) 50%/16px no-repeat; }
  .p--area .p--area__link {
    display: inline-block;
    width: calc((100% - 64px) / 9);
    margin: 8px 0;
    padding: 8px 7px;
    border: none;
    border-radius: 4px;
    background: #fff;
    text-align: center; }
    .p--area .p--area__link:hover {
      border-top: 4px solid #e92d82;
      padding-top: 4px; }
    .p--area .p--area__link[aria-selected=true] {
      margin: 8px 0 0;
      padding: 5px 7px 17px;
      border-top: 4px solid #e92d82;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background: #fff7f6;
      color: #e92d82;
      font-weight: bold; }
      .p--area .p--area__link[aria-selected=true]:hover {
        padding-top: 5px; }

.p--jobFair__areaResult {
  margin: 24px 0 0; }
  .p--jobFair__areaResult + .p--btnArea {
    margin: 25px 0 0; }

.p--areaResult {
  display: flex;
  flex-wrap: wrap; }
  .p--areaResult[aria-hidden=true] {
    display: none; }
  .p--areaResult__item {
    width: calc((100% - 56px) / 8);
    margin: 0 8px 0 0; }
    .p--areaResult__item:nth-child(8n) {
      margin: 0; }
    .p--areaResult__item:nth-child(n + 9) {
      margin-top: 8px; }
    .p--areaResult__item a {
      display: inline-block;
      width: 100%;
      height: 100%;
      padding: 2px;
      border: 1px solid #dadadb; }
    .p--areaResult__item .p--item__heading {
      padding: 4px 14px;
      background: #f5f5f5;
      color: #e92d82;
      font-weight: bold;
      text-align: center; }
      .p--areaResult__item .p--item__heading.c--arrow--p__right:before {
        right: 5px; }
    .p--areaResult__item .p--item__btm {
      padding: 7px;
      text-align: center; }
    .p--areaResult__item .p--item__tag {
      display: inline-block;
      min-width: 56px;
      padding: 0 7px;
      border: 1px solid #e92d82;
      border-radius: 100px;
      color: #e92d82;
      font-size: 0.625rem; }
      .p--areaResult__item .p--item__tag--bgOn {
        border-color: #ff80ab;
        background: #ff80ab;
        color: #fff; }
      .p--areaResult__item .p--item__tag--lightBgOn {
        border-color: #ff80ab;
        background: #fff7f6;
        color: #ff80ab; }
    .p--areaResult__item .p--item__date {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 3px 0 0;
      font-weight: bold; }
      .p--areaResult__item .p--item__date .p--date {
        font-size: 1.875rem; }
      .p--areaResult__item .p--item__date .p--week {
        display: inline-block;
        width: 25px;
        height: 25px;
        margin: 3px 0 0 2px;
        border: 1px solid #e92d82;
        border-radius: 50%;
        color: #e92d82; }
        .p--areaResult__item .p--item__date .p--week--pale {
          color: #ff80ab; }

@media screen and (max-width: 767px) {
  .p--search__jobFair {
    padding: 14px 0 40px; }
  .p--area .p--area__link {
    margin: 4px 0; }
  .p--jobFair__area {
    display: block;
    padding: 18px 20px; }
    .p--jobFair__area .p--area {
      flex-wrap: wrap;
      margin: 10px 0 0; }
      .p--jobFair__area .p--area__link {
        width: calc((100% - 16px) / 3);
        font-size: 0.75rem; }
        .p--jobFair__area .p--area__link[aria-selected=true] {
          margin: 4px 0;
          padding: 4px 7px 8px;
          border-radius: 4px;
          background: #fff; }
          .p--jobFair__area .p--area__link[aria-selected=true]:hover {
            padding-top: 4px; }
  .p--jobFair__areaResult {
    padding: 0 10px; }
    .p--jobFair__areaResult + .p--btnArea {
      margin: 23px 0 0; }
      .p--jobFair__areaResult + .p--btnArea .c--btn {
        width: calc(100% - 20px); }
  .p--areaResult__item {
    width: calc((100% - 15px) / 4);
    margin: 0 5px 0 0; }
    .p--areaResult__item:nth-child(4n) {
      margin: 0; }
    .p--areaResult__item:nth-child(n + 5) {
      margin-top: 5px; }
    .p--areaResult__item .p--item__heading {
      padding: 4px 14px 4px 0;
      font-size: 0.625rem; }
    .p--areaResult__item .p--item__btm {
      padding: 10px 5px; }
    .p--areaResult__item .p--item__date .p--date {
      font-size: 1.125rem; }
    .p--areaResult__item .p--item__date .p--week {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      margin: 0 0 0 2px;
      font-size: 0.625rem;
      line-height: 1.1; } }

/** ========================================
 * @p--featured
 * 注目の特集
 * ====================================== */
.p--featured {
  padding: 57px 0;
  background: #fff url(../images/featured_bg_pc.png) no-repeat left top/100%; }
  .p--featured .c--dot__line {
    margin: 24px 0; }
  .p--featured .c--secondary__heading {
    margin: 12px 0 0; }
    .p--featured .c--secondary__heading + p {
      margin: 25px 0 0; }
  .p--featured .p--btnArea {
    margin: 28px 0 0; }
  .p--featured__main {
    display: flex;
    justify-content: space-between;
    margin: 40px 0 0; }
  .p--featured .p--main__img {
    width: 576px; }
    .p--featured .p--main__img img {
      height: 280px; }
  .p--featured .p--main__txt {
    width: calc(100% - 588px - 48px); }
  .p--featured__sub .c--item__img {
    width: 282px; }
    .p--featured__sub .c--item__img img {
      height: 180px; }

@media screen and (max-width: 767px) {
  .p--featured {
    padding: 40px 20px;
    background: #fff url(../images/featured_bg_sp.png) no-repeat left top/100%; }
    .p--featured .c--primary__heading {
      padding: 0; }
    .p--featured .c--secondary__heading {
      margin: 8px 0 0; }
      .p--featured .c--secondary__heading + p {
        margin: 15px 0 0;
        line-height: 1.7; }
    .p--featured__main {
      display: block;
      margin: 25px 0 0; }
    .p--featured .p--main__img {
      width: 100%; }
      .p--featured .p--main__img img {
        height: calc((100vw - 20px) / 2.2207); }
    .p--featured .p--main__txt {
      width: 100%;
      margin: 17px 0 0; }
      .p--featured .p--main__txt .c--btn {
        width: 100%; }
    .p--featured__sub .c--item__img {
      width: 100%; }
      .p--featured__sub .c--item__img img {
        height: calc((50vw - 28px) / 1.5501) !important; }
  .c--card--spCol1 {
    padding: 0 20px; }
    .c--card--spCol1.c--card a {
      flex-direction: row;
      flex-wrap: wrap; }
    .c--card--spCol1 .c--card__item {
      padding: 0 0 20px; }
      .c--card--spCol1 .c--card__item:not(:first-child) {
        margin: 15px 0 0; }
      .c--card--spCol1 .c--card__item .c--item__img {
        width: 120px; }
        .c--card--spCol1 .c--card__item .c--item__img img {
          height: 72.7px; }
      .c--card--spCol1 .c--card__item .c--item__ttl {
        width: calc(100% - 128px);
        margin: 10px 0 0 8px;
        padding: 0;
        font-size: 0.875rem; }
      .c--card--spCol1 .c--card__item .c--item__txt {
        width: 100%;
        padding: 0;
        margin-top: 10px; }
        .c--card--spCol1 .c--card__item .c--item__txt span {
          width: auto;
          margin: 0 15px 0 0; }
        .c--card--spCol1 .c--card__item .c--item__txt span:nth-child(n+3) {
          margin-top: 0; } }

/** ========================================
 * @p--info
 * お役立ち情報
 * ====================================== */
.p--info {
  padding: 55px 0 32px; }
  .p--infoLink {
    display: flex;
    justify-content: space-between;
    margin: 42px 0 0; }
    .p--infoLink__item {
      width: 588px;
      height: 220px; }
      .p--infoLink__item img {
        border-radius: 4px; }
    .p--infoLink__master {
      display: flex;
      justify-content: space-between;
      padding: 0 30px 0 25px;
      background: url(../images/info_master_pc.png) no-repeat left top/100%;
      color: #fff; }
      .p--infoLink__master--simple {
        position: relative; }
        .p--infoLink__master--simple .p--master__a {
          position: absolute;
          bottom: 24px;
          left: 50%;
          width: 360px !important;
          padding: 10px 20px !important;
          transform: translateX(-50%); }
  .p--info .p--master__ttl {
    padding: 10px 0;
    width: 268px; }
    .p--info .p--master__ttl .c--arrow--circle {
      color: #fff;
      margin: 13px 0 0 6px; }
  .p--info .p--master__txt {
    width: 230px;
    padding: 21px 0 0;
    font-size: 0.75rem; }
    .p--info .p--master__txt a {
      font-size: 0.875rem;
      font-weight: bold; }
  .p--info .p--master__top {
    display: flex;
    justify-content: space-between; }
    .p--info .p--master__top span {
      display: inline-block;
      margin: 0 0 0 2px;
      letter-spacing: 2px; }
  .p--info .p--master__star {
    color: #fff04d; }
  .p--info .p--master__q {
    margin: 10px 0 0;
    line-height: 1.8; }
  .p--info .p--master__a {
    width: 100%;
    margin: 20px 0 0;
    padding: 9px 20px;
    color: #e92d82; }

.p--infoMenu {
  display: flex;
  margin: 24px 0 0;
  border: 1px solid #dadadb;
  border-radius: 4px; }
  .p--infoMenu__item {
    width: calc(100% / 6); }
    .p--infoMenu__item:not(:first-child) {
      border-left: 1px solid #dadadb; }
    .p--infoMenu__item .p--item {
      width: 100%;
      height: 100%;
      padding: 23px 0;
      text-align: center;
      font-size: 1rem; }
      .p--infoMenu__item .p--item .p--btn__icon:before {
        width: 80px;
        height: 80px;
        background-size: 80px;
        margin: 0 auto 17px; }
      .p--infoMenu__item .p--item .p--btn__icon:after {
        width: 44px;
        background-size: 100% !important; }
      .p--infoMenu__item .p--item .p--btn__icon--work:after {
        height: 46px;
        background: url(../images/icon_work.png) 50% no-repeat; }
      .p--infoMenu__item .p--item .p--btn__icon--topics:after {
        height: 46px;
        background: url(../images/icon_topics.png) 50% no-repeat; }
      .p--infoMenu__item .p--item .p--btn__icon--analysis:after {
        height: 46px;
        background: url(../images/icon_analysis.png) 50% no-repeat; }
      .p--infoMenu__item .p--item .p--btn__icon--practice:after {
        height: 46px;
        background: url(../images/icon_practice.png) 50% no-repeat; }
      .p--infoMenu__item .p--item .p--btn__icon--consult:after {
        height: 46px;
        background: url(../images/icon_consult.png) 50% no-repeat; }
      .p--infoMenu__item .p--item .p--btn__icon--ne:after {
        height: 46px;
        background: url(../images/icon_ne.png) 50% no-repeat; }

@media screen and (max-width: 767px) {
  .p--info {
    padding: 39px 20px 32px; }
    .p--info .c--primary__heading {
      padding: 0; }
    .p--infoLink {
      display: block;
      margin: 25px 0 0; }
      .p--infoLink__item {
        width: 100%;
        height: auto; }
      .p--infoLink__master {
        display: block;
        margin: 25px 0 0;
        padding: 0 16px 62px;
        border-radius: 4px;
        background: url(../images/info_master_sp-top.png) no-repeat left top/100%, url(../images/info_master_sp-btm.png) no-repeat left bottom/100%, url(../images/info_master_sp-mdl.png) repeat left top 25px/100%; }
        .p--infoLink__master img {
          padding: 0 5px; }
        .p--infoLink__master--simple {
          margin: 20px 0 0; }
          .p--infoLink__master--simple .p--master__a {
            width: 61% !important;
            bottom: 14px;
            padding: 5px 20px !important;
            font-size: 0.5rem; }
    .p--info .p--master__ttl {
      width: 100%;
      padding: 24px 3px 0; }
      .p--info .p--master__ttl .c--arrow--circle {
        margin: 15px 0 0 0; }
    .p--info .p--master__txt {
      width: 100%;
      padding: 27px 8px 0; }
      .p--info .p--master__txt a {
        padding: 14px 20px; }
    .p--info .p--master__top {
      justify-content: flex-start; }
      .p--info .p--master__top p + p {
        margin: 0 0 0 15px; }
    .p--info .p--master__a {
      margin: 18px 0 0; }
  .p--infoMenu {
    flex-wrap: wrap;
    border-top: none; }
    .p--infoMenu__item {
      width: calc(100% / 3);
      border-top: 1px solid #dadadb;
      border-right: 1px solid #dadadb; }
      .p--infoMenu__item:not(:first-child) {
        border-left: none; }
      .p--infoMenu__item:nth-child(3n) {
        border-right: none; }
      .p--infoMenu__item .p--item {
        padding: 17px 0 23px;
        font-size: 0.6875rem;
        font-weight: normal; }
        .p--infoMenu__item .p--item .p--btn__icon:before {
          width: 60px;
          height: 60px;
          margin: 0 auto 6px; }
        .p--infoMenu__item .p--item .p--btn__icon:after {
          width: 36px; } }

/** ========================================
 * @p--hospital
 * お勧めの病院
 * ====================================== */
.p--hospital {
  padding: 25px 0 60px; }
  .p--hospital .c--contents__inner {
    width: 1200px !important; }
  .p--hospital__card {
    margin: 42px 0 0; }
    .p--hospital__card .c--item__ttl {
      font-size: 0.875rem; }
    .p--hospital__card .c--item__txt {
      font-size: 0.75rem; }

@media screen and (max-width: 767px) {
  .p--hospital {
    margin-top: 20px;
    padding: 20px 0 40px;
    background: #fff; }
    .p--hospital .c--contents__inner {
      width: 100% !important; }
    .p--hospital__card {
      margin: 25px 0 0; }
      .p--hospital__card.c--hoverShadow--shadow .c--hoverShadow__item {
        box-shadow: none; } }

/** ========================================
 * @p--news
 * お知らせ
 * ====================================== */
.p--news {
  padding: 60px 0 0; }
  .p--news .c--dot__line {
    margin: 60px 0 0; }
  .p--news__inner {
    display: flex;
    align-items: center; }
  .p--news .c--primary__heading {
    width: 204px;
    text-align: left; }
  .p--news .p--notices__list {
    width: calc(100% - 204px);
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0; }
    .p--news .p--notices__list a {
      border-radius: 4px; }

@media screen and (max-width: 767px) {
  .p--news {
    padding: 40px 10px 0; }
    .p--news .c--dot__line {
      display: none; }
    .p--news__inner {
      display: block; }
    .p--news .c--primary__heading {
      width: 100%;
      padding: 0 10px;
      text-align: center; }
    .p--news .c--notices__list {
      width: 100%;
      margin: 25px 0 0; }
      .p--news .c--notices__list .c--list__item:first-child {
        border-top: 1px solid #f0f0f0; }
      .p--news .c--notices__list a, .p--news .c--notices__list div {
        flex-direction: column;
        padding: 7px 5px 19px;
        font-size: 0.875rem; }
        .p--news .c--notices__list a.c--arrow--p__right:before, .p--news .c--notices__list div.c--arrow--p__right:before {
          right: 15px !important; }
        .p--news .c--notices__list a .c--item__txt, .p--news .c--notices__list div .c--item__txt {
          width: calc(100% - 20px);
          margin: 7px 0 0; } }

/** ========================================
 * @p--top__fixed__bnr
 * 追従バナー(SPのみ)
 * ====================================== */
.p--top__fixedBnr {
  display: none; }

@media screen and (max-width: 767px) {
  .p--top__fixedBnr {
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 1010; }
    .p--top__fixedBnr .p--closeBtnBox {
      position: absolute;
      top: 0;
      right: 0; }
    .p--top__fixedBnr .p--closeBtn {
      position: relative;
      width: 25px;
      height: 25px;
      padding: 0;
      background: transparent;
      border: none; }
      .p--top__fixedBnr .p--closeBtn:before, .p--top__fixedBnr .p--closeBtn:after {
        content: "";
        position: absolute;
        left: 50%;
        width: 15px;
        height: 2px;
        background-color: #000;
        top: 50%; }
      .p--top__fixedBnr .p--closeBtn:before {
        transform: translateY(-50%) translateX(-50%) rotate(-45deg); }
      .p--top__fixedBnr .p--closeBtn:after {
        transform: translateY(-50%) translateX(-50%) rotate(45deg); } }

@media screen and (max-width: 767px) {
  .p--areaResult__item .p--item__tag {
    position: relative;
    height: 17px;
    margin: 4px 0 0; }
    .p--areaResult__item .p--item__tag i {
      display: inline-block;
      white-space: nowrap;
      position: absolute;
      left: 50%;
      top: 50%;
      height: 10px;
      transform: translateX(-50%) translateY(-50%) scale(0.7, 1);
      line-height: 1; } }

/*! 
 * お気に入り
 */
/** ========================================
 * @p--mypage
 * マイページエリア
 * ====================================== */
.p--fav__top {
  padding: 25px 0 60px; }

.p--fav__rmd {
  padding: 55px 0 60px;
  background: #fff; }
  .p--fav__rmd .c--card {
    margin: 40px 0 0; }

.p--fav__hospital {
  padding: 55px 0 115px; }
  .p--fav__hospital .c--card {
    margin: 40px 0 0; }
  .p--fav__hospital .c--hoverShadow__item {
    background: #fff7f6; }

.p--show__btn:hover {
  cursor: pointer; }

.p--show__btn--close, .p--show__btn--open {
  position: relative;
  height: 30px; }
  .p--show__btn--close:before, .p--show__btn--close:before, .p--show__btn--open:before, .p--show__btn--open:before {
    content: "";
    position: absolute;
    top: 48%;
    width: 12px;
    height: 12px;
    border: solid #e92d82;
    border-width: 0 2px 2px 0;
    z-index: 10;
    left: 50%; }

.p--show__btn--close:before {
  transform: translateY(-50%) rotate(45deg); }

.p--show__btn--open:before {
  margin: 5px 0 0;
  transform: translateY(-50%) rotate(-135deg); }

.p--show__block {
  overflow: hidden; }

.p--infoBlock {
  box-shadow: 2px 2px 2px rgba(97, 97, 106, 0.2), 1px 1px 5px rgba(97, 97, 106, 0.1); }

/** ========================================
 * @p--reg
 * 登録情報一覧エリア
 * ====================================== */
.p--reg {
  margin: 40px 0 0; }
  .p--reg__top {
    display: flex;
    justify-content: space-between; }
    .p--reg__top .p--top__num {
      font-size: 1rem;
      font-weight: bold; }
      .p--reg__top .p--top__num i {
        color: #e92d82;
        font-size: 1.5rem; }
    .p--reg__top .p--top__btn {
      display: flex;
      align-items: center; }
      .p--reg__top .p--top__btn .c--btn {
        height: 38px;
        padding: 7px;
        font-size: 0.8125rem;
        vertical-align: middle; }
      .p--reg__top .p--top__btn .c--select__tcArea {
        width: 230px;
        margin: 0 0 0 8px; }
  .p--reg__block {
    margin: 25px 0 0; }
  .p--reg__item {
    box-shadow: 2px 2px 2px rgba(97, 97, 106, 0.2), 1px 1px 5px rgba(97, 97, 106, 0.1); }
    .p--reg__item:not(:first-of-type) {
      margin: 33px 0 0; }
  .p--reg__head {
    position: relative;
    padding: 12px 55px 14px 25px;
    border-top: 2px solid #e92d82;
    border-bottom: 1px solid #dadadb;
    background: #fff;
    font-size: 1.25rem;
    font-weight: bold; }
    .p--reg__head:hover {
      cursor: pointer; }
    .p--reg__head:before {
      content: "";
      position: absolute;
      top: 0;
      right: 54px;
      display: inline-block;
      width: 1px;
      height: 100%;
      background: #dadadb; }
    .p--reg__head:after {
      content: "";
      position: absolute;
      top: 50%;
      width: 12px;
      height: 12px;
      border: solid #e92d82;
      border-width: 0 2px 2px 0;
      z-index: 10;
      transform: translateY(-50%) rotate(45deg);
      right: 20px; }
    .p--reg__head.p--tgl--open:after {
      transform: translateY(-50%) rotate(-135deg); }
    .p--reg__head .c--ovalTag {
      width: auto;
      padding: 0 10px; }
    .p--reg__head--finish {
      background: #dadadb;
      border-bottom-color: #bebec1; }
      .p--reg__head--finish:before {
        background: #bebec1; }
      .p--reg__head--finish + .p--reg__body {
        background: #dadadb; }
        .p--reg__head--finish + .p--reg__body .p--body__btm .p--favAnime {
          background: #dadadb;
          border: 1px solid #bebec1; }
  .p--reg__body {
    padding: 21px 24px 16px 24px;
    background: #fff; }
    .p--reg__body--open {
      display: block; }
    .p--reg__body .p--body__list {
      display: flex; }
      .p--reg__body .p--body__list .p--list__item {
        width: calc(25% - 10px);
        padding: 4px 4px 8px;
        background: #fafafa;
        text-align: center; }
        .p--reg__body .p--body__list .p--list__item:not(:first-child) {
          margin: 0 0 0 10px; }
        .p--reg__body .p--body__list .p--list__item--on {
          background: #f1f6fa; }
        .p--reg__body .p--body__list .p--list__item .p--item__heading {
          padding: 5px;
          background: #fff; }
        .p--reg__body .p--body__list .p--list__item .c--btn {
          width: calc(100% - 4px);
          padding: 11px 5px; }
          .p--reg__body .p--body__list .p--list__item .c--btn--disabled {
            border: 2px solid #dadadb;
            background: #dadadb;
            color: #828288; }
        .p--reg__body .p--body__list .p--list__item .p--item__icon {
          position: relative;
          width: 36px;
          height: 36px;
          margin: 16px auto; }
          .p--reg__body .p--body__list .p--list__item .p--item__icon:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: 100%;
            height: 100%;
            background-size: contain !important;
            background-repeat: no-repeat; }
          .p--reg__body .p--body__list .p--list__item .p--item__icon.p--item__icon--on:before {
            background-image: url(../images/icon_mypage.png); }
          .p--reg__body .p--body__list .p--list__item .p--item__icon.p--item__icon--on__event:before {
            background-image: url(../images/icon_mypage_event.png); }
          .p--reg__body .p--body__list .p--list__item .p--item__icon.p--item__icon--off:before {
            background-image: url(../images/icon_mypage_gray.png); }
          .p--reg__body .p--body__list .p--list__item .p--item__icon.p--item__icon--off__event:before {
            background-image: url(../images/icon_mypage_gray_event.png); }
          .p--reg__body .p--body__list .p--list__item .p--item__icon.p--item__icon--noSelect:before {
            background: #dadadb;
            height: 1px; }
    .p--reg__body .p--body__btm {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 13px 0 0;
      font-size: 0.75rem; }
      .p--reg__body .p--body__btm .p--btm__date {
        display: flex; }
        .p--reg__body .p--body__btm .p--btm__date p:not(:first-child) {
          margin: 0 0 0 20px; }
        .p--reg__body .p--body__btm .p--btm__date a {
          color: #e92d82;
          text-decoration: underline; }
      .p--reg__body .p--body__btm .p--fav__btn {
        position: relative;
        padding: 11px 9px 11px 30px; }
    .p--reg__body--nopd {
      padding: 0 0 16px; }
      .p--reg__body--nopd .p--body__btm {
        padding-right: 24px;
        padding-left: 24px; }
    .p--reg__body .p--reg__txt__bgGy {
      background: #fafafa;
      padding: 10px;
      font-size: 0.75rem; }

@media screen and (max-width: 767px) {
  .p--fav__top {
    padding-bottom: 30px; }
  .p--fav__hospital {
    padding: 30px 0 55px;
    background: #fff; }
    .p--fav__hospital .c--card {
      margin-top: 20px; }
    .p--fav__hospital .c--hoverShadow__item {
      background: #fff; }
  /** ========================================
   * @p--reg
   * 登録情報一覧エリア
   * ====================================== */
  .p--reg {
    margin: 10px 0 0; }
    .p--reg__top {
      flex-direction: column;
      padding: 0 20px; }
      .p--reg__top .p--top__num {
        font-size: 0.875rem; }
        .p--reg__top .p--top__num i {
          font-size: 1.25rem; }
      .p--reg__top .p--top__btn {
        margin: 10px 0 0; }
        .p--reg__top .p--top__btn .c--btn {
          width: calc(50% - 4px); }
        .p--reg__top .p--top__btn .c--select__tcArea {
          width: calc(50% - 4px); }
    .p--reg__head {
      padding: 15px 55px 15px 21px;
      font-size: 1rem; }
      .p--reg__head .c--ovalTag {
        width: auto;
        margin: 0;
        padding: 5px;
        font-size: .625rem;
        font-weight: 400;
        line-height: 1; }
    .p--reg__body {
      margin: 0;
      padding: 0; }
      .p--reg__body > P {
        padding: 10px 20px 0; }
      .p--reg__body .p--body__list .p--list__item {
        width: calc(25% - 1px); }
        .p--reg__body .p--body__list .p--list__item:not(:first-child) {
          margin: 0 0 0 1px; }
        .p--reg__body .p--body__list .p--list__item .p--item__heading {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 48px; }
        .p--reg__body .p--body__list .p--list__item .p--item__icon {
          margin: 11px auto; }
      .p--reg__body .p--body__btm {
        margin: 0;
        padding: 10px 19px 15px 21px; }
        .p--reg__body .p--body__btm .p--btm__date {
          display: block;
          font-size: 0.625rem; }
          .p--reg__body .p--body__btm .p--btm__date p:not(:first-child) {
            margin: 0; }
        .p--reg__body .p--body__btm .p--fav__btn {
          width: 38px;
          height: 38px;
          padding: 0; } }

@media screen and (max-width: 320px) {
  .p--reg__body .p--body__list .p--list__item .p--item__heading {
    font-size: .7rem; } }

/** ========================================
 * @p--history
 * 活動履歴
 * ====================================== */
.p--history .p--reg {
  margin-top: 15px; }
  .p--history .p--reg .p--top__narrowDown {
    display: flex;
    align-items: flex-start; }
    .p--history .p--reg .p--top__narrowDown li:not(:first-child) {
      margin: 0 0 0 20px;
      padding: 0 0 0 20px;
      border-left: 1px solid #dadadb; }
      .p--history .p--reg .p--top__narrowDown li:not(:first-child) a {
        color: #e92d82; }

.p--history .p--topBdBox {
  margin: 30px 0 0; }
  .p--history .p--topBdBox__heading {
    padding: 0; }
    .p--history .p--topBdBox__heading.p--topBdBox__link {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      padding: 12px 21px 12px 26px; }
      .p--history .p--topBdBox__heading.p--topBdBox__link .c--arrow--circle:before {
        top: .3rem;
        left: 0;
        transform: none; }
      .p--history .p--topBdBox__heading.p--topBdBox__link .p--moreMsg {
        display: block;
        margin: 0 0 0 20px;
        color: #e92d82;
        font-size: 0.875rem;
        font-weight: normal;
        line-height: 1; }
      .p--history .p--topBdBox__heading.p--topBdBox__link .p--date {
        font-size: 0.75rem;
        font-weight: normal; }

.p--history .p--contBox {
  padding: 20px 25px; }

.p--headLpBox:not(:first-child) {
  margin: 10px 0 0; }

.p--headLpBox--finished .p--headLp__ttl {
  background: #e5d7db; }
  .p--headLpBox--finished .p--headLp__ttl .c--ovalTag {
    background: #e5e5e5; }

.p--headLpBox--finished .p--headLp__body {
  background: #e5e5e5; }

.p--headLp__ttl {
  display: flex;
  justify-content: space-between;
  padding: 7px 13px;
  background: #ffeff4;
  color: #e92d82;
  font-weight: bold; }
  .p--headLp__ttl--off {
    color: #333;
    font-weight: normal; }
  .p--headLp__ttl .p--ttl__date {
    color: #333;
    font-size: 0.75rem;
    font-weight: normal; }
  .p--headLp__ttl .c--ovalTag {
    margin: 0 12px 0 0;
    font-weight: normal; }
  .p--headLp__ttl.c--arrow--p__right::before {
    right: 10px; }

.p--headLp__body {
  padding: 10px 13px;
  font-size: 0.75rem; }
  .p--headLp__body .p--headLp__list p {
    margin: 5px 0 0; }
    .p--headLp__body .p--headLp__list p span {
      display: inline-block;
      margin: 0 10px 0 0;
      color: #818187; }
  .p--headLp__body .p--headLp__steteBox {
    margin: 14px 0 0; }
    .p--headLp__body .p--headLp__steteBox .c--ovalTag {
      width: auto;
      padding: 2px 10px;
      font-size: 0.625rem; }

@media screen and (max-width: 767px) {
  .p--history .c--select__tcArea {
    width: 100%; }
  .p--history .p--contBox {
    padding: 10px 20px 15px; }
  .p--headLp__ttl {
    display: block; }
    .p--headLp__ttl .c--ovalTag {
      width: auto;
      min-width: 96px;
      padding: 1px 12px;
      font-size: 0.625rem; }
    .p--headLp__ttl i {
      display: block;
      margin: 5px 0 0; }
    .p--headLp__ttl p + p {
      margin: 3px 0 0; }
  .p--headLp__body .p--headLp__steteBox .c--btn {
    width: 100%;
    font-size: 0.75rem; } }

/** ========================================
 * @p--rsv
 * 活動履歴
 * ====================================== */
.p--rsv .c--primary__heading + .p--topBdBox {
  margin: 35px 0 0; }

.p--rsv .p--contBox__inner {
  width: 847px;
  margin: 0 auto; }

.p--rsv .p--contBox {
  padding: 38px 20px; }

.p--rsv .p--headLpBox:not(:first-child) {
  margin: 25px 0 0; }

.p--rsv .p--headLpBox + .p--topBdBox__infoTxt {
  margin: 32px 0 0;
  padding: 32px 0 0;
  border-top: 1px dotted #dadadb; }

.p--rsv .p--headLp__body {
  padding: 15px 13px; }

@media screen and (max-width: 767px) {
  .p--rsv .p--contBox__inner {
    width: 100%; }
  .p--rsv .p--contBox {
    padding: 13px 0; }
  .p--rsv .p--topBdBox__infoTxt {
    padding: 0 20px; }
  .p--rsv .p--headLpBox {
    padding: 0 20px; }
    .p--rsv .p--headLpBox:not(:first-child) {
      margin: 13px 0 0; }
    .p--rsv .p--headLpBox .p--headLp__ttl {
      padding: 10px 13px; }
    .p--rsv .p--headLpBox + .p--topBdBox__infoTxt {
      margin: 22px 0 0;
      padding: 22px 20px 0; }
  .p--rsv .p--headLp__body {
    padding: 10px 0;
    font-size: 0.875rem; } }

/** ========================================
 * @p--rsv
 * 各種予約・応募
 * ====================================== */
.p--rsv__detail .p--headLp__body {
  padding: 15px 0;
  font-size: 0.875rem; }

.p--rsvTable {
  width: 100%;
  height: 1px;
  margin: 20px 0 0; }
  .p--rsvTable tr {
    border-bottom: 1px solid #DADADB; }
  .p--rsvTable th, .p--rsvTable td {
    height: 100%;
    padding: 10px 15px;
    font-size: 0.8125rem;
    vertical-align: middle; }
    .p--rsvTable th:not(:first-child), .p--rsvTable td:not(:first-child) {
      border-left: 1px solid #DADADB; }
  .p--rsvTable th {
    background: #F5F5F5;
    text-align: left; }
  .p--rsvTable .c--btn {
    width: 100%; }
    .p--rsvTable .c--btn + .c--btn {
      margin-top: 5px; }
  .p--rsvTable .p--col2 {
    padding: 0; }
    .p--rsvTable .p--col2 > div {
      display: flex;
      align-items: center;
      height: 100%; }
      .p--rsvTable .p--col2 > div span {
        display: inline-block;
        width: 50%;
        height: 100%;
        padding: 10px 15px;
        display: flex;
        align-items: center; }
        .p--rsvTable .p--col2 > div span + span {
          border-left: 1px solid #DADADB; }

.p--guideLink {
  margin: 12px 5px 0;
  text-align: right; }
  .p--guideLink a {
    color: #e92d82;
    font-size: 0.875rem; }

.p--icon__route:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 18px;
  background: url(../images/icon_train.png) no-repeat;
  margin: 0 3px 0 0;
  vertical-align: middle; }

@media screen and (max-width: 767px) {
  .p--tag__finish--sp {
    display: inline-block;
    padding: 3px 15px;
    border-color: #f0f0f0;
    border-radius: 100px;
    background: #f0f0f0;
    color: #bebec1;
    font-size: 0.625rem; }
  .p--rsvTable {
    height: auto; }
    .p--rsvTable tr:nth-child(2) {
      border-top: 1px solid #dadadb; }
    .p--rsvTable th {
      display: none; }
    .p--rsvTable td {
      display: block;
      padding: 7px 0 0; }
      .p--rsvTable td:not(:first-child) {
        border: none; }
      .p--rsvTable td:last-child, .p--rsvTable td.p--sp__lastItem {
        padding-bottom: 15px; }
    .p--rsvTable .c--btn + .c--btn {
      margin-top: 10px; }
    .p--rsvTable .p--col2 > div {
      display: flex;
      align-items: center;
      height: 100%; }
      .p--rsvTable .p--col2 > div span {
        width: auto;
        margin: 5px 0 0;
        padding: 0 30px 0 0; }
        .p--rsvTable .p--col2 > div span + span {
          border-left: none; } }

/** ========================================
 * @p--rsv
 * 各種予約・応募
 * ====================================== */
.p--qrArea {
  width: 140px;
  margin: 30px auto 0; }

@media screen and (max-width: 767px) {
  .p--qrArea {
    margin: 20px auto 0; } }

/** ========================================
 * @p--checkArea
 * 「忘れずにこちらもチェック」エリア
 * ====================================== */
.p--checkArea {
  margin: 25px 0 0;
  padding: 13px 25px; }
  .p--checkArea .p--ttl {
    font-size: 0.875rem; }
  .p--checkArea .c--btn {
    min-width: auto;
    margin: 13px 0 0;
    padding: 10px 20px;
    font-size: .875rem; }
    .p--checkArea .c--btn + p {
      margin: 7px 0 10px; }

/** ========================================
 * お気に入りボタンアニメ
 * ====================================== */
.p--favAnime {
  position: relative;
  padding: 0 0 0 10px !important; }
  .p--favAnime:before {
    display: none; }
  .p--favAnime .p--favAnime__img {
    position: absolute;
    top: calc(50% - 10px);
    right: 0;
    width: 122px;
    height: 20px; }
  .p--favAnime.c--btn {
    width: 147px;
    height: 40px; }
    .p--favAnime.c--btn .p--favAnime__img {
      right: 12px; }

.p--favAnime--img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px !important;
  height: 70px !important;
  padding: 0 !important; }
  .p--favAnime--img.c--sp__only {
    display: none; }
  .p--favAnime--img span {
    width: 42px;
    height: 30px;
    background: url(../images/fav_anime_sp.png) no-repeat; }
  .p--favAnime--img.on span {
    background-position: -2348px 0; }
  .p--favAnime--img.off span {
    background-position: 0 0; }
  .p--favAnime--img.move.on span, .p--favAnime--img.move.off span {
    -webkit-transition: background 1s steps(55);
    transition: background 1s steps(55); }

@media screen and (max-width: 767px) {
  .p--favAnime--img {
    width: 44px !important;
    height: 44px !important; }
    .p--favAnime--img.c--sp__only {
      display: flex !important; } }

/*! 
 * 病院比較
 */
/** ========================================
 * @p--comparison__ctrl
 * ====================================== */
.p--compCheckBlock {
  border-radius: 4px;
  box-shadow: 2px 2px 2px rgba(97, 97, 106, 0.2), 1px 1px 5px rgba(97, 97, 106, 0.1); }
  .p--compCheckBlock .p--compCheck__list {
    border-radius: 0 0 4px 4px;
    background: #fff; }
    .p--compCheckBlock .p--compCheck__list .c--control {
      margin: 0;
      padding: 15px 20px 15px 45px;
      width: 100%; }
      .p--compCheckBlock .p--compCheck__list .c--control__indicator {
        left: 23px; }
    .p--compCheckBlock .p--compCheck__list .c--list__item label:hover {
      color: #e92d82; }
    .p--compCheckBlock .p--compCheck__list .c--list__item:not(:first-child) {
      border-top: 1px solid #dadadb; }
    .p--compCheckBlock .p--compCheck__list .c--list__item .c--hoverShadow__item {
      background: none; }
  .p--compCheckBlock .c--control--disabled.c--hoverShadow__item:hover, .p--compCheckBlock .p--schoolCards .c--control--disabled.p--item:hover, .p--schoolCards .p--compCheckBlock .c--control--disabled.p--item:hover {
    box-shadow: none; }

.p--btm__selectArea {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0 0; }
  .p--btm__selectArea .p--select__txt {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: bold; }
    .p--btm__selectArea .p--select__txt i {
      margin: 0 0 0 5px;
      font-size: 1.5rem; }
  .p--btm__selectArea .c--btn {
    margin: 0 0 0 10px;
    font-size: 1rem; }
  .p--btm__selectArea .c--btn--lb {
    margin: 0 0 0 20px; }
  .p--btm__selectArea .c--color--w {
    padding: 18px 31px; }

@media screen and (max-width: 767px) {
  .p--btm__selectArea {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 25px 10px;
    background: rgba(97, 97, 106, 0.9);
    z-index: 1010; }
    .p--btm__selectArea .p--select__txt {
      flex-direction: column;
      color: #fff;
      text-align: center;
      font-size: 0.75rem;
      font-weight: normal;
      line-height: 1.3; }
    .p--btm__selectArea .c--btn {
      margin: 0 0 0 8px;
      padding: 6px 10px;
      font-size: 0.875rem;
      line-height: 1.5; }
    .p--btm__selectArea .c--btn--lb {
      margin: 0 0 0 15px; }
    .p--btm__selectArea .c--btn--w {
      display: flex;
      align-items: center;
      align-self: stretch;
      padding: 6px 7px; } }

@media screen and (max-width: 320px) {
  .p--btm__selectArea .c--btn {
    font-size: 0.75rem; }
  .p--btm__selectArea .c--btn--lb {
    margin: 0 0 0 5px; } }

.p--comparison {
  /** ========================================
 * @p--comparison__ctrl
 * ====================================== */ }
  .p--comparison__ctrl {
    display: flex;
    align-items: center;
    margin-top: 50px;
    padding: 16px 24px;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1), 0 -2px 2px rgba(0, 0, 0, 0.02); }
    .p--comparison__ctrl--bottom {
      margin-top: 40px;
      margin-bottom: 120px; }
  .p--comparison .p--ctrl_msg .p--msg_total,
  .p--comparison .p--ctrl_msg .p--msg_view {
    display: inline-block; }
  .p--comparison .p--ctrl_msg .p--msg_view {
    color: #e92d82; }
  .p--comparison .p--ctrl_msg .l--numFont {
    font-size: 1.25rem;
    font-weight: 700; }
  .p--comparison .p--ctrl_btns {
    flex: 1 1 25%;
    text-align: right; }
    .p--comparison .p--ctrl_btns .c--btn {
      padding: 12px 8px; }
    .p--comparison .p--ctrl_btns .c--btn + .c--btn {
      margin-right: 8px; }
  .p--comparison__text {
    display: none; }

/** ========================================
 * @p--comparisonTable
 * ====================================== */
table.p--comparisonTable,
table.p--comparisonTable * {
  font-size: .8125rem; }

.p--comparisonTable {
  width: 100%;
  height: 100%;
  margin-top: 40px;
  border-collapse: collapse;
  table-layout: fixed;
  /* -------- PC左側の見出し（TFOOT部分は上書き修正） -------- */
  /* -------- 列の数毎の幅調整 -------- */
  /*ipadで悪さをするので一旦コメントアウトします。いらないはず。*/
  /*  &--2item {
    th,
    td {
      width: calc((100% - 114px) / 2);
    }
  }
  &--3item {
    th,
    td {
      width: calc((100% - 114px) / 3);
    }
  }
  &--4item {
    th,
    td {
      width: calc((100% - 114px) / 4);
    }
  }
  &--5item {
    th,
    td {
      width: calc((100% - 114px) / 5);
    }
  }

*/
  /** ------------------------------
   * THEAD
   * 病院名の見出し部分
   * ---------------------------- */
  /** ------------------------------
   * TBODY
   * 内容部分
   * ---------------------------- */
  /** ------------------------------
  * TFOOT
  * ---------------------------- */
  /** ------------------------------
  * パーツ
  * ---------------------------- */
  /* -------- コンテンツのラッパー -------- */
  /* end &__body */
  /* -------- トグルする部分のラッパー -------- */
  /* end &__toggle */
  /* トグルボタン */
  /* -------- モバイル用の見出し -------- */
  /* -------- 受付部分で使ったリンクボタン -------- */ }
  .p--comparisonTable th,
  .p--comparisonTable td {
    height: 100%;
    border-bottom: 1px solid #dadadb; }
    .p--comparisonTable th:not(:last-child),
    .p--comparisonTable td:not(:last-child) {
      border-right: 8px solid #fff7f6; }
    .p--comparisonTable th:first-child,
    .p--comparisonTable td:first-child {
      border-right: 4px solid #fff7f6; }
  .p--comparisonTable td {
    background: #fff; }
  .p--comparisonTable tr th:first-child {
    width: 114px; }
  .p--comparisonTable thead th {
    text-align: left;
    line-height: 1;
    vertical-align: top; }
    .p--comparisonTable thead th:not(:first-child) {
      background: #828288;
      border-bottom: none; }
      .p--comparisonTable thead th:not(:first-child) a {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 18px 12px;
        padding-left: calc(1em + 12px + 6px);
        color: #fff;
        line-height: 1.4; }
        .p--comparisonTable thead th:not(:first-child) a:hover {
          text-decoration: underline; }
        .p--comparisonTable thead th:not(:first-child) a::before {
          content: "";
          position: absolute;
          display: inline-block;
          top: 52%;
          left: 12px;
          width: 1em;
          height: 1em;
          background: #fff;
          border-radius: 50%;
          transform: translateY(-50%); }
        .p--comparisonTable thead th:not(:first-child) a::after {
          content: "";
          position: absolute;
          display: inline-block;
          top: 52%;
          left: 12px;
          width: 6px;
          height: 6px;
          border-top: 2px solid #828288;
          border-right: 2px solid #828288;
          transform: translate(50%, -50%) rotate(45deg); }
      .p--comparisonTable thead th:not(:first-child) p {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 18px 12px;
        color: #fff;
        line-height: 1.4; }
  .p--comparisonTable tbody tr:hover {
    background-color: #f1f6fa; }
    .p--comparisonTable tbody tr:hover th, .p--comparisonTable tbody tr:hover td {
      background-color: #f1f6fa; }
  .p--comparisonTable tbody tr:last-child th,
  .p--comparisonTable tbody tr:last-child td {
    border-bottom: none; }
  .p--comparisonTable tbody th,
  .p--comparisonTable tbody td {
    vertical-align: top; }
  .p--comparisonTable tbody th {
    padding: 20px 14px 20px 0;
    text-align: right; }
  .p--comparisonTable tbody .p--comparisonTable__txtBox {
    position: relative; }
  .p--comparisonTable tfoot {
    /* -------- 見出し -------- */ }
    .p--comparisonTable tfoot tr:first-child th {
      width: 100%;
      background: #f0f0f0; }
      .p--comparisonTable tfoot tr:first-child th div {
        padding: 10px;
        border-top: 1px solid #dadadb; }
    .p--comparisonTable tfoot tr:nth-child(n+2) th:first-child {
      padding: 20px 14px 20px 0;
      text-align: right;
      vertical-align: top; }
  .p--comparisonTable__body {
    position: relative;
    padding: 20px 14px;
    /* -------- モバイル用の見出し -------- */
    /* -------- 薄灰色の文章 -------- */ }
    .p--comparisonTable__body .p--comparisonTable__inner {
      overflow: hidden; }
    .p--comparisonTable__body.is--open .p--comparisonTable__inner {
      height: auto !important; }
    .p--comparisonTable__body li {
      padding-left: 1em;
      text-indent: -1em;
      line-height: 1.6; }
      .p--comparisonTable__body li:not(:first-child) {
        margin-top: 20px; }
      .p--comparisonTable__body li::before {
        content: "・";
        color: #bebec1; }
    .p--comparisonTable__body p + em {
      margin-top: 36px; }
    .p--comparisonTable__body p + p {
      margin-top: 36px; }
    .p--comparisonTable__body p + ul {
      margin-top: 36px; }
    .p--comparisonTable__body em {
      display: inline-block; }
      .p--comparisonTable__body em + p {
        margin-top: 20px; }
    .p--comparisonTable__body .p--point {
      display: block;
      margin-top: 18px;
      color: #bebec1;
      padding-left: 1em;
      text-indent: -1em; }
  .p--comparisonTable__toggle .p--comparisonTable__body:not(.is--open) {
    padding-bottom: 0; }
  .p--comparisonTable__toggle .p--comparisonTable__body.p--comparisonTable__body--fold::after {
    content: "";
    position: absolute;
    display: inline-block;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0), white 100%); }
  .p--comparisonTable__toggle .p--comparisonTable__body.is--open::after, .p--comparisonTable__toggle .p--comparisonTable__body.is--short::after {
    display: none; }
  .p--comparisonTable .p--toggle__btn {
    display: inline-block;
    cursor: pointer;
    padding: 5px 0; }
    .p--comparisonTable .p--toggle__btn::after {
      content: "";
      display: inline-block;
      width: .5em;
      height: .5em;
      margin-left: 5px;
      border-bottom: 2px solid #e92d82;
      border-right: 2px solid #e92d82;
      transform: translateY(-50%) rotate(45deg); }
    .p--comparisonTable .p--toggle__btn.is--active::after {
      transform: translateY(0) rotate(-135deg); }
    .p--comparisonTable .p--toggle__btn--floating {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center; }
  .p--comparisonTable th .p--toggle__btn {
    padding: 10px 6px;
    background: #fff;
    border: 1px solid #dadadb;
    border-radius: 6px;
    color: #e92d82;
    line-height: 1; }
  .p--comparisonTable .p--comparisonTable__body.is--short + .p--toggle__btn::after {
    display: none; }
  .p--comparisonTable__title--sp {
    display: none;
    padding: 8px 6px;
    background: #f1f6fa; }
  .p--comparisonTable_rcvBtn {
    position: relative;
    display: inline-block;
    padding-left: calc(1em + 6px); }
    .p--comparisonTable_rcvBtn::before {
      content: "";
      position: absolute;
      display: inline-block;
      top: 50%;
      left: 0;
      width: 1em;
      height: 1em;
      background: #ff80ab;
      border-radius: 50%;
      transform: translateY(-50%); }
    .p--comparisonTable_rcvBtn::after {
      content: "";
      position: absolute;
      display: inline-block;
      top: 50%;
      left: 0;
      width: 6px;
      height: 6px;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      transform: translate(50%, -50%) rotate(45deg); }

@media screen and (max-width: 767px) {
  .p--comparison__ctrl {
    display: block;
    margin-top: 30px; }
  .p--comparison .p--ctrl_btns, .p--comparison .p--ctrl_msg {
    text-align: center; }
  .p--comparison .p--ctrl_msg {
    margin-bottom: 10px; }
  .p--comparison__text {
    display: block;
    text-align: center;
    margin: 30px auto 0; }
    .p--comparison__text span {
      padding: 0 20px 0 0;
      position: relative;
      display: inline-block; }
      .p--comparison__text span:before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        width: 7px;
        height: 7px;
        border: solid #333;
        border-width: 0 2px 2px 0;
        z-index: 10;
        transform: translateY(-50%) rotate(-45deg); }
      .p--comparison__text span:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 8px;
        width: 7px;
        height: 7px;
        border: solid #333;
        border-width: 0 2px 2px 0;
        z-index: 10;
        transform: translateY(-50%) rotate(-45deg); }
  .p--comparisonTable__scroll {
    overflow-x: scroll;
    padding: 0 10px; }
  .p--comparisonTable {
    /** ------------------------------
     * TBODY
     * 内容部分
     * ---------------------------- */
    /* -------- モバイル用の見出し復活 -------- */
    /* -------- 5病院の場合 -------- */
    /* -------- 4病院の場合 -------- */
    /* -------- 3病院の場合 -------- */
    /* -------- 2病院の場合 -------- */ }
    .p--comparisonTable tr {
      /* -------- 最初の空白のTH削除 -------- */ }
      .p--comparisonTable tr th:first-child {
        display: none; }
      .p--comparisonTable tr th:last-child {
        border-right: 8px solid #fff7f6; }
      .p--comparisonTable tr td:last-child {
        border-right: 8px solid #fff7f6; }
    .p--comparisonTable tbody {
      /* -------- 左側のTH削除 -------- */ }
      .p--comparisonTable tbody tr:hover {
        background-color: #fff; }
        .p--comparisonTable tbody tr:hover th, .p--comparisonTable tbody tr:hover td {
          background-color: #fff; }
      .p--comparisonTable tbody th {
        display: none; }
    .p--comparisonTable tfoot {
      /* -------- 最初の文字表示 -------- */ }
      .p--comparisonTable tfoot tr:first-child th {
        display: table-cell; }
    .p--comparisonTable__title--sp {
      display: block; }
    .p--comparisonTable--5item {
      width: calc(100vw / 3 * 5); }
      .p--comparisonTable--5item th {
        width: calc(100vw / 3); }
    .p--comparisonTable--4item {
      width: calc(100vw / 3 * 4); }
      .p--comparisonTable--4item th {
        width: calc(100vw / 3); }
    .p--comparisonTable--3item th {
      width: calc((100vw - 20px ) / 3); }
      .p--comparisonTable--3item th:last-child {
        border-right: none !important; }
    .p--comparisonTable--3item td:last-child {
      border-right: none !important; }
    .p--comparisonTable--2item th {
      width: calc((100vw - 20px ) / 2); }
      .p--comparisonTable--2item th:last-child {
        border-right: none !important; }
    .p--comparisonTable--2item td:last-child {
      border-right: none !important; }
    .p--comparisonTable .p--toggle__btn::before {
      content: "";
      display: inline-block;
      width: 7px;
      height: 7px;
      border: solid #e92d82;
      border-width: 0 2px 2px 0;
      z-index: 10;
      transform: translateY(-50%) rotate(45deg); }
    .p--comparisonTable .p--toggle__btn.is--active::before {
      transform: translateY(0) rotate(-135deg); }
    .p--comparisonTable .p--toggle__btn::after {
      display: none; } }

@media screen and (max-width: 320px) {
  .p--comparison .p--ctrl_btns .c--btn {
    width: 150px; }
    .p--comparison .p--ctrl_btns .c--btn + .c--btn {
      margin: 10px 0 0; } }

/*! 
 * ログイン関連
 */
/** ========================================
 * @p--login
 * ログイン
======================================== */
.p--login {
  /*全体*/
  /*タイトル*/
  /*下のブロック*/ }
  .p--login__area {
    width: 600px;
    margin: 0 auto; }
  .p--login__box {
    width: 600px;
    margin: 30px auto 0;
    /*ピンク*/
    /*青*/ }
    .p--login__box--p {
      border-top: 2px solid #e92d82; }
    .p--login__box--b {
      border-top: 2px solid #40c4ff; }
    .p--login__box .p--signup__title {
      margin: 20px 0 10px; }
  .p--login__heading {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
    padding: 15px 0;
    border-bottom: 1px solid #dbdbdc; }
  .p--login__block {
    padding: 25px 40px; }
  .p--login__txt {
    font-size: 1rem; }
  .p--login__btn {
    width: 300px; }

/*パスワード*/
.p--pswd__btn {
  width: 300px; }

/*画像認証*/
.p--authentication {
  display: flex; }
  .p--authentication__btn {
    margin-left: 20px; }
    .p--authentication__btn p {
      margin-top: 5px; }

@media screen and (max-width: 767px) {
  .p--login {
    /*タイトル*/
    /*下のブロック*/ }
    .p--login__area {
      width: 100%;
      padding: 0 20px; }
    .p--login__box {
      width: 100%; }
    .p--login__heading {
      font-size: 1rem;
      text-align: left;
      padding: 15px; }
    .p--login__block {
      padding: 25px 20px; }
    .p--login__txt {
      font-size: 0.875rem; }
    .p--login__btn {
      width: 100%; }
  /*パスワード*/
  .p--pswd__btn {
    width: calc(100% - 100px); }
  /*画像認証*/
  .p--authentication {
    display: block; }
    .p--authentication__img img {
      width: auto; }
    .p--authentication__btn {
      margin-left: 0;
      margin-top: 10px; }
      .p--authentication__btn p {
        margin-top: 5px; } }

/** ========================================
 * @p--rule
 * 利用規約
======================================== */
.p--rule .c--contents__inner {
  width: 896px; }

.p--rule .c--boxWht {
  margin: 37px 0 0;
  padding: 27px 25px; }

.p--rule .c--btnArea {
  margin: 40px 0 30px;
  text-align: center; }
  .p--rule .c--btnArea .c--btn--pk {
    width: 296px;
    padding: 20px 22px; }

.p--ruleHeading:not(:first-child) {
  margin: 48px 0 0; }

.p--ruleHeading + p {
  margin: 25px 0 0; }

.p--ruleList {
  margin: 25px 0 0; }
  .p--ruleList dt {
    margin-bottom: 10px;
    font-weight: bold; }
    .p--ruleList dt:not(:first-child) {
      margin-top: 20px; }
  .p--ruleList dd {
    line-height: 1.65; }
  .p--ruleList + p {
    margin: 23px 0 0; }
  .p--ruleList ul li a {
    color: #e92d82; }
  .p--ruleList ul + p {
    margin: 25px 0 0; }
  .p--ruleList .p--rulrList--normal > li {
    padding-left: .9em;
    text-indent: -.9em; }
    .p--ruleList .p--rulrList--normal > li p > a {
      padding-left: .9em; }
  .p--ruleList .p--rulrList--normal .p--rulrList--brackets {
    padding-left: 0; }
    .p--ruleList .p--rulrList--normal .p--rulrList--brackets > li {
      padding-left: 1.5em;
      text-indent: -1.5em; }
  .p--ruleList .p--rulrList--brackets > li {
    padding-left: 1.9em;
    text-indent: -2.5em; }
  .p--ruleList .p--rulrList--brackets .p--rulrList--hyphen {
    padding: 0; }
    .p--ruleList .p--rulrList--brackets .p--rulrList--hyphen > li {
      padding-left: .35em;
      text-indent: -.35em; }
  .p--ruleList .p--rulrList--hyphen {
    padding-left: 1em; }

@media screen and (max-width: 767px) {
  .p--rule .c--contents__inner {
    width: 100%; }
  .p--rule .c--boxWht {
    margin: 37px 0 0;
    padding: 17px 20px; }
  .p--rule .c--btnArea {
    padding: 0; }
    .p--rule .c--btnArea .c--btn--pk {
      width: 100%; }
  .p--ruleBox * {
    word-break: normal !important;
    word-wrap: break-word; } }

/*! 
 * フォーム関係
 */
/** ========================================
 * @p--login
 * ログイン
======================================== */
.p--form {
  width: 895px;
  margin: 40px auto 0; }
  .p--form__main {
    padding: 28px 23px; }
  .p--form__table {
    margin: 45px 0 0; }
    .p--form__table th {
      font-size: 0.8125rem; }
    .p--form__table .p--input__heading {
      display: inline-block;
      min-width: 40px;
      margin: 0 5px 0 0;
      font-size: 0.8125rem; }
    .p--form__table input {
      vertical-align: middle; }
      .p--form__table input + a {
        margin: 0 0 0 10px; }
    .p--form__table .p--step__txt {
      background: #FAFAFA;
      padding: 8px 18px;
      font-size: 0.8125rem; }
      .p--form__table .p--step__txt span {
        display: inline-block;
        margin: 0 5px 0 0;
        font-weight: bold; }
    .p--form__table .c--step__main {
      padding: 10px 15px; }
      .p--form__table .c--step__main .c--main__attenTxt {
        margin: 8px 0 0;
        font-size: 0.75rem; }
      .p--form__table .c--step__main .c--main__infoTxt {
        margin: 15px 0 0;
        font-size: 0.8125rem; }
    .p--form__table .c--arrow--circle {
      vertical-align: middle; }
      .p--form__table .c--arrow--circle::before {
        top: calc(.5rem - 6px);
        transform: none; }
    .p--form__table .c--dsp--inlineBlock {
      vertical-align: middle; }
  .p--form__ruleBox {
    height: 180px;
    margin: 30px 0 0;
    padding: 19px 22px;
    border: 1px solid #dadadb;
    background: #fff;
    overflow-y: scroll; }
  .p--formRegistBtn {
    margin: 40px 0 0; }
    .p--formRegistBtn .c--btn:nth-child(1) {
      width: 155px; }
    .p--formRegistBtn .c--btn:nth-child(2) {
      width: 300px; }
    .p--formRegistBtn .c--btn + .c--btn {
      margin: 0 0 0 8px; }

.p--conf__area th {
  padding: 24px 20px; }

@media screen and (max-width: 767px) {
  .p--form {
    width: 100%; }
    .p--form__main {
      padding: 11px 0; }
      .p--form__main .p--form__infoTxt {
        padding: 0 22px; }
    .p--form__table {
      margin: 18px 0 0; }
      .p--form__table .c--step__main {
        margin: 10px 0;
        padding: 10px 20px; }
    .p--form .p--step__txt {
      display: none; }
    .p--form .p--ruleBox--sp {
      margin: 10px 0 0;
      padding: 10px 25px;
      border-top: 1px solid #dadadb; }
      .p--form .p--ruleBox--sp label {
        margin: 20px 0; }
    .p--form .p--formBtn {
      padding: 0 20px; }
    .p--form .p--formRegistBtn {
      display: flex;
      justify-content: center;
      padding: 0 20px;
      margin: 30px 0 0; }
      .p--form .p--formRegistBtn .c--btn--2line {
        padding: 7px 15px;
        line-height: 1.5; }
      .p--form .p--formRegistBtn .c--btn:nth-child(1) {
        width: 95px; }
      .p--form .p--formRegistBtn .c--btn:nth-child(2) {
        width: 230px; }
  .p--conf__area th {
    padding: 5px 20px; } }

/*! 
 * DRWL - 会員退会
 */
/** ========================================
 * @p--drwl
 * ====================================== */
.p--drwl .c--contents:last-child {
  padding-bottom: 120px; }

.p--drwl__lead {
  width: 896px;
  margin: 0 auto;
  margin-top: 40px; }

.p--drwl__box {
  width: 896px;
  margin: 0 auto;
  padding: 24px 24px 40px;
  border-radius: 4px; }

.p--drwl .p--box__upper {
  padding: 24px; }
  .p--drwl .p--box__upper .p--drwl__infoTxtBox {
    padding: 17px 14px; }
    .p--drwl .p--box__upper .p--drwl__infoTxtBox p:not(:first-child) {
      margin: 20px 0 0; }
    .p--drwl .p--box__upper .p--drwl__infoTxtBox .p--drwl__infoTtl {
      margin: 22px 0 0;
      font-weight: bold; }
      .p--drwl .p--box__upper .p--drwl__infoTxtBox .p--drwl__infoTtl + p {
        margin: 0; }

.p--drwl .c--select select {
  border-color: #DADADB; }

.p--drwl .p--box__reason {
  display: flex;
  align-items: center;
  margin-top: 20px;
  border-top: 1px solid #DADADB;
  border-bottom: 1px solid #DADADB; }

.p--drwl .p--reason__heading {
  flex: 0 0 30%;
  padding: 27px 18px;
  background: #fff0f5; }

.p--drwl .p--reason__body {
  flex: 1 1 70%;
  padding: 0 18px; }

@media screen and (max-width: 767px) {
  .p--drwl .c--contents:last-child {
    padding-bottom: 0; }
  .p--drwl .c--contents__flow {
    margin-bottom: 0; }
  .p--drwl__lead {
    width: auto;
    margin: 3.5vw 5.3vw 0; }
  .p--drwl__box {
    width: auto;
    padding: 2.6vw 0 3.2vw; }
  .p--drwl .p--box__upper {
    padding: 3.7vw; }
  .p--drwl .p--box__bottom {
    padding: 0; }
  .p--drwl .p--reason__heading {
    width: 100%;
    padding: 18px; }
  .p--drwl .p--reason__body {
    padding: 10px;
    width: 100%; }
  .p--box__reason {
    flex-direction: column; } }

/*! 
 * EDIT - スカウト条件追加登録
 */
/** ========================================
 * @p--edit
 * ====================================== */
.p--edit .c--contents:last-child {
  padding-bottom: 120px; }

.p--edit .c--contents__inner {
  width: 896px; }

.p--edit .c--error__list {
  margin-top: 30px; }

.p--edit__caption {
  margin-bottom: 24px;
  text-align: left; }

.p--edit__option {
  padding: 24px 24px 40px; }
  .p--edit__option caption {
    margin-bottom: 24px;
    text-align: left; }
  .p--edit__option th {
    width: 167px;
    vertical-align: top; }
  .p--edit__option .c--primaryTable + .c--primaryTable {
    margin-top: 30px; }
  .p--edit__option .c--primaryTable td.p--option__strong {
    padding: 0; }
  .p--edit__option .p--strong__heading {
    position: relative;
    display: block;
    padding: 8px 20px;
    background: #FAFAFA;
    color: #525252;
    font-size: .75rem;
    font-weight: normal; }
    .p--edit__option .p--strong__heading:hover {
      cursor: pointer; }
    .p--edit__option .p--strong__heading:before {
      margin: 0 5px 0 0;
      content: "▼"; }
    .p--edit__option .p--strong__heading.p--tgl--open:before {
      content: "▲"; }
  .p--edit__option .p--strong__wrap {
    padding: 10px 20px; }
  .p--edit__option .p--pr__txtarea {
    width: 100%;
    height: 12rem; }
  .p--edit__option .p--pr__count {
    display: flex;
    justify-content: space-between; }
  .p--edit__option .p--edit__passForget {
    margin: 30px 0 0; }
    .p--edit__option .p--edit__passForget .c--arrow--circle {
      margin: 10px 0 0; }

.p--edit__infoTxt + .c--tableHeading {
  margin: 20px 0 0; }

.p--editRegistBtn--col3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin: 40px 0 0; }
  .p--editRegistBtn--col3 > .c--btn:nth-child(1) {
    width: 155px; }
  .p--editRegistBtn--col3 > .c--btn:nth-child(2) {
    width: 300px; }
  .p--editRegistBtn--col3 > .c--btn + .c--btn {
    margin: 0 0 0 8px; }
  .p--editRegistBtn--col3 .p--editRegistBtn--WithdrawArea {
    margin: 0 0 0 30px; }

.p--merit {
  display: flex; }
  .p--merit__item {
    width: calc((100% - 20px) / 3);
    padding: 8px;
    background: #FAFAFA; }
    .p--merit__item:not(:first-child) {
      margin-left: 10px; }
    .p--merit__item figure {
      display: flex;
      margin-top: 8px; }
      .p--merit__item figure img {
        width: 100px;
        height: 100px; }
      .p--merit__item figure figcaption {
        width: calc(100% - 100px);
        padding: 0 20px; }
        .p--merit__item figure figcaption p {
          margin: 0 0 10px;
          font-weight: bold; }

@media screen and (max-width: 767px) {
  .p--edit .c--contents:last-child {
    padding-bottom: 0; }
  .p--edit .c--contents__inner {
    width: 100%; }
  .p--edit__lead {
    display: none; }
  .p--edit .c--error__list {
    margin: 5.3vw 5.3vw 0; }
  .p--edit__infoTxt {
    padding: 0 25px; }
  .p--edit__caption {
    margin: 0 5.3vw 4.2vw; }
  .p--edit__option {
    margin-top: 5.3vw;
    padding: 4.2vw 0 8vw; }
    .p--edit__option caption {
      margin: 0 5.3vw 4.2vw; }
    .p--edit__option th {
      width: 100%;
      vertical-align: middle; }
    .p--edit__option .c--primaryTable + p:not(.c--tableHeading) {
      padding: 0 25px; }
  .p--edit .p--edit__passForget {
    padding: 0 25px; }
  .p--editRegistBtn--col3 {
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 25px; }
    .p--editRegistBtn--col3 > .c--btn:nth-child(1) {
      width: 100px; }
    .p--editRegistBtn--col3 > .c--btn:nth-child(2) {
      width: calc(100% - 108px); }
    .p--editRegistBtn--col3 > .c--btn + .c--btn {
      margin: 0 0 0 8px; }
    .p--editRegistBtn--col3 .p--editRegistBtn--WithdrawArea {
      width: 100%;
      margin: 20px 0 0; }
      .p--editRegistBtn--col3 .p--editRegistBtn--WithdrawArea .c--btn {
        width: 100%;
        margin: 10px 0 0; }
  .p--merit {
    display: flex; }
    .p--merit__item figure img {
      display: none; }
    .p--merit__item figure figcaption {
      width: 100%;
      padding: 0;
      font-size: 0.75rem; } }

/*! 
 * ログイン関連
 */
/** ========================================
 * @p--inpt
 * 会員情報登録関連
======================================== */
.p--inpt {
  /*全体*/ }
  .p--inpt__area {
    width: 900px;
    margin: 0 auto; }
    .p--inpt__area .c--error__list {
      margin-top: 30px; }
  .p--inpt__box {
    width: 900px;
    margin: 30px auto 0; }
  .p--inpt__btn {
    width: 300px; }

@media screen and (max-width: 767px) {
  .p--inpt__area {
    width: 100%; }
    .p--inpt__area.p--edit__merit {
      padding: 0; }
    .p--inpt__area .c--error__list {
      padding: 0 25px; }
  .p--inpt__box {
    width: 100%; }
    .p--inpt__box .p--ruleBox--sp {
      padding: 0 25px; }
      .p--inpt__box .p--ruleBox--sp .c--control {
        margin: 20px 0; }
  .p--inpt__btn {
    width: calc(100% - 100px); } }

/** ========================================
 * @p--prefTbl
 * 希望勤務地エリア
 * ====================================== */
.p--prefTbl {
  display: flex;
  flex-wrap: wrap;
  border-width: 1px;
  border-style: solid;
  border-color: transparent #dadadb #dadadb #dadadb; }
  .p--prefTbl--th {
    background: #fff0f5;
    padding: 4px 15px;
    font-size: 0.8125rem;
    border-top: 1px solid #dadadb; }
  .p--prefTbl__heading {
    width: 140px; }
    .p--prefTbl__heading:not(.p--prefTbl--th) {
      padding: 5px 10px;
      border-top: 1px solid #dadadb;
      font-size: 0.8125rem; }
  .p--prefTbl__body {
    width: calc(100% - 140px);
    border-left: 1px solid #dadadb; }
    .p--prefTbl__body:not(.p--prefTbl--th) {
      padding: 5px 10px;
      border-top: 1px solid #dadadb;
      font-size: 0.8125rem; }
  .p--prefTbl .c--control__indicator {
    height: 16px;
    width: 16px; }

@media screen and (max-width: 767px) {
  .p--prefTbl__area {
    padding: 0 !important; }
  .p--prefTbl {
    display: block;
    border-width: 0 0 1px 0; }
    .p--prefTbl--th {
      display: none; }
      .p--prefTbl--th + .p--prefTbl__heading {
        border-top: none; }
    .p--prefTbl__heading {
      position: relative;
      width: 100%; }
      .p--prefTbl__heading:before {
        content: "";
        display: inline-block;
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 41px;
        background: #dadadb; }
      .p--prefTbl__heading:not(.p--prefTbl--th) {
        padding: 7px 10px 7px 22px;
        font-size: 0.875rem; }
    .p--prefTbl__body {
      width: 100%;
      border-left: none; }
      .p--prefTbl__body:not(.p--prefTbl--th) {
        padding: 9px 0 9px 46px;
        font-size: 0.875rem; }
      .p--prefTbl__body .c--control {
        width: 100%; }
        .p--prefTbl__body .c--control + .c--control {
          margin-top: 10px;
          padding-top: 10px;
          border-top: 1px solid #dadadb; }
          .p--prefTbl__body .c--control + .c--control .c--control__indicator {
            top: calc(50% + 5px); } }

/*20200406*/
.p--inpt__lead li {
  padding-left: 1em;
  text-indent: -1em; }
  .p--inpt__lead li::before {
    content: "・"; }

@media screen and (max-width: 767px) {
  .p--inpt__lead {
    padding: 0 22px; } }

.p--inpt__mailadd {
  width: 460px; }

.p--inpt__pass {
  width: 400px; }

@media screen and (max-width: 767px) {
  .p--inpt__mailadd {
    width: 100%; }
  .p--inpt__pass {
    width: 100%; } }

/*! 
 * 病院情報関連
 */
/** ========================================
 * @p--hosp
 * 病院情報関連
======================================== */
.p--reg__block--separate .p--reg__head {
  border: 1px solid #dadadb;
  border-radius: 4px;
  color: #e92d82;
  text-align: center;
  box-shadow: none; }

.p--reg__block--separate .p--reg__body {
  margin: 20px 0 0;
  padding: 25px 8px;
  box-shadow: 2px 2px 2px rgba(97, 97, 106, 0.2), 1px 1px 5px rgba(97, 97, 106, 0.1); }

.p--reg__block--topTab .p--searchArea__tab {
  margin: 20px 0 0; }
  .p--reg__block--topTab .p--searchArea__tab .p--tab {
    width: 50%;
    height: 60px; }
    .p--reg__block--topTab .p--searchArea__tab .p--tab--active {
      height: 68px; }
  .p--reg__block--topTab .p--searchArea__tab + .p--reg__body {
    margin: 0;
    box-shadow: none; }

/* strat 20200427 */
@media screen and (max-width: 767px) {
  #obsr .p--reg__block--topTab .p--searchArea__tab {
    margin: 0;
    padding-top: 20px;
    background: #fff; }
    #obsr .p--reg__block--topTab .p--searchArea__tab .p--tab:not(.p--tab--active) {
      overflow: hidden;
      border: 1px solid #dadadb;
      box-shadow: none; }
    #obsr .p--reg__block--topTab .p--searchArea__tab .p--tab--active {
      position: relative;
      border-left: 1px solid #dadadb;
      border-right: 1px solid #dadadb;
      border-radius: 0; }
      #obsr .p--reg__block--topTab .p--searchArea__tab .p--tab--active::after {
        content: "";
        display: inline-block;
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 2px;
        background: #fff; } }

/* end 20200427 */
.p--intern__block {
  padding: 0 30px; }
  .p--intern__block + .p--intern__block {
    margin: 17px 0 0;
    padding-top: 42px;
    border-top: 1px solid #ccc; }

.p--livingArea .p--area__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 14px;
  padding-bottom: 14px;
  background: #FFEFF4; }
  .p--livingArea .p--area__inner a {
    width: 420px;
    margin: 0 0 0 10px;
    padding: 15px 13px;
    font-size: 1rem;
    font-weight: normal; }
    .p--livingArea .p--area__inner a .c--arrow {
      display: inline-block;
      width: 100%; }
      .p--livingArea .p--area__inner a .c--arrow i {
        font-size: 1.125rem;
        font-weight: bold; }

.p--intern__ttl {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  font-weight: bold; }
  .p--intern__ttl:before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    margin: 0 7px 0 0; }
  .p--intern__ttl--search:before {
    background: url(../images/icon_circle_search.png) 100%/contain no-repeat; }
  .p--intern__ttl--list:before {
    background: url(../images/icon_circle_list.png) 100%/contain no-repeat; }
  .p--intern__ttl--hospital:before {
    background: url(../images/icon_circle_hospital.png) 100%/contain no-repeat; }

.c--primaryTable + .p--intern__ttl {
  margin: 40px 0 0; }

.p--intern__searchArea .p--searchArea__box {
  margin: 15px 0 0; }
  .p--intern__searchArea .p--searchArea__box input {
    width: 600px;
    height: 56px; }
  .p--intern__searchArea .p--searchArea__box .c--btn--input {
    width: 140px;
    height: 56px;
    margin: 0 0 0 10px; }

.p--simpleTab {
  display: flex;
  background: #dadadb;
  padding: 0 15px; }
  .p--simpleTab .p--tabSelect {
    border-radius: 0;
    color: #e92d82;
    font-weight: bold; }
  .p--simpleTab__item {
    margin: 8px 0;
    border-radius: 4px;
    background: #fff; }
    .p--simpleTab__item:not(:first-child) {
      margin-left: 8px; }
  .p--simpleTabHead .p--simpleTab__item p, .p--simpleTabHead .p--simpleTab__item a {
    display: inline-block;
    padding: 15px 22px; }
  .p--simpleTabHead .p--simpleTab__item.p--tabSelect {
    margin-bottom: 0;
    border-top: 4px solid #e92d82; }
    .p--simpleTabHead .p--simpleTab__item.p--tabSelect p, .p--simpleTabHead .p--simpleTab__item.p--tabSelect a {
      padding: 11px 22px 23px; }
  .p--simpleTabHead .p--simpleTab__item.p--tabArrow p, .p--simpleTabHead .p--simpleTab__item.p--tabArrow a {
    position: relative; }
    .p--simpleTabHead .p--simpleTab__item.p--tabArrow p:before, .p--simpleTabHead .p--simpleTab__item.p--tabArrow a:before {
      content: "";
      position: absolute;
      bottom: 7px;
      left: 50%;
      width: 6px;
      height: 6px;
      border: solid #D9D9DB;
      border-width: 0 2px 2px 0;
      z-index: 10;
      transform: translateX(-50%) rotate(45deg); }
  .p--simpleTabHead .p--simpleTab__item.p--tabArrow.p--tabSelect p:before, .p--simpleTabHead .p--simpleTab__item.p--tabArrow.p--tabSelect a:before {
    bottom: 15px;
    border-color: #e92d82; }
  .p--simpleTabBottom .p--simpleTab__item {
    padding: 0; }
    .p--simpleTabBottom .p--simpleTab__item a {
      display: inline-block;
      padding: 15px 22px; }
    .p--simpleTabBottom .p--simpleTab__item.p--tabSelect {
      margin-top: 0;
      border-bottom: 4px solid #e92d82; }
      .p--simpleTabBottom .p--simpleTab__item.p--tabSelect p, .p--simpleTabBottom .p--simpleTab__item.p--tabSelect a {
        padding: 23px 22px 11px; }
    .p--simpleTabBottom .p--simpleTab__item.p--tabArrow p, .p--simpleTabBottom .p--simpleTab__item.p--tabArrow a {
      position: relative; }
      .p--simpleTabBottom .p--simpleTab__item.p--tabArrow p:before, .p--simpleTabBottom .p--simpleTab__item.p--tabArrow a:before {
        content: "";
        position: absolute;
        top: 7px;
        left: 50%;
        width: 6px;
        height: 6px;
        border: solid #D9D9DB;
        border-width: 0 2px 2px 0;
        z-index: 10;
        transform: translateX(-50%) rotate(-135deg); }
    .p--simpleTabBottom .p--simpleTab__item.p--tabArrow.p--tabSelect p:before, .p--simpleTabBottom .p--simpleTab__item.p--tabArrow.p--tabSelect a:before {
      top: 15px;
      border-color: #e92d82; }
    .p--simpleTabBottom .p--simpleTab__item:hover a {
      padding-top: 15px; }
  .p--simpleTab__num {
    display: inline-block;
    margin: 0 0 0 8px;
    padding: 2px 8px;
    border-radius: 100px;
    background: #e92d82;
    color: #fff;
    font-size: 0.625rem;
    line-height: 1; }
    .p--simpleTab__num--empty {
      background: #dadadb;
      color: #828288; }

@media screen and (min-width: 768px) {
  .p--simpleTab__item:not(.p--tabSelect):hover {
    cursor: pointer; }
  .p--simpleTab__item:hover {
    color: #e92d82;
    font-weight: bold; }
    .p--simpleTab__item:hover a {
      padding-top: 11px; }
  .p--simpleTabHead .p--simpleTab__item:not(.p--tabSelect):hover {
    border-top: 4px solid #e92d82; }
    .p--simpleTabHead .p--simpleTab__item:not(.p--tabSelect):hover p {
      padding-top: 11px; }
  .p--simpleTabHead .p--simpleTab__item.p--tabArrow:hover p:before, .p--simpleTabHead .p--simpleTab__item.p--tabArrow:hover a:before {
    border-color: #e92d82; }
  .p--simpleTabBottom .p--simpleTab__item a:not(.p--tabSelect):hover {
    padding-bottom: 11px;
    border-bottom: 4px solid #e92d82; }
  .p--simpleTabBottom .p--simpleTab__item.p--tabArrow:hover p:before, .p--simpleTabBottom .p--simpleTab__item.p--tabArrow:hover a:before {
    border-color: #e92d82; }
  .p--simpleTabBottom .p--simpleTab__item.p--tabArrow.p--tabSelect p:before, .p--simpleTabBottom .p--simpleTab__item.p--tabArrow.p--tabSelect a:before {
    top: 15px;
    border-color: #e92d82; } }

.p--intern__detailCond .p--simpleTab {
  margin: 27px 0 0; }

.p--intern__detailCond .p__check dt {
  margin: 27px 0 0;
  padding: 9px 20px;
  background: #FFEFF4;
  font-weight: bold; }

.p--intern__detailCond .p__check dd {
  margin: 15px 0 0; }
  .p--intern__detailCond .p__check dd .p--tabBody__ttl {
    margin: 5px;
    color: #e92d82; }
    .p--intern__detailCond .p__check dd .p--tabBody__ttl.c--tgl__head {
      position: relative; }
      .p--intern__detailCond .p__check dd .p--tabBody__ttl.c--tgl__head:before {
        margin: 0 5px 0 0;
        content: "▼"; }
      .p--intern__detailCond .p__check dd .p--tabBody__ttl.c--tgl__head.p--tgl--open:before {
        content: "▲"; }
  .p--intern__detailCond .p__check dd .p__checkBox {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }

.p--intern__detailCond .p__check .c--control {
  align-self: stretch;
  margin: 0 24px 0 0;
  padding: 10px 10px 10px 33px;
  border-top: 1px solid #dadadb;
  width: calc((100% - 82px) / 4); }
  .p--intern__detailCond .p__check .c--control:nth-child(4n) {
    margin-right: 0; }
  .p--intern__detailCond .p__check .c--control--nobdt {
    border-top: none; }
  .p--intern__detailCond .p__check .c--control__indicator {
    top: calc(10px + .25em);
    left: 8px;
    height: 16px;
    width: 16px;
    transform: translateY(0); }

.p--intern__detailCond .c--main__map {
  margin: 50px 10px; }

.p--result__intern {
  margin: 35px 0 0; }
  .p--search__intern + .p--result__intern {
    margin: 60px 0 0; }
  .p--result__intern .p--intern__list {
    margin: 25px 0 0; }
    .p--result__intern .p--intern__list .p--list__item {
      background: #fff; }
      .p--result__intern .p--intern__list .p--list__item.c--boxShadow {
        box-shadow: 0 1px 3px rgba(97, 97, 106, 0.3), 0 1px 5px rgba(97, 97, 106, 0.3); }
      .p--result__intern .p--intern__list .p--list__item + .p--list__item {
        margin: 55px 0 0; }
      .p--result__intern .p--intern__list .p--list__item .p--item__heading {
        border-top: 2px solid #e92d82;
        padding: 12px 22px;
        font-size: 1.25rem;
        font-weight: bold; }
        .p--result__intern .p--intern__list .p--list__item .p--item__heading--fav {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0 0 22px; }
          .p--result__intern .p--intern__list .p--list__item .p--item__heading--fav .p--fav__ttl {
            display: flex;
            align-items: center;
            width: 100%; }
            .p--result__intern .p--intern__list .p--list__item .p--item__heading--fav .p--fav__ttl--col1 .c--control {
              width: 100%;
              margin-right: 0 !important; }
              .p--result__intern .p--intern__list .p--list__item .p--item__heading--fav .p--fav__ttl--col1 .c--control .c--boxHeading {
                display: inline-block;
                margin-left: 5px; }
          .p--result__intern .p--intern__list .p--list__item .p--item__heading--fav .c--ovalTag {
            width: auto;
            margin: 0;
            padding: 0 10px; }
          .p--result__intern .p--intern__list .p--list__item .p--item__heading--fav .p--heading__ttl {
            width: 100%;
            padding: 12px 12px 12px 0; }
          .p--result__intern .p--intern__list .p--list__item .p--item__heading--fav .p--fav__btn {
            align-self: stretch; }
            .p--result__intern .p--intern__list .p--list__item .p--item__heading--fav .p--fav__btn .p--favAnime {
              position: relative;
              display: flex;
              align-items: center;
              min-width: 134px;
              height: 100%;
              padding: 18px 12px 18px 37px;
              border-left: 1px solid #ccc;
              font-size: 0.75rem;
              text-align: center; }
              .p--result__intern .p--intern__list .p--list__item .p--item__heading--fav .p--fav__btn .p--favAnime:hover {
                cursor: pointer; }
      .p--result__intern .p--intern__list .p--list__item .p--item__body {
        display: flex;
        align-items: flex-start;
        padding: 15px 20px;
        border-top: 1px solid #dadadb; }
        .p--result__intern .p--intern__list .p--list__item .p--item__body--arrowLink > a {
          display: flex;
          align-items: flex-start;
          padding: 0 24px 0 0; }
          .p--result__intern .p--intern__list .p--list__item .p--item__body--arrowLink > a.c--arrow:before {
            right: 0; }
          .p--result__intern .p--intern__list .p--list__item .p--item__body--arrowLink > a .p--body__img--846__252 {
            width: 830px; }
            .p--result__intern .p--intern__list .p--list__item .p--item__body--arrowLink > a .p--body__img--846__252 img {
              height: calc(830px / 84.6 * 25.2); }
          .p--result__intern .p--intern__list .p--list__item .p--item__body--arrowLink > a .p--body__txt {
            width: 473px; }
        .p--result__intern .p--intern__list .p--list__item .p--item__body--arrowLink.p--item__body--col1 .p--body__txt {
          width: 100%; }
        .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__txt {
          width: calc(100% - 357px);
          margin: 0 0 0 27px; }
          .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__txt ul {
            margin: 7px 0 0; }
            .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__txt ul li {
              margin: 7px 10px 0 0; }
              .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__txt ul li span {
                color: #828288; }
        .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__ttl {
          font-size: 1rem;
          font-weight: bold; }
          .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__ttl + p {
            margin: 7px 0 0; }
        .p--result__intern .p--intern__list .p--list__item .p--item__body--noFlex {
          display: block; }
          .p--result__intern .p--intern__list .p--list__item .p--item__body--noFlex .p--body__txt {
            width: 100%;
            margin: 0; }
          .p--result__intern .p--intern__list .p--list__item .p--item__body--noFlex .p--body__catchcopy {
            padding: 0 10px;
            font-size: 1.125rem;
            font-weight: bold; }
          .p--result__intern .p--intern__list .p--list__item .p--item__body--noFlex.c--hoverShadow {
            padding: 15px 0; }
            .p--result__intern .p--intern__list .p--list__item .p--item__body--noFlex.c--hoverShadow .p--body__img {
              margin: 0 auto; }
            .p--result__intern .p--intern__list .p--list__item .p--item__body--noFlex.c--hoverShadow .p--body__rsvArea {
              margin-right: 10px !important;
              margin-left: 10px !important;
              width: calc(100% - 20px); }
        .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__img {
          margin: 2px auto 0; }
          .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__img--276__200 {
            width: 276px; }
            .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__img--276__200 img {
              height: 200px; }
          .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__img--330__200 {
            width: 330px; }
            .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__img--330__200 img {
              height: 200px; }
          .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__img--846__254 {
            width: 846px; }
            .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__img--846__254 img {
              height: 254px; }
          .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__img--846__252 {
            width: 846px; }
            .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__img--846__252 img {
              height: 252px; }
        .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__rsvArea {
          position: relative;
          width: 100%;
          padding: 10px;
          border-radius: 8px; }
          .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__rsvArea:first-of-type {
            margin: 15px 0 0; }
          .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__rsvArea .p--rsvArea__ttl {
            padding: 8px 14px;
            background: #FFEFF4; }
          .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__rsvArea .c--ovalTag {
            margin: 0 12px 0 0; }
          .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__rsvArea .p--list__item {
            display: flex;
            margin: 10px 0 0;
            padding: 10px 0 0;
            border-top: 1px solid #ccc;
            background: #fff; }
            .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__rsvArea .p--list__item p:first-child {
              width: 180px;
              padding: 0 0 0 17px; }
        .p--result__intern .p--intern__list .p--list__item .p--item__body--col1 {
          display: block; }
          .p--result__intern .p--intern__list .p--list__item .p--item__body--col1 > a {
            display: block; }
          .p--result__intern .p--intern__list .p--list__item .p--item__body--col1 .p--body__txt {
            width: 100%;
            margin: 0 !important; }
            .p--result__intern .p--intern__list .p--list__item .p--item__body--col1 .p--body__txt ul + .p--tgl__block {
              margin: 15px 0 0; }
          .p--result__intern .p--intern__list .p--list__item .p--item__body--col1 .p--body__img + .p--body__txt {
            margin: 10px 0 0 !important; }
  .p--result__intern .c--search__main .c--item__txt + .c--btn--searchIcon {
    margin: 30px 0 0; }
  .p--result__intern .c--search__main .c--control {
    width: 100%;
    margin: 15px 0 0; }
  .p--result__intern .c--search__main .c--main__item--selectArea .c--item__selectTTl:not(:first-child) {
    margin: 10px 0 0; }
  .p--result__intern .c--search__main .c--main__item--selectArea select {
    height: 29px;
    margin: 5px 0 0;
    padding: 5px 30px 5px 10px; }
  .p--result__intern .c--search__main .c--main__item--selectArea .c--select__arrow {
    width: 7px;
    height: 7px;
    border-color: #dadadb; }

.c--page__ctrl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #828288; }
  .c--page__ctrl .c--btn--m {
    margin: 0 0 0 5px;
    padding: 11px 7px; }
  .c--page__ctrl .c--arrow--p__right {
    padding: 0 11px 0 0; }
  .c--page__ctrl .c--arrow:before {
    width: 6px;
    height: 6px; }
  .c--page__ctrl:not(:first-of-type) {
    margin: 25px 0 0; }

.p--fixed__bnr {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  margin: 0;
  padding: 22px 10px;
  background: rgba(97, 97, 106, 0.9);
  z-index: 1010; }
  .p--fixed__bnr .p--bnr__txt {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 1rem; }
    .p--fixed__bnr .p--bnr__txt span {
      margin: 0 5px 0 10px;
      font-size: 1.5625rem;
      font-weight: 700; }
  .p--fixed__bnr .c--btn--lb {
    width: 295px;
    margin: 0 0 0 40px; }
  .p--fixed__bnr .c--btn--w {
    margin: 0 0 0 10px; }

.p--search__calender .c--select,
.p--search__calender .c--btn {
  margin: 0 10px; }

.p--search__calender .c--ui__date + span {
  margin: 0 10px; }

/** ========================================
 * @EDCT
 * EDCTページ
======================================== */
.p--result__intern.p--trainingInfo__area .p--intern__list > .p--list__item > .p--item__body {
  padding: 15px 40px; }

.p--result__intern.p--trainingInfo__area .p--trainingInfo__top {
  display: flex;
  flex-direction: row-reverse; }
  .p--result__intern.p--trainingInfo__area .p--trainingInfo__top .p--top__body {
    width: calc(100% - 335px);
    padding: 0 40px 0 0; }
    .p--result__intern.p--trainingInfo__area .p--trainingInfo__top .p--top__body .p--body__detail {
      display: flex;
      align-items: center; }
      .p--result__intern.p--trainingInfo__area .p--trainingInfo__top .p--top__body .p--body__detail li:not(:first-child) {
        margin: 0 0 0 15px; }
      .p--result__intern.p--trainingInfo__area .p--trainingInfo__top .p--top__body .p--body__detail span {
        color: #828288; }
    .p--result__intern.p--trainingInfo__area .p--trainingInfo__top .p--top__body .p--body__tag {
      margin: 10px 0 0; }
      .p--result__intern.p--trainingInfo__area .p--trainingInfo__top .p--top__body .p--body__tag .c--ovalTag {
        margin: 0 0 10px 10px; }
    .p--result__intern.p--trainingInfo__area .p--trainingInfo__top .p--top__body .p--body__catchcopy {
      margin: 10px 0 0; }
    .p--result__intern.p--trainingInfo__area .p--trainingInfo__top .p--top__body .p--body__list {
      display: flex;
      justify-content: space-between;
      margin: 22px 0 0; }
      .p--result__intern.p--trainingInfo__area .p--trainingInfo__top .p--top__body .p--body__list .p--list__item {
        width: calc(50% - 10px);
        margin: 0 !important; }
        .p--result__intern.p--trainingInfo__area .p--trainingInfo__top .p--top__body .p--body__list .p--list__item .p--item__ttl {
          background: #ffeff4;
          padding: 8px 18px;
          font-size: 0.75rem; }
        .p--result__intern.p--trainingInfo__area .p--trainingInfo__top .p--top__body .p--body__list .p--list__item .p--item__body {
          font-size: 0.75rem; }
          .p--result__intern.p--trainingInfo__area .p--trainingInfo__top .p--top__body .p--body__list .p--list__item .p--item__body--tag .c--ovalTag {
            width: 80px; }
          .p--result__intern.p--trainingInfo__area .p--trainingInfo__top .p--top__body .p--body__list .p--list__item .p--item__body > div {
            display: flex;
            flex-direction: column;
            margin: 0 0 0 5px; }
          .p--result__intern.p--trainingInfo__area .p--trainingInfo__top .p--top__body .p--body__list .p--list__item .p--item__body a + a {
            margin: 5px 0 0; }
  .p--result__intern.p--trainingInfo__area .p--trainingInfo__top .p--body__img {
    margin: 0 0 0 20px; }

.p--result__intern.p--trainingInfo__area .p--trainingInfo__btm {
  margin: 15px 0;
  padding: 8px 0;
  border-top: 1px solid #dadadb;
  border-bottom: 1px solid #dadadb; }
  .p--result__intern.p--trainingInfo__area .p--trainingInfo__btm .p--btm__info {
    display: flex;
    padding: 0 0 0 10px; }
    .p--result__intern.p--trainingInfo__area .p--trainingInfo__btm .p--btm__info + .p--btm__info {
      margin: 12px 0 0;
      padding-top: 10px;
      border-top: 1px solid #dadadb; }
    .p--result__intern.p--trainingInfo__area .p--trainingInfo__btm .p--btm__info .p--info__ttl {
      width: 123px;
      color: #e92d82;
      font-weight: bold; }
    .p--result__intern.p--trainingInfo__area .p--trainingInfo__btm .p--btm__info .p--info__list {
      width: calc(100% - 123px); }
      .p--result__intern.p--trainingInfo__area .p--trainingInfo__btm .p--btm__info .p--info__list a {
        display: block;
        width: 100%;
        padding-right: 20px; }
        .p--result__intern.p--trainingInfo__area .p--trainingInfo__btm .p--btm__info .p--info__list a.c--arrow--p__right:before {
          right: 14px; }
      .p--result__intern.p--trainingInfo__area .p--trainingInfo__btm .p--btm__info .p--info__list li span {
        margin: 0 2px 0 0; }
      .p--result__intern.p--trainingInfo__area .p--trainingInfo__btm .p--btm__info .p--info__list li + li {
        margin: 12px 0 0;
        padding: 10px 0 0;
        border-top: 1px solid #ccc; }

.p--result__intern.p--trainingInfo__area .p--trainingTab {
  margin: 55px 0 0; }
  .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main {
    width: 846px;
    margin: 0 auto;
    padding: 25px 0 60px; }
    .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__read {
      margin: 25px 0 0;
      font-size: 1rem;
      font-weight: bold; }
      .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__read + .p--body__img {
        margin: 25px 0 0; }
    .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__subHeading {
      margin: 28px 0 0; }
      .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__subHeading .p--subHeading__txt {
        margin: 12px 2px;
        color: #e92d82;
        font-size: 1rem;
        font-weight: bold; }
    .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__text {
      margin: 30px 0 0;
      display: flex;
      justify-content: space-between; }
      .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__text--right {
        flex-direction: row-reverse; }
        .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__text--right .p--text {
          margin-left: 50px; }
      .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__text--left .p--text {
        margin-right: 50px; }
      .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__text .p--text {
        width: calc(100% - 380px);
        line-height: 1.8; }
      .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__text .p--body__img span {
        margin: 5px 0;
        display: inline-block; }
      .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__text + .p--main__subHeading {
        margin: 50px 0 0; }
    .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__column {
      margin: 50px 0 0; }
      .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__column .p--column__ttl {
        background: #F1F6FA;
        padding: 20px 32px;
        line-height: 1.6;
        font-size: 1rem;
        font-weight: bold; }
      .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__column .p--column__txt {
        background: #F1F6FA;
        border-top: 1px solid #fff;
        padding: 20px 32px;
        line-height: 1.9; }
    .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__basicInfo {
      margin: 60px 0 0; }
      .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__basicInfo .c--primaryTable {
        margin: 15px 0 0;
        font-size: 0.8125rem; }
        .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__basicInfo .c--primaryTable th {
          width: 245px; }
      .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__basicInfo .p--basicInfo__list {
        margin: 40px 0 0; }
        .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__basicInfo .p--basicInfo__list .p--list__ttl {
          padding: 8px 18px;
          border-bottom: 1px solid #dadadb;
          background: #ffeff4;
          font-weight: bold; }
        .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__basicInfo .p--basicInfo__list ul li a {
          display: inline-block;
          width: 100%;
          padding: 13px 15px 13px 10px; }
          .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__basicInfo .p--basicInfo__list ul li a.c--arrow--p__right:before {
            right: 10px; }
        .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__basicInfo .p--basicInfo__list ul li:not(:first-child) {
          border-top: 1px solid #dadadb; }
        .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__basicInfo .p--basicInfo__list ul li span {
          margin: 0 20px 0 0; }

.p--trainingInfo__wrap .c--card {
  margin: 35px 0 50px; }

.p--trainingInfo__wrap .p--fixed__bnr .c--btn--l {
  width: 295px; }
  .p--trainingInfo__wrap .p--fixed__bnr .c--btn--l:first-child {
    margin: 0; }
  .p--trainingInfo__wrap .p--fixed__bnr .c--btn--l:not(:first-child) {
    margin: 0 0 0 10px; }

.p--trainingInfo__wrap .p--top__btn {
  padding: 0 0 50px 0; }

/** ========================================
 * @SPFT
 * SPFTページ
======================================== */
.p--hospFeature__wrap .c--contents__inner {
  width: 894px; }

.p--fixed__bnr--allCheck .c--control__indicator {
  width: 26px;
  height: 26px;
  border-radius: 4px; }

.p--fixed__bnr--allCheck .c--control__txt {
  margin: 0 0 0 10px;
  color: #fff;
  font-size: 1.25rem;
  font-weight: bold; }

.p--fixed__bnr--allCheck .c--btn--lb {
  width: 425px;
  margin: 0 0 0 15px; }

.p--intern__list--headCheck .p--list__item .p--item__heading .p--fav__ttl .c--ovalTag {
  width: auto;
  margin: 0;
  padding: 5px 14px; }

.p--intern__list--headCheck .p--list__item .p--item__heading .p--fav__ttl .c--control {
  min-width: 23px;
  margin: 0 10px 0 0; }
  .p--intern__list--headCheck .p--list__item .p--item__heading .p--fav__ttl .c--control__indicator {
    width: 23px;
    height: 23px; }

.p--intern__list--headCheck .p--list__item .p--item__heading .p--fav__ttl .c--control__txt a {
  display: inline;
  color: #333;
  text-decoration: none; }

.p--intern__list--headCheck .p--list__item .p--item__heading .p--fav__ttl .p--new {
  min-width: 30px;
  margin: 0 0 0 8px;
  color: #f84361;
  font-size: 0.75rem; }

.p--intern__list--headCheck .p--list__item .p--item__body {
  padding: 15px 20px 10px; }
  .p--intern__list--headCheck .p--list__item .p--item__body .p--body__txt ul {
    margin: 15px 0 0; }
  .p--intern__list--headCheck .p--list__item .p--item__body .p--body__txt .p--body__tag {
    margin: 13px 0 0; }
  .p--intern__list--headCheck .p--list__item .p--item__body--col1 .p--body__img + .p--body__txt {
    margin: 15px 0 0; }
  .p--intern__list--headCheck .p--list__item .p--item__body--col1 .p--body__txt {
    margin: 0; }
    .p--intern__list--headCheck .p--list__item .p--item__body--col1 .p--body__txt ul {
      display: flex;
      margin: 10px 0 0; }
      .p--intern__list--headCheck .p--list__item .p--item__body--col1 .p--body__txt ul li {
        margin: 7px 20px 0 0; }

.p--intern__list--headCheck .p--list__item .p--item__btnArea {
  display: flex;
  justify-content: center;
  padding: 17px 25px;
  border-top: 1px dotted #dadadb; }
  .p--intern__list--headCheck .p--list__item .p--item__btnArea .c--btn {
    width: calc(50% - 5px);
    padding: 20px 22px;
    font-size: 1rem; }
    .p--intern__list--headCheck .p--list__item .p--item__btnArea .c--btn + .c--btn {
      margin-left: 10px; }

@media screen and (max-width: 767px) {
  .p--reg__block--separate .p--reg__body {
    padding: 0;
    box-shadow: none; }
  .p--intern__block {
    padding: 20px; }
    .p--intern__block + .p--intern__block {
      margin: 0;
      padding-top: 20px; }
  .p--fixed__bnr--allCheck .c--control {
    margin-right: 10px; }
    .p--fixed__bnr--allCheck .c--control__indicator {
      width: 20px;
      height: 20px; }
    .p--fixed__bnr--allCheck .c--control__txt {
      font-size: 1rem; }
  .p--fixed__bnr--allCheck .c--btn--lb {
    width: 425px;
    margin: 0 0 0 15px; }
  /* strat 20200427 */
  #obsr .p--reg__block--separate .p--reg__body {
    border-top: 1px solid #dadadb; }
  #seni .p--reg__block--separate .p--reg__body {
    margin-top: 0; }
  /* end 20200427 */
  .p--livingArea .p--area__inner {
    padding: 10px;
    flex-wrap: wrap; }
    .p--livingArea .p--area__inner .p--area__ttl {
      width: 100%; }
    .p--livingArea .p--area__inner .p--area__link {
      width: calc(50% - 11px);
      margin: 10px 0 0;
      padding: 8px 13px; }
      .p--livingArea .p--area__inner .p--area__link + .p--area__link {
        margin-left: 14px; }
      .p--livingArea .p--area__inner .p--area__link--col1 {
        width: 100%; }
        .p--livingArea .p--area__inner .p--area__link--col1 .c--arrow:before {
          display: none; }
    .p--livingArea .p--area__inner a .c--arrow {
      font-size: 0.75rem;
      line-height: 1.5; }
      .p--livingArea .p--area__inner a .c--arrow i {
        font-size: 0.875rem; }
  .p--intern__searchArea .p--searchArea__box input {
    width: calc(100% - 106px);
    height: 50px; }
  .p--intern__searchArea .p--searchArea__box .c--btn--input {
    width: 96px;
    height: 50px;
    margin: 0 0 0 10px;
    padding: 14px 6px; }
  .p--intern__detailCond {
    padding: 20px 0 0; }
    .p--intern__detailCond .p--intern__ttl {
      padding: 0 20px; }
    .p--intern__detailCond .p--simpleTab {
      padding: 0 10px; }
      .p--intern__detailCond .p--simpleTab span {
        display: block;
        line-height: 1.25; }
      .p--intern__detailCond .p--simpleTabHead .p--simpleTab__item {
        min-width: 90px;
        text-align: center; }
        .p--intern__detailCond .p--simpleTabHead .p--simpleTab__item p, .p--intern__detailCond .p--simpleTabHead .p--simpleTab__item a {
          padding: 9px 10px; }
        .p--intern__detailCond .p--simpleTabHead .p--simpleTab__item.p--tabSelect p, .p--intern__detailCond .p--simpleTabHead .p--simpleTab__item.p--tabSelect a {
          padding: 5px 10px 13px; }
      .p--intern__detailCond .p--simpleTab__num {
        margin: 3px 0 0; }
    .p--intern__detailCond .c--main__map {
      margin: 14px 0; }
      .p--intern__detailCond .c--main__map .c--area__item--pref01-01.c--area__item:first-child,
      .p--intern__detailCond .c--main__map .c--area__item--pref02-01.c--area__item:first-child {
        border-top: 1px solid #dadadb; }
      .p--intern__detailCond .c--main__map .c--area__item--pref01-09,
      .p--intern__detailCond .c--main__map .c--area__item--pref02-09 {
        border-bottom: none; }
  .p--search__intern {
    margin: 20px 0 0; }
    .p--search__intern .p--select__area {
      background: #fff;
      padding: 14px; }
      .p--search__intern .p--select__area .p--select__result {
        display: flex !important;
        align-items: center; }
        .p--search__intern .p--select__area .p--select__result .p--result__resultNum {
          width: 105px; }
          .p--search__intern .p--select__area .p--select__result .p--result__resultNum i {
            color: #e92d82;
            font-size: 1.125rem;
            font-weight: bold; }
        .p--search__intern .p--select__area .p--select__result .p--result__selectList {
          width: calc(100% - 105px);
          padding: 0 0 0 15px;
          border-left: 1px solid;
          font-size: 0.625rem; }
      .p--search__intern .p--select__area .p--reg__head {
        margin: 13px 0 0;
        padding: 5px 35px 5px 21px;
        font-size: 0.8125rem; }
        .p--search__intern .p--select__area .p--reg__head:before {
          right: 35px; }
        .p--search__intern .p--select__area .p--reg__head:after {
          width: 6px;
          height: 6px;
          right: 14px; }
  .p--intern__detailCond .p__check dt {
    margin: 20px 0 0; }
  .p--intern__detailCond .p__check dd {
    margin: 0; }
    .p--intern__detailCond .p__check dd .p--tabBody__ttl {
      margin: 5px 0;
      padding: 0 20px; }
  .p--intern__detailCond .p__check .c--control {
    width: 100%;
    margin: 0;
    padding: 13px 10px 13px 48px; }
    .p--intern__detailCond .p__check .c--control:first-child {
      border-top: none; }
    .p--intern__detailCond .p__check .c--control__indicator {
      left: 22px; }
  .p--result__intern {
    margin: 28px 0 0; }
    .p--result__intern .p--intern__list .p--list__item .p--item__heading {
      padding: 14px 22px 16px;
      font-size: 1rem; }
      .p--result__intern .p--intern__list .p--list__item .p--item__heading--fav {
        padding: 0 0 0 22px; }
        .p--result__intern .p--intern__list .p--list__item .p--item__heading--fav .p--fav__ttl {
          width: 100%; }
          .p--result__intern .p--intern__list .p--list__item .p--item__heading--fav .p--fav__ttl p {
            display: inline-block;
            width: auto; }
        .p--result__intern .p--intern__list .p--list__item .p--item__heading--fav .p--fav__btn .p--favAnime {
          min-width: 54px;
          min-height: 54px;
          height: 100% !important;
          padding: 0; }
        .p--result__intern .p--intern__list .p--list__item .p--item__heading--fav .c--ovalTag {
          margin: 0;
          padding: 5px;
          font-size: .625rem;
          font-weight: 400;
          line-height: 1; }
    .p--result__intern .p--intern__list .p--list__item .p--item__body {
      flex-direction: column;
      padding-bottom: 10px; }
      .p--result__intern .p--intern__list .p--list__item .p--item__body__sp--row {
        flex-direction: row;
        flex-wrap: wrap; }
        .p--result__intern .p--intern__list .p--list__item .p--item__body__sp--row .p--body__img--330__200 {
          width: 120px !important; }
          .p--result__intern .p--intern__list .p--list__item .p--item__body__sp--row .p--body__img--330__200 img {
            height: 72.7px !important; }
          .p--result__intern .p--intern__list .p--list__item .p--item__body__sp--row .p--body__img--330__200 + ul {
            width: calc(100% - 130px);
            margin: 0 0 0 10px;
            font-size: .75rem; }
            .p--result__intern .p--intern__list .p--list__item .p--item__body__sp--row .p--body__img--330__200 + ul span {
              color: #828288; }
      .p--result__intern .p--intern__list .p--list__item .p--item__body--noFlex {
        padding: 15px 10px; }
      .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__img {
        margin: 0 auto; }
        .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__img--276__200 {
          width: 138px; }
          .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__img--276__200 img {
            height: 100px; }
        .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__img--330__200 {
          width: 100%;
          max-width: 330px; }
          .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__img--330__200 img {
            height: calc(((100vw - 40px) / 33) * 20);
            max-height: 200px; }
        .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__img--846__254 {
          width: 330px; }
          .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__img--846__254 img {
            height: 200px; }
        .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__img--846__252 {
          width: 100% !important;
          padding: 0 20px; }
          .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__img--846__252 img {
            height: calc((100vw - 40px) / 33.5 * 10) !important; }
      .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__rsvArea .p--rsvArea__ttl {
        display: flex;
        flex-direction: column; }
        .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__rsvArea .p--rsvArea__ttl .c--arrow--p__right {
          padding: 0; }
        .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__rsvArea .p--rsvArea__ttl .c--arrow:before {
          display: none; }
      .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__txt {
        width: 100%;
        margin: 10px 3px 0; }
        .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__txt ul {
          margin: 5px 0 0;
          font-size: 0.75rem; }
          .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__txt ul li {
            margin: 5px 0 0; }
      .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__ttl {
        font-size: 0.875rem; }
        .p--result__intern .p--intern__list .p--list__item .p--item__body .p--body__ttl + p {
          font-size: 0.75rem;
          margin: 5px 0 0; }
      .p--result__intern .p--intern__list .p--list__item .p--item__body--arrowLink > a .p--body__img--846__252 {
        padding: 0; }
        .p--result__intern .p--intern__list .p--list__item .p--item__body--arrowLink > a .p--body__img--846__252 img {
          height: calc((100vw - 64px) / 33.5 * 10) !important; }
      .p--result__intern .p--intern__list .p--list__item .p--item__body--arrowLink > a .p--body__txt {
        width: 100%; }
      .p--result__intern .p--intern__list .p--list__item .p--item__body--arrowLink.p--item__body__sp--row > a {
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%; }
    .p--result__intern .p--intern__list .p--list__item + .p--list__item {
      margin-top: 24px; }
    .p--result__intern .c--search__main .c--item__txt + .c--btn--searchIcon {
      margin: 13px 0 0;
      padding: 8px 22px; }
  .c--page__ctrl {
    padding: 0 20px;
    font-size: 0.75rem; }
  .p--fixed__bnr .p--bnr__txt {
    flex-direction: column;
    width: auto;
    font-size: 0.75rem; }
    .p--fixed__bnr .p--bnr__txt span {
      font-size: 1.25rem; }
  .p--fixed__bnr .c--btn {
    min-height: 58px; }
  .p--fixed__bnr .c--btn--lb {
    width: auto;
    margin: 0 0 0 10px;
    padding: 18px;
    font-size: 1rem; }
  .p--fixed__bnr .c--btn--w {
    padding: 10px 13px;
    font-size: 0.875rem;
    line-height: 1.3; }
  .c--search__main .c--main__item .p--item__spTop {
    display: flex !important; }
    .c--search__main .c--main__item .p--item__spTop .c--point__txt {
      width: 100px;
      color: #a2a2a7;
      font-size: 0.75rem;
      text-align: left; }
      .c--search__main .c--main__item .p--item__spTop .c--point__txt i {
        font-size: 1.25rem; }
    .c--search__main .c--main__item .p--item__spTop .c--item__txt {
      width: calc(100% - 100px);
      padding: 0 0 0 20px;
      border-left: 1px solid #dadadb;
      color: #a2a2a7;
      font-size: 0.625rem; }
  .p--search__calender {
    padding: 20px; }
  /** ========================================
  * @EDCT
  * EDCTページ
  ======================================== */
  .p--result__intern.p--trainingInfo__area .p--intern__list > .p--list__item > .p--item__body {
    padding: 20px 0 0; }
  .p--result__intern.p--trainingInfo__area .p--intern__list > .p--list__item > .p--item__heading--fav .p--heading__ttl {
    margin: 0 10px 0 0; }
    .p--result__intern.p--trainingInfo__area .p--intern__list > .p--list__item > .p--item__heading--fav .p--heading__ttl .p--fav__ttl {
      display: block; }
      .p--result__intern.p--trainingInfo__area .p--intern__list > .p--list__item > .p--item__heading--fav .p--heading__ttl .p--fav__ttl .c--boxHeading {
        margin-right: 0; }
  .p--result__intern.p--trainingInfo__area .p--intern__list > .p--list__item > .p--item__heading--fav .c--ovalTag {
    width: auto;
    margin: 0;
    padding: 5px;
    font-size: 0.625rem;
    font-weight: normal;
    line-height: 1; }
  .p--result__intern.p--trainingInfo__area .p--trainingInfo__top {
    display: block; }
    .p--result__intern.p--trainingInfo__area .p--trainingInfo__top .p--body__img--330__200 img {
      height: calc(100vw / 33 * 20) !important; }
    .p--result__intern.p--trainingInfo__area .p--trainingInfo__top .p--top__body {
      width: 100%;
      margin: 10px 0 0;
      padding: 0 20px; }
      .p--result__intern.p--trainingInfo__area .p--trainingInfo__top .p--top__body .p--body__detail {
        flex-wrap: wrap;
        font-size: 0.75rem; }
        .p--result__intern.p--trainingInfo__area .p--trainingInfo__top .p--top__body .p--body__detail li {
          margin: 0 10px 0 0 !important; }
          .p--result__intern.p--trainingInfo__area .p--trainingInfo__top .p--top__body .p--body__detail li:not(:first-child) {
            margin-top: 2px; }
      .p--result__intern.p--trainingInfo__area .p--trainingInfo__top .p--top__body .p--body__tag .c--ovalTag {
        margin: 4px; }
      .p--result__intern.p--trainingInfo__area .p--trainingInfo__top .p--top__body .p--body__catchcopy {
        margin: 15px 0 0;
        padding: 0;
        font-size: 0.875rem; }
      .p--result__intern.p--trainingInfo__area .p--trainingInfo__top .p--top__body .p--body__list {
        display: block;
        margin: 15px 0 0; }
        .p--result__intern.p--trainingInfo__area .p--trainingInfo__top .p--top__body .p--body__list .p--list__item {
          width: 100%; }
          .p--result__intern.p--trainingInfo__area .p--trainingInfo__top .p--top__body .p--body__list .p--list__item .p--item__ttl {
            padding: 0 12px; }
          .p--result__intern.p--trainingInfo__area .p--trainingInfo__top .p--top__body .p--body__list .p--list__item .p--item__body {
            flex-direction: row;
            padding: 13px 15px;
            border-top: none; }
            .p--result__intern.p--trainingInfo__area .p--trainingInfo__top .p--top__body .p--body__list .p--list__item .p--item__body--tag .c--ovalTag {
              width: 78px;
              padding: 2px 0;
              font-size: 0.625rem; }
  .p--result__intern.p--trainingInfo__area .p--trainingInfo__btm {
    margin: 13px 0;
    padding: 0 20px;
    border: none; }
    .p--result__intern.p--trainingInfo__area .p--trainingInfo__btm .p--btm__info {
      display: block;
      padding: 0; }
      .p--result__intern.p--trainingInfo__area .p--trainingInfo__btm .p--btm__info + .p--btm__info {
        border-bottom: 1px solid #dadadb;
        padding-bottom: 8px; }
      .p--result__intern.p--trainingInfo__area .p--trainingInfo__btm .p--btm__info .p--info__ttl {
        width: 100%;
        padding: 2px 12px 10px;
        border-bottom: 1px solid #dadadb;
        font-size: 0.75rem; }
      .p--result__intern.p--trainingInfo__area .p--trainingInfo__btm .p--btm__info .p--info__list {
        width: 100%;
        margin: 8px 0 0;
        font-size: 0.75rem;
        line-height: 1.3; }
        .p--result__intern.p--trainingInfo__area .p--trainingInfo__btm .p--btm__info .p--info__list a {
          display: flex;
          align-items: flex-start;
          padding: 0 10px; }
          .p--result__intern.p--trainingInfo__area .p--trainingInfo__btm .p--btm__info .p--info__list a.c--arrow--p__right:before {
            right: 1px; }
          .p--result__intern.p--trainingInfo__area .p--trainingInfo__btm .p--btm__info .p--info__list a .c--ovalTag + span {
            width: calc(100% - 80px);
            padding: 0 0 0 10px; }
          .p--result__intern.p--trainingInfo__area .p--trainingInfo__btm .p--btm__info .p--info__list a p {
            font-size: 0.75rem; }
  .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main {
    width: 100%;
    padding: 25px 0 10px; }
    .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .c--third__heading {
      padding: 0 20px; }
    .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__read {
      margin: 13px 0 0;
      padding: 0 20px;
      font-size: 0.875rem;
      line-height: 1.5; }
      .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__read + .p--body__img {
        margin: 35px auto 0; }
        .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__read + .p--body__img--846__252 {
          width: 335px; }
          .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__read + .p--body__img--846__252 img {
            height: 100px; }
    .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__subHeading {
      margin: 20px 0 0;
      padding: 0 20px; }
      .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__subHeading .p--subHeading__txt {
        margin: 12px 2px;
        font-size: 0.875rem; }
    .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__text {
      display: block;
      margin: 5px 0 0;
      padding: 0 20px; }
      .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__text .p--text {
        width: 100%;
        margin: 0;
        line-height: 1.5; }
      .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__text .p--body__img {
        margin: 25px auto 17px; }
        .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__text .p--body__img span {
          margin: 8px 0;
          padding: 0 10px;
          font-size: 0.75rem; }
      .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__text + .p--main__subHeading {
        margin: 20px 0 0; }
      .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__text.p--main__text--left, .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__text.p--main__text--right {
        display: flex;
        flex-direction: column-reverse; }
    .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__column {
      margin: 20px 0 0;
      padding: 0 20px; }
      .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__column .p--column__ttl {
        padding: 12px 16px;
        font-size: 0.875rem;
        line-height: 1.5; }
      .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__column .p--column__txt {
        padding: 5px 19px;
        line-height: 1.5; }
    .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__basicInfo {
      margin: 30px 0 0; }
      .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__basicInfo .p--intern__ttl {
        padding: 0 20px; }
      .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__basicInfo .c--primaryTable {
        width: calc(100% - 40px);
        margin: 15px 20px 0; }
        .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__basicInfo .c--primaryTable th {
          width: 100%;
          padding: 3px 15px; }
        .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__basicInfo .c--primaryTable td {
          padding: 8px 15px 15px; }
      .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__basicInfo .p--basicInfo__list {
        margin: 5px 0 0; }
        .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__basicInfo .p--basicInfo__list .p--list__ttl {
          border: none; }
        .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__basicInfo .p--basicInfo__list ul li {
          display: flex;
          flex-direction: column; }
          .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__basicInfo .p--basicInfo__list ul li a {
            padding-right: 20px;
            padding-left: 20px; }
          .p--result__intern.p--trainingInfo__area .p--trainingTab .p--tabBody__main .p--main__basicInfo .p--basicInfo__list ul li span {
            width: 100px;
            margin: 0 20px 5px 0;
            font-size: 0.625rem; }
  .p--trainingInfo__wrap .c--boxWht.c--boxShadow {
    box-shadow: none; }
  .p--trainingInfo__wrap .p--result__intern,
  .p--trainingInfo__wrap .p--intern__list {
    margin: 0; }
  .p--trainingInfo__wrap .p--fixed__bnr {
    padding: 22px 20px; }
    .p--trainingInfo__wrap .p--fixed__bnr .c--btn--l {
      width: auto;
      min-width: 70px;
      padding: 10px 13px;
      font-size: .875rem;
      line-height: 1.3; }
  .p--trainingInfo__wrap .c--card {
    margin: 20px 0; }
    .p--trainingInfo__wrap .c--card .p--top__btn {
      padding: 0 20px; }
  .p--trainingInfo__wrap .p--top__btn {
    padding: 20px; }
    .p--trainingInfo__wrap .p--top__btn .c--btn {
      padding: 13px 22px;
      font-size: 0.75rem; }
  .p--spMiniTab.p--simpleTab {
    flex-wrap: wrap;
    padding: 8px 20px; }
    .p--spMiniTab.p--simpleTab .p--simpleTab__item {
      width: calc((100% - 16px) / 3);
      margin: 0 8px 0 0;
      padding: 0;
      border-radius: 4px;
      font-size: 0.75rem;
      text-align: center; }
      .p--spMiniTab.p--simpleTab .p--simpleTab__item:nth-child(3n) {
        margin-right: 0; }
      .p--spMiniTab.p--simpleTab .p--simpleTab__item:nth-child(n + 4) {
        margin-top: 8px; }
      .p--spMiniTab.p--simpleTab .p--simpleTab__item p, .p--spMiniTab.p--simpleTab .p--simpleTab__item a {
        padding: 9px 5px; }
    .p--spMiniTab.p--simpleTabHead .p--simpleTab__item.p--tabSelect {
      border-radius: 0 0 4px 4px; }
      .p--spMiniTab.p--simpleTabHead .p--simpleTab__item.p--tabSelect p {
        padding-top: 5px; }
    .p--spMiniTab.p--simpleTabHead .p--simpleTab__item.p--tabArrow p, .p--spMiniTab.p--simpleTabHead .p--simpleTab__item.p--tabArrow a {
      padding: 4px 2px 14px; }
    .p--spMiniTab.p--simpleTabHead .p--simpleTab__item.p--tabArrow.p--tabSelect p, .p--spMiniTab.p--simpleTabHead .p--simpleTab__item.p--tabArrow.p--tabSelect a {
      padding: 4px 2px 13px; }
      .p--spMiniTab.p--simpleTabHead .p--simpleTab__item.p--tabArrow.p--tabSelect p:before, .p--spMiniTab.p--simpleTabHead .p--simpleTab__item.p--tabArrow.p--tabSelect a:before {
        bottom: 7px; }
    .p--spMiniTab.p--simpleTabBottom .p--simpleTab__item.p--tabSelect {
      border-bottom: 4px solid #e92d82;
      border-top: none;
      border-radius: 4px 4px 0 0; }
      .p--spMiniTab.p--simpleTabBottom .p--simpleTab__item.p--tabSelect p {
        padding-bottom: 5px; }
    .p--spMiniTab.p--simpleTabBottom .p--simpleTab__item.p--tabArrow p, .p--spMiniTab.p--simpleTabBottom .p--simpleTab__item.p--tabArrow a {
      padding: 14px 2px 4px; }
    .p--spMiniTab.p--simpleTabBottom .p--simpleTab__item.p--tabArrow.p--tabSelect p, .p--spMiniTab.p--simpleTabBottom .p--simpleTab__item.p--tabArrow.p--tabSelect a {
      padding: 13px 2px 4px; }
      .p--spMiniTab.p--simpleTabBottom .p--simpleTab__item.p--tabArrow.p--tabSelect p:before, .p--spMiniTab.p--simpleTabBottom .p--simpleTab__item.p--tabArrow.p--tabSelect a:before {
        top: 7px; }
  /** ========================================
   * @SPFT
   * SPFTページ
   ======================================== */
  .p--hospFeature__wrap .c--contents__inner {
    width: 100%; }
  .p--hospFeature__wrap .p--hospFeature {
    margin: 20px 0 0; }
  .p--hospFeature__wrap .p--fixed__bnr .c--control__txt {
    font-size: 1rem; }
  .p--hospFeature__wrap .p--fixed__bnr .c--btn--lb {
    width: auto;
    margin: 0;
    padding: 18px 45px; }
  .p--result__intern .p--intern__list.p--intern__list--headCheck .p--list__item .p--item__heading--fav .p--fav__ttl .c--control {
    margin-right: 5px; }
  .p--result__intern .p--intern__list.p--intern__list--headCheck .p--list__item .p--item__heading--fav .p--fav__ttl .p--new {
    min-width: 30px; }
  .p--result__intern .p--intern__list.p--intern__list--headCheck .p--list__item .p--item__body .p--body__txt ul {
    flex-wrap: wrap;
    margin: 0; }
    .p--result__intern .p--intern__list.p--intern__list--headCheck .p--list__item .p--item__body .p--body__txt ul li {
      margin-right: 13px; }
  .p--result__intern .p--intern__list.p--intern__list--headCheck .p--list__item .p--item__body .p--body__txt .p--body__tag {
    margin-top: 10px; }
  .p--result__intern .p--intern__list.p--intern__list--headCheck .p--list__item .p--item__btnArea {
    padding: 14px 20px; }
    .p--result__intern .p--intern__list.p--intern__list--headCheck .p--list__item .p--item__btnArea .c--btn {
      padding: 14px 10px;
      font-size: 0.8125rem; } }

@media screen and (max-width: 320px) {
  .p--spMiniTab.p--simpleTab .p--simpleTab__item {
    font-size: 0.5625rem; }
  .p--fixed__bnr .c--btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem; }
  .p--result__intern .p--intern__list.p--intern__list--headCheck .p--list__item .p--item__btnArea .c--btn {
    font-size: .65rem; } }

/** ========================================
 * @p--recruitList
 * 教育・研修情報
 ======================================== */
.p--recruitList__heading {
  margin: 22px 0 0;
  padding: 5px 20px;
  background: #FFEFF4; }
  .p--recruitList__heading--bgGy {
    margin: 10px 0 0;
    padding: 5px 15px;
    background: #F0F0F0;
    font-size: .8125rem; }

.p--recruitList__secondaryHeading {
  margin: 15px 0 0; }

.p--recruitList--bd {
  margin: 0 !important;
  padding: 10px 20px;
  border: 1px solid;
  border-color: transparent #F0F0F0 #F0F0F0 #F0F0F0; }
  .p--recruitList--bd a {
    text-decoration: underline; }

.p--recruitList__body {
  padding: 10px; }
  .p--recruitList__body .p--recruitList {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0 0;
    font-size: .8125rem; }
    .p--recruitList__body .p--recruitList__item {
      width: 50%; }
  .p--recruitList__body--bd {
    padding: 16px 20px !important;
    border: 1px solid #FFEFF4; }

.p--body__img--396__240 {
  width: 396px; }
  .p--body__img--396__240 img {
    height: 240px; }

.p--main__text--img__w--396 .p--main__read {
  width: calc(100% - 426px);
  margin: -5px 0 0 30px !important;
  font-size: 1.125rem !important;
  line-height: 1.8; }

@media screen and (max-width: 767px) {
  .p--recruitList__heading {
    padding: 5px 12px;
    font-size: .8125rem;
    font-weight: 700; }
    .p--recruitList__heading--bgGy {
      margin: 10px 0 0;
      padding: 3px 15px;
      font-size: .75rem; }
  .p--recruitList__secondaryHeading {
    margin: 10px 0 0;
    font-size: .75rem; }
  .p--recruitList__body .p--recruitList {
    display: block; }
    .p--recruitList__body .p--recruitList__item {
      width: 100%; }
      .p--recruitList__body .p--recruitList__item ul li {
        width: 50%; }
      .p--recruitList__body .p--recruitList__item + .p--recruitList__item {
        margin: 15px 0 0; }
  .p--recruitList__body--bd {
    padding: 10px 8px !important; }
  .p--recruitList--bd {
    padding: 5px 10px; }
    .p--recruitList--bd .p--recruitList__item {
      margin: 2px 0 0 !important; }
  .p--main__text--img__w--396 .p--main__read {
    width: 100% !important;
    margin: 10px 0 0 !important;
    padding: 0 !important;
    font-size: 0.875rem !important;
    font-weight: normal !important;
    line-height: 1.75; }
  .p--body__img--396__240 {
    width: 100%;
    max-width: 330px; }
    .p--body__img--396__240 img {
      height: calc((100vw - 40px)/33*20);
      max-height: 200px; } }

/** ================================================================================
 * 学生友達紹介 友達紹介
================================================================================ */
.p--contBox {
  padding: 15px;
  background: #fff;
  border-radius: 4px; }
  .p--contBox .p--memberAdd {
    padding: 15px 22px;
    text-align: center; }
    .p--contBox .p--memberAdd .c--btn {
      min-width: 236px; }
  .p--contBox .c--step__main .c--btn {
    min-width: auto;
    font-size: 0.8125rem; }

.c--contHeading--gr + .p--contBox {
  padding: 30px 20px;
  border-radius: 0 0 4px 4px; }

@media screen and (max-width: 767px) {
  .p--contBox {
    padding: 15px 0;
    border-radius: 0; }
    .p--contBox .p--memberAdd {
      padding: 20px 20px 0; }
      .p--contBox .p--memberAdd .c--btn {
        min-width: 100%; }
    .p--contBox .c--step__main .c--btn {
      width: auto; } }

/** ========================================
 * @p--referFriend
 * 友だち紹介トップ
======================================== */
.p--referFriend__imgArea {
  margin: 40px 0 0; }

.p--referFriend__link {
  margin: 40px 0 70px; }

.p--body__img--1200__340 {
  width: 1200px; }
  .p--body__img--1200__340 img {
    height: 340px; }

@media screen and (max-width: 767px) {
  .p--referFriend__imgArea {
    margin: 20px 0 0; }
  .p--referFriend__link {
    margin: 20px 0 30px;
    padding: 0 20px; }
  .p--body__img--1200__340 {
    max-width: 375px;
    width: 100%;
    margin: 0 auto; }
    .p--body__img--1200__340 img {
      height: 120px; } }

/** ========================================
 * @p--groupManage
 * グループ登録・解散ページ
======================================== */
.p--groupManage {
  text-align: center; }
  .p--groupManage .c--primary__heading + p {
    margin: 36px 0 0;
    font-size: 0.875rem; }
    .p--groupManage .c--primary__heading + p + p {
      margin: 14px 0 0; }
  .p--groupManage .c--btnArea__formBox {
    margin: 50px 0 0; }

@media screen and (max-width: 767px) {
  .p--groupManage .c--contents__inner {
    padding: 0 20px; }
  .p--groupManage .c--primary__heading {
    padding: 0; }
    .p--groupManage .c--primary__heading + p {
      margin: 17px 0 0; }
  .p--groupManage .c--btnArea__formBox {
    margin: 30px 0 25px; } }

/** ========================================
 * @p--groupParticipation
 * グループ参加特典
======================================== */
.p--groupBox__inner {
  width: 846px;
  margin: 0 auto;
  padding: 40px 0 70px; }

.p--topBdBox {
  border-radius: 0 0 4px 4px;
  background: #fff;
  box-shadow: 0 2px 2px rgba(97, 97, 106, 0.2), 0 1px 5px rgba(97, 97, 106, 0.1); }
  .p--topBdBox--pk {
    border-top: 2px solid #e92d82; }
  .p--topBdBox__heading {
    padding: 12px 42px;
    border-bottom: 1px solid #dbdbdc;
    font-size: 1.25rem;
    font-weight: 700; }
    .p--topBdBox__heading .c--ovalTag {
      width: auto;
      margin: 0;
      padding: 0 10px; }
  .p--topBdBox__body {
    border-radius: 0 0 4px 4px; }

.p--groupParticipation .p--topBdBox {
  margin: 38px 0 0; }

.p--groupParticipation .c--contHeading--gr {
  margin: 40px 0 0; }
  .p--groupParticipation .c--contHeading--gr.p--groupName__box {
    display: flex;
    align-items: center;
    justify-content: space-between; }

.p--groupParticipation .p--contBox {
  padding: 30px 85px; }

.p--groupParticipation .p--contBox__inputArea {
  display: flex;
  align-items: center;
  margin: 10px 0 0; }
  .p--groupParticipation .p--contBox__inputArea input {
    width: 75%;
    height: 52px;
    margin: 0 7px 0 0; }
  .p--groupParticipation .p--contBox__inputArea .c--btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    font-size: 1rem; }

.p--atten__ttl {
  margin: 40px 0 0;
  font-weight: bold; }

.p--atten__list {
  margin: 10px 0 0; }
  .p--atten__list li {
    margin: 5px 0 0 1rem;
    text-indent: -1rem; }
    .p--atten__list li:before {
      content: "●";
      margin: 0 3px 0 0; }

.p--faq {
  margin: 65px 0 0; }
  .p--faq__item {
    position: relative; }
    .p--faq__item:before {
      content: "";
      position: absolute;
      display: inline-block;
      left: 17px;
      width: 24px;
      height: 26px;
      background-size: contain !important; }
    .p--faq__item--q {
      padding: 20px 35px 0 55px;
      font-weight: bold; }
      .p--faq__item--q:before {
        top: 20px;
        background: url(../images/icon_question.png) no-repeat; }
    .p--faq__item--a {
      padding: 12px 35px 16px 55px;
      border-bottom: 1px solid #dadadb; }
      .p--faq__item--a:before {
        top: 15px;
        background: url(../images/icon_answer.png) no-repeat; }

@media screen and (max-width: 767px) {
  .p--groupBox__inner {
    width: 100%;
    padding: 0 0 20px; }
  .p--topBdBox__heading {
    padding: 12px 22px;
    font-size: 1rem; }
    .p--topBdBox__heading .c--ovalTag {
      margin: 0;
      padding: 5px;
      font-size: .625rem;
      font-weight: 400;
      line-height: 1; }
  .p--groupParticipation .p--topBdBox {
    margin: 22px 0 0; }
  .p--groupParticipation .c--contHeading--gr {
    margin: 20px 0 0; }
  .p--groupParticipation .p--contBox {
    padding: 15px 20px; }
  .p--groupParticipation .p--contBox__inputArea input {
    width: calc(100% - 100px); }
  .p--groupParticipation .p--contBox__inputArea .c--btn {
    min-width: auto;
    width: 100px;
    padding: 5px 12px;
    font-size: 0.875rem;
    line-height: 1.4; }
  .p--atten__ttl {
    margin: 20px 0 0;
    padding: 0 20px;
    font-size: 0.75rem; }
  .p--atten__list {
    margin: 5px 0 0;
    padding: 0 20px;
    font-size: 0.75rem; }
  .p--faq {
    margin: 35px 0 0; }
    .p--faq__ttl {
      padding: 7px 22px; }
    .p--faq__item:before {
      left: 24px; }
    .p--faq__item--q {
      padding: 15px 30px 0 62px; }
      .p--faq__item--q:before {
        top: 17px; }
    .p--faq__item--a {
      padding: 7px 30px 12px 62px; }
      .p--faq__item--a:before {
        top: 10px; } }

/** ====================
 * グループ参加特典（招待者） 参加前
==================== */
.p--groupParticipation.p--groupMember .p--contBox {
  padding: 30px; }

.p--groupParticipation.p--groupMember .c--btnArea__formBox .c--btn {
  min-width: 296px; }

.p--memberTotal {
  margin: 25px 0 0;
  text-align: center;
  font-size: 1rem;
  font-weight: bold; }
  .p--memberTotal span {
    display: inline-block;
    margin: 0 5px;
    color: #e92d82;
    font-size: 1.375rem; }

.p--memberList {
  display: flex;
  flex-wrap: wrap;
  margin: 27px 0 0; }
  .p--memberList__item {
    width: calc(50% - 3px);
    padding: 16px;
    border: 1px solid #dadadb;
    background: #dadadb;
    color: #828288; }
    .p--memberList__item:nth-child(even) {
      margin: 0 0 0 6px; }
    .p--memberList__item:nth-child(n + 3) {
      margin-top: 6px; }
    .p--memberList__item .p--itemNum {
      position: relative;
      padding: 0 0 0 23px;
      font-size: 0.8125rem; }
      .p--memberList__item .p--itemNum:before {
        content: "";
        position: absolute;
        display: inline-block;
        top: 50%;
        left: 0;
        width: 16px;
        height: 16px;
        background-size: contain !important;
        background: url(../images/icon_member_off.png) no-repeat;
        transform: translateY(-50%); }
      .p--memberList__item .p--itemNum .p--itemMsg {
        position: relative;
        display: inline-block;
        margin: 0 0 0 20px;
        padding: 3px 10px;
        border-radius: 3px;
        border: 1px solid #828288;
        color: #828288;
        font-size: .75rem; }
        .p--memberList__item .p--itemNum .p--itemMsg:before {
          content: "";
          position: absolute;
          top: 50%;
          left: -13px;
          border: 6px solid transparent;
          border-right: 8px solid #dadadb;
          z-index: 2;
          transform: translateY(-50%); }
        .p--memberList__item .p--itemNum .p--itemMsg:after {
          content: "";
          position: absolute;
          top: 50%;
          left: -16px;
          border: 7px solid transparent;
          border-right: 9px solid #828288;
          z-index: 1;
          transform: translateY(-50%); }
    .p--memberList__item--active {
      background: #fff;
      color: #333; }
      .p--memberList__item--active .p--itemNum {
        color: #e92d82; }
        .p--memberList__item--active .p--itemNum:before {
          background: url(../images/icon_member_on.png) no-repeat; }
        .p--memberList__item--active .p--itemNum .p--itemMsg {
          position: relative;
          display: inline-block;
          margin: 0 0 0 20px;
          padding: 3px 10px;
          border-radius: 3px;
          border: none;
          background: #e92d82;
          color: #fff;
          font-size: .75rem; }
          .p--memberList__item--active .p--itemNum .p--itemMsg:before {
            content: "";
            position: absolute;
            top: 50%;
            left: -12px;
            border: 5px solid transparent;
            border-right: 7px solid #e92d82;
            transform: translateY(-50%); }
          .p--memberList__item--active .p--itemNum .p--itemMsg:after {
            display: none; }
    .p--memberList__item--comp {
      background: #FFF79D; }
    .p--memberList__item--pk {
      background: #FFF0F5; }
    .p--memberList__item .p--itemName {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 12px 3px 0;
      font-size: 1rem; }
      .p--memberList__item .p--itemName .p--cancelBtn {
        display: inline-block;
        border: none;
        border-radius: 3px;
        padding: 3px 10px 6px;
        background: #828288;
        color: #fff;
        font-size: .75rem; }
      .p--memberList__item .p--itemName .p--representative {
        display: inline-block;
        border-radius: 3px;
        padding: 3px 10px;
        border: 1px solid #e92d82;
        background: #fff;
        color: #e92d82;
        font-size: .75rem; }

@media screen and (max-width: 767px) {
  .p--groupParticipation.p--groupMember .p--contBox {
    padding: 20px 0 0; }
  .p--groupParticipation.p--groupMember .c--btnArea__formBox .c--btn {
    min-width: 100%; }
  .p--groupParticipation.p--groupMember .p--groupMember__box {
    padding: 20px 20px 33px;
    border-bottom: 1px solid #ccc; }
  .p--groupParticipation.p--groupMember .p--lpWrap + .p--groupMember__box {
    padding-top: 0; }
  .p--groupParticipation .p--groupName {
    font-size: 1.125rem; }
  .p--memberTotal {
    margin: 10px 0 0 5px;
    padding: 0 20px;
    text-align: left;
    font-size: 0.875rem; }
  .p--memberList {
    margin: 15px 0 0;
    display: block;
    border-top: 1px solid #dadadb; }
    .p--memberList__item {
      width: 100%;
      border-color: transparent transparent #fff transparent; }
      .p--memberList__item:nth-child(even) {
        margin-left: 0; }
      .p--memberList__item:nth-child(n + 3) {
        margin-top: 0; }
      .p--memberList__item:last-child {
        border-bottom: none; }
      .p--memberList__item--active {
        border-color: transparent transparent #dadadb transparent; }
        .p--memberList__item--active .p--itemNum .p--itemMsg {
          font-size: 0.625rem; } }

/** ====================
 * グループ参加特典（招待者） 参加後
==================== */
.p--groupParticipation.p--groupMember .p--member__info {
  background: #fff79d;
  text-align: center;
  padding: 10px; }

.p--groupParticipation.p--groupMember .p--memberInv {
  margin: 20px 100px;
  padding: 23px 50px;
  background: #FFF0F5;
  font-weight: bold;
  text-align: center; }
  .p--groupParticipation.p--groupMember .p--memberInv__heading {
    position: relative;
    display: inline-block;
    margin: 0 0 16px 0;
    padding: 0 15px 0 20px;
    color: #e92d82;
    font-size: 1.125rem; }
    .p--groupParticipation.p--groupMember .p--memberInv__heading:before {
      content: "";
      position: absolute;
      display: inline-block;
      top: 50%;
      left: 0;
      width: 16px;
      height: 16px;
      background-size: contain !important;
      background: url(../images/icon_member_on.png) no-repeat;
      transform: translateY(-50%); }
  .p--groupParticipation.p--groupMember .p--memberInv__btnArea {
    display: flex;
    justify-content: space-between;
    margin: 18px 0 0; }
    .p--groupParticipation.p--groupMember .p--memberInv__btnArea .c--btn {
      min-width: 238px; }

.p--groupParticipation.p--groupMember .p--memberComp {
  margin: 25px 0 0;
  padding: 25px;
  background-color: #FFF0F5;
  background-image: url(../images/point_border_top.png), url(../images/point_border_bottom.png);
  background-position: top,bottom;
  background-repeat: repeat-x, repeat-x;
  background-size: auto,auto;
  font-weight: bold;
  text-align: center; }
  .p--groupParticipation.p--groupMember .p--memberComp__heading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 5px;
    color: #e92d82;
    font-size: 1.125rem; }
    .p--groupParticipation.p--groupMember .p--memberComp__heading:before, .p--groupParticipation.p--groupMember .p--memberComp__heading:after {
      content: "";
      display: inline-block;
      width: 18px;
      height: 21px;
      background-size: 18px; }
    .p--groupParticipation.p--groupMember .p--memberComp__heading:before {
      margin: 0 7px 0 0;
      background: url(../images/icon_comp_left.png) 50% no-repeat; }
    .p--groupParticipation.p--groupMember .p--memberComp__heading:after {
      content: "";
      margin: 0 0 0 7px;
      background: url(../images/icon_comp_right.png) 50% no-repeat; }

@media screen and (max-width: 767px) {
  .p--groupParticipation.p--groupMember.p--groupMember--join .p--contBox {
    padding: 0 0 20px; }
  .p--groupParticipation.p--groupMember .p--member__info {
    text-align: left; }
  .p--groupParticipation.p--groupMember .p--memberInv {
    margin: 20px 0;
    padding: 20px; }
    .p--groupParticipation.p--groupMember .p--memberInv__heading {
      margin: 0 0 10px;
      padding: 0 5px 0 20px;
      font-size: 0.8125rem; }
      .p--groupParticipation.p--groupMember .p--memberInv__heading + p {
        text-align: left; }
    .p--groupParticipation.p--groupMember .p--memberInv__btnArea {
      margin: 10px 0 0;
      display: block; }
      .p--groupParticipation.p--groupMember .p--memberInv__btnArea .c--btn {
        margin: 0;
        padding: 13px 5px; }
        .p--groupParticipation.p--groupMember .p--memberInv__btnArea .c--btn--line {
          margin: 0 0 12px;
          padding: 13px 22px 13px 5px; }
          .p--groupParticipation.p--groupMember .p--memberInv__btnArea .c--btn--line i {
            padding: 0 0 0 30px; }
            .p--groupParticipation.p--groupMember .p--memberInv__btnArea .c--btn--line i:before {
              width: 30px;
              height: 30px;
              background-size: 30px; }
  .p--groupParticipation.p--groupMember .p--memberComp {
    margin: 17px 0 0;
    padding: 25px 20px; }
    .p--groupParticipation.p--groupMember .p--memberComp__heading {
      margin: 0 0 10px;
      font-size: 1rem; } }

/** ====================
 * グループ参加特典ログインフォーム
==================== */
.p--groupParticipation.p--groupMember.p--groupMember__form--narrow .c--contents__inner {
  width: 895px; }

.p--groupParticipation.p--groupMember.p--groupMember__form--narrow .c--contHeading--gr + .p--contBox {
  padding: 30px 20px; }

.p--groupParticipation.p--groupMember.p--groupMember__form--narrow .c--btnArea__formBox {
  padding: 40px 0 20px; }

.p--groupParticipation.p--groupMember.p--groupMember__form .p--groupBox__inner {
  padding: 0; }
  .p--groupParticipation.p--groupMember.p--groupMember__form .p--groupBox__inner .c--primaryTable th, .p--groupParticipation.p--groupMember.p--groupMember__form .p--groupBox__inner .c--primaryTable td {
    height: 66px; }

.p--groupParticipation.p--groupMember.p--groupMember__form .c--primary__heading + .p--contBox {
  margin: 36px 0 0;
  padding: 24px; }

.p--groupParticipation.p--groupMember.p--groupMember__form .p--contBox + .p--contBox {
  margin: 40px 0 0; }

.p--groupParticipation.p--groupMember.p--groupMember__form .p--groupBox__infoArea {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .p--groupParticipation.p--groupMember.p--groupMember__form .p--groupBox__infoArea .c--btn {
    min-width: 156px; }
  .p--groupParticipation.p--groupMember.p--groupMember__form .p--groupBox__infoArea + .p--form__table {
    margin: 25px 0 0; }

@media screen and (max-width: 767px) {
  .p--groupParticipation.p--groupMember.p--groupMember__form--narrow .c--contents__inner {
    width: 100%; }
  .p--groupParticipation.p--groupMember.p--groupMember__form--narrow .c--contHeading--gr + .p--contBox {
    padding: 20px 0 !important; }
  .p--groupParticipation.p--groupMember.p--groupMember__form--narrow .c--btnArea__formBox {
    padding-right: 20px;
    padding-left: 20px; }
    .p--groupParticipation.p--groupMember.p--groupMember__form--narrow .c--btnArea__formBox p {
      padding: 0 !important;
      text-align: center !important; }
  .p--groupParticipation.p--groupMember.p--groupMember__form--narrow .c--primaryTable + .c--control {
    margin: 5px 25px; }
  .p--groupParticipation.p--groupMember.p--groupMember__form .p--groupBox__inner .c--primaryTable th, .p--groupParticipation.p--groupMember.p--groupMember__form .p--groupBox__inner .c--primaryTable td {
    height: auto; }
  .p--groupParticipation.p--groupMember.p--groupMember__form .p--contBox {
    padding: 0 !important; }
  .p--groupParticipation.p--groupMember.p--groupMember__form .p--groupBox__login {
    padding-top: 20px !important; }
    .p--groupParticipation.p--groupMember.p--groupMember__form .p--groupBox__login .c--primaryTable th {
      background: transparent; }
    .p--groupParticipation.p--groupMember.p--groupMember__form .p--groupBox__login .c--primaryTable + .c--control {
      margin: 5px 25px 5px 25px; }
      .p--groupParticipation.p--groupMember.p--groupMember__form .p--groupBox__login .c--primaryTable + .c--control + .c--btnArea__formBox .c--btn {
        margin: 0; }
  .p--groupParticipation.p--groupMember.p--groupMember__form .c--btnArea__formBox p {
    padding: 15px 0 35px;
    text-align: left; }
  .p--groupParticipation.p--groupMember.p--groupMember__form .p--groupBox__infoArea {
    display: block;
    padding: 10px 20px; }
    .p--groupParticipation.p--groupMember.p--groupMember__form .p--groupBox__infoArea + .p--form__table {
      margin: 0; } }

/** ================================================================================
 * 学生友達紹介 資料請求
================================================================================ */
/** ========================================
 * @p--docReq
 * 資料請求関係
======================================== */
.p--form__edit {
  display: flex;
  justify-content: space-between; }

.p--docReq .c--contents__inner {
  width: 895px; }
  .p--docReq .c--contents__inner .c--primary__heading + .p--topBdBox {
    margin: 40px 0 0; }
  .p--docReq .c--contents__inner .c--error__list {
    margin: 35px 0; }
  .p--docReq .c--contents__inner .p--topBdBox__heading {
    padding: 12px 25px; }
  .p--docReq .c--contents__inner .p--topBdBox + .p--contBox {
    margin: 50px 0 0; }
  .p--docReq .c--contents__inner .c--contHeading--gr {
    margin: 50px 0 0; }
  .p--docReq .c--contents__inner p + .p--form__infoTxt {
    margin: 17px 0 0; }
    .p--docReq .c--contents__inner p + .p--form__infoTxt + .p--form__table {
      margin: 25px 0 0; }
  .p--docReq .c--contents__inner .c--btnArea {
    padding-top: 40px;
    padding-bottom: 20px; }
  .p--docReq .c--contents__inner .c--primaryTable th, .p--docReq .c--contents__inner .c--primaryTable td {
    height: 66px; }
  .p--docReq .c--contents__inner .c--btnArea__formBox .c--btn {
    min-width: 296px; }

@media screen and (max-width: 767px) {
  .p--form__edit {
    display: block;
    text-align: right; }
    .p--form__edit .p--form__infoTxt {
      margin: 0 0 25px;
      text-align: left; }
  .p--docReq .c--contents__inner {
    width: 100%; }
    .p--docReq .c--contents__inner .c--error__list {
      padding: 0 20px; }
    .p--docReq .c--contents__inner .p--contBox {
      padding: 20px 0; }
      .p--docReq .c--contents__inner .p--contBox.c--boxShadow {
        box-shadow: none; }
      .p--docReq .c--contents__inner .p--contBox .p--finTxt {
        padding: 0 25px; }
    .p--docReq .c--contents__inner .p--form__infoWrap {
      padding: 0 20px; }
    .p--docReq .c--contents__inner .c--primaryTable th, .p--docReq .c--contents__inner .c--primaryTable td {
      height: auto; }
    .p--docReq .c--contents__inner .c--primaryTable + .c--control {
      margin: 5px 25px; }
    .p--docReq .c--contents__inner .p--topBdBox {
      box-shadow: none; }
      .p--docReq .c--contents__inner .p--topBdBox + .p--contBox {
        margin: 20px 0 0; }
    .p--docReq .c--contents__inner .c--btnArea__formBox .c--btn {
      min-width: 100%; } }

/** ====================
 * 資料請求一括申込
==================== */
.p--docReq__target {
  padding: 18px 23px; }
  .p--docReq__target .p--target__num {
    font-size: 1rem;
    font-weight: bold; }
    .p--docReq__target .p--target__num span {
      display: inline-block;
      margin: 0 5px 0 0;
      color: #e92d82;
      font-size: 1.4375rem; }
  .p--docReq__target .p--target__list {
    margin: 8px 0 0;
    font-weight: bold; }
  .p--docReq__target .p--target__already {
    margin: 10px 0 0;
    display: inline-block; }

@media screen and (max-width: 767px) {
  .p--docReq__target {
    margin: 0 20px; } }

/** ====================
 * 資料請求完了
==================== */
.p--docReq.p--docReq__finish .c--contents__inner .p--topBdBox .p--docReq__finishTxt {
  font-size: 1.125rem;
  font-weight: bold;
  text-align: center; }

.p--docReq.p--docReq__finish .c--contents__inner .c--btnArea .c--btn {
  min-width: auto; }
  .p--docReq.p--docReq__finish .c--contents__inner .c--btnArea .c--btn + .c--btn {
    margin: 0 0 0 10px; }

.p--docReq.p--docReq__finish .c--contents__inner .c--secondary__heading {
  margin: 45px 0 0;
  text-align: center; }

.c--primary__heading + .p--docReq__finishTxt {
  margin: 32px 0;
  color: #e92d82;
  font-size: 1.5rem;
  font-weight: bold; }

.p--docReq__finishInfoTxt {
  margin: 25px 0 0;
  text-align: center; }

@media screen and (max-width: 767px) {
  .p--docReq__finishInfoTxt {
    padding: 0 20px; }
  .p--docReq.p--docReq__finish .c--contents__inner .p--topBdBox .p--docReq__finishTxt {
    padding: 0 20px;
    font-size: 0.875rem;
    text-align: left; }
  .p--docReq.p--docReq__finish .c--contents__inner .c--btnArea {
    padding-top: 20px; }
    .p--docReq.p--docReq__finish .c--contents__inner .c--btnArea .c--btn {
      width: 100%; }
      .p--docReq.p--docReq__finish .c--contents__inner .c--btnArea .c--btn + .c--btn {
        margin: 10px 0 0; }
  .p--docReq.p--docReq__finish .c--contents__inner .c--secondary__heading {
    margin: 30px 0 0;
    padding: 0 20px;
    font-size: 1rem; }
  .c--primary__heading + .p--docReq__finishTxt {
    font-size: 1.125rem; } }

/*! 
 * 見学会申込
 */
/** ========================================
 * @p--aply
 * 見学会申込
 ======================================== */
.p--aply {
  /** ====================
  * APLY-001_02
  ==================== */
  /** ====================
  * APLY-002_03
  ==================== */ }
  .p--aply .p--contBox {
    padding: 0; }
    .p--aply .p--contBox__inner {
      width: 846px;
      margin: 0 auto;
      padding: 30px 0; }
  .p--aply .c--primary__heading + .p--topBdBox {
    margin: 35px 0 0; }
  .p--aply .c--boxlp {
    padding: 9px 16px; }
    .p--aply .c--boxlp .c--ovalTag {
      margin: 0 15px 0 0; }
    .p--aply .c--boxlp p + p {
      margin: 7px 0 0; }
      .p--aply .c--boxlp p + p span {
        display: inline-block;
        margin: 0 15px 0 0; }
  .p--aply .p--tagBox {
    margin: 25px 0 0; }
  .p--aply .c--btnArea + .p--form__infoWrap {
    margin-top: 38px; }
  .p--aply .p--form__table {
    margin: 25px 0 0; }
  .p--aply .c--btnArea__formBox {
    margin: 40px auto 30px; }
    .p--aply .c--btnArea__formBox .c--btn--pk {
      width: 296px;
      padding: 20px; }
  .p--aply div + .c--contHeading--gr {
    margin: 50px 0 0; }
  .p--aply .p--dateBox {
    margin: 13px 0 0; }
    .p--aply .p--dateBox .c--control {
      min-width: auto;
      margin-right: 5px; }
      .p--aply .p--dateBox .c--control__indicator {
        width: 16px;
        height: 16px; }
    .p--aply .p--dateBox .c--ui__date {
      width: 140px;
      height: 30px;
      border-width: 1px; }
    .p--aply .p--dateBox .c--select {
      width: auto;
      margin: 0 5px; }
      .p--aply .p--dateBox .c--select select {
        min-width: 70px;
        height: 30px;
        padding: 5px 10px;
        border-width: 1px; }
    .p--aply .p--dateBox + .p--tagBox {
      margin-top: 20px; }
  .p--aply .p--infoBox--btm {
    margin: 0 0 80px; }
  .p--aply .c--input__name {
    width: 155px; }

.p--detailInfoWrap > .u--fw__bold {
  margin: 20px 0 0;
  color: #e92d82; }

.p--detailInfoWrap .p--infoTxt {
  margin: 5px 0 0; }

.p--form__infoWrap {
  margin-bottom: 20px; }
  .p--form__infoWrap--topBd {
    margin-top: 28px;
    padding-top: 27px;
    border-top: 1px dotted #dadadb; }
  .p--form__infoWrap + .p--form__table {
    margin-top: 0; }
  .p--form__infoWrap .p--form__infoTxt + .p--form__infoTxt {
    margin-top: 20px; }

@media screen and (max-width: 767px) {
  .p--aply {
    /** ====================
    * APLY-001_02
    ==================== */ }
    .p--aply .p--contBox__inner {
      width: 100%;
      padding: 20px 0 30px; }
    .p--aply .c--boxlp {
      margin: 0 20px;
      padding: 12px; }
      .p--aply .c--boxlp .c--ovalTag {
        width: 100px;
        padding: 2px 5px;
        font-size: 0.625rem; }
        .p--aply .c--boxlp .c--ovalTag + span {
          display: block;
          width: 100%; }
    .p--aply .p--tagBox {
      margin: 16px 0 0;
      padding: 0 20px; }
    .p--aply .p--form__infoWrap {
      padding-right: 20px;
      padding-left: 20px; }
    .p--aply .p--form__edit {
      margin: 28px 0 0;
      padding-top: 20px; }
    .p--aply .c--btnArea__formBox {
      margin: 15px auto; }
      .p--aply .c--btnArea__formBox .c--btn--pk {
        width: 100%; }
    .p--aply .p--infoBox--btm {
      margin: 20px 0 0;
      padding: 0 20px; }
    .p--aply div + .c--contHeading--gr {
      margin: 0; }
    .p--aply .c--primaryTable + .c--control {
      margin: 5px 25px; }
    .p--aply .p--form__table + .p--tagBox {
      margin: 0; }
    .p--aply .c--input__name {
      width: 48%; }
    .p--aply .p--dateBox {
      padding: 0 20px; }
  .p--detailInfoWrap {
    margin: 25px 0 0;
    padding: 0 20px; }
    .p--detailInfoWrap > .u--fw__bold {
      margin: 20px 0 0; } }

/** ====================
 * APLY-001_03
==================== */
.p--aply__reserved .p--topBdBox__inner {
  padding-bottom: 60px; }

.p--aply__reserved .p--reserved__txt {
  margin: 7px 0 0; }

.p--aply__reserved .c--btnArea {
  margin: 30px auto 0;
  text-align: center; }
  .p--aply__reserved .c--btnArea .c--btn {
    width: 335px;
    text-align: center; }

@media screen and (max-width: 767px) {
  .p--aply__reserved .p--topBdBox__inner {
    padding-bottom: 35px; }
  .p--aply__reserved .p--reserved__txt {
    padding: 0 20px; }
  .p--aply__reserved .c--btnArea {
    margin: 20px auto 0; }
    .p--aply__reserved .c--btnArea .c--btn {
      width: 100%; } }

/** ====================
 * APLY-003_01
==================== */
.p--appFinish__txtArea {
  margin: 35px 0 0;
  text-align: center; }
  .p--appFinish__txtArea > p:not(.p--ttl) {
    margin: 25px 0 0; }
  .p--appFinish__txtArea .p--ttl {
    font-size: 1.125rem; }

.p--appFinish__checkArea {
  margin: 45px 0 0;
  padding: 10px 25px;
  text-align: center; }
  .p--appFinish__checkArea .p--ttl {
    text-align: left; }

.p--appFinish .c--btn {
  min-width: auto;
  margin: 16px 0 0;
  padding: 17px 20px;
  font-size: 0.875rem; }
  .p--appFinish .c--btn + p {
    margin: 7px 0 10px; }

.p--appFinish .c--btnArea__formBox {
  margin: 12px auto 0; }
  .p--appFinish .c--btnArea__formBox .c--btn {
    min-width: 168px; }
    .p--appFinish .c--btnArea__formBox .c--btn + .c--btn {
      margin: 0 0 0 7px; }

.p--appFinish .p--fav__hospital {
  padding-top: 20px; }

@media screen and (max-width: 767px) {
  .p--appFinish__txtArea {
    margin: 18px 0 0;
    padding: 0 20px;
    text-align: left; }
    .p--appFinish__txtArea .p--ttl {
      font-size: 1rem; }
  .p--appFinish__checkArea {
    margin: 30px 20px 0 !important;
    padding: 10px 20px !important; }
    .p--appFinish__checkArea .p--ttl {
      text-align: left; }
  .p--appFinish .c--btn {
    margin: 6px 0 0;
    padding: 13px 20px;
    font-size: 0.75rem; }
    .p--appFinish .c--btn + p {
      margin: 6px 0 13px;
      font-size: 0.75rem; }
  .p--appFinish .c--btnArea__formBox {
    margin: 25px auto 0; }
    .p--appFinish .c--btnArea__formBox .c--btn {
      margin: 0;
      padding: 13px 20px;
      font-size: 0.75rem; }
      .p--appFinish .c--btnArea__formBox .c--btn + .c--btn {
        margin: 15px 0 0; }
  .p--appFinish .p--fav__hospital {
    margin-top: 20px; } }

/** ========================================
 * @p--survey
 * アンケート回答
 ======================================== */
.p--survey .p--contBox {
  width: 895px;
  margin: 37px auto 0;
  padding: 32px 22px; }
  .p--survey .p--contBox.p--surveyTopInfo {
    padding: 0;
    background: transparent; }
    .p--survey .p--contBox.p--surveyTopInfo .p--surveyTopInfo__ttl {
      font-size: 1rem; }
    .p--survey .p--contBox.p--surveyTopInfo .p--surveyTopInfo__txt {
      margin: 10px 0 0; }

.p--survey .p--form__edit + .p--form__table {
  margin: 25px 0 0; }

.p--survey .c--btnArea__formBox {
  margin: 30px 0 0; }
  .p--survey .c--btnArea__formBox .c--btn--pk {
    width: 296px; }

.p--survey__finishTxt {
  margin: 42px 0 0;
  font-size: 1rem; }

@media screen and (max-width: 767px) {
  .p--survey .p--contBox {
    width: 100%;
    margin: 28px auto 0;
    padding: 0; }
    .p--survey .p--contBox.p--surveyTopInfo {
      padding: 0 20px; }
      .p--survey .p--contBox.p--surveyTopInfo .p--surveyTopInfo__ttl {
        font-size: 1rem; }
      .p--survey .p--contBox.p--surveyTopInfo .p--surveyTopInfo__txt {
        margin: 10px 0 0; }
  .p--survey .p--form__edit {
    padding: 15px 20px 0; }
  .p--survey .c--btnArea__formBox .c--btn--pk {
    width: 100%; }
  .p--survey .p--form__infoWrap {
    margin: 0;
    padding: 20px; }
  .p--survey__finishTxt {
    margin: 25px 0 0; } }

/*! 
 * SCHL-001
 */
/* 色の定数 */
/** ========================================
 * ページの上部：ヒーローエリアと見出し
 * ====================================== */
.p--schl__heroImage {
  display: inline-block;
  margin-top: 56px; }

.p--schl__heading {
  margin-top: 46px; }

.p--schl__lead {
  margin-top: 30px;
  text-align: center; }
  .p--schl__lead p:not(:nth-of-type(1)) {
    margin-top: 28px; }

/** ========================================
 * 地域タブ流用 - 上書き
 * ====================================== */
.p--schl__tab {
  margin-top: 42px; }
  .p--schl__tab .p--area, .p--schl__tab .p--jobFair__area {
    justify-content: flex-start; }
  .p--schl__tab .p--area .p--area__link {
    margin-right: 8px; }
  .p--schl__tab .p--area .p--area__link--disabled {
    opacity: .6;
    pointer-events: none; }
  .p--schl__tab .p--area .p--area__link--disabled:hover {
    border-top: none;
    padding-top: 8px; }

/** ========================================
 * 地域セクション
 * ====================================== */
.p--areaContainer {
  position: relative;
  margin-top: 50px;
  padding-top: 34px; }
  .p--areaContainer::before {
    content: "";
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-image: linear-gradient(90deg, #d9d9db, #d9d9db 3px, transparent 0, transparent 13px);
    background-size: 13px 3px;
    background-repeat: repeat-x; }
  .p--areaContainer__heading {
    text-align: center;
    font-size: 1.375rem;
    font-weight: bold; }

/** ========================================
 * スクールカード
 * ====================================== */
.p--schoolCards {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px; }
  .p--schoolCards .p--item {
    flex: 0 0 170px;
    padding: 2px;
    background: #fff;
    border: 1px solid #dadada;
    border-radius: 6px; }
    .p--schoolCards .p--item:not(:last-child) {
      margin-right: 8px; }
    .p--schoolCards .p--item:nth-child(n + 7) {
      margin-top: 12px; }
    .p--schoolCards .p--item__area {
      padding: 6px;
      background-color: #d9d9db;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      color: #fff;
      font-weight: bold;
      text-align: center;
      line-height: 1; }
      .p--schoolCards .p--item__area:before {
        right: 5px;
        width: 6px;
        height: 6px;
        border-color: #fff; }
    .p--schoolCards .p--item__dateTime {
      margin-top: 10px;
      text-align: center; }
      .p--schoolCards .p--item__dateTime .p--dateTime__year {
        font-size: .75rem; }
      .p--schoolCards .p--item__dateTime .p--dateTime__date {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2px 0 0;
        font-size: 2rem;
        font-weight: 700;
        line-height: 1; }
      .p--schoolCards .p--item__dateTime .p--date_dayOfWeek {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin: 0 0 0 2px;
        border-radius: 50%;
        background: #828288;
        color: #fff;
        font-size: 0.875rem;
        line-height: 24px; }
        .p--schoolCards .p--item__dateTime .p--date_dayOfWeek--sun {
          background: #E92D82; }
        .p--schoolCards .p--item__dateTime .p--date_dayOfWeek--sat {
          background: #00B0FF; }
      .p--schoolCards .p--item__dateTime .p--dateTime__time {
        margin: 3px 0 0;
        font-size: .75rem; }
    .p--schoolCards .p--item__txt {
      margin: 4px 10px 0;
      padding: 7px 0 10px;
      border-top: 1px solid #f5f5f5;
      font-size: 0.75rem;
      line-height: 1.2; }

/** ========================================
 * 地域ごとのカラー設定
 * ====================================== */
.p--areaContainer--hokkaido::before {
  background-image: linear-gradient(90deg, #b5d951, #b5d951 3px, transparent 0, transparent 13px); }

.p--areaContainer--hokkaido .p--schoolCards .p--item__area {
  background-color: #b5d951; }

.p--areaContainer--tohoku::before {
  background-image: linear-gradient(90deg, #3dd9a9, #3dd9a9 3px, transparent 0, transparent 13px); }

.p--areaContainer--tohoku .p--schoolCards .p--item__area {
  background-color: #3dd9a9; }

.p--areaContainer--kanto::before {
  background-image: linear-gradient(90deg, #ffbf2a, #ffbf2a 3px, transparent 0, transparent 13px); }

.p--areaContainer--kanto .p--schoolCards .p--item__area {
  background-color: #ffbf2a; }

.p--areaContainer--hokuriku::before {
  background-image: linear-gradient(90deg, #c983e6, #c983e6 3px, transparent 0, transparent 13px); }

.p--areaContainer--hokuriku .p--schoolCards .p--item__area {
  background-color: #c983e6; }

.p--areaContainer--tokai::before {
  background-image: linear-gradient(90deg, #6b98eb, #6b98eb 3px, transparent 0, transparent 13px); }

.p--areaContainer--tokai .p--schoolCards .p--item__area {
  background-color: #6b98eb; }

.p--areaContainer--kansai::before {
  background-image: linear-gradient(90deg, #ff9d04, #ff9d04 3px, transparent 0, transparent 13px); }

.p--areaContainer--kansai .p--schoolCards .p--item__area {
  background-color: #ff9d04; }

.p--areaContainer--chugoku::before {
  background-image: linear-gradient(90deg, #f48fd1, #f48fd1 3px, transparent 0, transparent 13px); }

.p--areaContainer--chugoku .p--schoolCards .p--item__area {
  background-color: #f48fd1; }

.p--areaContainer--kyusyu::before {
  background-image: linear-gradient(90deg, #58cfed, #58cfed 3px, transparent 0, transparent 13px); }

.p--areaContainer--kyusyu .p--schoolCards .p--item__area {
  background-color: #58cfed; }

@media screen and (max-width: 767px) {
  /** ========================================
   * ページの上部：ヒーローエリアと見出し
   * ====================================== */
  .p--schl .c--contents__inner {
    padding-bottom: 30px; }
  .p--schl__heroImage {
    display: flex;
    justify-content: center;
    margin-top: 0; }
  .p--schl__heading {
    margin-top: 25px;
    font-size: 1.375rem; }
  .p--schl__lead {
    margin-top: 12px;
    padding: 0 20px;
    text-align: left;
    font-size: 0.75rem; }
    .p--schl__lead p:not(:nth-of-type(1)) {
      margin-top: 18px; }
  /** ========================================
   * 地域タブ流用 - 上書き
   * ====================================== */
  .p--schl__tab {
    margin-top: 28px; }
    .p--schl__tab .p--area .p--area__link:nth-child(3n) {
      margin-right: 0; }
  /** ========================================
   * 地域セクション
   * ====================================== */
  .p--areaContainer {
    margin: 30px 20px 0;
    padding-top: 22px; }
    .p--areaContainer__heading {
      font-size: 1.2rem; }
  /** ========================================
   * スクールカード
   * ====================================== */
  .p--schoolCards {
    margin-top: 17px; }
    .p--schoolCards .p--item {
      flex: 0 0 calc((100% - 16px) / 3); }
      .p--schoolCards .p--item:nth-child(3n) {
        margin-right: 0; }
      .p--schoolCards .p--item:nth-child(n + 4) {
        margin-top: 7px !important; }
      .p--schoolCards .p--item__area {
        padding: 3px 6px;
        font-size: 0.8125rem; }
      .p--schoolCards .p--item__dateTime {
        margin-top: 8px; }
        .p--schoolCards .p--item__dateTime .p--dateTime__year {
          line-height: 1; }
        .p--schoolCards .p--item__dateTime .p--dateTime__date {
          font-size: 1.6rem; }
        .p--schoolCards .p--item__dateTime .p--date_dayOfWeek {
          width: 22px;
          height: 22px;
          margin: 0 0 0 5px;
          font-size: .7rem; }
      .p--schoolCards .p--item__txt {
        margin: 4px 5px 0;
        padding: 5px 0 10px;
        border-top: 1px solid #f5f5f5;
        font-size: .45rem;
        line-height: 1.2; }
  /** ========================================
   * スクールカード
   * ====================================== */
  .p--schoolCards .p--item__dateTime .p--dateTime__date {
    font-size: 1.3rem; }
  .p--schoolCards .p--item__dateTime .p--date_dayOfWeek {
    width: 18px;
    height: 18px;
    font-size: .6rem;
    line-height: 18px; } }

@media screen and (max-width: 370px) {
  /** ========================================
   * スクールカード
   * ====================================== */
  .p--schoolCards .p--item__txt {
    font-size: 1.8vw; } }

/*! 
 * メッセージページ関係
 */
/** ========================================
 * @COMN-001.html
 * メッセージ一覧
 ======================================== */
.p--msgBtn__box {
  display: flex;
  align-items: center; }
  .p--msgBtn__box .p--msgBtn__item:not(:first-child) {
    margin: 0 0 0 10px; }
  .p--msgBtn__box .p--msgBtn {
    display: flex;
    align-items: center;
    padding: 7px 11px;
    border: 1px solid #dadadb;
    border-radius: 4px;
    background: #fff;
    color: #e92d82;
    font-size: 0.8125rem; }
    .p--msgBtn__box .p--msgBtn:before {
      content: "";
      display: inline-block;
      width: 18px;
      height: 18px;
      margin: 0 7px 0 0;
      background-size: contain !important; }
    .p--msgBtn__box .p--msgBtn--inbox:before {
      background: url(../images/icon_inbox.png) 50% no-repeat; }
    .p--msgBtn__box .p--msgBtn--send:before {
      background: url(../images/icon_send.png) 50% no-repeat; }
    .p--msgBtn__box .p--msgBtn--trash:before {
      background: url(../images/icon_trash.png) 50% no-repeat; }
    .p--msgBtn__box .p--msgBtn i {
      display: inline-block;
      margin: 0 0 0 8px;
      padding: 2px 6px;
      border-radius: 100px;
      background: #f84361;
      color: #fff;
      font-size: 0.625rem;
      font-weight: bold; }
    .p--msgBtn__box .p--msgBtn--active {
      background: #e92d82;
      color: #fff; }
      .p--msgBtn__box .p--msgBtn--active.p--msgBtn {
        border: 1px solid #e92d82; }
        .p--msgBtn__box .p--msgBtn--active.p--msgBtn--inbox:before {
          background: url(../images/icon_inbox_w.png) 50% no-repeat; }
        .p--msgBtn__box .p--msgBtn--active.p--msgBtn--send:before {
          background: url(../images/icon_send_w.png) 50% no-repeat; }
        .p--msgBtn__box .p--msgBtn--active.p--msgBtn--trash:before {
          background: url(../images/icon_trash_w.png) 50% no-repeat; }
      .p--msgBtn__box .p--msgBtn--active i {
        background: #fff;
        color: #e92d82; }

.c--page__ctrl {
  margin: 25px 0 0; }

.p--msgSearch {
  margin: 20px 0 0;
  padding: 25px 15px 30px; }
  .p--msgSearch__ttl {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    font-weight: bold; }
    .p--msgSearch__ttl:before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 24px;
      margin: 0 7px 0 0;
      background: url(../images/icon_circle_search.png) 100%/contain no-repeat; }
  .p--msgSearch__box {
    display: flex;
    align-items: center;
    margin: 15px 0 0; }
    .p--msgSearch__box input {
      width: calc(100% - 104px);
      margin: 0 10px 0 0;
      height: 55px; }
    .p--msgSearch__box .c--btn--input {
      width: 82px;
      height: 55px; }
  .p--msgSearch__state {
    display: flex;
    align-items: center;
    margin: 20px 0 0; }
    .p--msgSearch__state .p--stateList {
      display: flex;
      align-items: center;
      margin: 0 0 0 5px; }
      .p--msgSearch__state .p--stateList__item {
        padding: 0 20px 0 0; }
        .p--msgSearch__state .p--stateList__item:not(:first-child) {
          padding-left: 20px;
          border-left: 1px solid #dadadb; }
        .p--msgSearch__state .p--stateList__item a {
          color: #e92d82; }
        .p--msgSearch__state .p--stateList__item--active a {
          pointer-events: none;
          color: #333;
          font-weight: bold; }
      .p--msgSearch__state .p--stateList + p {
        margin: 0 0 0 40px; }

.p--msgInbox__heading {
  margin: 20px 0 0; }

.p--msgInbox__body {
  margin: 30px 0 0; }
  .p--msgInbox__body--pageCtrl {
    margin: 0; }
    .p--msgInbox__body--pageCtrl .p--msgInboxTbl {
      margin: 30px 0 0; }
  .p--msgInbox__body .p--msgInboxTbl {
    border-radius: 4px; }
    .p--msgInbox__body .p--msgInboxTbl__head, .p--msgInbox__body .p--msgInboxTbl__body {
      display: flex; }
    .p--msgInbox__body .p--msgInboxTbl__head {
      background: #818187;
      border-radius: 4px 4px 0 0; }
      .p--msgInbox__body .p--msgInboxTbl__head .p--msgInboxTbl__item {
        padding: 0; }
        .p--msgInbox__body .p--msgInboxTbl__head .p--msgInboxTbl__item .p--sortTgl {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: 10px 13px 10px 16px;
          color: #fff; }
          .p--msgInbox__body .p--msgInboxTbl__head .p--msgInboxTbl__item .p--sortTgl:after {
            content: "";
            position: absolute;
            display: inline-block;
            top: 50%;
            right: 13px;
            width: 7px;
            height: 7px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: translateY(-50%) rotate(-135deg); }
          .p--msgInbox__body .p--msgInboxTbl__head .p--msgInboxTbl__item .p--sortTgl:hover {
            cursor: pointer; }
          .p--msgInbox__body .p--msgInboxTbl__head .p--msgInboxTbl__item .p--sortTgl--down:after {
            top: 48%;
            transform: translateY(-50%) rotate(45deg); }
    .p--msgInbox__body .p--msgInboxTbl__body {
      border-top: 1px solid #dadadb;
      font-weight: bold; }
      .p--msgInbox__body .p--msgInboxTbl__body--hosp {
        background: #FFFCF0; }
      .p--msgInbox__body .p--msgInboxTbl__body--open {
        background: #FAFAFA;
        font-weight: normal; }
      .p--msgInbox__body .p--msgInboxTbl__body .p--msgInboxTbl__item--subject {
        width: calc(100% - 418px); }
        .p--msgInbox__body .p--msgInboxTbl__body .p--msgInboxTbl__item--subject a {
          color: #e92d82; }
    .p--msgInbox__body .p--msgInboxTbl--check .p--msgInboxTbl__item--subject {
      width: calc(100% - 418px); }
    .p--msgInbox__body .p--msgInboxTbl--noCheck .p--msgInboxTbl__item--subject {
      width: calc(100% - 373px); }
    .p--msgInbox__body .p--msgInboxTbl__item {
      display: flex;
      align-items: center;
      min-height: 46px;
      padding: 0 15px; }
      .p--msgInbox__body .p--msgInboxTbl__item--check {
        width: 45px;
        padding: 0 14px; }
      .p--msgInbox__body .p--msgInboxTbl__item--name {
        width: 203px; }
      .p--msgInbox__body .p--msgInboxTbl__item--date {
        width: 170px; }
      .p--msgInbox__body .p--msgInboxTbl__item:not(:first-child) {
        border-left: 1px solid #dadadb; }
      .p--msgInbox__body .p--msgInboxTbl__item .p--item {
        position: relative;
        padding: 0 0 0 29px; }
        .p--msgInbox__body .p--msgInboxTbl__item .p--item:before, .p--msgInbox__body .p--msgInboxTbl__item .p--item:after {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          content: "";
          display: inline-block;
          width: 22px;
          height: 24px;
          background-size: contain !important; }
        .p--msgInbox__body .p--msgInboxTbl__item .p--item:before {
          left: 0; }
        .p--msgInbox__body .p--msgInboxTbl__item .p--item:after {
          left: 27px; }
        .p--msgInbox__body .p--msgInboxTbl__item .p--item--mail:before {
          background: url(../images/icon_inbox_mail.png) 50% no-repeat; }
        .p--msgInbox__body .p--msgInboxTbl__item .p--item--entry:before {
          background: url(../images/icon_inbox_entry.png) 50% no-repeat; }
        .p--msgInbox__body .p--msgInboxTbl__item .p--item--entry__read:before {
          background: url(../images/icon_inbox_entry_read.png) 50% no-repeat; }
        .p--msgInbox__body .p--msgInboxTbl__item .p--item--entry__reply:before {
          background: url(../images/icon_inbox_entry_reply.png) 50% no-repeat; }
        .p--msgInbox__body .p--msgInboxTbl__item .p--item--thanks:before {
          background: url(../images/icon_inbox_thanks.png) 50% no-repeat; }
        .p--msgInbox__body .p--msgInboxTbl__item .p--item--thanks__read:before {
          background: url(../images/icon_inbox_thanks_read.png) 50% no-repeat; }
        .p--msgInbox__body .p--msgInboxTbl__item .p--item--send:before {
          background: url(../images/icon_inbox_send.png) 50% no-repeat; }
        .p--msgInbox__body .p--msgInboxTbl__item .p--item--read:before {
          background: url(../images/icon_inbox_read.png) 50% no-repeat; }
        .p--msgInbox__body .p--msgInboxTbl__item .p--item--attachment {
          padding: 0 0 0 55px; }
          .p--msgInbox__body .p--msgInboxTbl__item .p--item--attachment:after {
            background: url(../images/icon_img_attachment.png) 50% no-repeat; }
  .p--msgInbox__body .p--noMsgBox {
    margin: 40px 0; }
  .p--msgInbox__body .p--msgInboxInfo {
    margin: 38px 0 0; }
    .p--msgInbox__body .p--msgInboxInfo__txt {
      margin: 10px 0 0; }

@media screen and (max-width: 767px) {
  .p--msgBtn__box {
    padding: 0 20px; }
    .p--msgBtn__box .p--msgBtn__item {
      width: calc(100% / 3); }
      .p--msgBtn__box .p--msgBtn__item .p--msgBtn {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        min-height: 54px;
        padding-right: 4px;
        padding-left: 4px; }
        .p--msgBtn__box .p--msgBtn__item .p--msgBtn:before {
          margin: 0; }
      .p--msgBtn__box .p--msgBtn__item span {
        display: block;
        width: 100%;
        text-align: center; }
    .p--msgBtn__box .p--msgBtn--inbox:before {
      order: 1; }
    .p--msgBtn__box .p--msgBtn--inbox span {
      order: 3; }
    .p--msgBtn__box .p--msgBtn--inbox i {
      order: 2;
      margin-left: 4px; }
  .p--msgSearch {
    padding: 20px 0 10px; }
    .p--msgSearch__ttl {
      padding: 0 20px; }
    .p--msgSearch__box {
      justify-content: space-between;
      padding: 0 20px; }
      .p--msgSearch__box input {
        width: calc(100% - 85px);
        height: 48px; }
      .p--msgSearch__box .c--btn--input {
        width: 75px;
        height: 48px; }
  .p--msgSearch__state {
    display: block; }
    .p--msgSearch__state > p {
      padding: 2px 20px;
      background: #F1F6FA;
      font-size: 0.8125rem;
      font-weight: bold; }
    .p--msgSearch__state .p--stateList {
      margin: 13px 0;
      padding: 0 20px; }
      .p--msgSearch__state .p--stateList + p {
        margin: 0; }
  .p--msgInbox__heading {
    padding: 0 20px; }
  .p--msgInbox__select {
    margin: 20px 0 0;
    padding: 0 20px; }
  .p--msgInbox__body {
    margin: 20px 0 0; }
    .p--msgInbox__body--pageCtrl .p--msgInboxTbl {
      margin: 20px 0 0; }
    .p--msgInbox__body .p--msgInboxTbl {
      border-radius: 0; }
      .p--msgInbox__body .p--msgInboxTbl__body {
        flex-wrap: wrap;
        padding: 16px 20px; }
      .p--msgInbox__body .p--msgInboxTbl__item {
        min-height: auto;
        padding: 0; }
        .p--msgInbox__body .p--msgInboxTbl__item--check {
          width: 35px; }
        .p--msgInbox__body .p--msgInboxTbl__item--name {
          width: calc(100% - 35px); }
        .p--msgInbox__body .p--msgInboxTbl__item--subject {
          margin: 8px 0 3px; }
        .p--msgInbox__body .p--msgInboxTbl__item--subject, .p--msgInbox__body .p--msgInboxTbl__item--date {
          width: 100% !important; }
        .p--msgInbox__body .p--msgInboxTbl__item:not(:first-child) {
          border: none; }
    .p--msgInbox__body .p--noMsgBox {
      margin: 30px 0 50px;
      padding: 0 20px; }
    .p--msgInbox__body .p--msgInboxInfo {
      margin: 30px 0 0;
      padding: 0 20px 30px; } }

/** ========================================
 * @RCVE-002.html
 * メッセージ表示
 ======================================== */
.p--msgDisp {
  padding: 25px; }

.p--msg .c--btnArea__formBox {
  margin: 30px 0 0; }

.p--msg__formBox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end; }
  .p--msg__formBox .c--btnArea--right {
    margin: 0 0 0 90px; }
  .p--msg__formBox--printBtn .p--btnArea__main {
    flex-grow: 1;
    text-align: center; }
  .p--msg__formBox--printBtn .c--btnArea--right {
    display: flex;
    align-items: center;
    margin: 0; }

@media screen and (max-width: 767px) {
  .p--msgDisp {
    padding: 0; }
  .p--msg .c--btnArea__formBox {
    margin: 20px 0 0;
    padding-bottom: 30px; }
  .p--msg__formBox .c--btn--w {
    padding: 18px 10px; }
  .c--btnArea__formBox.c--sp__only {
    display: flex !important;
    justify-content: flex-end; }
    .c--btnArea__formBox.c--sp__only .c--btn--trash {
      margin: 20px 0 0; } }

/** ========================================
 * @CRAT-003.html
 * メッセージ送信完了
 ======================================== */
.p--msgInboxFinish {
  margin: 45px 0 0; }
  .p--msgInboxFinish__ttl {
    font-size: 1rem; }
  .p--msgInboxFinish__txt {
    margin: 10px 0 0; }
  .p--msgInboxFinish + .p--msgInboxInfo {
    margin: 86px 0 0; }

@media screen and (max-width: 767px) {
  .p--msgInboxFinish {
    margin: 28px 0 0;
    padding: 0 20px; }
    .p--msgInboxFinish__txt {
      margin: 3px 0 0; }
    .p--msgInboxFinish + .p--msgInboxInfo {
      margin: 45px 0 0; } }

/** ========================================
 * 画像
 ======================================== */
.p--img--670__200 {
  width: 670px;
  margin: 0 auto; }
  .p--img--670__200 img {
    height: 200px; }

@media screen and (max-width: 767px) {
  .p--msgInbox__body .p--img--670__200 {
    width: 100%;
    max-width: 335px; }
    .p--msgInbox__body .p--img--670__200 img {
      height: calc((100vw - 40px) / 33.5 * 10);
      max-height: 100px; } }

/*! 
 * 相談関係
 */
/** ========================================
 * @p--cnst
 * 相談
======================================== */
/** ====================
 * 左メイン
==================== */
.p--cnst__bnr {
  display: inline-block; }
  .p--cnst__bnr + .p--cnstSearch {
    margin: 30px 0 0; }
  .p--cnst__bnr:not(:first-child) {
    margin: 40px 0 0; }
  .p--cnst__bnr + .p--cnst__bnr {
    margin: 30px 0 0; }

.p--cnstSearch {
  padding: 20px;
  border-radius: 4px; }

.p--cnstSearch__box {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0; }
  .p--cnstSearch__box input {
    width: 690px;
    height: 55px; }
  .p--cnstSearch__box .c--btn--input {
    width: 152px;
    height: 55px; }

.p--cnstSearch__word {
  margin: 15px 0 0; }
  .p--cnstSearch__word .p--word__tag {
    margin: 10px 0 0; }
    .p--cnstSearch__word .p--word__tag .p--tag__item {
      display: inline-block;
      background: #dadadb;
      margin: 0 8px 10px 0;
      padding: 8px 7px;
      border-radius: 4px;
      color: #e92d82;
      font-size: 0.75rem; }

.p--cnstRslt {
  margin: 30px 0 0; }
  .p--cnstRslt__tab {
    display: flex;
    justify-content: space-between; }
    .p--cnstRslt__tab .p--tab {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(50% - 5px);
      height: 61px;
      font-size: 1.25rem;
      font-weight: bold;
      box-shadow: 0 2px 2px rgba(97, 97, 106, 0.2), 0 1px 5px rgba(97, 97, 106, 0.1); }
      .p--cnstRslt__tab .p--tab:not(.p--tabSelect):hover {
        cursor: pointer; }
      .p--cnstRslt__tab .p--tabSelect {
        height: 70px;
        padding-bottom: 13px;
        border-top: 4px solid #e92d82;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        box-shadow: 0 0 2px -2px rgba(97, 97, 106, 0.2), 0 0 5px -5px rgba(97, 97, 106, 0.1); }
  .p--cnstRslt .p--cnstRslt__main {
    padding: 23px 0;
    background: #fff;
    box-shadow: 0 2px 2px rgba(97, 97, 106, 0.2), 0 1px 5px rgba(97, 97, 106, 0.1); }
    .p--cnstRslt .p--cnstRslt__main .c--page__ctrl {
      padding: 0 25px; }
      .p--cnstRslt .p--cnstRslt__main .c--page__ctrl:first-child {
        margin-top: 0; }

@media screen and (max-width: 767px) {
  .p--cnst .c--error__list {
    padding: 0 20px; }
    .p--cnst .c--error__list + .p--cnst__bnr {
      margin: 0; }
  .p--cnst__bnr {
    display: flex;
    justify-content: center; }
  .p--cnstSearch {
    padding: 0;
    border-radius: 0; }
  .p--cnstSearch__box {
    padding: 0 20px; }
    .p--cnstSearch__box input {
      width: calc(100% - 106px);
      height: 50px; }
    .p--cnstSearch__box .c--btn--input {
      width: 96px;
      height: 50px;
      padding: 14px 10px; }
  .p--cnstSearch__word {
    margin: 10px 0 0;
    padding: 0 20px 5px; }
    .p--cnstSearch__word .p--word__ttl {
      font-size: 0.75rem; }
  .p--cnstRslt__tab {
    padding: 0 10px; }
    .p--cnstRslt__tab .p--tab {
      height: 48px;
      font-size: 0.875rem; }
      .p--cnstRslt__tab .p--tabSelect {
        padding-bottom: 11px;
        height: 55px;
        color: #e92d82; }
  .p--cateModalLink {
    margin: 20px;
    padding: 6px;
    color: #e92d82;
    font-weight: bold;
    text-align: center; } }

/** ====================
 * 右メニュー
==================== */
/* 色の定数 */
.p--cnst .c--menu__side {
  box-shadow: none; }
  .p--cnst .c--menu__side > div {
    box-shadow: 2px 2px 2px rgba(97, 97, 106, 0.2), 1px 1px 5px rgba(97, 97, 106, 0.1); }
    .p--cnst .c--menu__side > div + div {
      margin: 25px 0 0; }

.p--categoryListWrap {
  background: #fff; }
  .p--categoryListWrap .p--categoryLink {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 14px 14px 14px 46px; }
    .p--categoryListWrap .p--categoryLink:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
      width: 16px;
      height: 20px;
      background-size: contain !important;
      background-repeat: no-repeat; }
  .p--categoryListWrap .p--category--student .p--categoryLink:before {
    background-image: url(../images/icon_student.png); }
  .p--categoryListWrap .p--category--training .p--categoryLink:before {
    background-image: url(../images/icon_training.png); }
  .p--categoryListWrap .p--category--job .p--categoryLink:before {
    background-image: url(../images/icon_job.png); }
  .p--categoryListWrap .p--category--test .p--categoryLink:before {
    background-image: url(../images/icon_test.png); }
  .p--categoryListWrap .p--category--other .p--categoryLink:before {
    background-image: url(../images/icon_other.png); }
  .p--categoryListWrap .p--categoryList {
    padding: 0 0 0 25px; }
    .p--categoryListWrap .p--categoryList li .p--categoryLink {
      padding: 10px 20px;
      font-size: 0.8125rem; }

@media screen and (max-width: 767px) {
  .p--cnst .c--menu__side {
    padding: 0 0 60px; } }

/** ========================================
 * カテゴリーリンクのカラー設定
 * ====================================== */
.p--category--student {
  background-color: rgba(38, 108, 191, 0.1); }
  .p--category--student + dd li + li {
    border-top: 1px solid #266CBF; }

.p--category--training {
  background-color: rgba(205, 138, 203, 0.1); }
  .p--category--training + dd li + li {
    border-top: 1px solid #CD8ACB; }

.p--category--job {
  background-color: rgba(234, 86, 163, 0.1); }
  .p--category--job + dd li + li {
    border-top: 1px solid #EA56A3; }

.p--category--test {
  background-color: rgba(255, 87, 132, 0.1); }
  .p--category--test + dd li + li {
    border-top: 1px solid #FF5784; }

.p--category--other {
  background-color: rgba(255, 154, 102, 0.1); }
  .p--category--other + dd li + li {
    border-top: 1px solid #FF9A66; }

/** ========================================
 * おすすめのQAのカラー設定
 * ====================================== */
.p--askList {
  background: #fff; }
  .p--askList li + li {
    border-top: 1px solid #dadadb; }
  .p--askList li a {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 12px 17px; }
  .p--askList__date {
    color: #a2a2a7;
    font-size: 0.75rem; }

.p--askList a:hover .p--ask__txt,
.p--askListMain a:hover .p--ask__txt {
  color: #e92d82; }

.p--ask__cate {
  margin: 10px 0 0;
  font-size: 0.75rem; }
  .p--ask__cate--student {
    color: #266CBF; }
    .p--ask__cate--student i {
      background-color: #266CBF; }
  .p--ask__cate--training {
    color: #CD8ACB; }
    .p--ask__cate--training i {
      background-color: #CD8ACB; }
  .p--ask__cate--job {
    color: #EA56A3; }
    .p--ask__cate--job i {
      background-color: #EA56A3; }
  .p--ask__cate--test {
    color: #FF5784; }
    .p--ask__cate--test i {
      background-color: #FF5784; }
  .p--ask__cate--other {
    color: #FF9A66; }
    .p--ask__cate--other i {
      background-color: #FF9A66; }
  .p--ask__cate i {
    display: inline-block;
    min-width: 68px;
    margin: 0 5px 0 0;
    padding: 2px 10px;
    border-radius: 100px;
    color: #fff;
    text-align: center; }

.p--ask__cmt {
  position: relative;
  margin: 7px 0 0;
  color: #a2a2a7;
  font-size: 0.75rem;
  padding: 0 0 0 20px; }
  .p--ask__cmt:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 16px;
    height: 18px;
    background-size: contain !important;
    background-repeat: no-repeat;
    background-image: url(../images/icon_cmt.png); }
  .p--ask__cmt--new:after {
    content: "NEW";
    display: inline-block;
    margin: 0 0 0 10px;
    color: #f84361; }

.p--ask__txt {
  margin: 5px 0 0;
  font-weight: bold; }
  .p--ask__txt .p--txt__sub {
    display: flex;
    align-items: center; }
    .p--ask__txt .p--txt__sub .p--ask__cmt {
      margin: 0 0 0 15px; }

.p--askListMainTtl {
  margin: 10px 0 0;
  padding: 0 25px;
  font-weight: bold; }

.p--askListMain {
  margin: 20px 0 0; }
  .p--askListMain a {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 25px 23px; }
  .p--askListMain .p--ask__img {
    width: 110px; }
    .p--askListMain .p--ask__img img {
      height: 80px; }
  .p--askListMain .p--ask__txt {
    width: calc(100% - 140px); }
    .p--askListMain .p--ask__txt .p--ask__cate {
      margin-top: 0; }
    .p--askListMain .p--ask__txt .p--txt__ttl {
      position: relative;
      width: 100%;
      margin: 7px 0 0;
      padding: 0 0 0 30px;
      font-size: 1rem; }
      .p--askListMain .p--ask__txt .p--txt__ttl:before {
        content: "";
        position: absolute;
        top: 4px;
        left: 0;
        width: 24px;
        height: 26px;
        background-size: contain !important;
        background-repeat: no-repeat;
        background-image: url(../images/icon_question.png); }

@media screen and (max-width: 767px) {
  .p--ask__cate i {
    min-width: 60px;
    margin: 0 5px 0 0;
    padding: 2px 6px;
    font-size: 0.625rem; }
  .p--ask__txt .p--txt__sub {
    display: block; }
    .p--ask__txt .p--txt__sub .p--ask__cmt {
      margin: 10px 0 0; }
  .p--ask__txt + .p--txt__ttl {
    margin: 8px 0 0;
    font-size: 0.875rem;
    font-weight: bold; }
  .p--ask__txt .p--txt__ttl {
    margin: 0; }
  .p--askListMainTtl {
    margin: 20px 0 0;
    padding: 0 20px; }
  .p--askListMain {
    margin: 12px 0 0;
    border-top: 1px solid #dadadb; }
    .p--askListMain li + li {
      border-top: 1px solid #dadadb; }
    .p--askListMain a {
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 12px 20px; }
    .p--askListMain .p--ask__img {
      width: 82px; }
      .p--askListMain .p--ask__img img {
        height: 60px; }
    .p--askListMain .p--ask__txt {
      width: calc(100% - 95px);
      margin: 0; }
  .p--menu__askArea .c--contHeading--gr {
    background: transparent;
    color: #333;
    font-size: 0.875rem; }
  /** ========================================
  * モーダル
  * ====================================== */
  .c--modal--category .ps__rail-y {
    top: 39px !important; }
  .c--modal--category .ps__rail-y.ps--clicking .ps__thumb-y,
  .c--modal--category .ps__rail-y:focus > .ps__thumb-y,
  .c--modal--category .ps__rail-y:hover > .ps__thumb-y,
  .c--modal--category .ps__thumb-y {
    background-color: #e92d82; }
  .c--modal--category .c--modal__content {
    max-height: 80vh;
    padding: 0; }
  .c--modal--category .c--modal__mainArea {
    padding: 10px; }
  .c--modal--category .c--modal__main {
    overflow-y: auto;
    max-height: calc(80vh - 59px);
    margin: 15px 0 0; }
    .c--modal--category .c--modal__main a {
      outline: none; }
  .c--modal--category .c--modal__title {
    color: #333;
    font-size: 1rem; }
  .c--modal--category .p--categoryListWrap .p--categoryList {
    padding: 0 0 0 45px; }
    .c--modal--category .p--categoryListWrap .p--categoryList li a {
      padding: 13px 20px; } }

/** ========================================
 * INFO-001.html 左メイン
======================================== */
.p--cnst__qaItem {
  margin: 30px 0 0;
  border-radius: 0 0 4px 4px;
  background: #fff; }
  .p--cnst__qaItem .p--qaItem__heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 93px;
    padding: 15px 25px 22px; }
    .p--cnst__qaItem .p--qaItem__heading--q {
      border-top: 2px solid #00b0ff;
      background: #f1f6fa; }
    .p--cnst__qaItem .p--qaItem__heading--a {
      border-top: 2px solid #e92d82;
      background: #fff0f5; }
    .p--cnst__qaItem .p--qaItem__heading .p--ask__cate {
      margin: 0; }
    .p--cnst__qaItem .p--qaItem__heading .p--qaItem__ttl {
      position: relative;
      margin: 8px 0 0;
      padding: 0 0 0 73px;
      font-size: 1.125rem;
      font-weight: bold; }
      .p--cnst__qaItem .p--qaItem__heading .p--qaItem__ttl::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 10px;
        width: 42px;
        height: 44px;
        background-size: contain !important;
        background-repeat: no-repeat;
        transform: translateY(-50%); }
      .p--cnst__qaItem .p--qaItem__heading .p--qaItem__ttl--q::before {
        background-image: url(../images/icon_question-l.png); }
      .p--cnst__qaItem .p--qaItem__heading .p--qaItem__ttl--a::before {
        background-image: url(../images/icon_answer-l.png); }
  .p--cnst__qaItem .p--qaItem__body {
    padding: 18px 0 20px; }
    .p--cnst__qaItem .p--qaItem__body > p {
      padding-right: 25px;
      padding-left: 25px; }
    .p--cnst__qaItem .p--qaItem__body > div {
      padding: 0 25px 18px 25px; }
      .p--cnst__qaItem .p--qaItem__body > div:nth-child(even) {
        background: #f5f5f5; }
        .p--cnst__qaItem .p--qaItem__body > div:nth-child(even) .p--qaItem__ttl {
          color: #828288; }
      .p--cnst__qaItem .p--qaItem__body > div + div {
        padding-top: 18px;
        border-top: 1px dotted #dadadb; }
      .p--cnst__qaItem .p--qaItem__body > div:last-child {
        padding-bottom: 0; }
    .p--cnst__qaItem .p--qaItem__body .p--qaItem__answer {
      display: flex;
      justify-content: space-between; }
      .p--cnst__qaItem .p--qaItem__body .p--qaItem__answer .p--answer__img {
        width: 110px; }
        .p--cnst__qaItem .p--qaItem__body .p--qaItem__answer .p--answer__img img {
          height: 110px; }
      .p--cnst__qaItem .p--qaItem__body .p--qaItem__answer .p--answer__box {
        width: calc(100% - 133px); }
      .p--cnst__qaItem .p--qaItem__body .p--qaItem__answer .p--answer__ttl {
        color: #e92d82;
        font-size: 0.9375rem;
        font-weight: bold; }
      .p--cnst__qaItem .p--qaItem__body .p--qaItem__answer .p--answer__txt {
        margin: 8px 0 0; }
    .p--cnst__qaItem .p--qaItem__body .p--qaItem__ttl {
      font-size: 0.75rem;
      font-weight: bold; }
    .p--cnst__qaItem .p--qaItem__body .p--qaItem__profile .p--profile__box {
      min-height: 110px;
      font-size: 0.8125rem; }
    .p--cnst__qaItem .p--qaItem__body .p--qaItem__profile .p--profile__ttl {
      width: calc(100% - 150px);
      margin-bottom: 8px; }
    .p--cnst__qaItem .p--qaItem__body .p--qaItem__profile .p--profile__imgBox {
      float: right;
      width: 150px;
      margin-left: 40px; }
      .p--cnst__qaItem .p--qaItem__body .p--qaItem__profile .p--profile__imgBox::before, .p--cnst__qaItem .p--qaItem__body .p--qaItem__profile .p--profile__imgBox::after {
        content: "";
        clear: both;
        display: block; }
      .p--cnst__qaItem .p--qaItem__body .p--qaItem__profile .p--profile__imgBox img {
        height: 110px; }
    .p--cnst__qaItem .p--qaItem__body .p--linkList {
      margin: 10px 0 0; }
      .p--cnst__qaItem .p--qaItem__body .p--linkList li {
        margin: 7px 0 0; }

.p--cnst__cmt {
  margin: 32px 0 0; }
  .p--cnst__cmt .p--cmt__ttl {
    font-weight: bold; }
  .p--cnst__cmt .c--boxWht {
    margin: 12px 0 0;
    padding: 23px;
    border-radius: 4px; }
    .p--cnst__cmt .c--boxWht.p--show__block {
      padding: 0; }
    .p--cnst__cmt .c--boxWht > p {
      margin: 25px 0 0;
      text-align: center; }
    .p--cnst__cmt .c--boxWht .p--cmt__link {
      font-size: 0.9375rem; }
    .p--cnst__cmt .c--boxWht .p--cmt__btn {
      margin: 18px 0 0; }
    .p--cnst__cmt .c--boxWht .p--txtCntBox .p--txtCntInfo {
      padding-left: 1em;
      text-indent: -1em; }

.p--show__block--cmt li {
  padding: 18px 20px 28px; }
  .p--show__block--cmt li p {
    position: relative;
    padding: 0 0 0 50px; }
    .p--show__block--cmt li p::before {
      content: "";
      position: absolute;
      top: 4px;
      left: 0;
      width: 24px;
      height: 26px;
      background-size: contain !important;
      background-repeat: no-repeat;
      background-image: url(../images/icon_answer.png); }
  .p--show__block--cmt li + li {
    border-top: 1px solid #dadadb; }

.p--loginBtnBox {
  margin: 33px 0 0;
  text-align: center; }
  .p--loginBtnBox .c--btn {
    width: 296px;
    font-size: 1rem; }
    .p--loginBtnBox .c--btn + .c--btn {
      margin-left: 10px; }
  .p--loginBtnBox > p {
    margin: 18px 0 18px;
    font-weight: bold; }

.p--page__ctrl--col2 .c--btn--m:first-child {
  margin: 0; }

@media screen and (max-width: 767px) {
  .p--cnst__qaItem {
    margin-top: 26px;
    border-radius: 0; }
    .p--cnst__qaItem + .p--cnst__qaItem {
      margin-top: 0; }
    .p--cnst__qaItem .p--qaItem__heading {
      padding: 11px 20px 15px; }
      .p--cnst__qaItem .p--qaItem__heading .p--qaItem__ttl {
        margin: 5px 0 0;
        padding: 0 0 0 35px;
        font-size: 1rem; }
        .p--cnst__qaItem .p--qaItem__heading .p--qaItem__ttl::before {
          top: .5em;
          left: 0;
          width: 23px;
          height: 24px;
          transform: translateY(0); }
    .p--cnst__qaItem .p--qaItem__body {
      padding: 12px 0; }
      .p--cnst__qaItem .p--qaItem__body > p {
        padding-right: 20px;
        padding-left: 20px; }
      .p--cnst__qaItem .p--qaItem__body > div {
        padding: 0 20px 18px 20px; }
        .p--cnst__qaItem .p--qaItem__body > div + div {
          padding-top: 12px; }
      .p--cnst__qaItem .p--qaItem__body .p--qaItem__answer {
        flex-wrap: wrap; }
        .p--cnst__qaItem .p--qaItem__body .p--qaItem__answer .p--answer__img {
          width: 60px; }
          .p--cnst__qaItem .p--qaItem__body .p--qaItem__answer .p--answer__img img {
            height: 60px; }
        .p--cnst__qaItem .p--qaItem__body .p--qaItem__answer .p--answer__box {
          width: calc(100% - 70px); }
        .p--cnst__qaItem .p--qaItem__body .p--qaItem__answer .p--answer__txt {
          margin: 10px 0 0; }
      .p--cnst__qaItem .p--qaItem__body .p--qaItem__profile .p--profile__box {
        min-height: auto;
        font-size: 0.75rem; }
      .p--cnst__qaItem .p--qaItem__body .p--qaItem__profile .p--profile__ttl {
        width: calc(100% - 110px);
        margin-bottom: 4px;
        font-size: 0.625rem; }
      .p--cnst__qaItem .p--qaItem__body .p--qaItem__profile .p--profile__imgBox {
        width: 110px;
        margin: 20px 0 5px 20px; }
        .p--cnst__qaItem .p--qaItem__body .p--qaItem__profile .p--profile__imgBox img {
          height: 80px; }
  .p--cnst__cmt {
    margin: 28px 0 0; }
    .p--cnst__cmt .p--cmt__ttl {
      padding: 0 20px; }
    .p--cnst__cmt .c--boxWht {
      padding: 20px;
      border-radius: 0; }
      .p--cnst__cmt .c--boxWht > p {
        margin: 15px 0 0; }
      .p--cnst__cmt .c--boxWht .p--cmt__link {
        font-size: 0.875rem; }
      .p--cnst__cmt .c--boxWht .p--cmt__btn {
        margin: 22px 0 0; }
        .p--cnst__cmt .c--boxWht .p--cmt__btn .c--btn {
          width: 100%; }
      .p--cnst__cmt .c--boxWht .p--txtCntBox {
        flex-direction: column-reverse; }
        .p--cnst__cmt .c--boxWht .p--txtCntBox .p--txtCntInfo {
          margin: 5px 0 0;
          font-size: 0.625rem; }
        .p--cnst__cmt .c--boxWht .p--txtCntBox .c--text__print {
          text-align: right; }
  .p--show__block li {
    padding-bottom: 18px; }
    .p--show__block li p {
      padding-left: 0 0 0 43px; }
  .p--loginBtnBox {
    margin: 20px 0 0;
    padding: 0 20px; }
    .p--loginBtnBox .c--btn {
      width: 100%; }
      .p--loginBtnBox .c--btn + .c--btn {
        margin: 17px 0 0; }
  .p--cnst .c--ctrl__btn {
    margin: 22px 0 0; }
    .p--cnst .c--ctrl__btn .c--btn {
      font-size: 0.75rem; } }

@media screen and (max-width: 320px) {
  .p--cnst .p--page__ctrl--col2 .c--btn {
    padding: 18px 8px;
    font-size: .6rem; } }

/** ========================================
 * search-result.html
======================================== */
.p--hp__search .p--reg__body {
  padding-top: 5px; }

.p--hp__search .c--page__ctrl {
  margin: 0; }
  .p--hp__search .c--page__ctrl:not(:first-of-type) {
    margin-top: 25px; }

.p--hp__search .c--allCheckBtn__box {
  text-align: right; }
  .p--hp__search .c--allCheckBtn__box .c--control {
    margin: 0; }
  .p--hp__search .c--allCheckBtn__box + .c--page__ctrl {
    margin-top: 20px; }

.p--hp__search .p--hspLink {
  margin: 18px 0 0;
  font-size: 0.6875rem; }
  .p--hp__search .p--hspLink a {
    margin: 0 20px 0 0; }
  .p--hp__search .p--hspLink + .p--hspLink {
    margin: 15px 0 0; }

.p--hp__search .p--item__body + .p--tgl__block {
  padding: 0 20px 15px; }

.p--intern__search {
  display: flex; }
  .p--intern__search .p--search__wrap {
    width: calc(100% - 360px); }
  .p--intern__search .p--search__box {
    display: flex;
    justify-content: space-between;
    margin: 15px 40px 0 0; }
    .p--intern__search .p--search__box input {
      width: 600px;
      height: 60px; }
    .p--intern__search .p--search__box .c--btn--input {
      width: calc(100% - 610px);
      height: 60px; }
  .p--intern__search .p--intern__ttl--search {
    margin: 20px 0 0; }
  .p--intern__search .p--search__tag {
    width: 360px;
    padding: 20px 0 20px 40px;
    border-left: 1px dotted #dadadb; }
    .p--intern__search .p--search__tag span a {
      display: inline-block;
      margin: 0 10px 10px 0;
      padding: 8px;
      border-radius: 4px;
      background: #f5f5f5;
      color: #e92d82;
      font-size: 0.75rem; }
      .p--intern__search .p--search__tag span a:hover {
        background: #e92d82;
        color: #fff; }

.p--searchBtnBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0 0; }
  .p--searchBtnBox .p--searchResult {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 700; }
    .p--searchBtnBox .p--searchResult i {
      margin: 0 0 0 5px;
      font-size: 1.5rem; }
  .p--searchBtnBox .c--btn {
    margin-left: 10px;
    font-size: 1.25rem; }
    .p--searchBtnBox .c--btn--lb {
      width: 296px;
      margin-left: 20px; }

@media screen and (max-width: 767px) {
  .p--hp__search .p--reg__block--topTab .p--searchArea__tab {
    margin: 0;
    padding-top: 20px;
    background: #fff; }
    .p--hp__search .p--reg__block--topTab .p--searchArea__tab .p--tab {
      height: 60px; }
      .p--hp__search .p--reg__block--topTab .p--searchArea__tab .p--tab:not(.p--tab--active) {
        overflow: hidden;
        border: 1px solid #dadadb;
        box-shadow: none; }
      .p--hp__search .p--reg__block--topTab .p--searchArea__tab .p--tab--active {
        position: relative;
        height: 68px;
        border-left: 1px solid #dadadb;
        border-right: 1px solid #dadadb;
        border-radius: 0; }
        .p--hp__search .p--reg__block--topTab .p--searchArea__tab .p--tab--active:after {
          content: "";
          display: inline-block;
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 100%;
          height: 2px;
          background: #fff; }
  .p--hp__search .p--reg__block--topTab .p--reg__body {
    border-top: 1px solid #dadadb; }
  .p--hp__search .c--page__ctrl {
    margin-top: 25px; }
  .p--hp__search .c--allCheckBtn__box {
    padding: 0 20px;
    text-align: left; }
  .p--intern__search {
    display: block; }
    .p--intern__search .p--search__wrap {
      width: 100%; }
    .p--intern__search .p--search__box {
      margin: 12px 0 0; }
      .p--intern__search .p--search__box input {
        width: calc(100% - 105px);
        height: 50px; }
      .p--intern__search .p--search__box .c--btn--input {
        width: 98px;
        height: 50px; }
    .p--intern__search .p--intern__ttl--search {
      margin: 0; }
    .p--intern__search .p--search__tag {
      width: 100%;
      margin: 15px 0 0;
      padding: 0;
      border: none; }
      .p--intern__search .p--search__tag span a {
        margin: 0 7px 8px 0; }
  .p--intern__detailCond .p--simpleTabHead.p--simpleTabCols .p--simpleTab__item {
    min-width: auto; }
    .p--intern__detailCond .p--simpleTabHead.p--simpleTabCols .p--simpleTab__item p {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%; }
    .p--intern__detailCond .p--simpleTabHead.p--simpleTabCols .p--simpleTab__item.p--tabSelect p {
      padding-bottom: 17px; }
  .c--control__allCheckBtn {
    margin: 5px 20px; }
  .p--searchBtnBox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 25px 0 0;
    padding: 0 0 25px; }
    .p--searchBtnBox .p--searchResult {
      flex-direction: column;
      width: auto;
      font-size: .75rem; }
      .p--searchBtnBox .p--searchResult i {
        font-size: 1.25rem; }
    .p--searchBtnBox .c--btn {
      padding: 10px 13px;
      font-size: .875rem;
      line-height: 1.3; }
      .p--searchBtnBox .c--btn--lb {
        width: auto;
        margin: 0 0 0 10px;
        padding: 18px;
        font-size: 1rem; } }

@media screen and (max-width: 320px) {
  .p--searchBtnBox .c--btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .75rem; } }

/** ------------------------------
 * Utility
 * DESCRIPTION : ユーティリティ・補足
 * ---------------------------- */
/*! 
 * ヘルパークラス
 */
.hr__solid {
  height: 0;
  margin: 0;
  padding: 0;
  border: 0;
  border-top: 1px solid #ddd; }

.u--table__scroll {
  overflow-x: scroll; }

.u--tLayout--fixed {
  table-layout: fixed; }

.u--tLayout--auto {
  table-layout: auto; }

/** ========================================
 * display
 * ====================================== */
.u--disp__inline {
  display: inline-block; }

/** ========================================
 * 配置汎用
 * ====================================== */
.u--txtal__center {
  text-align: center !important; }

.u--txtal__left {
  text-align: left !important; }

.u--txtal__right {
  text-align: right !important; }

.u--relative {
  position: relative !important; }

/** ========================================
 * 横並び汎用
 * ====================================== */
.u--flexBlock {
  display: flex; }
  .u--flexBlock__wrap {
    flex-wrap: wrap; }
  .u--flexBlock__between {
    justify-content: space-between; }
  .u--flexBlock__justEnd {
    justify-content: flex-end; }
  .u--flexBlock__justCenter {
    justify-content: center; }
  .u--flexBlock__itemsStart {
    align-items: flex-start; }
  .u--flexBlock__itemsCenter {
    align-items: center; }

/** ========================================
 * @
 * テーブルの幅用
 * ====================================== */
.u--w__per--1 {
  width: 1% !important; }

.u--w__per--rwd1 {
  width: 1% !important; }

.u--w__per--2 {
  width: 2% !important; }

.u--w__per--rwd2 {
  width: 2% !important; }

.u--w__per--3 {
  width: 3% !important; }

.u--w__per--rwd3 {
  width: 3% !important; }

.u--w__per--4 {
  width: 4% !important; }

.u--w__per--rwd4 {
  width: 4% !important; }

.u--w__per--5 {
  width: 5% !important; }

.u--w__per--rwd5 {
  width: 5% !important; }

.u--w__per--6 {
  width: 6% !important; }

.u--w__per--rwd6 {
  width: 6% !important; }

.u--w__per--7 {
  width: 7% !important; }

.u--w__per--rwd7 {
  width: 7% !important; }

.u--w__per--8 {
  width: 8% !important; }

.u--w__per--rwd8 {
  width: 8% !important; }

.u--w__per--9 {
  width: 9% !important; }

.u--w__per--rwd9 {
  width: 9% !important; }

.u--w__per--10 {
  width: 10% !important; }

.u--w__per--rwd10 {
  width: 10% !important; }

.u--w__per--11 {
  width: 11% !important; }

.u--w__per--rwd11 {
  width: 11% !important; }

.u--w__per--12 {
  width: 12% !important; }

.u--w__per--rwd12 {
  width: 12% !important; }

.u--w__per--13 {
  width: 13% !important; }

.u--w__per--rwd13 {
  width: 13% !important; }

.u--w__per--14 {
  width: 14% !important; }

.u--w__per--rwd14 {
  width: 14% !important; }

.u--w__per--15 {
  width: 15% !important; }

.u--w__per--rwd15 {
  width: 15% !important; }

.u--w__per--16 {
  width: 16% !important; }

.u--w__per--rwd16 {
  width: 16% !important; }

.u--w__per--17 {
  width: 17% !important; }

.u--w__per--rwd17 {
  width: 17% !important; }

.u--w__per--18 {
  width: 18% !important; }

.u--w__per--rwd18 {
  width: 18% !important; }

.u--w__per--19 {
  width: 19% !important; }

.u--w__per--rwd19 {
  width: 19% !important; }

.u--w__per--20 {
  width: 20% !important; }

.u--w__per--rwd20 {
  width: 20% !important; }

.u--w__per--21 {
  width: 21% !important; }

.u--w__per--rwd21 {
  width: 21% !important; }

.u--w__per--22 {
  width: 22% !important; }

.u--w__per--rwd22 {
  width: 22% !important; }

.u--w__per--23 {
  width: 23% !important; }

.u--w__per--rwd23 {
  width: 23% !important; }

.u--w__per--24 {
  width: 24% !important; }

.u--w__per--rwd24 {
  width: 24% !important; }

.u--w__per--25 {
  width: 25% !important; }

.u--w__per--rwd25 {
  width: 25% !important; }

.u--w__per--26 {
  width: 26% !important; }

.u--w__per--rwd26 {
  width: 26% !important; }

.u--w__per--27 {
  width: 27% !important; }

.u--w__per--rwd27 {
  width: 27% !important; }

.u--w__per--28 {
  width: 28% !important; }

.u--w__per--rwd28 {
  width: 28% !important; }

.u--w__per--29 {
  width: 29% !important; }

.u--w__per--rwd29 {
  width: 29% !important; }

.u--w__per--30 {
  width: 30% !important; }

.u--w__per--rwd30 {
  width: 30% !important; }

.u--w__per--31 {
  width: 31% !important; }

.u--w__per--rwd31 {
  width: 31% !important; }

.u--w__per--32 {
  width: 32% !important; }

.u--w__per--rwd32 {
  width: 32% !important; }

.u--w__per--33 {
  width: 33% !important; }

.u--w__per--rwd33 {
  width: 33% !important; }

.u--w__per--34 {
  width: 34% !important; }

.u--w__per--rwd34 {
  width: 34% !important; }

.u--w__per--35 {
  width: 35% !important; }

.u--w__per--rwd35 {
  width: 35% !important; }

.u--w__per--36 {
  width: 36% !important; }

.u--w__per--rwd36 {
  width: 36% !important; }

.u--w__per--37 {
  width: 37% !important; }

.u--w__per--rwd37 {
  width: 37% !important; }

.u--w__per--38 {
  width: 38% !important; }

.u--w__per--rwd38 {
  width: 38% !important; }

.u--w__per--39 {
  width: 39% !important; }

.u--w__per--rwd39 {
  width: 39% !important; }

.u--w__per--40 {
  width: 40% !important; }

.u--w__per--rwd40 {
  width: 40% !important; }

.u--w__per--41 {
  width: 41% !important; }

.u--w__per--rwd41 {
  width: 41% !important; }

.u--w__per--42 {
  width: 42% !important; }

.u--w__per--rwd42 {
  width: 42% !important; }

.u--w__per--43 {
  width: 43% !important; }

.u--w__per--rwd43 {
  width: 43% !important; }

.u--w__per--44 {
  width: 44% !important; }

.u--w__per--rwd44 {
  width: 44% !important; }

.u--w__per--45 {
  width: 45% !important; }

.u--w__per--rwd45 {
  width: 45% !important; }

.u--w__per--46 {
  width: 46% !important; }

.u--w__per--rwd46 {
  width: 46% !important; }

.u--w__per--47 {
  width: 47% !important; }

.u--w__per--rwd47 {
  width: 47% !important; }

.u--w__per--48 {
  width: 48% !important; }

.u--w__per--rwd48 {
  width: 48% !important; }

.u--w__per--49 {
  width: 49% !important; }

.u--w__per--rwd49 {
  width: 49% !important; }

.u--w__per--50 {
  width: 50% !important; }

.u--w__per--rwd50 {
  width: 50% !important; }

.u--w__per--51 {
  width: 51% !important; }

.u--w__per--rwd51 {
  width: 51% !important; }

.u--w__per--52 {
  width: 52% !important; }

.u--w__per--rwd52 {
  width: 52% !important; }

.u--w__per--53 {
  width: 53% !important; }

.u--w__per--rwd53 {
  width: 53% !important; }

.u--w__per--54 {
  width: 54% !important; }

.u--w__per--rwd54 {
  width: 54% !important; }

.u--w__per--55 {
  width: 55% !important; }

.u--w__per--rwd55 {
  width: 55% !important; }

.u--w__per--56 {
  width: 56% !important; }

.u--w__per--rwd56 {
  width: 56% !important; }

.u--w__per--57 {
  width: 57% !important; }

.u--w__per--rwd57 {
  width: 57% !important; }

.u--w__per--58 {
  width: 58% !important; }

.u--w__per--rwd58 {
  width: 58% !important; }

.u--w__per--59 {
  width: 59% !important; }

.u--w__per--rwd59 {
  width: 59% !important; }

.u--w__per--60 {
  width: 60% !important; }

.u--w__per--rwd60 {
  width: 60% !important; }

.u--w__per--61 {
  width: 61% !important; }

.u--w__per--rwd61 {
  width: 61% !important; }

.u--w__per--62 {
  width: 62% !important; }

.u--w__per--rwd62 {
  width: 62% !important; }

.u--w__per--63 {
  width: 63% !important; }

.u--w__per--rwd63 {
  width: 63% !important; }

.u--w__per--64 {
  width: 64% !important; }

.u--w__per--rwd64 {
  width: 64% !important; }

.u--w__per--65 {
  width: 65% !important; }

.u--w__per--rwd65 {
  width: 65% !important; }

.u--w__per--66 {
  width: 66% !important; }

.u--w__per--rwd66 {
  width: 66% !important; }

.u--w__per--67 {
  width: 67% !important; }

.u--w__per--rwd67 {
  width: 67% !important; }

.u--w__per--68 {
  width: 68% !important; }

.u--w__per--rwd68 {
  width: 68% !important; }

.u--w__per--69 {
  width: 69% !important; }

.u--w__per--rwd69 {
  width: 69% !important; }

.u--w__per--70 {
  width: 70% !important; }

.u--w__per--rwd70 {
  width: 70% !important; }

.u--w__per--71 {
  width: 71% !important; }

.u--w__per--rwd71 {
  width: 71% !important; }

.u--w__per--72 {
  width: 72% !important; }

.u--w__per--rwd72 {
  width: 72% !important; }

.u--w__per--73 {
  width: 73% !important; }

.u--w__per--rwd73 {
  width: 73% !important; }

.u--w__per--74 {
  width: 74% !important; }

.u--w__per--rwd74 {
  width: 74% !important; }

.u--w__per--75 {
  width: 75% !important; }

.u--w__per--rwd75 {
  width: 75% !important; }

.u--w__per--76 {
  width: 76% !important; }

.u--w__per--rwd76 {
  width: 76% !important; }

.u--w__per--77 {
  width: 77% !important; }

.u--w__per--rwd77 {
  width: 77% !important; }

.u--w__per--78 {
  width: 78% !important; }

.u--w__per--rwd78 {
  width: 78% !important; }

.u--w__per--79 {
  width: 79% !important; }

.u--w__per--rwd79 {
  width: 79% !important; }

.u--w__per--80 {
  width: 80% !important; }

.u--w__per--rwd80 {
  width: 80% !important; }

.u--w__per--81 {
  width: 81% !important; }

.u--w__per--rwd81 {
  width: 81% !important; }

.u--w__per--82 {
  width: 82% !important; }

.u--w__per--rwd82 {
  width: 82% !important; }

.u--w__per--83 {
  width: 83% !important; }

.u--w__per--rwd83 {
  width: 83% !important; }

.u--w__per--84 {
  width: 84% !important; }

.u--w__per--rwd84 {
  width: 84% !important; }

.u--w__per--85 {
  width: 85% !important; }

.u--w__per--rwd85 {
  width: 85% !important; }

.u--w__per--86 {
  width: 86% !important; }

.u--w__per--rwd86 {
  width: 86% !important; }

.u--w__per--87 {
  width: 87% !important; }

.u--w__per--rwd87 {
  width: 87% !important; }

.u--w__per--88 {
  width: 88% !important; }

.u--w__per--rwd88 {
  width: 88% !important; }

.u--w__per--89 {
  width: 89% !important; }

.u--w__per--rwd89 {
  width: 89% !important; }

.u--w__per--90 {
  width: 90% !important; }

.u--w__per--rwd90 {
  width: 90% !important; }

.u--w__per--91 {
  width: 91% !important; }

.u--w__per--rwd91 {
  width: 91% !important; }

.u--w__per--92 {
  width: 92% !important; }

.u--w__per--rwd92 {
  width: 92% !important; }

.u--w__per--93 {
  width: 93% !important; }

.u--w__per--rwd93 {
  width: 93% !important; }

.u--w__per--94 {
  width: 94% !important; }

.u--w__per--rwd94 {
  width: 94% !important; }

.u--w__per--95 {
  width: 95% !important; }

.u--w__per--rwd95 {
  width: 95% !important; }

.u--w__per--96 {
  width: 96% !important; }

.u--w__per--rwd96 {
  width: 96% !important; }

.u--w__per--97 {
  width: 97% !important; }

.u--w__per--rwd97 {
  width: 97% !important; }

.u--w__per--98 {
  width: 98% !important; }

.u--w__per--rwd98 {
  width: 98% !important; }

.u--w__per--99 {
  width: 99% !important; }

.u--w__per--rwd99 {
  width: 99% !important; }

.u--w__per--100 {
  width: 100% !important; }

.u--w__per--rwd100 {
  width: 100% !important; }

.u--w__per--101 {
  width: 101% !important; }

.u--w__per--rwd101 {
  width: 101% !important; }

.u--w__per--102 {
  width: 102% !important; }

.u--w__per--rwd102 {
  width: 102% !important; }

.u--w__per--103 {
  width: 103% !important; }

.u--w__per--rwd103 {
  width: 103% !important; }

.u--w__per--104 {
  width: 104% !important; }

.u--w__per--rwd104 {
  width: 104% !important; }

.u--w__per--105 {
  width: 105% !important; }

.u--w__per--rwd105 {
  width: 105% !important; }

.u--w__per--106 {
  width: 106% !important; }

.u--w__per--rwd106 {
  width: 106% !important; }

.u--w__per--107 {
  width: 107% !important; }

.u--w__per--rwd107 {
  width: 107% !important; }

.u--w__per--108 {
  width: 108% !important; }

.u--w__per--rwd108 {
  width: 108% !important; }

.u--w__per--109 {
  width: 109% !important; }

.u--w__per--rwd109 {
  width: 109% !important; }

.u--w__per--110 {
  width: 110% !important; }

.u--w__per--rwd110 {
  width: 110% !important; }

.u--w__per--111 {
  width: 111% !important; }

.u--w__per--rwd111 {
  width: 111% !important; }

.u--w__per--112 {
  width: 112% !important; }

.u--w__per--rwd112 {
  width: 112% !important; }

.u--w__per--113 {
  width: 113% !important; }

.u--w__per--rwd113 {
  width: 113% !important; }

.u--w__per--114 {
  width: 114% !important; }

.u--w__per--rwd114 {
  width: 114% !important; }

.u--w__per--115 {
  width: 115% !important; }

.u--w__per--rwd115 {
  width: 115% !important; }

.u--w__per--116 {
  width: 116% !important; }

.u--w__per--rwd116 {
  width: 116% !important; }

.u--w__per--117 {
  width: 117% !important; }

.u--w__per--rwd117 {
  width: 117% !important; }

.u--w__per--118 {
  width: 118% !important; }

.u--w__per--rwd118 {
  width: 118% !important; }

.u--w__per--119 {
  width: 119% !important; }

.u--w__per--rwd119 {
  width: 119% !important; }

.u--w__per--120 {
  width: 120% !important; }

.u--w__per--rwd120 {
  width: 120% !important; }

.u--w__per--121 {
  width: 121% !important; }

.u--w__per--rwd121 {
  width: 121% !important; }

.u--w__per--122 {
  width: 122% !important; }

.u--w__per--rwd122 {
  width: 122% !important; }

.u--w__per--123 {
  width: 123% !important; }

.u--w__per--rwd123 {
  width: 123% !important; }

.u--w__per--124 {
  width: 124% !important; }

.u--w__per--rwd124 {
  width: 124% !important; }

.u--w__per--125 {
  width: 125% !important; }

.u--w__per--rwd125 {
  width: 125% !important; }

.u--w__per--126 {
  width: 126% !important; }

.u--w__per--rwd126 {
  width: 126% !important; }

.u--w__per--127 {
  width: 127% !important; }

.u--w__per--rwd127 {
  width: 127% !important; }

.u--w__per--128 {
  width: 128% !important; }

.u--w__per--rwd128 {
  width: 128% !important; }

.u--w__per--129 {
  width: 129% !important; }

.u--w__per--rwd129 {
  width: 129% !important; }

.u--w__per--130 {
  width: 130% !important; }

.u--w__per--rwd130 {
  width: 130% !important; }

.u--w__per--131 {
  width: 131% !important; }

.u--w__per--rwd131 {
  width: 131% !important; }

.u--w__per--132 {
  width: 132% !important; }

.u--w__per--rwd132 {
  width: 132% !important; }

.u--w__per--133 {
  width: 133% !important; }

.u--w__per--rwd133 {
  width: 133% !important; }

.u--w__per--134 {
  width: 134% !important; }

.u--w__per--rwd134 {
  width: 134% !important; }

.u--w__per--135 {
  width: 135% !important; }

.u--w__per--rwd135 {
  width: 135% !important; }

.u--w__per--136 {
  width: 136% !important; }

.u--w__per--rwd136 {
  width: 136% !important; }

.u--w__per--137 {
  width: 137% !important; }

.u--w__per--rwd137 {
  width: 137% !important; }

.u--w__per--138 {
  width: 138% !important; }

.u--w__per--rwd138 {
  width: 138% !important; }

.u--w__per--139 {
  width: 139% !important; }

.u--w__per--rwd139 {
  width: 139% !important; }

.u--w__per--140 {
  width: 140% !important; }

.u--w__per--rwd140 {
  width: 140% !important; }

.u--w__per--141 {
  width: 141% !important; }

.u--w__per--rwd141 {
  width: 141% !important; }

.u--w__per--142 {
  width: 142% !important; }

.u--w__per--rwd142 {
  width: 142% !important; }

.u--w__per--143 {
  width: 143% !important; }

.u--w__per--rwd143 {
  width: 143% !important; }

.u--w__per--144 {
  width: 144% !important; }

.u--w__per--rwd144 {
  width: 144% !important; }

.u--w__per--145 {
  width: 145% !important; }

.u--w__per--rwd145 {
  width: 145% !important; }

.u--w__per--146 {
  width: 146% !important; }

.u--w__per--rwd146 {
  width: 146% !important; }

.u--w__per--147 {
  width: 147% !important; }

.u--w__per--rwd147 {
  width: 147% !important; }

.u--w__per--148 {
  width: 148% !important; }

.u--w__per--rwd148 {
  width: 148% !important; }

.u--w__per--149 {
  width: 149% !important; }

.u--w__per--rwd149 {
  width: 149% !important; }

.u--w__per--150 {
  width: 150% !important; }

.u--w__per--rwd150 {
  width: 150% !important; }

.u--w__per--151 {
  width: 151% !important; }

.u--w__per--rwd151 {
  width: 151% !important; }

.u--w__per--152 {
  width: 152% !important; }

.u--w__per--rwd152 {
  width: 152% !important; }

.u--w__per--153 {
  width: 153% !important; }

.u--w__per--rwd153 {
  width: 153% !important; }

.u--w__per--154 {
  width: 154% !important; }

.u--w__per--rwd154 {
  width: 154% !important; }

.u--w__per--155 {
  width: 155% !important; }

.u--w__per--rwd155 {
  width: 155% !important; }

.u--w__per--156 {
  width: 156% !important; }

.u--w__per--rwd156 {
  width: 156% !important; }

.u--w__per--157 {
  width: 157% !important; }

.u--w__per--rwd157 {
  width: 157% !important; }

.u--w__per--158 {
  width: 158% !important; }

.u--w__per--rwd158 {
  width: 158% !important; }

.u--w__per--159 {
  width: 159% !important; }

.u--w__per--rwd159 {
  width: 159% !important; }

.u--w__per--160 {
  width: 160% !important; }

.u--w__per--rwd160 {
  width: 160% !important; }

.u--w__per--161 {
  width: 161% !important; }

.u--w__per--rwd161 {
  width: 161% !important; }

.u--w__per--162 {
  width: 162% !important; }

.u--w__per--rwd162 {
  width: 162% !important; }

.u--w__per--163 {
  width: 163% !important; }

.u--w__per--rwd163 {
  width: 163% !important; }

.u--w__per--164 {
  width: 164% !important; }

.u--w__per--rwd164 {
  width: 164% !important; }

.u--w__per--165 {
  width: 165% !important; }

.u--w__per--rwd165 {
  width: 165% !important; }

.u--w__per--166 {
  width: 166% !important; }

.u--w__per--rwd166 {
  width: 166% !important; }

.u--w__per--167 {
  width: 167% !important; }

.u--w__per--rwd167 {
  width: 167% !important; }

.u--w__per--168 {
  width: 168% !important; }

.u--w__per--rwd168 {
  width: 168% !important; }

.u--w__per--169 {
  width: 169% !important; }

.u--w__per--rwd169 {
  width: 169% !important; }

.u--w__per--170 {
  width: 170% !important; }

.u--w__per--rwd170 {
  width: 170% !important; }

.u--w__per--171 {
  width: 171% !important; }

.u--w__per--rwd171 {
  width: 171% !important; }

.u--w__per--172 {
  width: 172% !important; }

.u--w__per--rwd172 {
  width: 172% !important; }

.u--w__per--173 {
  width: 173% !important; }

.u--w__per--rwd173 {
  width: 173% !important; }

.u--w__per--174 {
  width: 174% !important; }

.u--w__per--rwd174 {
  width: 174% !important; }

.u--w__per--175 {
  width: 175% !important; }

.u--w__per--rwd175 {
  width: 175% !important; }

.u--w__per--176 {
  width: 176% !important; }

.u--w__per--rwd176 {
  width: 176% !important; }

.u--w__per--177 {
  width: 177% !important; }

.u--w__per--rwd177 {
  width: 177% !important; }

.u--w__per--178 {
  width: 178% !important; }

.u--w__per--rwd178 {
  width: 178% !important; }

.u--w__per--179 {
  width: 179% !important; }

.u--w__per--rwd179 {
  width: 179% !important; }

.u--w__per--180 {
  width: 180% !important; }

.u--w__per--rwd180 {
  width: 180% !important; }

.u--w__per--181 {
  width: 181% !important; }

.u--w__per--rwd181 {
  width: 181% !important; }

.u--w__per--182 {
  width: 182% !important; }

.u--w__per--rwd182 {
  width: 182% !important; }

.u--w__per--183 {
  width: 183% !important; }

.u--w__per--rwd183 {
  width: 183% !important; }

.u--w__per--184 {
  width: 184% !important; }

.u--w__per--rwd184 {
  width: 184% !important; }

.u--w__per--185 {
  width: 185% !important; }

.u--w__per--rwd185 {
  width: 185% !important; }

.u--w__per--186 {
  width: 186% !important; }

.u--w__per--rwd186 {
  width: 186% !important; }

.u--w__per--187 {
  width: 187% !important; }

.u--w__per--rwd187 {
  width: 187% !important; }

.u--w__per--188 {
  width: 188% !important; }

.u--w__per--rwd188 {
  width: 188% !important; }

.u--w__per--189 {
  width: 189% !important; }

.u--w__per--rwd189 {
  width: 189% !important; }

.u--w__per--190 {
  width: 190% !important; }

.u--w__per--rwd190 {
  width: 190% !important; }

.u--w__per--191 {
  width: 191% !important; }

.u--w__per--rwd191 {
  width: 191% !important; }

.u--w__per--192 {
  width: 192% !important; }

.u--w__per--rwd192 {
  width: 192% !important; }

.u--w__per--193 {
  width: 193% !important; }

.u--w__per--rwd193 {
  width: 193% !important; }

.u--w__per--194 {
  width: 194% !important; }

.u--w__per--rwd194 {
  width: 194% !important; }

.u--w__per--195 {
  width: 195% !important; }

.u--w__per--rwd195 {
  width: 195% !important; }

.u--w__per--196 {
  width: 196% !important; }

.u--w__per--rwd196 {
  width: 196% !important; }

.u--w__per--197 {
  width: 197% !important; }

.u--w__per--rwd197 {
  width: 197% !important; }

.u--w__per--198 {
  width: 198% !important; }

.u--w__per--rwd198 {
  width: 198% !important; }

.u--w__per--199 {
  width: 199% !important; }

.u--w__per--rwd199 {
  width: 199% !important; }

.u--w__per--200 {
  width: 200% !important; }

.u--w__per--rwd200 {
  width: 200% !important; }

.u--w__per--201 {
  width: 201% !important; }

.u--w__per--rwd201 {
  width: 201% !important; }

.u--w__per--202 {
  width: 202% !important; }

.u--w__per--rwd202 {
  width: 202% !important; }

.u--w__per--203 {
  width: 203% !important; }

.u--w__per--rwd203 {
  width: 203% !important; }

.u--w__per--204 {
  width: 204% !important; }

.u--w__per--rwd204 {
  width: 204% !important; }

.u--w__per--205 {
  width: 205% !important; }

.u--w__per--rwd205 {
  width: 205% !important; }

.u--w__per--206 {
  width: 206% !important; }

.u--w__per--rwd206 {
  width: 206% !important; }

.u--w__per--207 {
  width: 207% !important; }

.u--w__per--rwd207 {
  width: 207% !important; }

.u--w__per--208 {
  width: 208% !important; }

.u--w__per--rwd208 {
  width: 208% !important; }

.u--w__per--209 {
  width: 209% !important; }

.u--w__per--rwd209 {
  width: 209% !important; }

.u--w__per--210 {
  width: 210% !important; }

.u--w__per--rwd210 {
  width: 210% !important; }

.u--w__per--211 {
  width: 211% !important; }

.u--w__per--rwd211 {
  width: 211% !important; }

.u--w__per--212 {
  width: 212% !important; }

.u--w__per--rwd212 {
  width: 212% !important; }

.u--w__per--213 {
  width: 213% !important; }

.u--w__per--rwd213 {
  width: 213% !important; }

.u--w__per--214 {
  width: 214% !important; }

.u--w__per--rwd214 {
  width: 214% !important; }

.u--w__per--215 {
  width: 215% !important; }

.u--w__per--rwd215 {
  width: 215% !important; }

.u--w__per--216 {
  width: 216% !important; }

.u--w__per--rwd216 {
  width: 216% !important; }

.u--w__per--217 {
  width: 217% !important; }

.u--w__per--rwd217 {
  width: 217% !important; }

.u--w__per--218 {
  width: 218% !important; }

.u--w__per--rwd218 {
  width: 218% !important; }

.u--w__per--219 {
  width: 219% !important; }

.u--w__per--rwd219 {
  width: 219% !important; }

.u--w__per--220 {
  width: 220% !important; }

.u--w__per--rwd220 {
  width: 220% !important; }

.u--w__per--221 {
  width: 221% !important; }

.u--w__per--rwd221 {
  width: 221% !important; }

.u--w__per--222 {
  width: 222% !important; }

.u--w__per--rwd222 {
  width: 222% !important; }

.u--w__per--223 {
  width: 223% !important; }

.u--w__per--rwd223 {
  width: 223% !important; }

.u--w__per--224 {
  width: 224% !important; }

.u--w__per--rwd224 {
  width: 224% !important; }

.u--w__per--225 {
  width: 225% !important; }

.u--w__per--rwd225 {
  width: 225% !important; }

.u--w__per--226 {
  width: 226% !important; }

.u--w__per--rwd226 {
  width: 226% !important; }

.u--w__per--227 {
  width: 227% !important; }

.u--w__per--rwd227 {
  width: 227% !important; }

.u--w__per--228 {
  width: 228% !important; }

.u--w__per--rwd228 {
  width: 228% !important; }

.u--w__per--229 {
  width: 229% !important; }

.u--w__per--rwd229 {
  width: 229% !important; }

.u--w__per--230 {
  width: 230% !important; }

.u--w__per--rwd230 {
  width: 230% !important; }

.u--w__per--231 {
  width: 231% !important; }

.u--w__per--rwd231 {
  width: 231% !important; }

.u--w__per--232 {
  width: 232% !important; }

.u--w__per--rwd232 {
  width: 232% !important; }

.u--w__per--233 {
  width: 233% !important; }

.u--w__per--rwd233 {
  width: 233% !important; }

.u--w__per--234 {
  width: 234% !important; }

.u--w__per--rwd234 {
  width: 234% !important; }

.u--w__per--235 {
  width: 235% !important; }

.u--w__per--rwd235 {
  width: 235% !important; }

.u--w__per--236 {
  width: 236% !important; }

.u--w__per--rwd236 {
  width: 236% !important; }

.u--w__per--237 {
  width: 237% !important; }

.u--w__per--rwd237 {
  width: 237% !important; }

.u--w__per--238 {
  width: 238% !important; }

.u--w__per--rwd238 {
  width: 238% !important; }

.u--w__per--239 {
  width: 239% !important; }

.u--w__per--rwd239 {
  width: 239% !important; }

.u--w__per--240 {
  width: 240% !important; }

.u--w__per--rwd240 {
  width: 240% !important; }

.u--w__per--241 {
  width: 241% !important; }

.u--w__per--rwd241 {
  width: 241% !important; }

.u--w__per--242 {
  width: 242% !important; }

.u--w__per--rwd242 {
  width: 242% !important; }

.u--w__per--243 {
  width: 243% !important; }

.u--w__per--rwd243 {
  width: 243% !important; }

.u--w__per--244 {
  width: 244% !important; }

.u--w__per--rwd244 {
  width: 244% !important; }

.u--w__per--245 {
  width: 245% !important; }

.u--w__per--rwd245 {
  width: 245% !important; }

.u--w__per--246 {
  width: 246% !important; }

.u--w__per--rwd246 {
  width: 246% !important; }

.u--w__per--247 {
  width: 247% !important; }

.u--w__per--rwd247 {
  width: 247% !important; }

.u--w__per--248 {
  width: 248% !important; }

.u--w__per--rwd248 {
  width: 248% !important; }

.u--w__per--249 {
  width: 249% !important; }

.u--w__per--rwd249 {
  width: 249% !important; }

.u--w__per--250 {
  width: 250% !important; }

.u--w__per--rwd250 {
  width: 250% !important; }

.u--w__per--251 {
  width: 251% !important; }

.u--w__per--rwd251 {
  width: 251% !important; }

.u--w__px--5 {
  width: 5px !important;
  max-width: 5px !important;
  min-width: 5px !important; }

.u--w__px--rwd5 {
  width: 5px !important;
  max-width: 5px !important;
  min-width: 5px !important; }

.u--w__px--10 {
  width: 10px !important;
  max-width: 10px !important;
  min-width: 10px !important; }

.u--w__px--rwd10 {
  width: 10px !important;
  max-width: 10px !important;
  min-width: 10px !important; }

.u--w__px--15 {
  width: 15px !important;
  max-width: 15px !important;
  min-width: 15px !important; }

.u--w__px--rwd15 {
  width: 15px !important;
  max-width: 15px !important;
  min-width: 15px !important; }

.u--w__px--20 {
  width: 20px !important;
  max-width: 20px !important;
  min-width: 20px !important; }

.u--w__px--rwd20 {
  width: 20px !important;
  max-width: 20px !important;
  min-width: 20px !important; }

.u--w__px--25 {
  width: 25px !important;
  max-width: 25px !important;
  min-width: 25px !important; }

.u--w__px--rwd25 {
  width: 25px !important;
  max-width: 25px !important;
  min-width: 25px !important; }

.u--w__px--30 {
  width: 30px !important;
  max-width: 30px !important;
  min-width: 30px !important; }

.u--w__px--rwd30 {
  width: 30px !important;
  max-width: 30px !important;
  min-width: 30px !important; }

.u--w__px--35 {
  width: 35px !important;
  max-width: 35px !important;
  min-width: 35px !important; }

.u--w__px--rwd35 {
  width: 35px !important;
  max-width: 35px !important;
  min-width: 35px !important; }

.u--w__px--40 {
  width: 40px !important;
  max-width: 40px !important;
  min-width: 40px !important; }

.u--w__px--rwd40 {
  width: 40px !important;
  max-width: 40px !important;
  min-width: 40px !important; }

.u--w__px--45 {
  width: 45px !important;
  max-width: 45px !important;
  min-width: 45px !important; }

.u--w__px--rwd45 {
  width: 45px !important;
  max-width: 45px !important;
  min-width: 45px !important; }

.u--w__px--50 {
  width: 50px !important;
  max-width: 50px !important;
  min-width: 50px !important; }

.u--w__px--rwd50 {
  width: 50px !important;
  max-width: 50px !important;
  min-width: 50px !important; }

.u--w__px--55 {
  width: 55px !important;
  max-width: 55px !important;
  min-width: 55px !important; }

.u--w__px--rwd55 {
  width: 55px !important;
  max-width: 55px !important;
  min-width: 55px !important; }

.u--w__px--60 {
  width: 60px !important;
  max-width: 60px !important;
  min-width: 60px !important; }

.u--w__px--rwd60 {
  width: 60px !important;
  max-width: 60px !important;
  min-width: 60px !important; }

.u--w__px--65 {
  width: 65px !important;
  max-width: 65px !important;
  min-width: 65px !important; }

.u--w__px--rwd65 {
  width: 65px !important;
  max-width: 65px !important;
  min-width: 65px !important; }

.u--w__px--70 {
  width: 70px !important;
  max-width: 70px !important;
  min-width: 70px !important; }

.u--w__px--rwd70 {
  width: 70px !important;
  max-width: 70px !important;
  min-width: 70px !important; }

.u--w__px--75 {
  width: 75px !important;
  max-width: 75px !important;
  min-width: 75px !important; }

.u--w__px--rwd75 {
  width: 75px !important;
  max-width: 75px !important;
  min-width: 75px !important; }

.u--w__px--80 {
  width: 80px !important;
  max-width: 80px !important;
  min-width: 80px !important; }

.u--w__px--rwd80 {
  width: 80px !important;
  max-width: 80px !important;
  min-width: 80px !important; }

.u--w__px--85 {
  width: 85px !important;
  max-width: 85px !important;
  min-width: 85px !important; }

.u--w__px--rwd85 {
  width: 85px !important;
  max-width: 85px !important;
  min-width: 85px !important; }

.u--w__px--90 {
  width: 90px !important;
  max-width: 90px !important;
  min-width: 90px !important; }

.u--w__px--rwd90 {
  width: 90px !important;
  max-width: 90px !important;
  min-width: 90px !important; }

.u--w__px--95 {
  width: 95px !important;
  max-width: 95px !important;
  min-width: 95px !important; }

.u--w__px--rwd95 {
  width: 95px !important;
  max-width: 95px !important;
  min-width: 95px !important; }

.u--w__px--100 {
  width: 100px !important;
  max-width: 100px !important;
  min-width: 100px !important; }

.u--w__px--rwd100 {
  width: 100px !important;
  max-width: 100px !important;
  min-width: 100px !important; }

.u--w__px--105 {
  width: 105px !important;
  max-width: 105px !important;
  min-width: 105px !important; }

.u--w__px--rwd105 {
  width: 105px !important;
  max-width: 105px !important;
  min-width: 105px !important; }

.u--w__px--110 {
  width: 110px !important;
  max-width: 110px !important;
  min-width: 110px !important; }

.u--w__px--rwd110 {
  width: 110px !important;
  max-width: 110px !important;
  min-width: 110px !important; }

.u--w__px--115 {
  width: 115px !important;
  max-width: 115px !important;
  min-width: 115px !important; }

.u--w__px--rwd115 {
  width: 115px !important;
  max-width: 115px !important;
  min-width: 115px !important; }

.u--w__px--120 {
  width: 120px !important;
  max-width: 120px !important;
  min-width: 120px !important; }

.u--w__px--rwd120 {
  width: 120px !important;
  max-width: 120px !important;
  min-width: 120px !important; }

.u--w__px--125 {
  width: 125px !important;
  max-width: 125px !important;
  min-width: 125px !important; }

.u--w__px--rwd125 {
  width: 125px !important;
  max-width: 125px !important;
  min-width: 125px !important; }

.u--w__px--130 {
  width: 130px !important;
  max-width: 130px !important;
  min-width: 130px !important; }

.u--w__px--rwd130 {
  width: 130px !important;
  max-width: 130px !important;
  min-width: 130px !important; }

.u--w__px--135 {
  width: 135px !important;
  max-width: 135px !important;
  min-width: 135px !important; }

.u--w__px--rwd135 {
  width: 135px !important;
  max-width: 135px !important;
  min-width: 135px !important; }

.u--w__px--140 {
  width: 140px !important;
  max-width: 140px !important;
  min-width: 140px !important; }

.u--w__px--rwd140 {
  width: 140px !important;
  max-width: 140px !important;
  min-width: 140px !important; }

.u--w__px--145 {
  width: 145px !important;
  max-width: 145px !important;
  min-width: 145px !important; }

.u--w__px--rwd145 {
  width: 145px !important;
  max-width: 145px !important;
  min-width: 145px !important; }

.u--w__px--150 {
  width: 150px !important;
  max-width: 150px !important;
  min-width: 150px !important; }

.u--w__px--rwd150 {
  width: 150px !important;
  max-width: 150px !important;
  min-width: 150px !important; }

.u--w__px--155 {
  width: 155px !important;
  max-width: 155px !important;
  min-width: 155px !important; }

.u--w__px--rwd155 {
  width: 155px !important;
  max-width: 155px !important;
  min-width: 155px !important; }

.u--w__px--160 {
  width: 160px !important;
  max-width: 160px !important;
  min-width: 160px !important; }

.u--w__px--rwd160 {
  width: 160px !important;
  max-width: 160px !important;
  min-width: 160px !important; }

.u--w__px--165 {
  width: 165px !important;
  max-width: 165px !important;
  min-width: 165px !important; }

.u--w__px--rwd165 {
  width: 165px !important;
  max-width: 165px !important;
  min-width: 165px !important; }

.u--w__px--170 {
  width: 170px !important;
  max-width: 170px !important;
  min-width: 170px !important; }

.u--w__px--rwd170 {
  width: 170px !important;
  max-width: 170px !important;
  min-width: 170px !important; }

.u--w__px--175 {
  width: 175px !important;
  max-width: 175px !important;
  min-width: 175px !important; }

.u--w__px--rwd175 {
  width: 175px !important;
  max-width: 175px !important;
  min-width: 175px !important; }

.u--w__px--180 {
  width: 180px !important;
  max-width: 180px !important;
  min-width: 180px !important; }

.u--w__px--rwd180 {
  width: 180px !important;
  max-width: 180px !important;
  min-width: 180px !important; }

.u--w__px--185 {
  width: 185px !important;
  max-width: 185px !important;
  min-width: 185px !important; }

.u--w__px--rwd185 {
  width: 185px !important;
  max-width: 185px !important;
  min-width: 185px !important; }

.u--w__px--190 {
  width: 190px !important;
  max-width: 190px !important;
  min-width: 190px !important; }

.u--w__px--rwd190 {
  width: 190px !important;
  max-width: 190px !important;
  min-width: 190px !important; }

.u--w__px--195 {
  width: 195px !important;
  max-width: 195px !important;
  min-width: 195px !important; }

.u--w__px--rwd195 {
  width: 195px !important;
  max-width: 195px !important;
  min-width: 195px !important; }

.u--w__px--200 {
  width: 200px !important;
  max-width: 200px !important;
  min-width: 200px !important; }

.u--w__px--rwd200 {
  width: 200px !important;
  max-width: 200px !important;
  min-width: 200px !important; }

.u--w__px--205 {
  width: 205px !important;
  max-width: 205px !important;
  min-width: 205px !important; }

.u--w__px--rwd205 {
  width: 205px !important;
  max-width: 205px !important;
  min-width: 205px !important; }

.u--w__px--210 {
  width: 210px !important;
  max-width: 210px !important;
  min-width: 210px !important; }

.u--w__px--rwd210 {
  width: 210px !important;
  max-width: 210px !important;
  min-width: 210px !important; }

.u--w__px--215 {
  width: 215px !important;
  max-width: 215px !important;
  min-width: 215px !important; }

.u--w__px--rwd215 {
  width: 215px !important;
  max-width: 215px !important;
  min-width: 215px !important; }

.u--w__px--220 {
  width: 220px !important;
  max-width: 220px !important;
  min-width: 220px !important; }

.u--w__px--rwd220 {
  width: 220px !important;
  max-width: 220px !important;
  min-width: 220px !important; }

.u--w__px--225 {
  width: 225px !important;
  max-width: 225px !important;
  min-width: 225px !important; }

.u--w__px--rwd225 {
  width: 225px !important;
  max-width: 225px !important;
  min-width: 225px !important; }

.u--w__px--230 {
  width: 230px !important;
  max-width: 230px !important;
  min-width: 230px !important; }

.u--w__px--rwd230 {
  width: 230px !important;
  max-width: 230px !important;
  min-width: 230px !important; }

.u--w__px--235 {
  width: 235px !important;
  max-width: 235px !important;
  min-width: 235px !important; }

.u--w__px--rwd235 {
  width: 235px !important;
  max-width: 235px !important;
  min-width: 235px !important; }

.u--w__px--240 {
  width: 240px !important;
  max-width: 240px !important;
  min-width: 240px !important; }

.u--w__px--rwd240 {
  width: 240px !important;
  max-width: 240px !important;
  min-width: 240px !important; }

.u--w__px--245 {
  width: 245px !important;
  max-width: 245px !important;
  min-width: 245px !important; }

.u--w__px--rwd245 {
  width: 245px !important;
  max-width: 245px !important;
  min-width: 245px !important; }

.u--w__px--250 {
  width: 250px !important;
  max-width: 250px !important;
  min-width: 250px !important; }

.u--w__px--rwd250 {
  width: 250px !important;
  max-width: 250px !important;
  min-width: 250px !important; }

.u--w__px--255 {
  width: 255px !important;
  max-width: 255px !important;
  min-width: 255px !important; }

.u--w__px--rwd255 {
  width: 255px !important;
  max-width: 255px !important;
  min-width: 255px !important; }

.u--w__px--260 {
  width: 260px !important;
  max-width: 260px !important;
  min-width: 260px !important; }

.u--w__px--rwd260 {
  width: 260px !important;
  max-width: 260px !important;
  min-width: 260px !important; }

.u--w__px--265 {
  width: 265px !important;
  max-width: 265px !important;
  min-width: 265px !important; }

.u--w__px--rwd265 {
  width: 265px !important;
  max-width: 265px !important;
  min-width: 265px !important; }

.u--w__px--270 {
  width: 270px !important;
  max-width: 270px !important;
  min-width: 270px !important; }

.u--w__px--rwd270 {
  width: 270px !important;
  max-width: 270px !important;
  min-width: 270px !important; }

.u--w__px--275 {
  width: 275px !important;
  max-width: 275px !important;
  min-width: 275px !important; }

.u--w__px--rwd275 {
  width: 275px !important;
  max-width: 275px !important;
  min-width: 275px !important; }

.u--w__px--280 {
  width: 280px !important;
  max-width: 280px !important;
  min-width: 280px !important; }

.u--w__px--rwd280 {
  width: 280px !important;
  max-width: 280px !important;
  min-width: 280px !important; }

.u--w__px--285 {
  width: 285px !important;
  max-width: 285px !important;
  min-width: 285px !important; }

.u--w__px--rwd285 {
  width: 285px !important;
  max-width: 285px !important;
  min-width: 285px !important; }

.u--w__px--290 {
  width: 290px !important;
  max-width: 290px !important;
  min-width: 290px !important; }

.u--w__px--rwd290 {
  width: 290px !important;
  max-width: 290px !important;
  min-width: 290px !important; }

.u--w__px--295 {
  width: 295px !important;
  max-width: 295px !important;
  min-width: 295px !important; }

.u--w__px--rwd295 {
  width: 295px !important;
  max-width: 295px !important;
  min-width: 295px !important; }

.u--w__px--300 {
  width: 300px !important;
  max-width: 300px !important;
  min-width: 300px !important; }

.u--w__px--rwd300 {
  width: 300px !important;
  max-width: 300px !important;
  min-width: 300px !important; }

.u--w__px--305 {
  width: 305px !important;
  max-width: 305px !important;
  min-width: 305px !important; }

.u--w__px--rwd305 {
  width: 305px !important;
  max-width: 305px !important;
  min-width: 305px !important; }

.u--w__px--310 {
  width: 310px !important;
  max-width: 310px !important;
  min-width: 310px !important; }

.u--w__px--rwd310 {
  width: 310px !important;
  max-width: 310px !important;
  min-width: 310px !important; }

.u--w__px--315 {
  width: 315px !important;
  max-width: 315px !important;
  min-width: 315px !important; }

.u--w__px--rwd315 {
  width: 315px !important;
  max-width: 315px !important;
  min-width: 315px !important; }

.u--w__px--320 {
  width: 320px !important;
  max-width: 320px !important;
  min-width: 320px !important; }

.u--w__px--rwd320 {
  width: 320px !important;
  max-width: 320px !important;
  min-width: 320px !important; }

.u--w__px--325 {
  width: 325px !important;
  max-width: 325px !important;
  min-width: 325px !important; }

.u--w__px--rwd325 {
  width: 325px !important;
  max-width: 325px !important;
  min-width: 325px !important; }

.u--w__px--330 {
  width: 330px !important;
  max-width: 330px !important;
  min-width: 330px !important; }

.u--w__px--rwd330 {
  width: 330px !important;
  max-width: 330px !important;
  min-width: 330px !important; }

.u--w__px--335 {
  width: 335px !important;
  max-width: 335px !important;
  min-width: 335px !important; }

.u--w__px--rwd335 {
  width: 335px !important;
  max-width: 335px !important;
  min-width: 335px !important; }

.u--w__px--340 {
  width: 340px !important;
  max-width: 340px !important;
  min-width: 340px !important; }

.u--w__px--rwd340 {
  width: 340px !important;
  max-width: 340px !important;
  min-width: 340px !important; }

.u--w__px--345 {
  width: 345px !important;
  max-width: 345px !important;
  min-width: 345px !important; }

.u--w__px--rwd345 {
  width: 345px !important;
  max-width: 345px !important;
  min-width: 345px !important; }

.u--w__px--350 {
  width: 350px !important;
  max-width: 350px !important;
  min-width: 350px !important; }

.u--w__px--rwd350 {
  width: 350px !important;
  max-width: 350px !important;
  min-width: 350px !important; }

.u--w__px--355 {
  width: 355px !important;
  max-width: 355px !important;
  min-width: 355px !important; }

.u--w__px--rwd355 {
  width: 355px !important;
  max-width: 355px !important;
  min-width: 355px !important; }

.u--w__px--360 {
  width: 360px !important;
  max-width: 360px !important;
  min-width: 360px !important; }

.u--w__px--rwd360 {
  width: 360px !important;
  max-width: 360px !important;
  min-width: 360px !important; }

.u--w__px--365 {
  width: 365px !important;
  max-width: 365px !important;
  min-width: 365px !important; }

.u--w__px--rwd365 {
  width: 365px !important;
  max-width: 365px !important;
  min-width: 365px !important; }

.u--w__px--370 {
  width: 370px !important;
  max-width: 370px !important;
  min-width: 370px !important; }

.u--w__px--rwd370 {
  width: 370px !important;
  max-width: 370px !important;
  min-width: 370px !important; }

.u--w__px--375 {
  width: 375px !important;
  max-width: 375px !important;
  min-width: 375px !important; }

.u--w__px--rwd375 {
  width: 375px !important;
  max-width: 375px !important;
  min-width: 375px !important; }

.u--w__px--380 {
  width: 380px !important;
  max-width: 380px !important;
  min-width: 380px !important; }

.u--w__px--rwd380 {
  width: 380px !important;
  max-width: 380px !important;
  min-width: 380px !important; }

.u--w__px--385 {
  width: 385px !important;
  max-width: 385px !important;
  min-width: 385px !important; }

.u--w__px--rwd385 {
  width: 385px !important;
  max-width: 385px !important;
  min-width: 385px !important; }

.u--w__px--390 {
  width: 390px !important;
  max-width: 390px !important;
  min-width: 390px !important; }

.u--w__px--rwd390 {
  width: 390px !important;
  max-width: 390px !important;
  min-width: 390px !important; }

.u--w__px--395 {
  width: 395px !important;
  max-width: 395px !important;
  min-width: 395px !important; }

.u--w__px--rwd395 {
  width: 395px !important;
  max-width: 395px !important;
  min-width: 395px !important; }

.u--w__px--400 {
  width: 400px !important;
  max-width: 400px !important;
  min-width: 400px !important; }

.u--w__px--rwd400 {
  width: 400px !important;
  max-width: 400px !important;
  min-width: 400px !important; }

.u--w__px--405 {
  width: 405px !important;
  max-width: 405px !important;
  min-width: 405px !important; }

.u--w__px--rwd405 {
  width: 405px !important;
  max-width: 405px !important;
  min-width: 405px !important; }

.u--w__px--410 {
  width: 410px !important;
  max-width: 410px !important;
  min-width: 410px !important; }

.u--w__px--rwd410 {
  width: 410px !important;
  max-width: 410px !important;
  min-width: 410px !important; }

.u--w__px--415 {
  width: 415px !important;
  max-width: 415px !important;
  min-width: 415px !important; }

.u--w__px--rwd415 {
  width: 415px !important;
  max-width: 415px !important;
  min-width: 415px !important; }

.u--w__px--420 {
  width: 420px !important;
  max-width: 420px !important;
  min-width: 420px !important; }

.u--w__px--rwd420 {
  width: 420px !important;
  max-width: 420px !important;
  min-width: 420px !important; }

.u--w__px--425 {
  width: 425px !important;
  max-width: 425px !important;
  min-width: 425px !important; }

.u--w__px--rwd425 {
  width: 425px !important;
  max-width: 425px !important;
  min-width: 425px !important; }

.u--w__px--430 {
  width: 430px !important;
  max-width: 430px !important;
  min-width: 430px !important; }

.u--w__px--rwd430 {
  width: 430px !important;
  max-width: 430px !important;
  min-width: 430px !important; }

.u--w__px--435 {
  width: 435px !important;
  max-width: 435px !important;
  min-width: 435px !important; }

.u--w__px--rwd435 {
  width: 435px !important;
  max-width: 435px !important;
  min-width: 435px !important; }

.u--w__px--440 {
  width: 440px !important;
  max-width: 440px !important;
  min-width: 440px !important; }

.u--w__px--rwd440 {
  width: 440px !important;
  max-width: 440px !important;
  min-width: 440px !important; }

.u--w__px--445 {
  width: 445px !important;
  max-width: 445px !important;
  min-width: 445px !important; }

.u--w__px--rwd445 {
  width: 445px !important;
  max-width: 445px !important;
  min-width: 445px !important; }

.u--w__px--450 {
  width: 450px !important;
  max-width: 450px !important;
  min-width: 450px !important; }

.u--w__px--rwd450 {
  width: 450px !important;
  max-width: 450px !important;
  min-width: 450px !important; }

.u--w__px--455 {
  width: 455px !important;
  max-width: 455px !important;
  min-width: 455px !important; }

.u--w__px--rwd455 {
  width: 455px !important;
  max-width: 455px !important;
  min-width: 455px !important; }

.u--w__px--460 {
  width: 460px !important;
  max-width: 460px !important;
  min-width: 460px !important; }

.u--w__px--rwd460 {
  width: 460px !important;
  max-width: 460px !important;
  min-width: 460px !important; }

.u--w__px--465 {
  width: 465px !important;
  max-width: 465px !important;
  min-width: 465px !important; }

.u--w__px--rwd465 {
  width: 465px !important;
  max-width: 465px !important;
  min-width: 465px !important; }

.u--w__px--470 {
  width: 470px !important;
  max-width: 470px !important;
  min-width: 470px !important; }

.u--w__px--rwd470 {
  width: 470px !important;
  max-width: 470px !important;
  min-width: 470px !important; }

.u--w__px--475 {
  width: 475px !important;
  max-width: 475px !important;
  min-width: 475px !important; }

.u--w__px--rwd475 {
  width: 475px !important;
  max-width: 475px !important;
  min-width: 475px !important; }

.u--w__px--480 {
  width: 480px !important;
  max-width: 480px !important;
  min-width: 480px !important; }

.u--w__px--rwd480 {
  width: 480px !important;
  max-width: 480px !important;
  min-width: 480px !important; }

.u--w__px--485 {
  width: 485px !important;
  max-width: 485px !important;
  min-width: 485px !important; }

.u--w__px--rwd485 {
  width: 485px !important;
  max-width: 485px !important;
  min-width: 485px !important; }

.u--w__px--490 {
  width: 490px !important;
  max-width: 490px !important;
  min-width: 490px !important; }

.u--w__px--rwd490 {
  width: 490px !important;
  max-width: 490px !important;
  min-width: 490px !important; }

.u--w__px--495 {
  width: 495px !important;
  max-width: 495px !important;
  min-width: 495px !important; }

.u--w__px--rwd495 {
  width: 495px !important;
  max-width: 495px !important;
  min-width: 495px !important; }

.u--w__px--500 {
  width: 500px !important;
  max-width: 500px !important;
  min-width: 500px !important; }

.u--w__px--rwd500 {
  width: 500px !important;
  max-width: 500px !important;
  min-width: 500px !important; }

.u--w__px--505 {
  width: 505px !important;
  max-width: 505px !important;
  min-width: 505px !important; }

.u--w__px--rwd505 {
  width: 505px !important;
  max-width: 505px !important;
  min-width: 505px !important; }

.u--w__px--510 {
  width: 510px !important;
  max-width: 510px !important;
  min-width: 510px !important; }

.u--w__px--rwd510 {
  width: 510px !important;
  max-width: 510px !important;
  min-width: 510px !important; }

.u--w__px--515 {
  width: 515px !important;
  max-width: 515px !important;
  min-width: 515px !important; }

.u--w__px--rwd515 {
  width: 515px !important;
  max-width: 515px !important;
  min-width: 515px !important; }

.u--w__px--520 {
  width: 520px !important;
  max-width: 520px !important;
  min-width: 520px !important; }

.u--w__px--rwd520 {
  width: 520px !important;
  max-width: 520px !important;
  min-width: 520px !important; }

.u--w__px--525 {
  width: 525px !important;
  max-width: 525px !important;
  min-width: 525px !important; }

.u--w__px--rwd525 {
  width: 525px !important;
  max-width: 525px !important;
  min-width: 525px !important; }

.u--w__px--530 {
  width: 530px !important;
  max-width: 530px !important;
  min-width: 530px !important; }

.u--w__px--rwd530 {
  width: 530px !important;
  max-width: 530px !important;
  min-width: 530px !important; }

.u--w__px--535 {
  width: 535px !important;
  max-width: 535px !important;
  min-width: 535px !important; }

.u--w__px--rwd535 {
  width: 535px !important;
  max-width: 535px !important;
  min-width: 535px !important; }

.u--w__px--540 {
  width: 540px !important;
  max-width: 540px !important;
  min-width: 540px !important; }

.u--w__px--rwd540 {
  width: 540px !important;
  max-width: 540px !important;
  min-width: 540px !important; }

.u--w__px--545 {
  width: 545px !important;
  max-width: 545px !important;
  min-width: 545px !important; }

.u--w__px--rwd545 {
  width: 545px !important;
  max-width: 545px !important;
  min-width: 545px !important; }

.u--w__px--550 {
  width: 550px !important;
  max-width: 550px !important;
  min-width: 550px !important; }

.u--w__px--rwd550 {
  width: 550px !important;
  max-width: 550px !important;
  min-width: 550px !important; }

.u--w__px--555 {
  width: 555px !important;
  max-width: 555px !important;
  min-width: 555px !important; }

.u--w__px--rwd555 {
  width: 555px !important;
  max-width: 555px !important;
  min-width: 555px !important; }

.u--w__px--560 {
  width: 560px !important;
  max-width: 560px !important;
  min-width: 560px !important; }

.u--w__px--rwd560 {
  width: 560px !important;
  max-width: 560px !important;
  min-width: 560px !important; }

.u--w__px--565 {
  width: 565px !important;
  max-width: 565px !important;
  min-width: 565px !important; }

.u--w__px--rwd565 {
  width: 565px !important;
  max-width: 565px !important;
  min-width: 565px !important; }

.u--w__px--570 {
  width: 570px !important;
  max-width: 570px !important;
  min-width: 570px !important; }

.u--w__px--rwd570 {
  width: 570px !important;
  max-width: 570px !important;
  min-width: 570px !important; }

.u--w__px--575 {
  width: 575px !important;
  max-width: 575px !important;
  min-width: 575px !important; }

.u--w__px--rwd575 {
  width: 575px !important;
  max-width: 575px !important;
  min-width: 575px !important; }

.u--w__px--580 {
  width: 580px !important;
  max-width: 580px !important;
  min-width: 580px !important; }

.u--w__px--rwd580 {
  width: 580px !important;
  max-width: 580px !important;
  min-width: 580px !important; }

.u--w__px--585 {
  width: 585px !important;
  max-width: 585px !important;
  min-width: 585px !important; }

.u--w__px--rwd585 {
  width: 585px !important;
  max-width: 585px !important;
  min-width: 585px !important; }

.u--w__px--590 {
  width: 590px !important;
  max-width: 590px !important;
  min-width: 590px !important; }

.u--w__px--rwd590 {
  width: 590px !important;
  max-width: 590px !important;
  min-width: 590px !important; }

.u--w__px--595 {
  width: 595px !important;
  max-width: 595px !important;
  min-width: 595px !important; }

.u--w__px--rwd595 {
  width: 595px !important;
  max-width: 595px !important;
  min-width: 595px !important; }

.u--w__px--600 {
  width: 600px !important;
  max-width: 600px !important;
  min-width: 600px !important; }

.u--w__px--rwd600 {
  width: 600px !important;
  max-width: 600px !important;
  min-width: 600px !important; }

.u--w__px--605 {
  width: 605px !important;
  max-width: 605px !important;
  min-width: 605px !important; }

.u--w__px--rwd605 {
  width: 605px !important;
  max-width: 605px !important;
  min-width: 605px !important; }

.u--w__px--610 {
  width: 610px !important;
  max-width: 610px !important;
  min-width: 610px !important; }

.u--w__px--rwd610 {
  width: 610px !important;
  max-width: 610px !important;
  min-width: 610px !important; }

.u--w__px--615 {
  width: 615px !important;
  max-width: 615px !important;
  min-width: 615px !important; }

.u--w__px--rwd615 {
  width: 615px !important;
  max-width: 615px !important;
  min-width: 615px !important; }

.u--w__px--620 {
  width: 620px !important;
  max-width: 620px !important;
  min-width: 620px !important; }

.u--w__px--rwd620 {
  width: 620px !important;
  max-width: 620px !important;
  min-width: 620px !important; }

.u--w__px--625 {
  width: 625px !important;
  max-width: 625px !important;
  min-width: 625px !important; }

.u--w__px--rwd625 {
  width: 625px !important;
  max-width: 625px !important;
  min-width: 625px !important; }

.u--w__px--630 {
  width: 630px !important;
  max-width: 630px !important;
  min-width: 630px !important; }

.u--w__px--rwd630 {
  width: 630px !important;
  max-width: 630px !important;
  min-width: 630px !important; }

.u--w__px--635 {
  width: 635px !important;
  max-width: 635px !important;
  min-width: 635px !important; }

.u--w__px--rwd635 {
  width: 635px !important;
  max-width: 635px !important;
  min-width: 635px !important; }

.u--w__px--640 {
  width: 640px !important;
  max-width: 640px !important;
  min-width: 640px !important; }

.u--w__px--rwd640 {
  width: 640px !important;
  max-width: 640px !important;
  min-width: 640px !important; }

.u--w__px--645 {
  width: 645px !important;
  max-width: 645px !important;
  min-width: 645px !important; }

.u--w__px--rwd645 {
  width: 645px !important;
  max-width: 645px !important;
  min-width: 645px !important; }

.u--w__px--650 {
  width: 650px !important;
  max-width: 650px !important;
  min-width: 650px !important; }

.u--w__px--rwd650 {
  width: 650px !important;
  max-width: 650px !important;
  min-width: 650px !important; }

.u--w__px--655 {
  width: 655px !important;
  max-width: 655px !important;
  min-width: 655px !important; }

.u--w__px--rwd655 {
  width: 655px !important;
  max-width: 655px !important;
  min-width: 655px !important; }

.u--w__px--660 {
  width: 660px !important;
  max-width: 660px !important;
  min-width: 660px !important; }

.u--w__px--rwd660 {
  width: 660px !important;
  max-width: 660px !important;
  min-width: 660px !important; }

.u--w__px--665 {
  width: 665px !important;
  max-width: 665px !important;
  min-width: 665px !important; }

.u--w__px--rwd665 {
  width: 665px !important;
  max-width: 665px !important;
  min-width: 665px !important; }

.u--w__px--670 {
  width: 670px !important;
  max-width: 670px !important;
  min-width: 670px !important; }

.u--w__px--rwd670 {
  width: 670px !important;
  max-width: 670px !important;
  min-width: 670px !important; }

.u--w__px--675 {
  width: 675px !important;
  max-width: 675px !important;
  min-width: 675px !important; }

.u--w__px--rwd675 {
  width: 675px !important;
  max-width: 675px !important;
  min-width: 675px !important; }

.u--w__px--680 {
  width: 680px !important;
  max-width: 680px !important;
  min-width: 680px !important; }

.u--w__px--rwd680 {
  width: 680px !important;
  max-width: 680px !important;
  min-width: 680px !important; }

.u--w__px--685 {
  width: 685px !important;
  max-width: 685px !important;
  min-width: 685px !important; }

.u--w__px--rwd685 {
  width: 685px !important;
  max-width: 685px !important;
  min-width: 685px !important; }

.u--w__px--690 {
  width: 690px !important;
  max-width: 690px !important;
  min-width: 690px !important; }

.u--w__px--rwd690 {
  width: 690px !important;
  max-width: 690px !important;
  min-width: 690px !important; }

.u--w__px--695 {
  width: 695px !important;
  max-width: 695px !important;
  min-width: 695px !important; }

.u--w__px--rwd695 {
  width: 695px !important;
  max-width: 695px !important;
  min-width: 695px !important; }

.u--w__px--700 {
  width: 700px !important;
  max-width: 700px !important;
  min-width: 700px !important; }

.u--w__px--rwd700 {
  width: 700px !important;
  max-width: 700px !important;
  min-width: 700px !important; }

.u--w__px--705 {
  width: 705px !important;
  max-width: 705px !important;
  min-width: 705px !important; }

.u--w__px--rwd705 {
  width: 705px !important;
  max-width: 705px !important;
  min-width: 705px !important; }

.u--w__px--710 {
  width: 710px !important;
  max-width: 710px !important;
  min-width: 710px !important; }

.u--w__px--rwd710 {
  width: 710px !important;
  max-width: 710px !important;
  min-width: 710px !important; }

.u--w__px--715 {
  width: 715px !important;
  max-width: 715px !important;
  min-width: 715px !important; }

.u--w__px--rwd715 {
  width: 715px !important;
  max-width: 715px !important;
  min-width: 715px !important; }

.u--w__px--720 {
  width: 720px !important;
  max-width: 720px !important;
  min-width: 720px !important; }

.u--w__px--rwd720 {
  width: 720px !important;
  max-width: 720px !important;
  min-width: 720px !important; }

.u--w__px--725 {
  width: 725px !important;
  max-width: 725px !important;
  min-width: 725px !important; }

.u--w__px--rwd725 {
  width: 725px !important;
  max-width: 725px !important;
  min-width: 725px !important; }

.u--w__px--730 {
  width: 730px !important;
  max-width: 730px !important;
  min-width: 730px !important; }

.u--w__px--rwd730 {
  width: 730px !important;
  max-width: 730px !important;
  min-width: 730px !important; }

.u--w__px--735 {
  width: 735px !important;
  max-width: 735px !important;
  min-width: 735px !important; }

.u--w__px--rwd735 {
  width: 735px !important;
  max-width: 735px !important;
  min-width: 735px !important; }

.u--w__px--740 {
  width: 740px !important;
  max-width: 740px !important;
  min-width: 740px !important; }

.u--w__px--rwd740 {
  width: 740px !important;
  max-width: 740px !important;
  min-width: 740px !important; }

.u--w__px--745 {
  width: 745px !important;
  max-width: 745px !important;
  min-width: 745px !important; }

.u--w__px--rwd745 {
  width: 745px !important;
  max-width: 745px !important;
  min-width: 745px !important; }

.u--w__px--750 {
  width: 750px !important;
  max-width: 750px !important;
  min-width: 750px !important; }

.u--w__px--rwd750 {
  width: 750px !important;
  max-width: 750px !important;
  min-width: 750px !important; }

.u--w__px--755 {
  width: 755px !important;
  max-width: 755px !important;
  min-width: 755px !important; }

.u--w__px--rwd755 {
  width: 755px !important;
  max-width: 755px !important;
  min-width: 755px !important; }

.u--w__px--760 {
  width: 760px !important;
  max-width: 760px !important;
  min-width: 760px !important; }

.u--w__px--rwd760 {
  width: 760px !important;
  max-width: 760px !important;
  min-width: 760px !important; }

.u--w__px--765 {
  width: 765px !important;
  max-width: 765px !important;
  min-width: 765px !important; }

.u--w__px--rwd765 {
  width: 765px !important;
  max-width: 765px !important;
  min-width: 765px !important; }

.u--w__px--770 {
  width: 770px !important;
  max-width: 770px !important;
  min-width: 770px !important; }

.u--w__px--rwd770 {
  width: 770px !important;
  max-width: 770px !important;
  min-width: 770px !important; }

.u--w__px--775 {
  width: 775px !important;
  max-width: 775px !important;
  min-width: 775px !important; }

.u--w__px--rwd775 {
  width: 775px !important;
  max-width: 775px !important;
  min-width: 775px !important; }

.u--w__px--780 {
  width: 780px !important;
  max-width: 780px !important;
  min-width: 780px !important; }

.u--w__px--rwd780 {
  width: 780px !important;
  max-width: 780px !important;
  min-width: 780px !important; }

.u--w__px--785 {
  width: 785px !important;
  max-width: 785px !important;
  min-width: 785px !important; }

.u--w__px--rwd785 {
  width: 785px !important;
  max-width: 785px !important;
  min-width: 785px !important; }

.u--w__px--790 {
  width: 790px !important;
  max-width: 790px !important;
  min-width: 790px !important; }

.u--w__px--rwd790 {
  width: 790px !important;
  max-width: 790px !important;
  min-width: 790px !important; }

.u--w__px--795 {
  width: 795px !important;
  max-width: 795px !important;
  min-width: 795px !important; }

.u--w__px--rwd795 {
  width: 795px !important;
  max-width: 795px !important;
  min-width: 795px !important; }

.u--w__px--800 {
  width: 800px !important;
  max-width: 800px !important;
  min-width: 800px !important; }

.u--w__px--rwd800 {
  width: 800px !important;
  max-width: 800px !important;
  min-width: 800px !important; }

.u--w__px--805 {
  width: 805px !important;
  max-width: 805px !important;
  min-width: 805px !important; }

.u--w__px--rwd805 {
  width: 805px !important;
  max-width: 805px !important;
  min-width: 805px !important; }

.u--w__px--810 {
  width: 810px !important;
  max-width: 810px !important;
  min-width: 810px !important; }

.u--w__px--rwd810 {
  width: 810px !important;
  max-width: 810px !important;
  min-width: 810px !important; }

.u--w__px--815 {
  width: 815px !important;
  max-width: 815px !important;
  min-width: 815px !important; }

.u--w__px--rwd815 {
  width: 815px !important;
  max-width: 815px !important;
  min-width: 815px !important; }

.u--w__px--820 {
  width: 820px !important;
  max-width: 820px !important;
  min-width: 820px !important; }

.u--w__px--rwd820 {
  width: 820px !important;
  max-width: 820px !important;
  min-width: 820px !important; }

.u--w__px--825 {
  width: 825px !important;
  max-width: 825px !important;
  min-width: 825px !important; }

.u--w__px--rwd825 {
  width: 825px !important;
  max-width: 825px !important;
  min-width: 825px !important; }

.u--w__px--830 {
  width: 830px !important;
  max-width: 830px !important;
  min-width: 830px !important; }

.u--w__px--rwd830 {
  width: 830px !important;
  max-width: 830px !important;
  min-width: 830px !important; }

.u--w__px--835 {
  width: 835px !important;
  max-width: 835px !important;
  min-width: 835px !important; }

.u--w__px--rwd835 {
  width: 835px !important;
  max-width: 835px !important;
  min-width: 835px !important; }

.u--w__px--840 {
  width: 840px !important;
  max-width: 840px !important;
  min-width: 840px !important; }

.u--w__px--rwd840 {
  width: 840px !important;
  max-width: 840px !important;
  min-width: 840px !important; }

.u--w__px--845 {
  width: 845px !important;
  max-width: 845px !important;
  min-width: 845px !important; }

.u--w__px--rwd845 {
  width: 845px !important;
  max-width: 845px !important;
  min-width: 845px !important; }

.u--w__px--850 {
  width: 850px !important;
  max-width: 850px !important;
  min-width: 850px !important; }

.u--w__px--rwd850 {
  width: 850px !important;
  max-width: 850px !important;
  min-width: 850px !important; }

.u--w__px--855 {
  width: 855px !important;
  max-width: 855px !important;
  min-width: 855px !important; }

.u--w__px--rwd855 {
  width: 855px !important;
  max-width: 855px !important;
  min-width: 855px !important; }

.u--w__px--860 {
  width: 860px !important;
  max-width: 860px !important;
  min-width: 860px !important; }

.u--w__px--rwd860 {
  width: 860px !important;
  max-width: 860px !important;
  min-width: 860px !important; }

.u--w__px--865 {
  width: 865px !important;
  max-width: 865px !important;
  min-width: 865px !important; }

.u--w__px--rwd865 {
  width: 865px !important;
  max-width: 865px !important;
  min-width: 865px !important; }

.u--w__px--870 {
  width: 870px !important;
  max-width: 870px !important;
  min-width: 870px !important; }

.u--w__px--rwd870 {
  width: 870px !important;
  max-width: 870px !important;
  min-width: 870px !important; }

.u--w__px--875 {
  width: 875px !important;
  max-width: 875px !important;
  min-width: 875px !important; }

.u--w__px--rwd875 {
  width: 875px !important;
  max-width: 875px !important;
  min-width: 875px !important; }

.u--w__px--880 {
  width: 880px !important;
  max-width: 880px !important;
  min-width: 880px !important; }

.u--w__px--rwd880 {
  width: 880px !important;
  max-width: 880px !important;
  min-width: 880px !important; }

.u--w__px--885 {
  width: 885px !important;
  max-width: 885px !important;
  min-width: 885px !important; }

.u--w__px--rwd885 {
  width: 885px !important;
  max-width: 885px !important;
  min-width: 885px !important; }

.u--w__px--890 {
  width: 890px !important;
  max-width: 890px !important;
  min-width: 890px !important; }

.u--w__px--rwd890 {
  width: 890px !important;
  max-width: 890px !important;
  min-width: 890px !important; }

.u--w__px--895 {
  width: 895px !important;
  max-width: 895px !important;
  min-width: 895px !important; }

.u--w__px--rwd895 {
  width: 895px !important;
  max-width: 895px !important;
  min-width: 895px !important; }

.u--w__px--900 {
  width: 900px !important;
  max-width: 900px !important;
  min-width: 900px !important; }

.u--w__px--rwd900 {
  width: 900px !important;
  max-width: 900px !important;
  min-width: 900px !important; }

.u--w__px--905 {
  width: 905px !important;
  max-width: 905px !important;
  min-width: 905px !important; }

.u--w__px--rwd905 {
  width: 905px !important;
  max-width: 905px !important;
  min-width: 905px !important; }

.u--w__px--910 {
  width: 910px !important;
  max-width: 910px !important;
  min-width: 910px !important; }

.u--w__px--rwd910 {
  width: 910px !important;
  max-width: 910px !important;
  min-width: 910px !important; }

.u--w__px--915 {
  width: 915px !important;
  max-width: 915px !important;
  min-width: 915px !important; }

.u--w__px--rwd915 {
  width: 915px !important;
  max-width: 915px !important;
  min-width: 915px !important; }

.u--w__px--920 {
  width: 920px !important;
  max-width: 920px !important;
  min-width: 920px !important; }

.u--w__px--rwd920 {
  width: 920px !important;
  max-width: 920px !important;
  min-width: 920px !important; }

.u--w__px--925 {
  width: 925px !important;
  max-width: 925px !important;
  min-width: 925px !important; }

.u--w__px--rwd925 {
  width: 925px !important;
  max-width: 925px !important;
  min-width: 925px !important; }

.u--w__px--930 {
  width: 930px !important;
  max-width: 930px !important;
  min-width: 930px !important; }

.u--w__px--rwd930 {
  width: 930px !important;
  max-width: 930px !important;
  min-width: 930px !important; }

.u--w__px--935 {
  width: 935px !important;
  max-width: 935px !important;
  min-width: 935px !important; }

.u--w__px--rwd935 {
  width: 935px !important;
  max-width: 935px !important;
  min-width: 935px !important; }

.u--w__px--940 {
  width: 940px !important;
  max-width: 940px !important;
  min-width: 940px !important; }

.u--w__px--rwd940 {
  width: 940px !important;
  max-width: 940px !important;
  min-width: 940px !important; }

.u--w__px--945 {
  width: 945px !important;
  max-width: 945px !important;
  min-width: 945px !important; }

.u--w__px--rwd945 {
  width: 945px !important;
  max-width: 945px !important;
  min-width: 945px !important; }

.u--w__px--950 {
  width: 950px !important;
  max-width: 950px !important;
  min-width: 950px !important; }

.u--w__px--rwd950 {
  width: 950px !important;
  max-width: 950px !important;
  min-width: 950px !important; }

.u--w__px--955 {
  width: 955px !important;
  max-width: 955px !important;
  min-width: 955px !important; }

.u--w__px--rwd955 {
  width: 955px !important;
  max-width: 955px !important;
  min-width: 955px !important; }

.u--w__px--960 {
  width: 960px !important;
  max-width: 960px !important;
  min-width: 960px !important; }

.u--w__px--rwd960 {
  width: 960px !important;
  max-width: 960px !important;
  min-width: 960px !important; }

.u--w__px--965 {
  width: 965px !important;
  max-width: 965px !important;
  min-width: 965px !important; }

.u--w__px--rwd965 {
  width: 965px !important;
  max-width: 965px !important;
  min-width: 965px !important; }

.u--w__px--970 {
  width: 970px !important;
  max-width: 970px !important;
  min-width: 970px !important; }

.u--w__px--rwd970 {
  width: 970px !important;
  max-width: 970px !important;
  min-width: 970px !important; }

.u--w__px--975 {
  width: 975px !important;
  max-width: 975px !important;
  min-width: 975px !important; }

.u--w__px--rwd975 {
  width: 975px !important;
  max-width: 975px !important;
  min-width: 975px !important; }

.u--w__px--980 {
  width: 980px !important;
  max-width: 980px !important;
  min-width: 980px !important; }

.u--w__px--rwd980 {
  width: 980px !important;
  max-width: 980px !important;
  min-width: 980px !important; }

.u--w__px--985 {
  width: 985px !important;
  max-width: 985px !important;
  min-width: 985px !important; }

.u--w__px--rwd985 {
  width: 985px !important;
  max-width: 985px !important;
  min-width: 985px !important; }

.u--w__px--990 {
  width: 990px !important;
  max-width: 990px !important;
  min-width: 990px !important; }

.u--w__px--rwd990 {
  width: 990px !important;
  max-width: 990px !important;
  min-width: 990px !important; }

.u--w__px--995 {
  width: 995px !important;
  max-width: 995px !important;
  min-width: 995px !important; }

.u--w__px--rwd995 {
  width: 995px !important;
  max-width: 995px !important;
  min-width: 995px !important; }

.u--w__px--1000 {
  width: 1000px !important;
  max-width: 1000px !important;
  min-width: 1000px !important; }

.u--w__px--rwd1000 {
  width: 1000px !important;
  max-width: 1000px !important;
  min-width: 1000px !important; }

.u--w__px--1005 {
  width: 1005px !important;
  max-width: 1005px !important;
  min-width: 1005px !important; }

.u--w__px--rwd1005 {
  width: 1005px !important;
  max-width: 1005px !important;
  min-width: 1005px !important; }

.u--w__px--1010 {
  width: 1010px !important;
  max-width: 1010px !important;
  min-width: 1010px !important; }

.u--w__px--rwd1010 {
  width: 1010px !important;
  max-width: 1010px !important;
  min-width: 1010px !important; }

.u--w__px--1015 {
  width: 1015px !important;
  max-width: 1015px !important;
  min-width: 1015px !important; }

.u--w__px--rwd1015 {
  width: 1015px !important;
  max-width: 1015px !important;
  min-width: 1015px !important; }

.u--w__px--1020 {
  width: 1020px !important;
  max-width: 1020px !important;
  min-width: 1020px !important; }

.u--w__px--rwd1020 {
  width: 1020px !important;
  max-width: 1020px !important;
  min-width: 1020px !important; }

.u--w__px--1025 {
  width: 1025px !important;
  max-width: 1025px !important;
  min-width: 1025px !important; }

.u--w__px--rwd1025 {
  width: 1025px !important;
  max-width: 1025px !important;
  min-width: 1025px !important; }

.u--w__px--1030 {
  width: 1030px !important;
  max-width: 1030px !important;
  min-width: 1030px !important; }

.u--w__px--rwd1030 {
  width: 1030px !important;
  max-width: 1030px !important;
  min-width: 1030px !important; }

.u--w__px--1035 {
  width: 1035px !important;
  max-width: 1035px !important;
  min-width: 1035px !important; }

.u--w__px--rwd1035 {
  width: 1035px !important;
  max-width: 1035px !important;
  min-width: 1035px !important; }

.u--w__px--1040 {
  width: 1040px !important;
  max-width: 1040px !important;
  min-width: 1040px !important; }

.u--w__px--rwd1040 {
  width: 1040px !important;
  max-width: 1040px !important;
  min-width: 1040px !important; }

.u--w__px--1045 {
  width: 1045px !important;
  max-width: 1045px !important;
  min-width: 1045px !important; }

.u--w__px--rwd1045 {
  width: 1045px !important;
  max-width: 1045px !important;
  min-width: 1045px !important; }

.u--w__px--1050 {
  width: 1050px !important;
  max-width: 1050px !important;
  min-width: 1050px !important; }

.u--w__px--rwd1050 {
  width: 1050px !important;
  max-width: 1050px !important;
  min-width: 1050px !important; }

.u--w__px--1055 {
  width: 1055px !important;
  max-width: 1055px !important;
  min-width: 1055px !important; }

.u--w__px--rwd1055 {
  width: 1055px !important;
  max-width: 1055px !important;
  min-width: 1055px !important; }

.u--w__px--1060 {
  width: 1060px !important;
  max-width: 1060px !important;
  min-width: 1060px !important; }

.u--w__px--rwd1060 {
  width: 1060px !important;
  max-width: 1060px !important;
  min-width: 1060px !important; }

.u--w__px--1065 {
  width: 1065px !important;
  max-width: 1065px !important;
  min-width: 1065px !important; }

.u--w__px--rwd1065 {
  width: 1065px !important;
  max-width: 1065px !important;
  min-width: 1065px !important; }

.u--w__px--1070 {
  width: 1070px !important;
  max-width: 1070px !important;
  min-width: 1070px !important; }

.u--w__px--rwd1070 {
  width: 1070px !important;
  max-width: 1070px !important;
  min-width: 1070px !important; }

.u--w__px--1075 {
  width: 1075px !important;
  max-width: 1075px !important;
  min-width: 1075px !important; }

.u--w__px--rwd1075 {
  width: 1075px !important;
  max-width: 1075px !important;
  min-width: 1075px !important; }

.u--w__px--1080 {
  width: 1080px !important;
  max-width: 1080px !important;
  min-width: 1080px !important; }

.u--w__px--rwd1080 {
  width: 1080px !important;
  max-width: 1080px !important;
  min-width: 1080px !important; }

.u--w__px--1085 {
  width: 1085px !important;
  max-width: 1085px !important;
  min-width: 1085px !important; }

.u--w__px--rwd1085 {
  width: 1085px !important;
  max-width: 1085px !important;
  min-width: 1085px !important; }

.u--w__px--1090 {
  width: 1090px !important;
  max-width: 1090px !important;
  min-width: 1090px !important; }

.u--w__px--rwd1090 {
  width: 1090px !important;
  max-width: 1090px !important;
  min-width: 1090px !important; }

.u--w__px--1095 {
  width: 1095px !important;
  max-width: 1095px !important;
  min-width: 1095px !important; }

.u--w__px--rwd1095 {
  width: 1095px !important;
  max-width: 1095px !important;
  min-width: 1095px !important; }

.u--w__px--1100 {
  width: 1100px !important;
  max-width: 1100px !important;
  min-width: 1100px !important; }

.u--w__px--rwd1100 {
  width: 1100px !important;
  max-width: 1100px !important;
  min-width: 1100px !important; }

.u--w__px--1105 {
  width: 1105px !important;
  max-width: 1105px !important;
  min-width: 1105px !important; }

.u--w__px--rwd1105 {
  width: 1105px !important;
  max-width: 1105px !important;
  min-width: 1105px !important; }

.u--w__px--1110 {
  width: 1110px !important;
  max-width: 1110px !important;
  min-width: 1110px !important; }

.u--w__px--rwd1110 {
  width: 1110px !important;
  max-width: 1110px !important;
  min-width: 1110px !important; }

.u--w__px--1115 {
  width: 1115px !important;
  max-width: 1115px !important;
  min-width: 1115px !important; }

.u--w__px--rwd1115 {
  width: 1115px !important;
  max-width: 1115px !important;
  min-width: 1115px !important; }

.u--w__px--1120 {
  width: 1120px !important;
  max-width: 1120px !important;
  min-width: 1120px !important; }

.u--w__px--rwd1120 {
  width: 1120px !important;
  max-width: 1120px !important;
  min-width: 1120px !important; }

.u--w__px--1125 {
  width: 1125px !important;
  max-width: 1125px !important;
  min-width: 1125px !important; }

.u--w__px--rwd1125 {
  width: 1125px !important;
  max-width: 1125px !important;
  min-width: 1125px !important; }

.u--w__px--1130 {
  width: 1130px !important;
  max-width: 1130px !important;
  min-width: 1130px !important; }

.u--w__px--rwd1130 {
  width: 1130px !important;
  max-width: 1130px !important;
  min-width: 1130px !important; }

.u--w__px--1135 {
  width: 1135px !important;
  max-width: 1135px !important;
  min-width: 1135px !important; }

.u--w__px--rwd1135 {
  width: 1135px !important;
  max-width: 1135px !important;
  min-width: 1135px !important; }

.u--w__px--1140 {
  width: 1140px !important;
  max-width: 1140px !important;
  min-width: 1140px !important; }

.u--w__px--rwd1140 {
  width: 1140px !important;
  max-width: 1140px !important;
  min-width: 1140px !important; }

.u--w__px--1145 {
  width: 1145px !important;
  max-width: 1145px !important;
  min-width: 1145px !important; }

.u--w__px--rwd1145 {
  width: 1145px !important;
  max-width: 1145px !important;
  min-width: 1145px !important; }

.u--w__px--1150 {
  width: 1150px !important;
  max-width: 1150px !important;
  min-width: 1150px !important; }

.u--w__px--rwd1150 {
  width: 1150px !important;
  max-width: 1150px !important;
  min-width: 1150px !important; }

.u--w__px--1155 {
  width: 1155px !important;
  max-width: 1155px !important;
  min-width: 1155px !important; }

.u--w__px--rwd1155 {
  width: 1155px !important;
  max-width: 1155px !important;
  min-width: 1155px !important; }

.u--w__px--1160 {
  width: 1160px !important;
  max-width: 1160px !important;
  min-width: 1160px !important; }

.u--w__px--rwd1160 {
  width: 1160px !important;
  max-width: 1160px !important;
  min-width: 1160px !important; }

.u--w__px--1165 {
  width: 1165px !important;
  max-width: 1165px !important;
  min-width: 1165px !important; }

.u--w__px--rwd1165 {
  width: 1165px !important;
  max-width: 1165px !important;
  min-width: 1165px !important; }

.u--w__px--1170 {
  width: 1170px !important;
  max-width: 1170px !important;
  min-width: 1170px !important; }

.u--w__px--rwd1170 {
  width: 1170px !important;
  max-width: 1170px !important;
  min-width: 1170px !important; }

.u--w__px--1175 {
  width: 1175px !important;
  max-width: 1175px !important;
  min-width: 1175px !important; }

.u--w__px--rwd1175 {
  width: 1175px !important;
  max-width: 1175px !important;
  min-width: 1175px !important; }

.u--w__px--1180 {
  width: 1180px !important;
  max-width: 1180px !important;
  min-width: 1180px !important; }

.u--w__px--rwd1180 {
  width: 1180px !important;
  max-width: 1180px !important;
  min-width: 1180px !important; }

.u--w__px--1185 {
  width: 1185px !important;
  max-width: 1185px !important;
  min-width: 1185px !important; }

.u--w__px--rwd1185 {
  width: 1185px !important;
  max-width: 1185px !important;
  min-width: 1185px !important; }

.u--w__px--1190 {
  width: 1190px !important;
  max-width: 1190px !important;
  min-width: 1190px !important; }

.u--w__px--rwd1190 {
  width: 1190px !important;
  max-width: 1190px !important;
  min-width: 1190px !important; }

.u--w__px--1195 {
  width: 1195px !important;
  max-width: 1195px !important;
  min-width: 1195px !important; }

.u--w__px--rwd1195 {
  width: 1195px !important;
  max-width: 1195px !important;
  min-width: 1195px !important; }

.u--w__px--1200 {
  width: 1200px !important;
  max-width: 1200px !important;
  min-width: 1200px !important; }

.u--w__px--rwd1200 {
  width: 1200px !important;
  max-width: 1200px !important;
  min-width: 1200px !important; }

.u--w__px--1205 {
  width: 1205px !important;
  max-width: 1205px !important;
  min-width: 1205px !important; }

.u--w__px--rwd1205 {
  width: 1205px !important;
  max-width: 1205px !important;
  min-width: 1205px !important; }

.u--w__px--1210 {
  width: 1210px !important;
  max-width: 1210px !important;
  min-width: 1210px !important; }

.u--w__px--rwd1210 {
  width: 1210px !important;
  max-width: 1210px !important;
  min-width: 1210px !important; }

.u--w__px--1215 {
  width: 1215px !important;
  max-width: 1215px !important;
  min-width: 1215px !important; }

.u--w__px--rwd1215 {
  width: 1215px !important;
  max-width: 1215px !important;
  min-width: 1215px !important; }

.u--w__px--1220 {
  width: 1220px !important;
  max-width: 1220px !important;
  min-width: 1220px !important; }

.u--w__px--rwd1220 {
  width: 1220px !important;
  max-width: 1220px !important;
  min-width: 1220px !important; }

.u--w__px--1225 {
  width: 1225px !important;
  max-width: 1225px !important;
  min-width: 1225px !important; }

.u--w__px--rwd1225 {
  width: 1225px !important;
  max-width: 1225px !important;
  min-width: 1225px !important; }

.u--w__px--1230 {
  width: 1230px !important;
  max-width: 1230px !important;
  min-width: 1230px !important; }

.u--w__px--rwd1230 {
  width: 1230px !important;
  max-width: 1230px !important;
  min-width: 1230px !important; }

.u--w__px--1235 {
  width: 1235px !important;
  max-width: 1235px !important;
  min-width: 1235px !important; }

.u--w__px--rwd1235 {
  width: 1235px !important;
  max-width: 1235px !important;
  min-width: 1235px !important; }

.u--w__px--1240 {
  width: 1240px !important;
  max-width: 1240px !important;
  min-width: 1240px !important; }

.u--w__px--rwd1240 {
  width: 1240px !important;
  max-width: 1240px !important;
  min-width: 1240px !important; }

.u--w__px--1245 {
  width: 1245px !important;
  max-width: 1245px !important;
  min-width: 1245px !important; }

.u--w__px--rwd1245 {
  width: 1245px !important;
  max-width: 1245px !important;
  min-width: 1245px !important; }

.u--w__px--1250 {
  width: 1250px !important;
  max-width: 1250px !important;
  min-width: 1250px !important; }

.u--w__px--rwd1250 {
  width: 1250px !important;
  max-width: 1250px !important;
  min-width: 1250px !important; }

@media screen and (max-width: 768px) {
  .u--w__per--rwd2 {
    width: 100% !important; }
  .u--w__per--rwd3 {
    width: 100% !important; }
  .u--w__per--rwd4 {
    width: 100% !important; }
  .u--w__per--rwd5 {
    width: 100% !important; }
  .u--w__per--rwd6 {
    width: 100% !important; }
  .u--w__per--rwd7 {
    width: 100% !important; }
  .u--w__per--rwd8 {
    width: 100% !important; }
  .u--w__per--rwd9 {
    width: 100% !important; }
  .u--w__per--rwd10 {
    width: 100% !important; }
  .u--w__per--rwd11 {
    width: 100% !important; }
  .u--w__per--rwd12 {
    width: 100% !important; }
  .u--w__per--rwd13 {
    width: 100% !important; }
  .u--w__per--rwd14 {
    width: 100% !important; }
  .u--w__per--rwd15 {
    width: 100% !important; }
  .u--w__per--rwd16 {
    width: 100% !important; }
  .u--w__per--rwd17 {
    width: 100% !important; }
  .u--w__per--rwd18 {
    width: 100% !important; }
  .u--w__per--rwd19 {
    width: 100% !important; }
  .u--w__per--rwd20 {
    width: 100% !important; }
  .u--w__per--rwd21 {
    width: 100% !important; }
  .u--w__per--rwd22 {
    width: 100% !important; }
  .u--w__per--rwd23 {
    width: 100% !important; }
  .u--w__per--rwd24 {
    width: 100% !important; }
  .u--w__per--rwd25 {
    width: 100% !important; }
  .u--w__per--rwd26 {
    width: 100% !important; }
  .u--w__per--rwd27 {
    width: 100% !important; }
  .u--w__per--rwd28 {
    width: 100% !important; }
  .u--w__per--rwd29 {
    width: 100% !important; }
  .u--w__per--rwd30 {
    width: 100% !important; }
  .u--w__per--rwd31 {
    width: 100% !important; }
  .u--w__per--rwd32 {
    width: 100% !important; }
  .u--w__per--rwd33 {
    width: 100% !important; }
  .u--w__per--rwd34 {
    width: 100% !important; }
  .u--w__per--rwd35 {
    width: 100% !important; }
  .u--w__per--rwd36 {
    width: 100% !important; }
  .u--w__per--rwd37 {
    width: 100% !important; }
  .u--w__per--rwd38 {
    width: 100% !important; }
  .u--w__per--rwd39 {
    width: 100% !important; }
  .u--w__per--rwd40 {
    width: 100% !important; }
  .u--w__per--rwd41 {
    width: 100% !important; }
  .u--w__per--rwd42 {
    width: 100% !important; }
  .u--w__per--rwd43 {
    width: 100% !important; }
  .u--w__per--rwd44 {
    width: 100% !important; }
  .u--w__per--rwd45 {
    width: 100% !important; }
  .u--w__per--rwd46 {
    width: 100% !important; }
  .u--w__per--rwd47 {
    width: 100% !important; }
  .u--w__per--rwd48 {
    width: 100% !important; }
  .u--w__per--rwd49 {
    width: 100% !important; }
  .u--w__per--rwd50 {
    width: 100% !important; }
  .u--w__per--rwd51 {
    width: 100% !important; }
  .u--w__per--rwd52 {
    width: 100% !important; }
  .u--w__per--rwd53 {
    width: 100% !important; }
  .u--w__per--rwd54 {
    width: 100% !important; }
  .u--w__per--rwd55 {
    width: 100% !important; }
  .u--w__per--rwd56 {
    width: 100% !important; }
  .u--w__per--rwd57 {
    width: 100% !important; }
  .u--w__per--rwd58 {
    width: 100% !important; }
  .u--w__per--rwd59 {
    width: 100% !important; }
  .u--w__per--rwd60 {
    width: 100% !important; }
  .u--w__per--rwd61 {
    width: 100% !important; }
  .u--w__per--rwd62 {
    width: 100% !important; }
  .u--w__per--rwd63 {
    width: 100% !important; }
  .u--w__per--rwd64 {
    width: 100% !important; }
  .u--w__per--rwd65 {
    width: 100% !important; }
  .u--w__per--rwd66 {
    width: 100% !important; }
  .u--w__per--rwd67 {
    width: 100% !important; }
  .u--w__per--rwd68 {
    width: 100% !important; }
  .u--w__per--rwd69 {
    width: 100% !important; }
  .u--w__per--rwd70 {
    width: 100% !important; }
  .u--w__per--rwd71 {
    width: 100% !important; }
  .u--w__per--rwd72 {
    width: 100% !important; }
  .u--w__per--rwd73 {
    width: 100% !important; }
  .u--w__per--rwd74 {
    width: 100% !important; }
  .u--w__per--rwd75 {
    width: 100% !important; }
  .u--w__per--rwd76 {
    width: 100% !important; }
  .u--w__per--rwd77 {
    width: 100% !important; }
  .u--w__per--rwd78 {
    width: 100% !important; }
  .u--w__per--rwd79 {
    width: 100% !important; }
  .u--w__per--rwd80 {
    width: 100% !important; }
  .u--w__per--rwd81 {
    width: 100% !important; }
  .u--w__per--rwd82 {
    width: 100% !important; }
  .u--w__per--rwd83 {
    width: 100% !important; }
  .u--w__per--rwd84 {
    width: 100% !important; }
  .u--w__per--rwd85 {
    width: 100% !important; }
  .u--w__per--rwd86 {
    width: 100% !important; }
  .u--w__per--rwd87 {
    width: 100% !important; }
  .u--w__per--rwd88 {
    width: 100% !important; }
  .u--w__per--rwd89 {
    width: 100% !important; }
  .u--w__per--rwd90 {
    width: 100% !important; }
  .u--w__per--rwd91 {
    width: 100% !important; }
  .u--w__per--rwd92 {
    width: 100% !important; }
  .u--w__per--rwd93 {
    width: 100% !important; }
  .u--w__per--rwd94 {
    width: 100% !important; }
  .u--w__per--rwd95 {
    width: 100% !important; }
  .u--w__per--rwd96 {
    width: 100% !important; }
  .u--w__per--rwd97 {
    width: 100% !important; }
  .u--w__per--rwd98 {
    width: 100% !important; }
  .u--w__per--rwd99 {
    width: 100% !important; }
  .u--w__per--rwd100 {
    width: 100% !important; }
  .u--w__per--rwd101 {
    width: 100% !important; }
  .u--w__per--rwd102 {
    width: 100% !important; }
  .u--w__per--rwd103 {
    width: 100% !important; }
  .u--w__per--rwd104 {
    width: 100% !important; }
  .u--w__per--rwd105 {
    width: 100% !important; }
  .u--w__per--rwd106 {
    width: 100% !important; }
  .u--w__per--rwd107 {
    width: 100% !important; }
  .u--w__per--rwd108 {
    width: 100% !important; }
  .u--w__per--rwd109 {
    width: 100% !important; }
  .u--w__per--rwd110 {
    width: 100% !important; }
  .u--w__per--rwd111 {
    width: 100% !important; }
  .u--w__per--rwd112 {
    width: 100% !important; }
  .u--w__per--rwd113 {
    width: 100% !important; }
  .u--w__per--rwd114 {
    width: 100% !important; }
  .u--w__per--rwd115 {
    width: 100% !important; }
  .u--w__per--rwd116 {
    width: 100% !important; }
  .u--w__per--rwd117 {
    width: 100% !important; }
  .u--w__per--rwd118 {
    width: 100% !important; }
  .u--w__per--rwd119 {
    width: 100% !important; }
  .u--w__per--rwd120 {
    width: 100% !important; }
  .u--w__per--rwd121 {
    width: 100% !important; }
  .u--w__per--rwd122 {
    width: 100% !important; }
  .u--w__per--rwd123 {
    width: 100% !important; }
  .u--w__per--rwd124 {
    width: 100% !important; }
  .u--w__per--rwd125 {
    width: 100% !important; }
  .u--w__per--rwd126 {
    width: 100% !important; }
  .u--w__per--rwd127 {
    width: 100% !important; }
  .u--w__per--rwd128 {
    width: 100% !important; }
  .u--w__per--rwd129 {
    width: 100% !important; }
  .u--w__per--rwd130 {
    width: 100% !important; }
  .u--w__per--rwd131 {
    width: 100% !important; }
  .u--w__per--rwd132 {
    width: 100% !important; }
  .u--w__per--rwd133 {
    width: 100% !important; }
  .u--w__per--rwd134 {
    width: 100% !important; }
  .u--w__per--rwd135 {
    width: 100% !important; }
  .u--w__per--rwd136 {
    width: 100% !important; }
  .u--w__per--rwd137 {
    width: 100% !important; }
  .u--w__per--rwd138 {
    width: 100% !important; }
  .u--w__per--rwd139 {
    width: 100% !important; }
  .u--w__per--rwd140 {
    width: 100% !important; }
  .u--w__per--rwd141 {
    width: 100% !important; }
  .u--w__per--rwd142 {
    width: 100% !important; }
  .u--w__per--rwd143 {
    width: 100% !important; }
  .u--w__per--rwd144 {
    width: 100% !important; }
  .u--w__per--rwd145 {
    width: 100% !important; }
  .u--w__per--rwd146 {
    width: 100% !important; }
  .u--w__per--rwd147 {
    width: 100% !important; }
  .u--w__per--rwd148 {
    width: 100% !important; }
  .u--w__per--rwd149 {
    width: 100% !important; }
  .u--w__per--rwd150 {
    width: 100% !important; }
  .u--w__per--rwd151 {
    width: 100% !important; }
  .u--w__per--rwd152 {
    width: 100% !important; }
  .u--w__per--rwd153 {
    width: 100% !important; }
  .u--w__per--rwd154 {
    width: 100% !important; }
  .u--w__per--rwd155 {
    width: 100% !important; }
  .u--w__per--rwd156 {
    width: 100% !important; }
  .u--w__per--rwd157 {
    width: 100% !important; }
  .u--w__per--rwd158 {
    width: 100% !important; }
  .u--w__per--rwd159 {
    width: 100% !important; }
  .u--w__per--rwd160 {
    width: 100% !important; }
  .u--w__per--rwd161 {
    width: 100% !important; }
  .u--w__per--rwd162 {
    width: 100% !important; }
  .u--w__per--rwd163 {
    width: 100% !important; }
  .u--w__per--rwd164 {
    width: 100% !important; }
  .u--w__per--rwd165 {
    width: 100% !important; }
  .u--w__per--rwd166 {
    width: 100% !important; }
  .u--w__per--rwd167 {
    width: 100% !important; }
  .u--w__per--rwd168 {
    width: 100% !important; }
  .u--w__per--rwd169 {
    width: 100% !important; }
  .u--w__per--rwd170 {
    width: 100% !important; }
  .u--w__per--rwd171 {
    width: 100% !important; }
  .u--w__per--rwd172 {
    width: 100% !important; }
  .u--w__per--rwd173 {
    width: 100% !important; }
  .u--w__per--rwd174 {
    width: 100% !important; }
  .u--w__per--rwd175 {
    width: 100% !important; }
  .u--w__per--rwd176 {
    width: 100% !important; }
  .u--w__per--rwd177 {
    width: 100% !important; }
  .u--w__per--rwd178 {
    width: 100% !important; }
  .u--w__per--rwd179 {
    width: 100% !important; }
  .u--w__per--rwd180 {
    width: 100% !important; }
  .u--w__per--rwd181 {
    width: 100% !important; }
  .u--w__per--rwd182 {
    width: 100% !important; }
  .u--w__per--rwd183 {
    width: 100% !important; }
  .u--w__per--rwd184 {
    width: 100% !important; }
  .u--w__per--rwd185 {
    width: 100% !important; }
  .u--w__per--rwd186 {
    width: 100% !important; }
  .u--w__per--rwd187 {
    width: 100% !important; }
  .u--w__per--rwd188 {
    width: 100% !important; }
  .u--w__per--rwd189 {
    width: 100% !important; }
  .u--w__per--rwd190 {
    width: 100% !important; }
  .u--w__per--rwd191 {
    width: 100% !important; }
  .u--w__per--rwd192 {
    width: 100% !important; }
  .u--w__per--rwd193 {
    width: 100% !important; }
  .u--w__per--rwd194 {
    width: 100% !important; }
  .u--w__per--rwd195 {
    width: 100% !important; }
  .u--w__per--rwd196 {
    width: 100% !important; }
  .u--w__per--rwd197 {
    width: 100% !important; }
  .u--w__per--rwd198 {
    width: 100% !important; }
  .u--w__per--rwd199 {
    width: 100% !important; }
  .u--w__per--rwd200 {
    width: 100% !important; }
  .u--w__per--rwd201 {
    width: 100% !important; }
  .u--w__per--rwd202 {
    width: 100% !important; }
  .u--w__per--rwd203 {
    width: 100% !important; }
  .u--w__per--rwd204 {
    width: 100% !important; }
  .u--w__per--rwd205 {
    width: 100% !important; }
  .u--w__per--rwd206 {
    width: 100% !important; }
  .u--w__per--rwd207 {
    width: 100% !important; }
  .u--w__per--rwd208 {
    width: 100% !important; }
  .u--w__per--rwd209 {
    width: 100% !important; }
  .u--w__per--rwd210 {
    width: 100% !important; }
  .u--w__per--rwd211 {
    width: 100% !important; }
  .u--w__per--rwd212 {
    width: 100% !important; }
  .u--w__per--rwd213 {
    width: 100% !important; }
  .u--w__per--rwd214 {
    width: 100% !important; }
  .u--w__per--rwd215 {
    width: 100% !important; }
  .u--w__per--rwd216 {
    width: 100% !important; }
  .u--w__per--rwd217 {
    width: 100% !important; }
  .u--w__per--rwd218 {
    width: 100% !important; }
  .u--w__per--rwd219 {
    width: 100% !important; }
  .u--w__per--rwd220 {
    width: 100% !important; }
  .u--w__per--rwd221 {
    width: 100% !important; }
  .u--w__per--rwd222 {
    width: 100% !important; }
  .u--w__per--rwd223 {
    width: 100% !important; }
  .u--w__per--rwd224 {
    width: 100% !important; }
  .u--w__per--rwd225 {
    width: 100% !important; }
  .u--w__per--rwd226 {
    width: 100% !important; }
  .u--w__per--rwd227 {
    width: 100% !important; }
  .u--w__per--rwd228 {
    width: 100% !important; }
  .u--w__per--rwd229 {
    width: 100% !important; }
  .u--w__per--rwd230 {
    width: 100% !important; }
  .u--w__per--rwd231 {
    width: 100% !important; }
  .u--w__per--rwd232 {
    width: 100% !important; }
  .u--w__per--rwd233 {
    width: 100% !important; }
  .u--w__per--rwd234 {
    width: 100% !important; }
  .u--w__per--rwd235 {
    width: 100% !important; }
  .u--w__per--rwd236 {
    width: 100% !important; }
  .u--w__per--rwd237 {
    width: 100% !important; }
  .u--w__per--rwd238 {
    width: 100% !important; }
  .u--w__per--rwd239 {
    width: 100% !important; }
  .u--w__per--rwd240 {
    width: 100% !important; }
  .u--w__per--rwd241 {
    width: 100% !important; }
  .u--w__per--rwd242 {
    width: 100% !important; }
  .u--w__per--rwd243 {
    width: 100% !important; }
  .u--w__per--rwd244 {
    width: 100% !important; }
  .u--w__per--rwd245 {
    width: 100% !important; }
  .u--w__per--rwd246 {
    width: 100% !important; }
  .u--w__per--rwd247 {
    width: 100% !important; }
  .u--w__per--rwd248 {
    width: 100% !important; }
  .u--w__per--rwd249 {
    width: 100% !important; }
  .u--w__per--rwd250 {
    width: 100% !important; }
  .u--w__per--rwd251 {
    width: 100% !important; }
  .u--w__px--rwd5 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd10 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd15 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd20 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd25 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd30 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd35 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd40 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd45 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd50 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd55 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd60 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd65 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd70 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd75 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd80 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd85 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd90 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd95 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd100 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd105 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd110 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd115 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd120 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd125 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd130 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd135 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd140 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd145 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd150 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd155 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd160 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd165 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd170 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd175 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd180 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd185 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd190 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd195 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd200 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd205 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd210 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd215 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd220 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd225 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd230 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd235 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd240 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd245 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd250 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd255 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd260 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd265 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd270 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd275 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd280 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd285 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd290 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd295 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd300 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd305 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd310 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd315 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd320 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd325 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd330 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd335 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd340 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd345 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd350 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd355 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd360 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd365 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd370 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd375 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd380 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd385 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd390 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd395 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd400 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd405 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd410 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd415 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd420 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd425 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd430 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd435 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd440 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd445 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd450 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd455 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd460 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd465 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd470 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd475 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd480 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd485 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd490 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd495 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd500 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd505 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd510 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd515 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd520 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd525 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd530 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd535 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd540 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd545 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd550 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd555 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd560 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd565 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd570 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd575 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd580 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd585 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd590 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd595 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd600 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd605 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd610 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd615 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd620 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd625 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd630 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd635 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd640 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd645 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd650 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd655 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd660 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd665 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd670 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd675 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd680 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd685 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd690 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd695 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd700 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd705 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd710 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd715 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd720 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd725 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd730 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd735 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd740 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd745 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd750 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd755 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd760 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd765 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd770 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd775 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd780 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd785 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd790 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd795 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd800 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd805 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd810 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd815 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd820 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd825 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd830 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd835 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd840 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd845 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd850 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd855 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd860 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd865 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd870 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd875 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd880 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd885 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd890 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd895 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd900 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd905 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd910 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd915 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd920 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd925 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd930 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd935 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd940 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd945 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd950 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd955 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd960 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd965 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd970 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd975 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd980 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd985 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd990 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd995 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1000 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1005 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1010 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1015 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1020 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1025 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1030 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1035 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1040 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1045 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1050 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1055 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1060 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1065 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1070 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1075 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1080 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1085 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1090 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1095 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1100 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1105 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1110 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1115 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1120 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1125 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1130 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1135 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1140 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1145 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1150 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1155 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1160 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1165 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1170 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1175 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1180 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1185 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1190 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1195 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1200 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1205 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1210 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1215 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1220 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1225 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1230 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1235 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1240 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1245 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; }
  .u--w__px--rwd1250 {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important; } }

.u--mx__w--0 {
  max-width: 0 !important; }

.u--mi__w--auto {
  min-width: auto !important; }

.u--w__auto {
  width: auto !important; }

.u--h__auto {
  height: auto !important; }

.u--bdb__no {
  border-bottom: none !important; }

.u--bdt__no {
  border-top: none !important; }

.u--vtc__al--top {
  vertical-align: top !important; }
  .u--vtc__al--top--th th {
    vertical-align: top !important; }
  .u--vtc__al--top--td td {
    vertical-align: top !important; }

.u--vtc__al--middle {
  vertical-align: middle !important; }

.u--vtc__al--bottom {
  vertical-align: bottom !important; }

/** ========================================
 * @
 * 余白用
 * ====================================== */
.u--mg--0 {
  margin: 0 !important; }

.u--mgt--0 {
  margin-top: 0 !important; }

.u--mgr--0 {
  margin-right: 0 !important; }

.u--mgb--0 {
  margin-bottom: 0 !important; }

.u--mgl--0 {
  margin-left: 0 !important; }

.u--pd--0 {
  padding: 0 !important; }

.u--pdr--0 {
  padding-right: 0 !important; }

.u--pdl--0 {
  padding-left: 0 !important; }

.u--pdt--0 {
  padding-top: 0 !important; }

.u--pdb--0 {
  padding-bottom: 0 !important; }

.u--mgt--5 {
  margin-top: 5px !important; }

.u--mgb--5 {
  margin-bottom: 5px !important; }

.u--mgl--5 {
  margin-left: 5px !important; }

.u--mgr--5 {
  margin-right: 5px !important; }

.u--pdt--5 {
  padding-top: 5px !important; }

.u--pdb--5 {
  padding-bottom: 5px !important; }

.u--pdl--5 {
  padding-left: 5px !important; }

.u--pdr--5 {
  padding-right: 5px !important; }

.u--mgt--10 {
  margin-top: 10px !important; }

.u--mgb--10 {
  margin-bottom: 10px !important; }

.u--mgl--10 {
  margin-left: 10px !important; }

.u--mgr--10 {
  margin-right: 10px !important; }

.u--pdt--10 {
  padding-top: 10px !important; }

.u--pdb--10 {
  padding-bottom: 10px !important; }

.u--pdl--10 {
  padding-left: 10px !important; }

.u--pdr--10 {
  padding-right: 10px !important; }

.u--mgt--15 {
  margin-top: 15px !important; }

.u--mgb--15 {
  margin-bottom: 15px !important; }

.u--mgl--15 {
  margin-left: 15px !important; }

.u--mgr--15 {
  margin-right: 15px !important; }

.u--pdt--15 {
  padding-top: 15px !important; }

.u--pdb--15 {
  padding-bottom: 15px !important; }

.u--pdl--15 {
  padding-left: 15px !important; }

.u--pdr--15 {
  padding-right: 15px !important; }

.u--mgt--20 {
  margin-top: 20px !important; }

.u--mgb--20 {
  margin-bottom: 20px !important; }

.u--mgl--20 {
  margin-left: 20px !important; }

.u--mgr--20 {
  margin-right: 20px !important; }

.u--pdt--20 {
  padding-top: 20px !important; }

.u--pdb--20 {
  padding-bottom: 20px !important; }

.u--pdl--20 {
  padding-left: 20px !important; }

.u--pdr--20 {
  padding-right: 20px !important; }

.u--mgt--25 {
  margin-top: 25px !important; }

.u--mgb--25 {
  margin-bottom: 25px !important; }

.u--mgl--25 {
  margin-left: 25px !important; }

.u--mgr--25 {
  margin-right: 25px !important; }

.u--pdt--25 {
  padding-top: 25px !important; }

.u--pdb--25 {
  padding-bottom: 25px !important; }

.u--pdl--25 {
  padding-left: 25px !important; }

.u--pdr--25 {
  padding-right: 25px !important; }

.u--mgt--30 {
  margin-top: 30px !important; }

.u--mgb--30 {
  margin-bottom: 30px !important; }

.u--mgl--30 {
  margin-left: 30px !important; }

.u--mgr--30 {
  margin-right: 30px !important; }

.u--pdt--30 {
  padding-top: 30px !important; }

.u--pdb--30 {
  padding-bottom: 30px !important; }

.u--pdl--30 {
  padding-left: 30px !important; }

.u--pdr--30 {
  padding-right: 30px !important; }

.u--mgt--35 {
  margin-top: 35px !important; }

.u--mgb--35 {
  margin-bottom: 35px !important; }

.u--mgl--35 {
  margin-left: 35px !important; }

.u--mgr--35 {
  margin-right: 35px !important; }

.u--pdt--35 {
  padding-top: 35px !important; }

.u--pdb--35 {
  padding-bottom: 35px !important; }

.u--pdl--35 {
  padding-left: 35px !important; }

.u--pdr--35 {
  padding-right: 35px !important; }

.u--mgt--40 {
  margin-top: 40px !important; }

.u--mgb--40 {
  margin-bottom: 40px !important; }

.u--mgl--40 {
  margin-left: 40px !important; }

.u--mgr--40 {
  margin-right: 40px !important; }

.u--pdt--40 {
  padding-top: 40px !important; }

.u--pdb--40 {
  padding-bottom: 40px !important; }

.u--pdl--40 {
  padding-left: 40px !important; }

.u--pdr--40 {
  padding-right: 40px !important; }

.u--mgt--45 {
  margin-top: 45px !important; }

.u--mgb--45 {
  margin-bottom: 45px !important; }

.u--mgl--45 {
  margin-left: 45px !important; }

.u--mgr--45 {
  margin-right: 45px !important; }

.u--pdt--45 {
  padding-top: 45px !important; }

.u--pdb--45 {
  padding-bottom: 45px !important; }

.u--pdl--45 {
  padding-left: 45px !important; }

.u--pdr--45 {
  padding-right: 45px !important; }

.u--mgt--50 {
  margin-top: 50px !important; }

.u--mgb--50 {
  margin-bottom: 50px !important; }

.u--mgl--50 {
  margin-left: 50px !important; }

.u--mgr--50 {
  margin-right: 50px !important; }

.u--pdt--50 {
  padding-top: 50px !important; }

.u--pdb--50 {
  padding-bottom: 50px !important; }

.u--pdl--50 {
  padding-left: 50px !important; }

.u--pdr--50 {
  padding-right: 50px !important; }

.u--mgt--55 {
  margin-top: 55px !important; }

.u--mgb--55 {
  margin-bottom: 55px !important; }

.u--mgl--55 {
  margin-left: 55px !important; }

.u--mgr--55 {
  margin-right: 55px !important; }

.u--pdt--55 {
  padding-top: 55px !important; }

.u--pdb--55 {
  padding-bottom: 55px !important; }

.u--pdl--55 {
  padding-left: 55px !important; }

.u--pdr--55 {
  padding-right: 55px !important; }

.u--mgt--60 {
  margin-top: 60px !important; }

.u--mgb--60 {
  margin-bottom: 60px !important; }

.u--mgl--60 {
  margin-left: 60px !important; }

.u--mgr--60 {
  margin-right: 60px !important; }

.u--pdt--60 {
  padding-top: 60px !important; }

.u--pdb--60 {
  padding-bottom: 60px !important; }

.u--pdl--60 {
  padding-left: 60px !important; }

.u--pdr--60 {
  padding-right: 60px !important; }

.u--mgt--65 {
  margin-top: 65px !important; }

.u--mgb--65 {
  margin-bottom: 65px !important; }

.u--mgl--65 {
  margin-left: 65px !important; }

.u--mgr--65 {
  margin-right: 65px !important; }

.u--pdt--65 {
  padding-top: 65px !important; }

.u--pdb--65 {
  padding-bottom: 65px !important; }

.u--pdl--65 {
  padding-left: 65px !important; }

.u--pdr--65 {
  padding-right: 65px !important; }

.u--mgt--70 {
  margin-top: 70px !important; }

.u--mgb--70 {
  margin-bottom: 70px !important; }

.u--mgl--70 {
  margin-left: 70px !important; }

.u--mgr--70 {
  margin-right: 70px !important; }

.u--pdt--70 {
  padding-top: 70px !important; }

.u--pdb--70 {
  padding-bottom: 70px !important; }

.u--pdl--70 {
  padding-left: 70px !important; }

.u--pdr--70 {
  padding-right: 70px !important; }

.u--mgt--75 {
  margin-top: 75px !important; }

.u--mgb--75 {
  margin-bottom: 75px !important; }

.u--mgl--75 {
  margin-left: 75px !important; }

.u--mgr--75 {
  margin-right: 75px !important; }

.u--pdt--75 {
  padding-top: 75px !important; }

.u--pdb--75 {
  padding-bottom: 75px !important; }

.u--pdl--75 {
  padding-left: 75px !important; }

.u--pdr--75 {
  padding-right: 75px !important; }

.u--mgt--80 {
  margin-top: 80px !important; }

.u--mgb--80 {
  margin-bottom: 80px !important; }

.u--mgl--80 {
  margin-left: 80px !important; }

.u--mgr--80 {
  margin-right: 80px !important; }

.u--pdt--80 {
  padding-top: 80px !important; }

.u--pdb--80 {
  padding-bottom: 80px !important; }

.u--pdl--80 {
  padding-left: 80px !important; }

.u--pdr--80 {
  padding-right: 80px !important; }

.u--mgt--85 {
  margin-top: 85px !important; }

.u--mgb--85 {
  margin-bottom: 85px !important; }

.u--mgl--85 {
  margin-left: 85px !important; }

.u--mgr--85 {
  margin-right: 85px !important; }

.u--pdt--85 {
  padding-top: 85px !important; }

.u--pdb--85 {
  padding-bottom: 85px !important; }

.u--pdl--85 {
  padding-left: 85px !important; }

.u--pdr--85 {
  padding-right: 85px !important; }

.u--mgt--90 {
  margin-top: 90px !important; }

.u--mgb--90 {
  margin-bottom: 90px !important; }

.u--mgl--90 {
  margin-left: 90px !important; }

.u--mgr--90 {
  margin-right: 90px !important; }

.u--pdt--90 {
  padding-top: 90px !important; }

.u--pdb--90 {
  padding-bottom: 90px !important; }

.u--pdl--90 {
  padding-left: 90px !important; }

.u--pdr--90 {
  padding-right: 90px !important; }

.u--mgt--95 {
  margin-top: 95px !important; }

.u--mgb--95 {
  margin-bottom: 95px !important; }

.u--mgl--95 {
  margin-left: 95px !important; }

.u--mgr--95 {
  margin-right: 95px !important; }

.u--pdt--95 {
  padding-top: 95px !important; }

.u--pdb--95 {
  padding-bottom: 95px !important; }

.u--pdl--95 {
  padding-left: 95px !important; }

.u--pdr--95 {
  padding-right: 95px !important; }

.u--mgt--100 {
  margin-top: 100px !important; }

.u--mgb--100 {
  margin-bottom: 100px !important; }

.u--mgl--100 {
  margin-left: 100px !important; }

.u--mgr--100 {
  margin-right: 100px !important; }

.u--pdt--100 {
  padding-top: 100px !important; }

.u--pdb--100 {
  padding-bottom: 100px !important; }

.u--pdl--100 {
  padding-left: 100px !important; }

.u--pdr--100 {
  padding-right: 100px !important; }

.u--mgt--105 {
  margin-top: 105px !important; }

.u--mgb--105 {
  margin-bottom: 105px !important; }

.u--mgl--105 {
  margin-left: 105px !important; }

.u--mgr--105 {
  margin-right: 105px !important; }

.u--pdt--105 {
  padding-top: 105px !important; }

.u--pdb--105 {
  padding-bottom: 105px !important; }

.u--pdl--105 {
  padding-left: 105px !important; }

.u--pdr--105 {
  padding-right: 105px !important; }

.u--mgt--110 {
  margin-top: 110px !important; }

.u--mgb--110 {
  margin-bottom: 110px !important; }

.u--mgl--110 {
  margin-left: 110px !important; }

.u--mgr--110 {
  margin-right: 110px !important; }

.u--pdt--110 {
  padding-top: 110px !important; }

.u--pdb--110 {
  padding-bottom: 110px !important; }

.u--pdl--110 {
  padding-left: 110px !important; }

.u--pdr--110 {
  padding-right: 110px !important; }

.u--mgt--115 {
  margin-top: 115px !important; }

.u--mgb--115 {
  margin-bottom: 115px !important; }

.u--mgl--115 {
  margin-left: 115px !important; }

.u--mgr--115 {
  margin-right: 115px !important; }

.u--pdt--115 {
  padding-top: 115px !important; }

.u--pdb--115 {
  padding-bottom: 115px !important; }

.u--pdl--115 {
  padding-left: 115px !important; }

.u--pdr--115 {
  padding-right: 115px !important; }

.u--mgt--120 {
  margin-top: 120px !important; }

.u--mgb--120 {
  margin-bottom: 120px !important; }

.u--mgl--120 {
  margin-left: 120px !important; }

.u--mgr--120 {
  margin-right: 120px !important; }

.u--pdt--120 {
  padding-top: 120px !important; }

.u--pdb--120 {
  padding-bottom: 120px !important; }

.u--pdl--120 {
  padding-left: 120px !important; }

.u--pdr--120 {
  padding-right: 120px !important; }

.u--mgt--125 {
  margin-top: 125px !important; }

.u--mgb--125 {
  margin-bottom: 125px !important; }

.u--mgl--125 {
  margin-left: 125px !important; }

.u--mgr--125 {
  margin-right: 125px !important; }

.u--pdt--125 {
  padding-top: 125px !important; }

.u--pdb--125 {
  padding-bottom: 125px !important; }

.u--pdl--125 {
  padding-left: 125px !important; }

.u--pdr--125 {
  padding-right: 125px !important; }

.u--mgt--130 {
  margin-top: 130px !important; }

.u--mgb--130 {
  margin-bottom: 130px !important; }

.u--mgl--130 {
  margin-left: 130px !important; }

.u--mgr--130 {
  margin-right: 130px !important; }

.u--pdt--130 {
  padding-top: 130px !important; }

.u--pdb--130 {
  padding-bottom: 130px !important; }

.u--pdl--130 {
  padding-left: 130px !important; }

.u--pdr--130 {
  padding-right: 130px !important; }

.u--mgt--135 {
  margin-top: 135px !important; }

.u--mgb--135 {
  margin-bottom: 135px !important; }

.u--mgl--135 {
  margin-left: 135px !important; }

.u--mgr--135 {
  margin-right: 135px !important; }

.u--pdt--135 {
  padding-top: 135px !important; }

.u--pdb--135 {
  padding-bottom: 135px !important; }

.u--pdl--135 {
  padding-left: 135px !important; }

.u--pdr--135 {
  padding-right: 135px !important; }

.u--mgt--140 {
  margin-top: 140px !important; }

.u--mgb--140 {
  margin-bottom: 140px !important; }

.u--mgl--140 {
  margin-left: 140px !important; }

.u--mgr--140 {
  margin-right: 140px !important; }

.u--pdt--140 {
  padding-top: 140px !important; }

.u--pdb--140 {
  padding-bottom: 140px !important; }

.u--pdl--140 {
  padding-left: 140px !important; }

.u--pdr--140 {
  padding-right: 140px !important; }

.u--mgt--145 {
  margin-top: 145px !important; }

.u--mgb--145 {
  margin-bottom: 145px !important; }

.u--mgl--145 {
  margin-left: 145px !important; }

.u--mgr--145 {
  margin-right: 145px !important; }

.u--pdt--145 {
  padding-top: 145px !important; }

.u--pdb--145 {
  padding-bottom: 145px !important; }

.u--pdl--145 {
  padding-left: 145px !important; }

.u--pdr--145 {
  padding-right: 145px !important; }

.u--mgt--150 {
  margin-top: 150px !important; }

.u--mgb--150 {
  margin-bottom: 150px !important; }

.u--mgl--150 {
  margin-left: 150px !important; }

.u--mgr--150 {
  margin-right: 150px !important; }

.u--pdt--150 {
  padding-top: 150px !important; }

.u--pdb--150 {
  padding-bottom: 150px !important; }

.u--pdl--150 {
  padding-left: 150px !important; }

.u--pdr--150 {
  padding-right: 150px !important; }

.u--mgt--155 {
  margin-top: 155px !important; }

.u--mgb--155 {
  margin-bottom: 155px !important; }

.u--mgl--155 {
  margin-left: 155px !important; }

.u--mgr--155 {
  margin-right: 155px !important; }

.u--pdt--155 {
  padding-top: 155px !important; }

.u--pdb--155 {
  padding-bottom: 155px !important; }

.u--pdl--155 {
  padding-left: 155px !important; }

.u--pdr--155 {
  padding-right: 155px !important; }

.u--mgt--160 {
  margin-top: 160px !important; }

.u--mgb--160 {
  margin-bottom: 160px !important; }

.u--mgl--160 {
  margin-left: 160px !important; }

.u--mgr--160 {
  margin-right: 160px !important; }

.u--pdt--160 {
  padding-top: 160px !important; }

.u--pdb--160 {
  padding-bottom: 160px !important; }

.u--pdl--160 {
  padding-left: 160px !important; }

.u--pdr--160 {
  padding-right: 160px !important; }

.u--mgt--165 {
  margin-top: 165px !important; }

.u--mgb--165 {
  margin-bottom: 165px !important; }

.u--mgl--165 {
  margin-left: 165px !important; }

.u--mgr--165 {
  margin-right: 165px !important; }

.u--pdt--165 {
  padding-top: 165px !important; }

.u--pdb--165 {
  padding-bottom: 165px !important; }

.u--pdl--165 {
  padding-left: 165px !important; }

.u--pdr--165 {
  padding-right: 165px !important; }

.u--mgt--170 {
  margin-top: 170px !important; }

.u--mgb--170 {
  margin-bottom: 170px !important; }

.u--mgl--170 {
  margin-left: 170px !important; }

.u--mgr--170 {
  margin-right: 170px !important; }

.u--pdt--170 {
  padding-top: 170px !important; }

.u--pdb--170 {
  padding-bottom: 170px !important; }

.u--pdl--170 {
  padding-left: 170px !important; }

.u--pdr--170 {
  padding-right: 170px !important; }

.u--mgt--175 {
  margin-top: 175px !important; }

.u--mgb--175 {
  margin-bottom: 175px !important; }

.u--mgl--175 {
  margin-left: 175px !important; }

.u--mgr--175 {
  margin-right: 175px !important; }

.u--pdt--175 {
  padding-top: 175px !important; }

.u--pdb--175 {
  padding-bottom: 175px !important; }

.u--pdl--175 {
  padding-left: 175px !important; }

.u--pdr--175 {
  padding-right: 175px !important; }

.u--mgt--180 {
  margin-top: 180px !important; }

.u--mgb--180 {
  margin-bottom: 180px !important; }

.u--mgl--180 {
  margin-left: 180px !important; }

.u--mgr--180 {
  margin-right: 180px !important; }

.u--pdt--180 {
  padding-top: 180px !important; }

.u--pdb--180 {
  padding-bottom: 180px !important; }

.u--pdl--180 {
  padding-left: 180px !important; }

.u--pdr--180 {
  padding-right: 180px !important; }

.u--mgt--185 {
  margin-top: 185px !important; }

.u--mgb--185 {
  margin-bottom: 185px !important; }

.u--mgl--185 {
  margin-left: 185px !important; }

.u--mgr--185 {
  margin-right: 185px !important; }

.u--pdt--185 {
  padding-top: 185px !important; }

.u--pdb--185 {
  padding-bottom: 185px !important; }

.u--pdl--185 {
  padding-left: 185px !important; }

.u--pdr--185 {
  padding-right: 185px !important; }

.u--mgt--190 {
  margin-top: 190px !important; }

.u--mgb--190 {
  margin-bottom: 190px !important; }

.u--mgl--190 {
  margin-left: 190px !important; }

.u--mgr--190 {
  margin-right: 190px !important; }

.u--pdt--190 {
  padding-top: 190px !important; }

.u--pdb--190 {
  padding-bottom: 190px !important; }

.u--pdl--190 {
  padding-left: 190px !important; }

.u--pdr--190 {
  padding-right: 190px !important; }

.u--mgt--195 {
  margin-top: 195px !important; }

.u--mgb--195 {
  margin-bottom: 195px !important; }

.u--mgl--195 {
  margin-left: 195px !important; }

.u--mgr--195 {
  margin-right: 195px !important; }

.u--pdt--195 {
  padding-top: 195px !important; }

.u--pdb--195 {
  padding-bottom: 195px !important; }

.u--pdl--195 {
  padding-left: 195px !important; }

.u--pdr--195 {
  padding-right: 195px !important; }

.u--mgt--200 {
  margin-top: 200px !important; }

.u--mgb--200 {
  margin-bottom: 200px !important; }

.u--mgl--200 {
  margin-left: 200px !important; }

.u--mgr--200 {
  margin-right: 200px !important; }

.u--pdt--200 {
  padding-top: 200px !important; }

.u--pdb--200 {
  padding-bottom: 200px !important; }

.u--pdl--200 {
  padding-left: 200px !important; }

.u--pdr--200 {
  padding-right: 200px !important; }

.u--mgt--205 {
  margin-top: 205px !important; }

.u--mgb--205 {
  margin-bottom: 205px !important; }

.u--mgl--205 {
  margin-left: 205px !important; }

.u--mgr--205 {
  margin-right: 205px !important; }

.u--pdt--205 {
  padding-top: 205px !important; }

.u--pdb--205 {
  padding-bottom: 205px !important; }

.u--pdl--205 {
  padding-left: 205px !important; }

.u--pdr--205 {
  padding-right: 205px !important; }

.u--mgt--210 {
  margin-top: 210px !important; }

.u--mgb--210 {
  margin-bottom: 210px !important; }

.u--mgl--210 {
  margin-left: 210px !important; }

.u--mgr--210 {
  margin-right: 210px !important; }

.u--pdt--210 {
  padding-top: 210px !important; }

.u--pdb--210 {
  padding-bottom: 210px !important; }

.u--pdl--210 {
  padding-left: 210px !important; }

.u--pdr--210 {
  padding-right: 210px !important; }

.u--mgt--215 {
  margin-top: 215px !important; }

.u--mgb--215 {
  margin-bottom: 215px !important; }

.u--mgl--215 {
  margin-left: 215px !important; }

.u--mgr--215 {
  margin-right: 215px !important; }

.u--pdt--215 {
  padding-top: 215px !important; }

.u--pdb--215 {
  padding-bottom: 215px !important; }

.u--pdl--215 {
  padding-left: 215px !important; }

.u--pdr--215 {
  padding-right: 215px !important; }

.u--mgt--220 {
  margin-top: 220px !important; }

.u--mgb--220 {
  margin-bottom: 220px !important; }

.u--mgl--220 {
  margin-left: 220px !important; }

.u--mgr--220 {
  margin-right: 220px !important; }

.u--pdt--220 {
  padding-top: 220px !important; }

.u--pdb--220 {
  padding-bottom: 220px !important; }

.u--pdl--220 {
  padding-left: 220px !important; }

.u--pdr--220 {
  padding-right: 220px !important; }

.u--mgt--225 {
  margin-top: 225px !important; }

.u--mgb--225 {
  margin-bottom: 225px !important; }

.u--mgl--225 {
  margin-left: 225px !important; }

.u--mgr--225 {
  margin-right: 225px !important; }

.u--pdt--225 {
  padding-top: 225px !important; }

.u--pdb--225 {
  padding-bottom: 225px !important; }

.u--pdl--225 {
  padding-left: 225px !important; }

.u--pdr--225 {
  padding-right: 225px !important; }

.u--mgt--230 {
  margin-top: 230px !important; }

.u--mgb--230 {
  margin-bottom: 230px !important; }

.u--mgl--230 {
  margin-left: 230px !important; }

.u--mgr--230 {
  margin-right: 230px !important; }

.u--pdt--230 {
  padding-top: 230px !important; }

.u--pdb--230 {
  padding-bottom: 230px !important; }

.u--pdl--230 {
  padding-left: 230px !important; }

.u--pdr--230 {
  padding-right: 230px !important; }

.u--mgt--235 {
  margin-top: 235px !important; }

.u--mgb--235 {
  margin-bottom: 235px !important; }

.u--mgl--235 {
  margin-left: 235px !important; }

.u--mgr--235 {
  margin-right: 235px !important; }

.u--pdt--235 {
  padding-top: 235px !important; }

.u--pdb--235 {
  padding-bottom: 235px !important; }

.u--pdl--235 {
  padding-left: 235px !important; }

.u--pdr--235 {
  padding-right: 235px !important; }

.u--mgt--240 {
  margin-top: 240px !important; }

.u--mgb--240 {
  margin-bottom: 240px !important; }

.u--mgl--240 {
  margin-left: 240px !important; }

.u--mgr--240 {
  margin-right: 240px !important; }

.u--pdt--240 {
  padding-top: 240px !important; }

.u--pdb--240 {
  padding-bottom: 240px !important; }

.u--pdl--240 {
  padding-left: 240px !important; }

.u--pdr--240 {
  padding-right: 240px !important; }

.u--mgt--245 {
  margin-top: 245px !important; }

.u--mgb--245 {
  margin-bottom: 245px !important; }

.u--mgl--245 {
  margin-left: 245px !important; }

.u--mgr--245 {
  margin-right: 245px !important; }

.u--pdt--245 {
  padding-top: 245px !important; }

.u--pdb--245 {
  padding-bottom: 245px !important; }

.u--pdl--245 {
  padding-left: 245px !important; }

.u--pdr--245 {
  padding-right: 245px !important; }

.u--mgt--250 {
  margin-top: 250px !important; }

.u--mgb--250 {
  margin-bottom: 250px !important; }

.u--mgl--250 {
  margin-left: 250px !important; }

.u--mgr--250 {
  margin-right: 250px !important; }

.u--pdt--250 {
  padding-top: 250px !important; }

.u--pdb--250 {
  padding-bottom: 250px !important; }

.u--pdl--250 {
  padding-left: 250px !important; }

.u--pdr--250 {
  padding-right: 250px !important; }

.u--mgt--255 {
  margin-top: 255px !important; }

.u--mgb--255 {
  margin-bottom: 255px !important; }

.u--mgl--255 {
  margin-left: 255px !important; }

.u--mgr--255 {
  margin-right: 255px !important; }

.u--pdt--255 {
  padding-top: 255px !important; }

.u--pdb--255 {
  padding-bottom: 255px !important; }

.u--pdl--255 {
  padding-left: 255px !important; }

.u--pdr--255 {
  padding-right: 255px !important; }

.u--mgt--260 {
  margin-top: 260px !important; }

.u--mgb--260 {
  margin-bottom: 260px !important; }

.u--mgl--260 {
  margin-left: 260px !important; }

.u--mgr--260 {
  margin-right: 260px !important; }

.u--pdt--260 {
  padding-top: 260px !important; }

.u--pdb--260 {
  padding-bottom: 260px !important; }

.u--pdl--260 {
  padding-left: 260px !important; }

.u--pdr--260 {
  padding-right: 260px !important; }

.u--mgt--265 {
  margin-top: 265px !important; }

.u--mgb--265 {
  margin-bottom: 265px !important; }

.u--mgl--265 {
  margin-left: 265px !important; }

.u--mgr--265 {
  margin-right: 265px !important; }

.u--pdt--265 {
  padding-top: 265px !important; }

.u--pdb--265 {
  padding-bottom: 265px !important; }

.u--pdl--265 {
  padding-left: 265px !important; }

.u--pdr--265 {
  padding-right: 265px !important; }

.u--mgt--270 {
  margin-top: 270px !important; }

.u--mgb--270 {
  margin-bottom: 270px !important; }

.u--mgl--270 {
  margin-left: 270px !important; }

.u--mgr--270 {
  margin-right: 270px !important; }

.u--pdt--270 {
  padding-top: 270px !important; }

.u--pdb--270 {
  padding-bottom: 270px !important; }

.u--pdl--270 {
  padding-left: 270px !important; }

.u--pdr--270 {
  padding-right: 270px !important; }

.u--mgt--275 {
  margin-top: 275px !important; }

.u--mgb--275 {
  margin-bottom: 275px !important; }

.u--mgl--275 {
  margin-left: 275px !important; }

.u--mgr--275 {
  margin-right: 275px !important; }

.u--pdt--275 {
  padding-top: 275px !important; }

.u--pdb--275 {
  padding-bottom: 275px !important; }

.u--pdl--275 {
  padding-left: 275px !important; }

.u--pdr--275 {
  padding-right: 275px !important; }

.u--mgt--280 {
  margin-top: 280px !important; }

.u--mgb--280 {
  margin-bottom: 280px !important; }

.u--mgl--280 {
  margin-left: 280px !important; }

.u--mgr--280 {
  margin-right: 280px !important; }

.u--pdt--280 {
  padding-top: 280px !important; }

.u--pdb--280 {
  padding-bottom: 280px !important; }

.u--pdl--280 {
  padding-left: 280px !important; }

.u--pdr--280 {
  padding-right: 280px !important; }

.u--mgt--285 {
  margin-top: 285px !important; }

.u--mgb--285 {
  margin-bottom: 285px !important; }

.u--mgl--285 {
  margin-left: 285px !important; }

.u--mgr--285 {
  margin-right: 285px !important; }

.u--pdt--285 {
  padding-top: 285px !important; }

.u--pdb--285 {
  padding-bottom: 285px !important; }

.u--pdl--285 {
  padding-left: 285px !important; }

.u--pdr--285 {
  padding-right: 285px !important; }

.u--mgt--290 {
  margin-top: 290px !important; }

.u--mgb--290 {
  margin-bottom: 290px !important; }

.u--mgl--290 {
  margin-left: 290px !important; }

.u--mgr--290 {
  margin-right: 290px !important; }

.u--pdt--290 {
  padding-top: 290px !important; }

.u--pdb--290 {
  padding-bottom: 290px !important; }

.u--pdl--290 {
  padding-left: 290px !important; }

.u--pdr--290 {
  padding-right: 290px !important; }

.u--mgt--295 {
  margin-top: 295px !important; }

.u--mgb--295 {
  margin-bottom: 295px !important; }

.u--mgl--295 {
  margin-left: 295px !important; }

.u--mgr--295 {
  margin-right: 295px !important; }

.u--pdt--295 {
  padding-top: 295px !important; }

.u--pdb--295 {
  padding-bottom: 295px !important; }

.u--pdl--295 {
  padding-left: 295px !important; }

.u--pdr--295 {
  padding-right: 295px !important; }

.u--mgt--300 {
  margin-top: 300px !important; }

.u--mgb--300 {
  margin-bottom: 300px !important; }

.u--mgl--300 {
  margin-left: 300px !important; }

.u--mgr--300 {
  margin-right: 300px !important; }

.u--pdt--300 {
  padding-top: 300px !important; }

.u--pdb--300 {
  padding-bottom: 300px !important; }

.u--pdl--300 {
  padding-left: 300px !important; }

.u--pdr--300 {
  padding-right: 300px !important; }

.u--mgt--305 {
  margin-top: 305px !important; }

.u--mgb--305 {
  margin-bottom: 305px !important; }

.u--mgl--305 {
  margin-left: 305px !important; }

.u--mgr--305 {
  margin-right: 305px !important; }

.u--pdt--305 {
  padding-top: 305px !important; }

.u--pdb--305 {
  padding-bottom: 305px !important; }

.u--pdl--305 {
  padding-left: 305px !important; }

.u--pdr--305 {
  padding-right: 305px !important; }

.u--mgt--310 {
  margin-top: 310px !important; }

.u--mgb--310 {
  margin-bottom: 310px !important; }

.u--mgl--310 {
  margin-left: 310px !important; }

.u--mgr--310 {
  margin-right: 310px !important; }

.u--pdt--310 {
  padding-top: 310px !important; }

.u--pdb--310 {
  padding-bottom: 310px !important; }

.u--pdl--310 {
  padding-left: 310px !important; }

.u--pdr--310 {
  padding-right: 310px !important; }

.u--mgt--315 {
  margin-top: 315px !important; }

.u--mgb--315 {
  margin-bottom: 315px !important; }

.u--mgl--315 {
  margin-left: 315px !important; }

.u--mgr--315 {
  margin-right: 315px !important; }

.u--pdt--315 {
  padding-top: 315px !important; }

.u--pdb--315 {
  padding-bottom: 315px !important; }

.u--pdl--315 {
  padding-left: 315px !important; }

.u--pdr--315 {
  padding-right: 315px !important; }

.u--mgt--320 {
  margin-top: 320px !important; }

.u--mgb--320 {
  margin-bottom: 320px !important; }

.u--mgl--320 {
  margin-left: 320px !important; }

.u--mgr--320 {
  margin-right: 320px !important; }

.u--pdt--320 {
  padding-top: 320px !important; }

.u--pdb--320 {
  padding-bottom: 320px !important; }

.u--pdl--320 {
  padding-left: 320px !important; }

.u--pdr--320 {
  padding-right: 320px !important; }

.u--mgt--325 {
  margin-top: 325px !important; }

.u--mgb--325 {
  margin-bottom: 325px !important; }

.u--mgl--325 {
  margin-left: 325px !important; }

.u--mgr--325 {
  margin-right: 325px !important; }

.u--pdt--325 {
  padding-top: 325px !important; }

.u--pdb--325 {
  padding-bottom: 325px !important; }

.u--pdl--325 {
  padding-left: 325px !important; }

.u--pdr--325 {
  padding-right: 325px !important; }

.u--mgt--330 {
  margin-top: 330px !important; }

.u--mgb--330 {
  margin-bottom: 330px !important; }

.u--mgl--330 {
  margin-left: 330px !important; }

.u--mgr--330 {
  margin-right: 330px !important; }

.u--pdt--330 {
  padding-top: 330px !important; }

.u--pdb--330 {
  padding-bottom: 330px !important; }

.u--pdl--330 {
  padding-left: 330px !important; }

.u--pdr--330 {
  padding-right: 330px !important; }

.u--mgt--335 {
  margin-top: 335px !important; }

.u--mgb--335 {
  margin-bottom: 335px !important; }

.u--mgl--335 {
  margin-left: 335px !important; }

.u--mgr--335 {
  margin-right: 335px !important; }

.u--pdt--335 {
  padding-top: 335px !important; }

.u--pdb--335 {
  padding-bottom: 335px !important; }

.u--pdl--335 {
  padding-left: 335px !important; }

.u--pdr--335 {
  padding-right: 335px !important; }

.u--mgt--340 {
  margin-top: 340px !important; }

.u--mgb--340 {
  margin-bottom: 340px !important; }

.u--mgl--340 {
  margin-left: 340px !important; }

.u--mgr--340 {
  margin-right: 340px !important; }

.u--pdt--340 {
  padding-top: 340px !important; }

.u--pdb--340 {
  padding-bottom: 340px !important; }

.u--pdl--340 {
  padding-left: 340px !important; }

.u--pdr--340 {
  padding-right: 340px !important; }

.u--mgt--345 {
  margin-top: 345px !important; }

.u--mgb--345 {
  margin-bottom: 345px !important; }

.u--mgl--345 {
  margin-left: 345px !important; }

.u--mgr--345 {
  margin-right: 345px !important; }

.u--pdt--345 {
  padding-top: 345px !important; }

.u--pdb--345 {
  padding-bottom: 345px !important; }

.u--pdl--345 {
  padding-left: 345px !important; }

.u--pdr--345 {
  padding-right: 345px !important; }

.u--mgt--350 {
  margin-top: 350px !important; }

.u--mgb--350 {
  margin-bottom: 350px !important; }

.u--mgl--350 {
  margin-left: 350px !important; }

.u--mgr--350 {
  margin-right: 350px !important; }

.u--pdt--350 {
  padding-top: 350px !important; }

.u--pdb--350 {
  padding-bottom: 350px !important; }

.u--pdl--350 {
  padding-left: 350px !important; }

.u--pdr--350 {
  padding-right: 350px !important; }

.u--mgt--355 {
  margin-top: 355px !important; }

.u--mgb--355 {
  margin-bottom: 355px !important; }

.u--mgl--355 {
  margin-left: 355px !important; }

.u--mgr--355 {
  margin-right: 355px !important; }

.u--pdt--355 {
  padding-top: 355px !important; }

.u--pdb--355 {
  padding-bottom: 355px !important; }

.u--pdl--355 {
  padding-left: 355px !important; }

.u--pdr--355 {
  padding-right: 355px !important; }

.u--mgt--360 {
  margin-top: 360px !important; }

.u--mgb--360 {
  margin-bottom: 360px !important; }

.u--mgl--360 {
  margin-left: 360px !important; }

.u--mgr--360 {
  margin-right: 360px !important; }

.u--pdt--360 {
  padding-top: 360px !important; }

.u--pdb--360 {
  padding-bottom: 360px !important; }

.u--pdl--360 {
  padding-left: 360px !important; }

.u--pdr--360 {
  padding-right: 360px !important; }

.u--mgt--365 {
  margin-top: 365px !important; }

.u--mgb--365 {
  margin-bottom: 365px !important; }

.u--mgl--365 {
  margin-left: 365px !important; }

.u--mgr--365 {
  margin-right: 365px !important; }

.u--pdt--365 {
  padding-top: 365px !important; }

.u--pdb--365 {
  padding-bottom: 365px !important; }

.u--pdl--365 {
  padding-left: 365px !important; }

.u--pdr--365 {
  padding-right: 365px !important; }

.u--mgt--370 {
  margin-top: 370px !important; }

.u--mgb--370 {
  margin-bottom: 370px !important; }

.u--mgl--370 {
  margin-left: 370px !important; }

.u--mgr--370 {
  margin-right: 370px !important; }

.u--pdt--370 {
  padding-top: 370px !important; }

.u--pdb--370 {
  padding-bottom: 370px !important; }

.u--pdl--370 {
  padding-left: 370px !important; }

.u--pdr--370 {
  padding-right: 370px !important; }

.u--mgt--375 {
  margin-top: 375px !important; }

.u--mgb--375 {
  margin-bottom: 375px !important; }

.u--mgl--375 {
  margin-left: 375px !important; }

.u--mgr--375 {
  margin-right: 375px !important; }

.u--pdt--375 {
  padding-top: 375px !important; }

.u--pdb--375 {
  padding-bottom: 375px !important; }

.u--pdl--375 {
  padding-left: 375px !important; }

.u--pdr--375 {
  padding-right: 375px !important; }

.u--mgt--380 {
  margin-top: 380px !important; }

.u--mgb--380 {
  margin-bottom: 380px !important; }

.u--mgl--380 {
  margin-left: 380px !important; }

.u--mgr--380 {
  margin-right: 380px !important; }

.u--pdt--380 {
  padding-top: 380px !important; }

.u--pdb--380 {
  padding-bottom: 380px !important; }

.u--pdl--380 {
  padding-left: 380px !important; }

.u--pdr--380 {
  padding-right: 380px !important; }

.u--mgt--385 {
  margin-top: 385px !important; }

.u--mgb--385 {
  margin-bottom: 385px !important; }

.u--mgl--385 {
  margin-left: 385px !important; }

.u--mgr--385 {
  margin-right: 385px !important; }

.u--pdt--385 {
  padding-top: 385px !important; }

.u--pdb--385 {
  padding-bottom: 385px !important; }

.u--pdl--385 {
  padding-left: 385px !important; }

.u--pdr--385 {
  padding-right: 385px !important; }

.u--mgt--390 {
  margin-top: 390px !important; }

.u--mgb--390 {
  margin-bottom: 390px !important; }

.u--mgl--390 {
  margin-left: 390px !important; }

.u--mgr--390 {
  margin-right: 390px !important; }

.u--pdt--390 {
  padding-top: 390px !important; }

.u--pdb--390 {
  padding-bottom: 390px !important; }

.u--pdl--390 {
  padding-left: 390px !important; }

.u--pdr--390 {
  padding-right: 390px !important; }

.u--mgt--395 {
  margin-top: 395px !important; }

.u--mgb--395 {
  margin-bottom: 395px !important; }

.u--mgl--395 {
  margin-left: 395px !important; }

.u--mgr--395 {
  margin-right: 395px !important; }

.u--pdt--395 {
  padding-top: 395px !important; }

.u--pdb--395 {
  padding-bottom: 395px !important; }

.u--pdl--395 {
  padding-left: 395px !important; }

.u--pdr--395 {
  padding-right: 395px !important; }

.u--mgt--400 {
  margin-top: 400px !important; }

.u--mgb--400 {
  margin-bottom: 400px !important; }

.u--mgl--400 {
  margin-left: 400px !important; }

.u--mgr--400 {
  margin-right: 400px !important; }

.u--pdt--400 {
  padding-top: 400px !important; }

.u--pdb--400 {
  padding-bottom: 400px !important; }

.u--pdl--400 {
  padding-left: 400px !important; }

.u--pdr--400 {
  padding-right: 400px !important; }

.u--mgt--405 {
  margin-top: 405px !important; }

.u--mgb--405 {
  margin-bottom: 405px !important; }

.u--mgl--405 {
  margin-left: 405px !important; }

.u--mgr--405 {
  margin-right: 405px !important; }

.u--pdt--405 {
  padding-top: 405px !important; }

.u--pdb--405 {
  padding-bottom: 405px !important; }

.u--pdl--405 {
  padding-left: 405px !important; }

.u--pdr--405 {
  padding-right: 405px !important; }

.u--mgt--410 {
  margin-top: 410px !important; }

.u--mgb--410 {
  margin-bottom: 410px !important; }

.u--mgl--410 {
  margin-left: 410px !important; }

.u--mgr--410 {
  margin-right: 410px !important; }

.u--pdt--410 {
  padding-top: 410px !important; }

.u--pdb--410 {
  padding-bottom: 410px !important; }

.u--pdl--410 {
  padding-left: 410px !important; }

.u--pdr--410 {
  padding-right: 410px !important; }

.u--mgt--415 {
  margin-top: 415px !important; }

.u--mgb--415 {
  margin-bottom: 415px !important; }

.u--mgl--415 {
  margin-left: 415px !important; }

.u--mgr--415 {
  margin-right: 415px !important; }

.u--pdt--415 {
  padding-top: 415px !important; }

.u--pdb--415 {
  padding-bottom: 415px !important; }

.u--pdl--415 {
  padding-left: 415px !important; }

.u--pdr--415 {
  padding-right: 415px !important; }

.u--mgt--420 {
  margin-top: 420px !important; }

.u--mgb--420 {
  margin-bottom: 420px !important; }

.u--mgl--420 {
  margin-left: 420px !important; }

.u--mgr--420 {
  margin-right: 420px !important; }

.u--pdt--420 {
  padding-top: 420px !important; }

.u--pdb--420 {
  padding-bottom: 420px !important; }

.u--pdl--420 {
  padding-left: 420px !important; }

.u--pdr--420 {
  padding-right: 420px !important; }

.u--mgt--425 {
  margin-top: 425px !important; }

.u--mgb--425 {
  margin-bottom: 425px !important; }

.u--mgl--425 {
  margin-left: 425px !important; }

.u--mgr--425 {
  margin-right: 425px !important; }

.u--pdt--425 {
  padding-top: 425px !important; }

.u--pdb--425 {
  padding-bottom: 425px !important; }

.u--pdl--425 {
  padding-left: 425px !important; }

.u--pdr--425 {
  padding-right: 425px !important; }

.u--mgt--430 {
  margin-top: 430px !important; }

.u--mgb--430 {
  margin-bottom: 430px !important; }

.u--mgl--430 {
  margin-left: 430px !important; }

.u--mgr--430 {
  margin-right: 430px !important; }

.u--pdt--430 {
  padding-top: 430px !important; }

.u--pdb--430 {
  padding-bottom: 430px !important; }

.u--pdl--430 {
  padding-left: 430px !important; }

.u--pdr--430 {
  padding-right: 430px !important; }

.u--mgt--435 {
  margin-top: 435px !important; }

.u--mgb--435 {
  margin-bottom: 435px !important; }

.u--mgl--435 {
  margin-left: 435px !important; }

.u--mgr--435 {
  margin-right: 435px !important; }

.u--pdt--435 {
  padding-top: 435px !important; }

.u--pdb--435 {
  padding-bottom: 435px !important; }

.u--pdl--435 {
  padding-left: 435px !important; }

.u--pdr--435 {
  padding-right: 435px !important; }

.u--mgt--440 {
  margin-top: 440px !important; }

.u--mgb--440 {
  margin-bottom: 440px !important; }

.u--mgl--440 {
  margin-left: 440px !important; }

.u--mgr--440 {
  margin-right: 440px !important; }

.u--pdt--440 {
  padding-top: 440px !important; }

.u--pdb--440 {
  padding-bottom: 440px !important; }

.u--pdl--440 {
  padding-left: 440px !important; }

.u--pdr--440 {
  padding-right: 440px !important; }

.u--mgt--445 {
  margin-top: 445px !important; }

.u--mgb--445 {
  margin-bottom: 445px !important; }

.u--mgl--445 {
  margin-left: 445px !important; }

.u--mgr--445 {
  margin-right: 445px !important; }

.u--pdt--445 {
  padding-top: 445px !important; }

.u--pdb--445 {
  padding-bottom: 445px !important; }

.u--pdl--445 {
  padding-left: 445px !important; }

.u--pdr--445 {
  padding-right: 445px !important; }

.u--mgt--450 {
  margin-top: 450px !important; }

.u--mgb--450 {
  margin-bottom: 450px !important; }

.u--mgl--450 {
  margin-left: 450px !important; }

.u--mgr--450 {
  margin-right: 450px !important; }

.u--pdt--450 {
  padding-top: 450px !important; }

.u--pdb--450 {
  padding-bottom: 450px !important; }

.u--pdl--450 {
  padding-left: 450px !important; }

.u--pdr--450 {
  padding-right: 450px !important; }

.u--mgt--455 {
  margin-top: 455px !important; }

.u--mgb--455 {
  margin-bottom: 455px !important; }

.u--mgl--455 {
  margin-left: 455px !important; }

.u--mgr--455 {
  margin-right: 455px !important; }

.u--pdt--455 {
  padding-top: 455px !important; }

.u--pdb--455 {
  padding-bottom: 455px !important; }

.u--pdl--455 {
  padding-left: 455px !important; }

.u--pdr--455 {
  padding-right: 455px !important; }

.u--mgt--460 {
  margin-top: 460px !important; }

.u--mgb--460 {
  margin-bottom: 460px !important; }

.u--mgl--460 {
  margin-left: 460px !important; }

.u--mgr--460 {
  margin-right: 460px !important; }

.u--pdt--460 {
  padding-top: 460px !important; }

.u--pdb--460 {
  padding-bottom: 460px !important; }

.u--pdl--460 {
  padding-left: 460px !important; }

.u--pdr--460 {
  padding-right: 460px !important; }

.u--mgt--465 {
  margin-top: 465px !important; }

.u--mgb--465 {
  margin-bottom: 465px !important; }

.u--mgl--465 {
  margin-left: 465px !important; }

.u--mgr--465 {
  margin-right: 465px !important; }

.u--pdt--465 {
  padding-top: 465px !important; }

.u--pdb--465 {
  padding-bottom: 465px !important; }

.u--pdl--465 {
  padding-left: 465px !important; }

.u--pdr--465 {
  padding-right: 465px !important; }

.u--mgt--470 {
  margin-top: 470px !important; }

.u--mgb--470 {
  margin-bottom: 470px !important; }

.u--mgl--470 {
  margin-left: 470px !important; }

.u--mgr--470 {
  margin-right: 470px !important; }

.u--pdt--470 {
  padding-top: 470px !important; }

.u--pdb--470 {
  padding-bottom: 470px !important; }

.u--pdl--470 {
  padding-left: 470px !important; }

.u--pdr--470 {
  padding-right: 470px !important; }

.u--mgt--475 {
  margin-top: 475px !important; }

.u--mgb--475 {
  margin-bottom: 475px !important; }

.u--mgl--475 {
  margin-left: 475px !important; }

.u--mgr--475 {
  margin-right: 475px !important; }

.u--pdt--475 {
  padding-top: 475px !important; }

.u--pdb--475 {
  padding-bottom: 475px !important; }

.u--pdl--475 {
  padding-left: 475px !important; }

.u--pdr--475 {
  padding-right: 475px !important; }

.u--mgt--480 {
  margin-top: 480px !important; }

.u--mgb--480 {
  margin-bottom: 480px !important; }

.u--mgl--480 {
  margin-left: 480px !important; }

.u--mgr--480 {
  margin-right: 480px !important; }

.u--pdt--480 {
  padding-top: 480px !important; }

.u--pdb--480 {
  padding-bottom: 480px !important; }

.u--pdl--480 {
  padding-left: 480px !important; }

.u--pdr--480 {
  padding-right: 480px !important; }

.u--mgt--485 {
  margin-top: 485px !important; }

.u--mgb--485 {
  margin-bottom: 485px !important; }

.u--mgl--485 {
  margin-left: 485px !important; }

.u--mgr--485 {
  margin-right: 485px !important; }

.u--pdt--485 {
  padding-top: 485px !important; }

.u--pdb--485 {
  padding-bottom: 485px !important; }

.u--pdl--485 {
  padding-left: 485px !important; }

.u--pdr--485 {
  padding-right: 485px !important; }

.u--mgt--490 {
  margin-top: 490px !important; }

.u--mgb--490 {
  margin-bottom: 490px !important; }

.u--mgl--490 {
  margin-left: 490px !important; }

.u--mgr--490 {
  margin-right: 490px !important; }

.u--pdt--490 {
  padding-top: 490px !important; }

.u--pdb--490 {
  padding-bottom: 490px !important; }

.u--pdl--490 {
  padding-left: 490px !important; }

.u--pdr--490 {
  padding-right: 490px !important; }

.u--mgt--495 {
  margin-top: 495px !important; }

.u--mgb--495 {
  margin-bottom: 495px !important; }

.u--mgl--495 {
  margin-left: 495px !important; }

.u--mgr--495 {
  margin-right: 495px !important; }

.u--pdt--495 {
  padding-top: 495px !important; }

.u--pdb--495 {
  padding-bottom: 495px !important; }

.u--pdl--495 {
  padding-left: 495px !important; }

.u--pdr--495 {
  padding-right: 495px !important; }

.u--mgt--500 {
  margin-top: 500px !important; }

.u--mgb--500 {
  margin-bottom: 500px !important; }

.u--mgl--500 {
  margin-left: 500px !important; }

.u--mgr--500 {
  margin-right: 500px !important; }

.u--pdt--500 {
  padding-top: 500px !important; }

.u--pdb--500 {
  padding-bottom: 500px !important; }

.u--pdl--500 {
  padding-left: 500px !important; }

.u--pdr--500 {
  padding-right: 500px !important; }

@media screen and (max-width: 768px) {
  .u--mgt--5 {
    margin-top: 0.65104vw !important; }
  .u--mgb--5 {
    margin-bottom: 0.65104vw !important; }
  .u--mgl--5 {
    margin-left: 0.65104vw !important; }
  .u--mgr--5 {
    margin-right: 0.65104vw !important; }
  .u--pdt--5 {
    padding-top: 0.65104vw !important; }
  .u--pdb--5 {
    padding-bottom: 0.65104vw !important; }
  .u--pdl--5 {
    padding-left: 0.65104vw !important; }
  .u--pdr--5 {
    padding-right: 0.65104vw !important; }
  .u--mgt--10 {
    margin-top: 1.30208vw !important; }
  .u--mgb--10 {
    margin-bottom: 1.30208vw !important; }
  .u--mgl--10 {
    margin-left: 1.30208vw !important; }
  .u--mgr--10 {
    margin-right: 1.30208vw !important; }
  .u--pdt--10 {
    padding-top: 1.30208vw !important; }
  .u--pdb--10 {
    padding-bottom: 1.30208vw !important; }
  .u--pdl--10 {
    padding-left: 1.30208vw !important; }
  .u--pdr--10 {
    padding-right: 1.30208vw !important; }
  .u--mgt--15 {
    margin-top: 1.95313vw !important; }
  .u--mgb--15 {
    margin-bottom: 1.95313vw !important; }
  .u--mgl--15 {
    margin-left: 1.95313vw !important; }
  .u--mgr--15 {
    margin-right: 1.95313vw !important; }
  .u--pdt--15 {
    padding-top: 1.95313vw !important; }
  .u--pdb--15 {
    padding-bottom: 1.95313vw !important; }
  .u--pdl--15 {
    padding-left: 1.95313vw !important; }
  .u--pdr--15 {
    padding-right: 1.95313vw !important; }
  .u--mgt--20 {
    margin-top: 2.60417vw !important; }
  .u--mgb--20 {
    margin-bottom: 2.60417vw !important; }
  .u--mgl--20 {
    margin-left: 2.60417vw !important; }
  .u--mgr--20 {
    margin-right: 2.60417vw !important; }
  .u--pdt--20 {
    padding-top: 2.60417vw !important; }
  .u--pdb--20 {
    padding-bottom: 2.60417vw !important; }
  .u--pdl--20 {
    padding-left: 2.60417vw !important; }
  .u--pdr--20 {
    padding-right: 2.60417vw !important; }
  .u--mgt--25 {
    margin-top: 3.25521vw !important; }
  .u--mgb--25 {
    margin-bottom: 3.25521vw !important; }
  .u--mgl--25 {
    margin-left: 3.25521vw !important; }
  .u--mgr--25 {
    margin-right: 3.25521vw !important; }
  .u--pdt--25 {
    padding-top: 3.25521vw !important; }
  .u--pdb--25 {
    padding-bottom: 3.25521vw !important; }
  .u--pdl--25 {
    padding-left: 3.25521vw !important; }
  .u--pdr--25 {
    padding-right: 3.25521vw !important; }
  .u--mgt--30 {
    margin-top: 3.90625vw !important; }
  .u--mgb--30 {
    margin-bottom: 3.90625vw !important; }
  .u--mgl--30 {
    margin-left: 3.90625vw !important; }
  .u--mgr--30 {
    margin-right: 3.90625vw !important; }
  .u--pdt--30 {
    padding-top: 3.90625vw !important; }
  .u--pdb--30 {
    padding-bottom: 3.90625vw !important; }
  .u--pdl--30 {
    padding-left: 3.90625vw !important; }
  .u--pdr--30 {
    padding-right: 3.90625vw !important; }
  .u--mgt--35 {
    margin-top: 4.55729vw !important; }
  .u--mgb--35 {
    margin-bottom: 4.55729vw !important; }
  .u--mgl--35 {
    margin-left: 4.55729vw !important; }
  .u--mgr--35 {
    margin-right: 4.55729vw !important; }
  .u--pdt--35 {
    padding-top: 4.55729vw !important; }
  .u--pdb--35 {
    padding-bottom: 4.55729vw !important; }
  .u--pdl--35 {
    padding-left: 4.55729vw !important; }
  .u--pdr--35 {
    padding-right: 4.55729vw !important; }
  .u--mgt--40 {
    margin-top: 5.20833vw !important; }
  .u--mgb--40 {
    margin-bottom: 5.20833vw !important; }
  .u--mgl--40 {
    margin-left: 5.20833vw !important; }
  .u--mgr--40 {
    margin-right: 5.20833vw !important; }
  .u--pdt--40 {
    padding-top: 5.20833vw !important; }
  .u--pdb--40 {
    padding-bottom: 5.20833vw !important; }
  .u--pdl--40 {
    padding-left: 5.20833vw !important; }
  .u--pdr--40 {
    padding-right: 5.20833vw !important; }
  .u--mgt--45 {
    margin-top: 5.85938vw !important; }
  .u--mgb--45 {
    margin-bottom: 5.85938vw !important; }
  .u--mgl--45 {
    margin-left: 5.85938vw !important; }
  .u--mgr--45 {
    margin-right: 5.85938vw !important; }
  .u--pdt--45 {
    padding-top: 5.85938vw !important; }
  .u--pdb--45 {
    padding-bottom: 5.85938vw !important; }
  .u--pdl--45 {
    padding-left: 5.85938vw !important; }
  .u--pdr--45 {
    padding-right: 5.85938vw !important; }
  .u--mgt--50 {
    margin-top: 6.51042vw !important; }
  .u--mgb--50 {
    margin-bottom: 6.51042vw !important; }
  .u--mgl--50 {
    margin-left: 6.51042vw !important; }
  .u--mgr--50 {
    margin-right: 6.51042vw !important; }
  .u--pdt--50 {
    padding-top: 6.51042vw !important; }
  .u--pdb--50 {
    padding-bottom: 6.51042vw !important; }
  .u--pdl--50 {
    padding-left: 6.51042vw !important; }
  .u--pdr--50 {
    padding-right: 6.51042vw !important; }
  .u--mgt--55 {
    margin-top: 7.16146vw !important; }
  .u--mgb--55 {
    margin-bottom: 7.16146vw !important; }
  .u--mgl--55 {
    margin-left: 7.16146vw !important; }
  .u--mgr--55 {
    margin-right: 7.16146vw !important; }
  .u--pdt--55 {
    padding-top: 7.16146vw !important; }
  .u--pdb--55 {
    padding-bottom: 7.16146vw !important; }
  .u--pdl--55 {
    padding-left: 7.16146vw !important; }
  .u--pdr--55 {
    padding-right: 7.16146vw !important; }
  .u--mgt--60 {
    margin-top: 7.8125vw !important; }
  .u--mgb--60 {
    margin-bottom: 7.8125vw !important; }
  .u--mgl--60 {
    margin-left: 7.8125vw !important; }
  .u--mgr--60 {
    margin-right: 7.8125vw !important; }
  .u--pdt--60 {
    padding-top: 7.8125vw !important; }
  .u--pdb--60 {
    padding-bottom: 7.8125vw !important; }
  .u--pdl--60 {
    padding-left: 7.8125vw !important; }
  .u--pdr--60 {
    padding-right: 7.8125vw !important; }
  .u--mgt--65 {
    margin-top: 8.46354vw !important; }
  .u--mgb--65 {
    margin-bottom: 8.46354vw !important; }
  .u--mgl--65 {
    margin-left: 8.46354vw !important; }
  .u--mgr--65 {
    margin-right: 8.46354vw !important; }
  .u--pdt--65 {
    padding-top: 8.46354vw !important; }
  .u--pdb--65 {
    padding-bottom: 8.46354vw !important; }
  .u--pdl--65 {
    padding-left: 8.46354vw !important; }
  .u--pdr--65 {
    padding-right: 8.46354vw !important; }
  .u--mgt--70 {
    margin-top: 9.11458vw !important; }
  .u--mgb--70 {
    margin-bottom: 9.11458vw !important; }
  .u--mgl--70 {
    margin-left: 9.11458vw !important; }
  .u--mgr--70 {
    margin-right: 9.11458vw !important; }
  .u--pdt--70 {
    padding-top: 9.11458vw !important; }
  .u--pdb--70 {
    padding-bottom: 9.11458vw !important; }
  .u--pdl--70 {
    padding-left: 9.11458vw !important; }
  .u--pdr--70 {
    padding-right: 9.11458vw !important; }
  .u--mgt--75 {
    margin-top: 9.76562vw !important; }
  .u--mgb--75 {
    margin-bottom: 9.76562vw !important; }
  .u--mgl--75 {
    margin-left: 9.76562vw !important; }
  .u--mgr--75 {
    margin-right: 9.76562vw !important; }
  .u--pdt--75 {
    padding-top: 9.76562vw !important; }
  .u--pdb--75 {
    padding-bottom: 9.76562vw !important; }
  .u--pdl--75 {
    padding-left: 9.76562vw !important; }
  .u--pdr--75 {
    padding-right: 9.76562vw !important; }
  .u--mgt--80 {
    margin-top: 10.41667vw !important; }
  .u--mgb--80 {
    margin-bottom: 10.41667vw !important; }
  .u--mgl--80 {
    margin-left: 10.41667vw !important; }
  .u--mgr--80 {
    margin-right: 10.41667vw !important; }
  .u--pdt--80 {
    padding-top: 10.41667vw !important; }
  .u--pdb--80 {
    padding-bottom: 10.41667vw !important; }
  .u--pdl--80 {
    padding-left: 10.41667vw !important; }
  .u--pdr--80 {
    padding-right: 10.41667vw !important; }
  .u--mgt--85 {
    margin-top: 11.06771vw !important; }
  .u--mgb--85 {
    margin-bottom: 11.06771vw !important; }
  .u--mgl--85 {
    margin-left: 11.06771vw !important; }
  .u--mgr--85 {
    margin-right: 11.06771vw !important; }
  .u--pdt--85 {
    padding-top: 11.06771vw !important; }
  .u--pdb--85 {
    padding-bottom: 11.06771vw !important; }
  .u--pdl--85 {
    padding-left: 11.06771vw !important; }
  .u--pdr--85 {
    padding-right: 11.06771vw !important; }
  .u--mgt--90 {
    margin-top: 11.71875vw !important; }
  .u--mgb--90 {
    margin-bottom: 11.71875vw !important; }
  .u--mgl--90 {
    margin-left: 11.71875vw !important; }
  .u--mgr--90 {
    margin-right: 11.71875vw !important; }
  .u--pdt--90 {
    padding-top: 11.71875vw !important; }
  .u--pdb--90 {
    padding-bottom: 11.71875vw !important; }
  .u--pdl--90 {
    padding-left: 11.71875vw !important; }
  .u--pdr--90 {
    padding-right: 11.71875vw !important; }
  .u--mgt--95 {
    margin-top: 12.36979vw !important; }
  .u--mgb--95 {
    margin-bottom: 12.36979vw !important; }
  .u--mgl--95 {
    margin-left: 12.36979vw !important; }
  .u--mgr--95 {
    margin-right: 12.36979vw !important; }
  .u--pdt--95 {
    padding-top: 12.36979vw !important; }
  .u--pdb--95 {
    padding-bottom: 12.36979vw !important; }
  .u--pdl--95 {
    padding-left: 12.36979vw !important; }
  .u--pdr--95 {
    padding-right: 12.36979vw !important; }
  .u--mgt--100 {
    margin-top: 13.02083vw !important; }
  .u--mgb--100 {
    margin-bottom: 13.02083vw !important; }
  .u--mgl--100 {
    margin-left: 13.02083vw !important; }
  .u--mgr--100 {
    margin-right: 13.02083vw !important; }
  .u--pdt--100 {
    padding-top: 13.02083vw !important; }
  .u--pdb--100 {
    padding-bottom: 13.02083vw !important; }
  .u--pdl--100 {
    padding-left: 13.02083vw !important; }
  .u--pdr--100 {
    padding-right: 13.02083vw !important; }
  .u--mgt--105 {
    margin-top: 13.67188vw !important; }
  .u--mgb--105 {
    margin-bottom: 13.67188vw !important; }
  .u--mgl--105 {
    margin-left: 13.67188vw !important; }
  .u--mgr--105 {
    margin-right: 13.67188vw !important; }
  .u--pdt--105 {
    padding-top: 13.67188vw !important; }
  .u--pdb--105 {
    padding-bottom: 13.67188vw !important; }
  .u--pdl--105 {
    padding-left: 13.67188vw !important; }
  .u--pdr--105 {
    padding-right: 13.67188vw !important; }
  .u--mgt--110 {
    margin-top: 14.32292vw !important; }
  .u--mgb--110 {
    margin-bottom: 14.32292vw !important; }
  .u--mgl--110 {
    margin-left: 14.32292vw !important; }
  .u--mgr--110 {
    margin-right: 14.32292vw !important; }
  .u--pdt--110 {
    padding-top: 14.32292vw !important; }
  .u--pdb--110 {
    padding-bottom: 14.32292vw !important; }
  .u--pdl--110 {
    padding-left: 14.32292vw !important; }
  .u--pdr--110 {
    padding-right: 14.32292vw !important; }
  .u--mgt--115 {
    margin-top: 14.97396vw !important; }
  .u--mgb--115 {
    margin-bottom: 14.97396vw !important; }
  .u--mgl--115 {
    margin-left: 14.97396vw !important; }
  .u--mgr--115 {
    margin-right: 14.97396vw !important; }
  .u--pdt--115 {
    padding-top: 14.97396vw !important; }
  .u--pdb--115 {
    padding-bottom: 14.97396vw !important; }
  .u--pdl--115 {
    padding-left: 14.97396vw !important; }
  .u--pdr--115 {
    padding-right: 14.97396vw !important; }
  .u--mgt--120 {
    margin-top: 15.625vw !important; }
  .u--mgb--120 {
    margin-bottom: 15.625vw !important; }
  .u--mgl--120 {
    margin-left: 15.625vw !important; }
  .u--mgr--120 {
    margin-right: 15.625vw !important; }
  .u--pdt--120 {
    padding-top: 15.625vw !important; }
  .u--pdb--120 {
    padding-bottom: 15.625vw !important; }
  .u--pdl--120 {
    padding-left: 15.625vw !important; }
  .u--pdr--120 {
    padding-right: 15.625vw !important; }
  .u--mgt--125 {
    margin-top: 16.27604vw !important; }
  .u--mgb--125 {
    margin-bottom: 16.27604vw !important; }
  .u--mgl--125 {
    margin-left: 16.27604vw !important; }
  .u--mgr--125 {
    margin-right: 16.27604vw !important; }
  .u--pdt--125 {
    padding-top: 16.27604vw !important; }
  .u--pdb--125 {
    padding-bottom: 16.27604vw !important; }
  .u--pdl--125 {
    padding-left: 16.27604vw !important; }
  .u--pdr--125 {
    padding-right: 16.27604vw !important; }
  .u--mgt--130 {
    margin-top: 16.92708vw !important; }
  .u--mgb--130 {
    margin-bottom: 16.92708vw !important; }
  .u--mgl--130 {
    margin-left: 16.92708vw !important; }
  .u--mgr--130 {
    margin-right: 16.92708vw !important; }
  .u--pdt--130 {
    padding-top: 16.92708vw !important; }
  .u--pdb--130 {
    padding-bottom: 16.92708vw !important; }
  .u--pdl--130 {
    padding-left: 16.92708vw !important; }
  .u--pdr--130 {
    padding-right: 16.92708vw !important; }
  .u--mgt--135 {
    margin-top: 17.57812vw !important; }
  .u--mgb--135 {
    margin-bottom: 17.57812vw !important; }
  .u--mgl--135 {
    margin-left: 17.57812vw !important; }
  .u--mgr--135 {
    margin-right: 17.57812vw !important; }
  .u--pdt--135 {
    padding-top: 17.57812vw !important; }
  .u--pdb--135 {
    padding-bottom: 17.57812vw !important; }
  .u--pdl--135 {
    padding-left: 17.57812vw !important; }
  .u--pdr--135 {
    padding-right: 17.57812vw !important; }
  .u--mgt--140 {
    margin-top: 18.22917vw !important; }
  .u--mgb--140 {
    margin-bottom: 18.22917vw !important; }
  .u--mgl--140 {
    margin-left: 18.22917vw !important; }
  .u--mgr--140 {
    margin-right: 18.22917vw !important; }
  .u--pdt--140 {
    padding-top: 18.22917vw !important; }
  .u--pdb--140 {
    padding-bottom: 18.22917vw !important; }
  .u--pdl--140 {
    padding-left: 18.22917vw !important; }
  .u--pdr--140 {
    padding-right: 18.22917vw !important; }
  .u--mgt--145 {
    margin-top: 18.88021vw !important; }
  .u--mgb--145 {
    margin-bottom: 18.88021vw !important; }
  .u--mgl--145 {
    margin-left: 18.88021vw !important; }
  .u--mgr--145 {
    margin-right: 18.88021vw !important; }
  .u--pdt--145 {
    padding-top: 18.88021vw !important; }
  .u--pdb--145 {
    padding-bottom: 18.88021vw !important; }
  .u--pdl--145 {
    padding-left: 18.88021vw !important; }
  .u--pdr--145 {
    padding-right: 18.88021vw !important; }
  .u--mgt--150 {
    margin-top: 19.53125vw !important; }
  .u--mgb--150 {
    margin-bottom: 19.53125vw !important; }
  .u--mgl--150 {
    margin-left: 19.53125vw !important; }
  .u--mgr--150 {
    margin-right: 19.53125vw !important; }
  .u--pdt--150 {
    padding-top: 19.53125vw !important; }
  .u--pdb--150 {
    padding-bottom: 19.53125vw !important; }
  .u--pdl--150 {
    padding-left: 19.53125vw !important; }
  .u--pdr--150 {
    padding-right: 19.53125vw !important; }
  .u--mgt--155 {
    margin-top: 20.18229vw !important; }
  .u--mgb--155 {
    margin-bottom: 20.18229vw !important; }
  .u--mgl--155 {
    margin-left: 20.18229vw !important; }
  .u--mgr--155 {
    margin-right: 20.18229vw !important; }
  .u--pdt--155 {
    padding-top: 20.18229vw !important; }
  .u--pdb--155 {
    padding-bottom: 20.18229vw !important; }
  .u--pdl--155 {
    padding-left: 20.18229vw !important; }
  .u--pdr--155 {
    padding-right: 20.18229vw !important; }
  .u--mgt--160 {
    margin-top: 20.83333vw !important; }
  .u--mgb--160 {
    margin-bottom: 20.83333vw !important; }
  .u--mgl--160 {
    margin-left: 20.83333vw !important; }
  .u--mgr--160 {
    margin-right: 20.83333vw !important; }
  .u--pdt--160 {
    padding-top: 20.83333vw !important; }
  .u--pdb--160 {
    padding-bottom: 20.83333vw !important; }
  .u--pdl--160 {
    padding-left: 20.83333vw !important; }
  .u--pdr--160 {
    padding-right: 20.83333vw !important; }
  .u--mgt--165 {
    margin-top: 21.48438vw !important; }
  .u--mgb--165 {
    margin-bottom: 21.48438vw !important; }
  .u--mgl--165 {
    margin-left: 21.48438vw !important; }
  .u--mgr--165 {
    margin-right: 21.48438vw !important; }
  .u--pdt--165 {
    padding-top: 21.48438vw !important; }
  .u--pdb--165 {
    padding-bottom: 21.48438vw !important; }
  .u--pdl--165 {
    padding-left: 21.48438vw !important; }
  .u--pdr--165 {
    padding-right: 21.48438vw !important; }
  .u--mgt--170 {
    margin-top: 22.13542vw !important; }
  .u--mgb--170 {
    margin-bottom: 22.13542vw !important; }
  .u--mgl--170 {
    margin-left: 22.13542vw !important; }
  .u--mgr--170 {
    margin-right: 22.13542vw !important; }
  .u--pdt--170 {
    padding-top: 22.13542vw !important; }
  .u--pdb--170 {
    padding-bottom: 22.13542vw !important; }
  .u--pdl--170 {
    padding-left: 22.13542vw !important; }
  .u--pdr--170 {
    padding-right: 22.13542vw !important; }
  .u--mgt--175 {
    margin-top: 22.78646vw !important; }
  .u--mgb--175 {
    margin-bottom: 22.78646vw !important; }
  .u--mgl--175 {
    margin-left: 22.78646vw !important; }
  .u--mgr--175 {
    margin-right: 22.78646vw !important; }
  .u--pdt--175 {
    padding-top: 22.78646vw !important; }
  .u--pdb--175 {
    padding-bottom: 22.78646vw !important; }
  .u--pdl--175 {
    padding-left: 22.78646vw !important; }
  .u--pdr--175 {
    padding-right: 22.78646vw !important; }
  .u--mgt--180 {
    margin-top: 23.4375vw !important; }
  .u--mgb--180 {
    margin-bottom: 23.4375vw !important; }
  .u--mgl--180 {
    margin-left: 23.4375vw !important; }
  .u--mgr--180 {
    margin-right: 23.4375vw !important; }
  .u--pdt--180 {
    padding-top: 23.4375vw !important; }
  .u--pdb--180 {
    padding-bottom: 23.4375vw !important; }
  .u--pdl--180 {
    padding-left: 23.4375vw !important; }
  .u--pdr--180 {
    padding-right: 23.4375vw !important; }
  .u--mgt--185 {
    margin-top: 24.08854vw !important; }
  .u--mgb--185 {
    margin-bottom: 24.08854vw !important; }
  .u--mgl--185 {
    margin-left: 24.08854vw !important; }
  .u--mgr--185 {
    margin-right: 24.08854vw !important; }
  .u--pdt--185 {
    padding-top: 24.08854vw !important; }
  .u--pdb--185 {
    padding-bottom: 24.08854vw !important; }
  .u--pdl--185 {
    padding-left: 24.08854vw !important; }
  .u--pdr--185 {
    padding-right: 24.08854vw !important; }
  .u--mgt--190 {
    margin-top: 24.73958vw !important; }
  .u--mgb--190 {
    margin-bottom: 24.73958vw !important; }
  .u--mgl--190 {
    margin-left: 24.73958vw !important; }
  .u--mgr--190 {
    margin-right: 24.73958vw !important; }
  .u--pdt--190 {
    padding-top: 24.73958vw !important; }
  .u--pdb--190 {
    padding-bottom: 24.73958vw !important; }
  .u--pdl--190 {
    padding-left: 24.73958vw !important; }
  .u--pdr--190 {
    padding-right: 24.73958vw !important; }
  .u--mgt--195 {
    margin-top: 25.39063vw !important; }
  .u--mgb--195 {
    margin-bottom: 25.39063vw !important; }
  .u--mgl--195 {
    margin-left: 25.39063vw !important; }
  .u--mgr--195 {
    margin-right: 25.39063vw !important; }
  .u--pdt--195 {
    padding-top: 25.39063vw !important; }
  .u--pdb--195 {
    padding-bottom: 25.39063vw !important; }
  .u--pdl--195 {
    padding-left: 25.39063vw !important; }
  .u--pdr--195 {
    padding-right: 25.39063vw !important; }
  .u--mgt--200 {
    margin-top: 26.04167vw !important; }
  .u--mgb--200 {
    margin-bottom: 26.04167vw !important; }
  .u--mgl--200 {
    margin-left: 26.04167vw !important; }
  .u--mgr--200 {
    margin-right: 26.04167vw !important; }
  .u--pdt--200 {
    padding-top: 26.04167vw !important; }
  .u--pdb--200 {
    padding-bottom: 26.04167vw !important; }
  .u--pdl--200 {
    padding-left: 26.04167vw !important; }
  .u--pdr--200 {
    padding-right: 26.04167vw !important; }
  .u--mgt--205 {
    margin-top: 26.69271vw !important; }
  .u--mgb--205 {
    margin-bottom: 26.69271vw !important; }
  .u--mgl--205 {
    margin-left: 26.69271vw !important; }
  .u--mgr--205 {
    margin-right: 26.69271vw !important; }
  .u--pdt--205 {
    padding-top: 26.69271vw !important; }
  .u--pdb--205 {
    padding-bottom: 26.69271vw !important; }
  .u--pdl--205 {
    padding-left: 26.69271vw !important; }
  .u--pdr--205 {
    padding-right: 26.69271vw !important; }
  .u--mgt--210 {
    margin-top: 27.34375vw !important; }
  .u--mgb--210 {
    margin-bottom: 27.34375vw !important; }
  .u--mgl--210 {
    margin-left: 27.34375vw !important; }
  .u--mgr--210 {
    margin-right: 27.34375vw !important; }
  .u--pdt--210 {
    padding-top: 27.34375vw !important; }
  .u--pdb--210 {
    padding-bottom: 27.34375vw !important; }
  .u--pdl--210 {
    padding-left: 27.34375vw !important; }
  .u--pdr--210 {
    padding-right: 27.34375vw !important; }
  .u--mgt--215 {
    margin-top: 27.99479vw !important; }
  .u--mgb--215 {
    margin-bottom: 27.99479vw !important; }
  .u--mgl--215 {
    margin-left: 27.99479vw !important; }
  .u--mgr--215 {
    margin-right: 27.99479vw !important; }
  .u--pdt--215 {
    padding-top: 27.99479vw !important; }
  .u--pdb--215 {
    padding-bottom: 27.99479vw !important; }
  .u--pdl--215 {
    padding-left: 27.99479vw !important; }
  .u--pdr--215 {
    padding-right: 27.99479vw !important; }
  .u--mgt--220 {
    margin-top: 28.64583vw !important; }
  .u--mgb--220 {
    margin-bottom: 28.64583vw !important; }
  .u--mgl--220 {
    margin-left: 28.64583vw !important; }
  .u--mgr--220 {
    margin-right: 28.64583vw !important; }
  .u--pdt--220 {
    padding-top: 28.64583vw !important; }
  .u--pdb--220 {
    padding-bottom: 28.64583vw !important; }
  .u--pdl--220 {
    padding-left: 28.64583vw !important; }
  .u--pdr--220 {
    padding-right: 28.64583vw !important; }
  .u--mgt--225 {
    margin-top: 29.29688vw !important; }
  .u--mgb--225 {
    margin-bottom: 29.29688vw !important; }
  .u--mgl--225 {
    margin-left: 29.29688vw !important; }
  .u--mgr--225 {
    margin-right: 29.29688vw !important; }
  .u--pdt--225 {
    padding-top: 29.29688vw !important; }
  .u--pdb--225 {
    padding-bottom: 29.29688vw !important; }
  .u--pdl--225 {
    padding-left: 29.29688vw !important; }
  .u--pdr--225 {
    padding-right: 29.29688vw !important; }
  .u--mgt--230 {
    margin-top: 29.94792vw !important; }
  .u--mgb--230 {
    margin-bottom: 29.94792vw !important; }
  .u--mgl--230 {
    margin-left: 29.94792vw !important; }
  .u--mgr--230 {
    margin-right: 29.94792vw !important; }
  .u--pdt--230 {
    padding-top: 29.94792vw !important; }
  .u--pdb--230 {
    padding-bottom: 29.94792vw !important; }
  .u--pdl--230 {
    padding-left: 29.94792vw !important; }
  .u--pdr--230 {
    padding-right: 29.94792vw !important; }
  .u--mgt--235 {
    margin-top: 30.59896vw !important; }
  .u--mgb--235 {
    margin-bottom: 30.59896vw !important; }
  .u--mgl--235 {
    margin-left: 30.59896vw !important; }
  .u--mgr--235 {
    margin-right: 30.59896vw !important; }
  .u--pdt--235 {
    padding-top: 30.59896vw !important; }
  .u--pdb--235 {
    padding-bottom: 30.59896vw !important; }
  .u--pdl--235 {
    padding-left: 30.59896vw !important; }
  .u--pdr--235 {
    padding-right: 30.59896vw !important; }
  .u--mgt--240 {
    margin-top: 31.25vw !important; }
  .u--mgb--240 {
    margin-bottom: 31.25vw !important; }
  .u--mgl--240 {
    margin-left: 31.25vw !important; }
  .u--mgr--240 {
    margin-right: 31.25vw !important; }
  .u--pdt--240 {
    padding-top: 31.25vw !important; }
  .u--pdb--240 {
    padding-bottom: 31.25vw !important; }
  .u--pdl--240 {
    padding-left: 31.25vw !important; }
  .u--pdr--240 {
    padding-right: 31.25vw !important; }
  .u--mgt--245 {
    margin-top: 31.90104vw !important; }
  .u--mgb--245 {
    margin-bottom: 31.90104vw !important; }
  .u--mgl--245 {
    margin-left: 31.90104vw !important; }
  .u--mgr--245 {
    margin-right: 31.90104vw !important; }
  .u--pdt--245 {
    padding-top: 31.90104vw !important; }
  .u--pdb--245 {
    padding-bottom: 31.90104vw !important; }
  .u--pdl--245 {
    padding-left: 31.90104vw !important; }
  .u--pdr--245 {
    padding-right: 31.90104vw !important; }
  .u--mgt--250 {
    margin-top: 32.55208vw !important; }
  .u--mgb--250 {
    margin-bottom: 32.55208vw !important; }
  .u--mgl--250 {
    margin-left: 32.55208vw !important; }
  .u--mgr--250 {
    margin-right: 32.55208vw !important; }
  .u--pdt--250 {
    padding-top: 32.55208vw !important; }
  .u--pdb--250 {
    padding-bottom: 32.55208vw !important; }
  .u--pdl--250 {
    padding-left: 32.55208vw !important; }
  .u--pdr--250 {
    padding-right: 32.55208vw !important; }
  .u--mgt--255 {
    margin-top: 33.20312vw !important; }
  .u--mgb--255 {
    margin-bottom: 33.20312vw !important; }
  .u--mgl--255 {
    margin-left: 33.20312vw !important; }
  .u--mgr--255 {
    margin-right: 33.20312vw !important; }
  .u--pdt--255 {
    padding-top: 33.20312vw !important; }
  .u--pdb--255 {
    padding-bottom: 33.20312vw !important; }
  .u--pdl--255 {
    padding-left: 33.20312vw !important; }
  .u--pdr--255 {
    padding-right: 33.20312vw !important; }
  .u--mgt--260 {
    margin-top: 33.85417vw !important; }
  .u--mgb--260 {
    margin-bottom: 33.85417vw !important; }
  .u--mgl--260 {
    margin-left: 33.85417vw !important; }
  .u--mgr--260 {
    margin-right: 33.85417vw !important; }
  .u--pdt--260 {
    padding-top: 33.85417vw !important; }
  .u--pdb--260 {
    padding-bottom: 33.85417vw !important; }
  .u--pdl--260 {
    padding-left: 33.85417vw !important; }
  .u--pdr--260 {
    padding-right: 33.85417vw !important; }
  .u--mgt--265 {
    margin-top: 34.50521vw !important; }
  .u--mgb--265 {
    margin-bottom: 34.50521vw !important; }
  .u--mgl--265 {
    margin-left: 34.50521vw !important; }
  .u--mgr--265 {
    margin-right: 34.50521vw !important; }
  .u--pdt--265 {
    padding-top: 34.50521vw !important; }
  .u--pdb--265 {
    padding-bottom: 34.50521vw !important; }
  .u--pdl--265 {
    padding-left: 34.50521vw !important; }
  .u--pdr--265 {
    padding-right: 34.50521vw !important; }
  .u--mgt--270 {
    margin-top: 35.15625vw !important; }
  .u--mgb--270 {
    margin-bottom: 35.15625vw !important; }
  .u--mgl--270 {
    margin-left: 35.15625vw !important; }
  .u--mgr--270 {
    margin-right: 35.15625vw !important; }
  .u--pdt--270 {
    padding-top: 35.15625vw !important; }
  .u--pdb--270 {
    padding-bottom: 35.15625vw !important; }
  .u--pdl--270 {
    padding-left: 35.15625vw !important; }
  .u--pdr--270 {
    padding-right: 35.15625vw !important; }
  .u--mgt--275 {
    margin-top: 35.80729vw !important; }
  .u--mgb--275 {
    margin-bottom: 35.80729vw !important; }
  .u--mgl--275 {
    margin-left: 35.80729vw !important; }
  .u--mgr--275 {
    margin-right: 35.80729vw !important; }
  .u--pdt--275 {
    padding-top: 35.80729vw !important; }
  .u--pdb--275 {
    padding-bottom: 35.80729vw !important; }
  .u--pdl--275 {
    padding-left: 35.80729vw !important; }
  .u--pdr--275 {
    padding-right: 35.80729vw !important; }
  .u--mgt--280 {
    margin-top: 36.45833vw !important; }
  .u--mgb--280 {
    margin-bottom: 36.45833vw !important; }
  .u--mgl--280 {
    margin-left: 36.45833vw !important; }
  .u--mgr--280 {
    margin-right: 36.45833vw !important; }
  .u--pdt--280 {
    padding-top: 36.45833vw !important; }
  .u--pdb--280 {
    padding-bottom: 36.45833vw !important; }
  .u--pdl--280 {
    padding-left: 36.45833vw !important; }
  .u--pdr--280 {
    padding-right: 36.45833vw !important; }
  .u--mgt--285 {
    margin-top: 37.10938vw !important; }
  .u--mgb--285 {
    margin-bottom: 37.10938vw !important; }
  .u--mgl--285 {
    margin-left: 37.10938vw !important; }
  .u--mgr--285 {
    margin-right: 37.10938vw !important; }
  .u--pdt--285 {
    padding-top: 37.10938vw !important; }
  .u--pdb--285 {
    padding-bottom: 37.10938vw !important; }
  .u--pdl--285 {
    padding-left: 37.10938vw !important; }
  .u--pdr--285 {
    padding-right: 37.10938vw !important; }
  .u--mgt--290 {
    margin-top: 37.76042vw !important; }
  .u--mgb--290 {
    margin-bottom: 37.76042vw !important; }
  .u--mgl--290 {
    margin-left: 37.76042vw !important; }
  .u--mgr--290 {
    margin-right: 37.76042vw !important; }
  .u--pdt--290 {
    padding-top: 37.76042vw !important; }
  .u--pdb--290 {
    padding-bottom: 37.76042vw !important; }
  .u--pdl--290 {
    padding-left: 37.76042vw !important; }
  .u--pdr--290 {
    padding-right: 37.76042vw !important; }
  .u--mgt--295 {
    margin-top: 38.41146vw !important; }
  .u--mgb--295 {
    margin-bottom: 38.41146vw !important; }
  .u--mgl--295 {
    margin-left: 38.41146vw !important; }
  .u--mgr--295 {
    margin-right: 38.41146vw !important; }
  .u--pdt--295 {
    padding-top: 38.41146vw !important; }
  .u--pdb--295 {
    padding-bottom: 38.41146vw !important; }
  .u--pdl--295 {
    padding-left: 38.41146vw !important; }
  .u--pdr--295 {
    padding-right: 38.41146vw !important; }
  .u--mgt--300 {
    margin-top: 39.0625vw !important; }
  .u--mgb--300 {
    margin-bottom: 39.0625vw !important; }
  .u--mgl--300 {
    margin-left: 39.0625vw !important; }
  .u--mgr--300 {
    margin-right: 39.0625vw !important; }
  .u--pdt--300 {
    padding-top: 39.0625vw !important; }
  .u--pdb--300 {
    padding-bottom: 39.0625vw !important; }
  .u--pdl--300 {
    padding-left: 39.0625vw !important; }
  .u--pdr--300 {
    padding-right: 39.0625vw !important; }
  .u--mgt--305 {
    margin-top: 39.71354vw !important; }
  .u--mgb--305 {
    margin-bottom: 39.71354vw !important; }
  .u--mgl--305 {
    margin-left: 39.71354vw !important; }
  .u--mgr--305 {
    margin-right: 39.71354vw !important; }
  .u--pdt--305 {
    padding-top: 39.71354vw !important; }
  .u--pdb--305 {
    padding-bottom: 39.71354vw !important; }
  .u--pdl--305 {
    padding-left: 39.71354vw !important; }
  .u--pdr--305 {
    padding-right: 39.71354vw !important; }
  .u--mgt--310 {
    margin-top: 40.36458vw !important; }
  .u--mgb--310 {
    margin-bottom: 40.36458vw !important; }
  .u--mgl--310 {
    margin-left: 40.36458vw !important; }
  .u--mgr--310 {
    margin-right: 40.36458vw !important; }
  .u--pdt--310 {
    padding-top: 40.36458vw !important; }
  .u--pdb--310 {
    padding-bottom: 40.36458vw !important; }
  .u--pdl--310 {
    padding-left: 40.36458vw !important; }
  .u--pdr--310 {
    padding-right: 40.36458vw !important; }
  .u--mgt--315 {
    margin-top: 41.01562vw !important; }
  .u--mgb--315 {
    margin-bottom: 41.01562vw !important; }
  .u--mgl--315 {
    margin-left: 41.01562vw !important; }
  .u--mgr--315 {
    margin-right: 41.01562vw !important; }
  .u--pdt--315 {
    padding-top: 41.01562vw !important; }
  .u--pdb--315 {
    padding-bottom: 41.01562vw !important; }
  .u--pdl--315 {
    padding-left: 41.01562vw !important; }
  .u--pdr--315 {
    padding-right: 41.01562vw !important; }
  .u--mgt--320 {
    margin-top: 41.66667vw !important; }
  .u--mgb--320 {
    margin-bottom: 41.66667vw !important; }
  .u--mgl--320 {
    margin-left: 41.66667vw !important; }
  .u--mgr--320 {
    margin-right: 41.66667vw !important; }
  .u--pdt--320 {
    padding-top: 41.66667vw !important; }
  .u--pdb--320 {
    padding-bottom: 41.66667vw !important; }
  .u--pdl--320 {
    padding-left: 41.66667vw !important; }
  .u--pdr--320 {
    padding-right: 41.66667vw !important; }
  .u--mgt--325 {
    margin-top: 42.31771vw !important; }
  .u--mgb--325 {
    margin-bottom: 42.31771vw !important; }
  .u--mgl--325 {
    margin-left: 42.31771vw !important; }
  .u--mgr--325 {
    margin-right: 42.31771vw !important; }
  .u--pdt--325 {
    padding-top: 42.31771vw !important; }
  .u--pdb--325 {
    padding-bottom: 42.31771vw !important; }
  .u--pdl--325 {
    padding-left: 42.31771vw !important; }
  .u--pdr--325 {
    padding-right: 42.31771vw !important; }
  .u--mgt--330 {
    margin-top: 42.96875vw !important; }
  .u--mgb--330 {
    margin-bottom: 42.96875vw !important; }
  .u--mgl--330 {
    margin-left: 42.96875vw !important; }
  .u--mgr--330 {
    margin-right: 42.96875vw !important; }
  .u--pdt--330 {
    padding-top: 42.96875vw !important; }
  .u--pdb--330 {
    padding-bottom: 42.96875vw !important; }
  .u--pdl--330 {
    padding-left: 42.96875vw !important; }
  .u--pdr--330 {
    padding-right: 42.96875vw !important; }
  .u--mgt--335 {
    margin-top: 43.61979vw !important; }
  .u--mgb--335 {
    margin-bottom: 43.61979vw !important; }
  .u--mgl--335 {
    margin-left: 43.61979vw !important; }
  .u--mgr--335 {
    margin-right: 43.61979vw !important; }
  .u--pdt--335 {
    padding-top: 43.61979vw !important; }
  .u--pdb--335 {
    padding-bottom: 43.61979vw !important; }
  .u--pdl--335 {
    padding-left: 43.61979vw !important; }
  .u--pdr--335 {
    padding-right: 43.61979vw !important; }
  .u--mgt--340 {
    margin-top: 44.27083vw !important; }
  .u--mgb--340 {
    margin-bottom: 44.27083vw !important; }
  .u--mgl--340 {
    margin-left: 44.27083vw !important; }
  .u--mgr--340 {
    margin-right: 44.27083vw !important; }
  .u--pdt--340 {
    padding-top: 44.27083vw !important; }
  .u--pdb--340 {
    padding-bottom: 44.27083vw !important; }
  .u--pdl--340 {
    padding-left: 44.27083vw !important; }
  .u--pdr--340 {
    padding-right: 44.27083vw !important; }
  .u--mgt--345 {
    margin-top: 44.92188vw !important; }
  .u--mgb--345 {
    margin-bottom: 44.92188vw !important; }
  .u--mgl--345 {
    margin-left: 44.92188vw !important; }
  .u--mgr--345 {
    margin-right: 44.92188vw !important; }
  .u--pdt--345 {
    padding-top: 44.92188vw !important; }
  .u--pdb--345 {
    padding-bottom: 44.92188vw !important; }
  .u--pdl--345 {
    padding-left: 44.92188vw !important; }
  .u--pdr--345 {
    padding-right: 44.92188vw !important; }
  .u--mgt--350 {
    margin-top: 45.57292vw !important; }
  .u--mgb--350 {
    margin-bottom: 45.57292vw !important; }
  .u--mgl--350 {
    margin-left: 45.57292vw !important; }
  .u--mgr--350 {
    margin-right: 45.57292vw !important; }
  .u--pdt--350 {
    padding-top: 45.57292vw !important; }
  .u--pdb--350 {
    padding-bottom: 45.57292vw !important; }
  .u--pdl--350 {
    padding-left: 45.57292vw !important; }
  .u--pdr--350 {
    padding-right: 45.57292vw !important; }
  .u--mgt--355 {
    margin-top: 46.22396vw !important; }
  .u--mgb--355 {
    margin-bottom: 46.22396vw !important; }
  .u--mgl--355 {
    margin-left: 46.22396vw !important; }
  .u--mgr--355 {
    margin-right: 46.22396vw !important; }
  .u--pdt--355 {
    padding-top: 46.22396vw !important; }
  .u--pdb--355 {
    padding-bottom: 46.22396vw !important; }
  .u--pdl--355 {
    padding-left: 46.22396vw !important; }
  .u--pdr--355 {
    padding-right: 46.22396vw !important; }
  .u--mgt--360 {
    margin-top: 46.875vw !important; }
  .u--mgb--360 {
    margin-bottom: 46.875vw !important; }
  .u--mgl--360 {
    margin-left: 46.875vw !important; }
  .u--mgr--360 {
    margin-right: 46.875vw !important; }
  .u--pdt--360 {
    padding-top: 46.875vw !important; }
  .u--pdb--360 {
    padding-bottom: 46.875vw !important; }
  .u--pdl--360 {
    padding-left: 46.875vw !important; }
  .u--pdr--360 {
    padding-right: 46.875vw !important; }
  .u--mgt--365 {
    margin-top: 47.52604vw !important; }
  .u--mgb--365 {
    margin-bottom: 47.52604vw !important; }
  .u--mgl--365 {
    margin-left: 47.52604vw !important; }
  .u--mgr--365 {
    margin-right: 47.52604vw !important; }
  .u--pdt--365 {
    padding-top: 47.52604vw !important; }
  .u--pdb--365 {
    padding-bottom: 47.52604vw !important; }
  .u--pdl--365 {
    padding-left: 47.52604vw !important; }
  .u--pdr--365 {
    padding-right: 47.52604vw !important; }
  .u--mgt--370 {
    margin-top: 48.17708vw !important; }
  .u--mgb--370 {
    margin-bottom: 48.17708vw !important; }
  .u--mgl--370 {
    margin-left: 48.17708vw !important; }
  .u--mgr--370 {
    margin-right: 48.17708vw !important; }
  .u--pdt--370 {
    padding-top: 48.17708vw !important; }
  .u--pdb--370 {
    padding-bottom: 48.17708vw !important; }
  .u--pdl--370 {
    padding-left: 48.17708vw !important; }
  .u--pdr--370 {
    padding-right: 48.17708vw !important; }
  .u--mgt--375 {
    margin-top: 48.82812vw !important; }
  .u--mgb--375 {
    margin-bottom: 48.82812vw !important; }
  .u--mgl--375 {
    margin-left: 48.82812vw !important; }
  .u--mgr--375 {
    margin-right: 48.82812vw !important; }
  .u--pdt--375 {
    padding-top: 48.82812vw !important; }
  .u--pdb--375 {
    padding-bottom: 48.82812vw !important; }
  .u--pdl--375 {
    padding-left: 48.82812vw !important; }
  .u--pdr--375 {
    padding-right: 48.82812vw !important; }
  .u--mgt--380 {
    margin-top: 49.47917vw !important; }
  .u--mgb--380 {
    margin-bottom: 49.47917vw !important; }
  .u--mgl--380 {
    margin-left: 49.47917vw !important; }
  .u--mgr--380 {
    margin-right: 49.47917vw !important; }
  .u--pdt--380 {
    padding-top: 49.47917vw !important; }
  .u--pdb--380 {
    padding-bottom: 49.47917vw !important; }
  .u--pdl--380 {
    padding-left: 49.47917vw !important; }
  .u--pdr--380 {
    padding-right: 49.47917vw !important; }
  .u--mgt--385 {
    margin-top: 50.13021vw !important; }
  .u--mgb--385 {
    margin-bottom: 50.13021vw !important; }
  .u--mgl--385 {
    margin-left: 50.13021vw !important; }
  .u--mgr--385 {
    margin-right: 50.13021vw !important; }
  .u--pdt--385 {
    padding-top: 50.13021vw !important; }
  .u--pdb--385 {
    padding-bottom: 50.13021vw !important; }
  .u--pdl--385 {
    padding-left: 50.13021vw !important; }
  .u--pdr--385 {
    padding-right: 50.13021vw !important; }
  .u--mgt--390 {
    margin-top: 50.78125vw !important; }
  .u--mgb--390 {
    margin-bottom: 50.78125vw !important; }
  .u--mgl--390 {
    margin-left: 50.78125vw !important; }
  .u--mgr--390 {
    margin-right: 50.78125vw !important; }
  .u--pdt--390 {
    padding-top: 50.78125vw !important; }
  .u--pdb--390 {
    padding-bottom: 50.78125vw !important; }
  .u--pdl--390 {
    padding-left: 50.78125vw !important; }
  .u--pdr--390 {
    padding-right: 50.78125vw !important; }
  .u--mgt--395 {
    margin-top: 51.43229vw !important; }
  .u--mgb--395 {
    margin-bottom: 51.43229vw !important; }
  .u--mgl--395 {
    margin-left: 51.43229vw !important; }
  .u--mgr--395 {
    margin-right: 51.43229vw !important; }
  .u--pdt--395 {
    padding-top: 51.43229vw !important; }
  .u--pdb--395 {
    padding-bottom: 51.43229vw !important; }
  .u--pdl--395 {
    padding-left: 51.43229vw !important; }
  .u--pdr--395 {
    padding-right: 51.43229vw !important; }
  .u--mgt--400 {
    margin-top: 52.08333vw !important; }
  .u--mgb--400 {
    margin-bottom: 52.08333vw !important; }
  .u--mgl--400 {
    margin-left: 52.08333vw !important; }
  .u--mgr--400 {
    margin-right: 52.08333vw !important; }
  .u--pdt--400 {
    padding-top: 52.08333vw !important; }
  .u--pdb--400 {
    padding-bottom: 52.08333vw !important; }
  .u--pdl--400 {
    padding-left: 52.08333vw !important; }
  .u--pdr--400 {
    padding-right: 52.08333vw !important; }
  .u--mgt--405 {
    margin-top: 52.73438vw !important; }
  .u--mgb--405 {
    margin-bottom: 52.73438vw !important; }
  .u--mgl--405 {
    margin-left: 52.73438vw !important; }
  .u--mgr--405 {
    margin-right: 52.73438vw !important; }
  .u--pdt--405 {
    padding-top: 52.73438vw !important; }
  .u--pdb--405 {
    padding-bottom: 52.73438vw !important; }
  .u--pdl--405 {
    padding-left: 52.73438vw !important; }
  .u--pdr--405 {
    padding-right: 52.73438vw !important; }
  .u--mgt--410 {
    margin-top: 53.38542vw !important; }
  .u--mgb--410 {
    margin-bottom: 53.38542vw !important; }
  .u--mgl--410 {
    margin-left: 53.38542vw !important; }
  .u--mgr--410 {
    margin-right: 53.38542vw !important; }
  .u--pdt--410 {
    padding-top: 53.38542vw !important; }
  .u--pdb--410 {
    padding-bottom: 53.38542vw !important; }
  .u--pdl--410 {
    padding-left: 53.38542vw !important; }
  .u--pdr--410 {
    padding-right: 53.38542vw !important; }
  .u--mgt--415 {
    margin-top: 54.03646vw !important; }
  .u--mgb--415 {
    margin-bottom: 54.03646vw !important; }
  .u--mgl--415 {
    margin-left: 54.03646vw !important; }
  .u--mgr--415 {
    margin-right: 54.03646vw !important; }
  .u--pdt--415 {
    padding-top: 54.03646vw !important; }
  .u--pdb--415 {
    padding-bottom: 54.03646vw !important; }
  .u--pdl--415 {
    padding-left: 54.03646vw !important; }
  .u--pdr--415 {
    padding-right: 54.03646vw !important; }
  .u--mgt--420 {
    margin-top: 54.6875vw !important; }
  .u--mgb--420 {
    margin-bottom: 54.6875vw !important; }
  .u--mgl--420 {
    margin-left: 54.6875vw !important; }
  .u--mgr--420 {
    margin-right: 54.6875vw !important; }
  .u--pdt--420 {
    padding-top: 54.6875vw !important; }
  .u--pdb--420 {
    padding-bottom: 54.6875vw !important; }
  .u--pdl--420 {
    padding-left: 54.6875vw !important; }
  .u--pdr--420 {
    padding-right: 54.6875vw !important; }
  .u--mgt--425 {
    margin-top: 55.33854vw !important; }
  .u--mgb--425 {
    margin-bottom: 55.33854vw !important; }
  .u--mgl--425 {
    margin-left: 55.33854vw !important; }
  .u--mgr--425 {
    margin-right: 55.33854vw !important; }
  .u--pdt--425 {
    padding-top: 55.33854vw !important; }
  .u--pdb--425 {
    padding-bottom: 55.33854vw !important; }
  .u--pdl--425 {
    padding-left: 55.33854vw !important; }
  .u--pdr--425 {
    padding-right: 55.33854vw !important; }
  .u--mgt--430 {
    margin-top: 55.98958vw !important; }
  .u--mgb--430 {
    margin-bottom: 55.98958vw !important; }
  .u--mgl--430 {
    margin-left: 55.98958vw !important; }
  .u--mgr--430 {
    margin-right: 55.98958vw !important; }
  .u--pdt--430 {
    padding-top: 55.98958vw !important; }
  .u--pdb--430 {
    padding-bottom: 55.98958vw !important; }
  .u--pdl--430 {
    padding-left: 55.98958vw !important; }
  .u--pdr--430 {
    padding-right: 55.98958vw !important; }
  .u--mgt--435 {
    margin-top: 56.64063vw !important; }
  .u--mgb--435 {
    margin-bottom: 56.64063vw !important; }
  .u--mgl--435 {
    margin-left: 56.64063vw !important; }
  .u--mgr--435 {
    margin-right: 56.64063vw !important; }
  .u--pdt--435 {
    padding-top: 56.64063vw !important; }
  .u--pdb--435 {
    padding-bottom: 56.64063vw !important; }
  .u--pdl--435 {
    padding-left: 56.64063vw !important; }
  .u--pdr--435 {
    padding-right: 56.64063vw !important; }
  .u--mgt--440 {
    margin-top: 57.29167vw !important; }
  .u--mgb--440 {
    margin-bottom: 57.29167vw !important; }
  .u--mgl--440 {
    margin-left: 57.29167vw !important; }
  .u--mgr--440 {
    margin-right: 57.29167vw !important; }
  .u--pdt--440 {
    padding-top: 57.29167vw !important; }
  .u--pdb--440 {
    padding-bottom: 57.29167vw !important; }
  .u--pdl--440 {
    padding-left: 57.29167vw !important; }
  .u--pdr--440 {
    padding-right: 57.29167vw !important; }
  .u--mgt--445 {
    margin-top: 57.94271vw !important; }
  .u--mgb--445 {
    margin-bottom: 57.94271vw !important; }
  .u--mgl--445 {
    margin-left: 57.94271vw !important; }
  .u--mgr--445 {
    margin-right: 57.94271vw !important; }
  .u--pdt--445 {
    padding-top: 57.94271vw !important; }
  .u--pdb--445 {
    padding-bottom: 57.94271vw !important; }
  .u--pdl--445 {
    padding-left: 57.94271vw !important; }
  .u--pdr--445 {
    padding-right: 57.94271vw !important; }
  .u--mgt--450 {
    margin-top: 58.59375vw !important; }
  .u--mgb--450 {
    margin-bottom: 58.59375vw !important; }
  .u--mgl--450 {
    margin-left: 58.59375vw !important; }
  .u--mgr--450 {
    margin-right: 58.59375vw !important; }
  .u--pdt--450 {
    padding-top: 58.59375vw !important; }
  .u--pdb--450 {
    padding-bottom: 58.59375vw !important; }
  .u--pdl--450 {
    padding-left: 58.59375vw !important; }
  .u--pdr--450 {
    padding-right: 58.59375vw !important; }
  .u--mgt--455 {
    margin-top: 59.24479vw !important; }
  .u--mgb--455 {
    margin-bottom: 59.24479vw !important; }
  .u--mgl--455 {
    margin-left: 59.24479vw !important; }
  .u--mgr--455 {
    margin-right: 59.24479vw !important; }
  .u--pdt--455 {
    padding-top: 59.24479vw !important; }
  .u--pdb--455 {
    padding-bottom: 59.24479vw !important; }
  .u--pdl--455 {
    padding-left: 59.24479vw !important; }
  .u--pdr--455 {
    padding-right: 59.24479vw !important; }
  .u--mgt--460 {
    margin-top: 59.89583vw !important; }
  .u--mgb--460 {
    margin-bottom: 59.89583vw !important; }
  .u--mgl--460 {
    margin-left: 59.89583vw !important; }
  .u--mgr--460 {
    margin-right: 59.89583vw !important; }
  .u--pdt--460 {
    padding-top: 59.89583vw !important; }
  .u--pdb--460 {
    padding-bottom: 59.89583vw !important; }
  .u--pdl--460 {
    padding-left: 59.89583vw !important; }
  .u--pdr--460 {
    padding-right: 59.89583vw !important; }
  .u--mgt--465 {
    margin-top: 60.54688vw !important; }
  .u--mgb--465 {
    margin-bottom: 60.54688vw !important; }
  .u--mgl--465 {
    margin-left: 60.54688vw !important; }
  .u--mgr--465 {
    margin-right: 60.54688vw !important; }
  .u--pdt--465 {
    padding-top: 60.54688vw !important; }
  .u--pdb--465 {
    padding-bottom: 60.54688vw !important; }
  .u--pdl--465 {
    padding-left: 60.54688vw !important; }
  .u--pdr--465 {
    padding-right: 60.54688vw !important; }
  .u--mgt--470 {
    margin-top: 61.19792vw !important; }
  .u--mgb--470 {
    margin-bottom: 61.19792vw !important; }
  .u--mgl--470 {
    margin-left: 61.19792vw !important; }
  .u--mgr--470 {
    margin-right: 61.19792vw !important; }
  .u--pdt--470 {
    padding-top: 61.19792vw !important; }
  .u--pdb--470 {
    padding-bottom: 61.19792vw !important; }
  .u--pdl--470 {
    padding-left: 61.19792vw !important; }
  .u--pdr--470 {
    padding-right: 61.19792vw !important; }
  .u--mgt--475 {
    margin-top: 61.84896vw !important; }
  .u--mgb--475 {
    margin-bottom: 61.84896vw !important; }
  .u--mgl--475 {
    margin-left: 61.84896vw !important; }
  .u--mgr--475 {
    margin-right: 61.84896vw !important; }
  .u--pdt--475 {
    padding-top: 61.84896vw !important; }
  .u--pdb--475 {
    padding-bottom: 61.84896vw !important; }
  .u--pdl--475 {
    padding-left: 61.84896vw !important; }
  .u--pdr--475 {
    padding-right: 61.84896vw !important; }
  .u--mgt--480 {
    margin-top: 62.5vw !important; }
  .u--mgb--480 {
    margin-bottom: 62.5vw !important; }
  .u--mgl--480 {
    margin-left: 62.5vw !important; }
  .u--mgr--480 {
    margin-right: 62.5vw !important; }
  .u--pdt--480 {
    padding-top: 62.5vw !important; }
  .u--pdb--480 {
    padding-bottom: 62.5vw !important; }
  .u--pdl--480 {
    padding-left: 62.5vw !important; }
  .u--pdr--480 {
    padding-right: 62.5vw !important; }
  .u--mgt--485 {
    margin-top: 63.15104vw !important; }
  .u--mgb--485 {
    margin-bottom: 63.15104vw !important; }
  .u--mgl--485 {
    margin-left: 63.15104vw !important; }
  .u--mgr--485 {
    margin-right: 63.15104vw !important; }
  .u--pdt--485 {
    padding-top: 63.15104vw !important; }
  .u--pdb--485 {
    padding-bottom: 63.15104vw !important; }
  .u--pdl--485 {
    padding-left: 63.15104vw !important; }
  .u--pdr--485 {
    padding-right: 63.15104vw !important; }
  .u--mgt--490 {
    margin-top: 63.80208vw !important; }
  .u--mgb--490 {
    margin-bottom: 63.80208vw !important; }
  .u--mgl--490 {
    margin-left: 63.80208vw !important; }
  .u--mgr--490 {
    margin-right: 63.80208vw !important; }
  .u--pdt--490 {
    padding-top: 63.80208vw !important; }
  .u--pdb--490 {
    padding-bottom: 63.80208vw !important; }
  .u--pdl--490 {
    padding-left: 63.80208vw !important; }
  .u--pdr--490 {
    padding-right: 63.80208vw !important; }
  .u--mgt--495 {
    margin-top: 64.45312vw !important; }
  .u--mgb--495 {
    margin-bottom: 64.45312vw !important; }
  .u--mgl--495 {
    margin-left: 64.45312vw !important; }
  .u--mgr--495 {
    margin-right: 64.45312vw !important; }
  .u--pdt--495 {
    padding-top: 64.45312vw !important; }
  .u--pdb--495 {
    padding-bottom: 64.45312vw !important; }
  .u--pdl--495 {
    padding-left: 64.45312vw !important; }
  .u--pdr--495 {
    padding-right: 64.45312vw !important; }
  .u--mgt--500 {
    margin-top: 65.10417vw !important; }
  .u--mgb--500 {
    margin-bottom: 65.10417vw !important; }
  .u--mgl--500 {
    margin-left: 65.10417vw !important; }
  .u--mgr--500 {
    margin-right: 65.10417vw !important; }
  .u--pdt--500 {
    padding-top: 65.10417vw !important; }
  .u--pdb--500 {
    padding-bottom: 65.10417vw !important; }
  .u--pdl--500 {
    padding-left: 65.10417vw !important; }
  .u--pdr--500 {
    padding-right: 65.10417vw !important; } }

.u--mgl--210--rwd {
  margin-left: 210px; }

@media screen and (max-width: 768px) {
  .u--mgl--210--rwd {
    margin-left: 0; }
  .u--sppdb--30 {
    padding-bottom: 30px; }
  .u--sppdb--40 {
    padding-bottom: 40px; } }

/** ========================================
 * @
 * 文字関連
 * ====================================== */
.u--fs__10 {
  font-size: 0.625rem !important; }

.u--fs__11 {
  font-size: 0.6875rem !important; }

.u--fs__12 {
  font-size: 0.75rem !important; }

.u--fs__13 {
  font-size: 0.8125rem !important; }

.u--fs__14 {
  font-size: 0.875rem !important; }

.u--fs__16 {
  font-size: 1rem !important; }

.u--fs__18 {
  font-size: 1.125rem !important; }

.u--fs__22 {
  font-size: 1.375rem !important; }

.u--fs__24 {
  font-size: 1.5rem !important; }

.u--fw__bold {
  font-weight: bold !important; }

.u--fw__normal {
  font-weight: normal !important; }

.u--color__red {
  color: #EB2B51 !important; }

.u--color__dkred {
  color: #B20000 !important; }

.u--color__navy {
  color: #00468C !important; }

.u--color__skyblue {
  color: #00AAEB !important; }

.u--bg__f2fbfd {
  background: #f2fbfd !important; }
  .u--bg__f2fbfd td, .u--bg__f2fbfd th {
    background: #f2fbfd !important; }

.u--bg__e5e5e5 {
  background: #e5e5e5 !important; }
  .u--bg__e5e5e5 td, .u--bg__e5e5e5 th {
    background: #e5e5e5 !important; }

.u--bg__f5f6f8 {
  background: #f5f6f8 !important; }
  .u--bg__f5f6f8 td, .u--bg__f5f6f8 th {
    background: #f5f6f8 !important; }

.u--bg__ffe8e9 {
  background: #ffe8e9 !important; }
  .u--bg__ffe8e9 td, .u--bg__ffe8e9 th {
    background: #ffe8e9 !important; }

.u--bg__error {
  padding: 16px 13px;
  background: #ffe8e9 !important; }

.u--ellipsis {
  overflow: hidden;
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap; }

.u--lineClamp {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical; }
  .u--lineClamp--2 {
    -webkit-line-clamp: 2;
    max-height: 34px; }
  .u--lineClamp--3 {
    -webkit-line-clamp: 3;
    max-height: 52px; }
