/*! 
 * フッター
 */

/** ========================================
 * @FOOTER
 * フッター
 * ====================================== */
.c--footer{
  min-width: 1200px;
  background: $clr-w;
  &--nolink{
    .c--pageTop__box{
      min-width: 1200px;
      margin: 0 auto;
    }
  }
  // 説明エリア
  &Info{
    padding: 77px 0 0;
    text-align: center;
    &__link{
      display: flex;
      justify-content: center;
      margin: 43px 0 0;
      li{
        border-left: 1px solid $clr-g40;
        padding: 0 16px;
        &:first-child{
          border-left: none;
        }
      }
    }
    
  }
  &Logo{
    width: 300px;
    margin: 0 auto;
  }
  &Sns{
    display: flex;
    justify-content: center;
    margin: 32px 0 0;
    &__item{
      width: 32px;
      &:not(:first-child){
        margin: 0 0 0 15px;
      }
    }
  }
  &__txt{
    margin: 25px 0 0;
    color: $clr-g70;
    font-size: .72rem;
  }


  &Nav{
    position: relative;
    width: 1200px;
    margin: 40px auto 0;

    &__ttl{
      margin: 37px 0 0;
      color: $clr-lb80;
      font-size: .92rem;
      letter-spacing: 1px;
    }
    
    &__link{
      display: flex;
      justify-content: space-between;
      margin: 18px 0 0;
      .c--link__wrap{
        width: calc(25% - 20px);
        dt{
          padding: 0 0 4px 0;
          border-bottom: 1px solid $clr-g30;
        }
        dd{
          margin: 10px 0 0;
          + dt{
            margin: 33px 0 0;
          }
        }
      }
      .c--link__list{
        font-size: .75rem;
        li{
          margin: 6px 0 0;
          a{
            padding: 0 0 0 14px;
          }
        }
      }
    }
  }

  &__simple{
    .c--subFooter{
      margin: 0;
    }
  }
}

.c--pageTop{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  dl{
    flex-grow: 1;
  }
  &__box{
    position: relative;
    display: flex;
    justify-content: flex-end;
  }
}
.c--box__linkArea{
  position: fixed;
  right: 28px;
  bottom: 18vh;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  .c--linkArea__btn{
    content: "";
    display: inline-block;
    width: 50px;
    height: 50px;
    background: url(../images/page_top.png) 50%/50px no-repeat;
    vertical-align: bottom;
    box-shadow: 4px 0 8px rgba(97,97,106,.2), 1px 0 20px rgba(97,97,106,.2);
  }
}

.c--privacy__logo{
  width: 50px;
  margin: 0 0 2px 0;
}

.c--subFooter{
  margin: 58px 0 0;
  background: $clr-g80;
  font-size: .68rem;
  &__inner{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 11px 0;
  }
  .c--subFooter__list{
    display: flex;
    a{
      color: $clr-w;
      letter-spacing: 1px;
    }
    li{
      margin: 0 0 0 23px;
    }
  }
}
.c--copyrightText{
  color: $clr-w;
}

/** ========================================
 * @FOOTER
 * 2020.04.24 フッター追加
 * ====================================== */

 .c--footerInfo + .c--dot__line{
  margin: 40px 0 0;
}

#FooterService{
  position: relative;
  width: 1200px;
  margin: 37px auto 0;
  .FooterTitle{
    margin: 37px 0 0;
    color: #00b0ff;
    font-size: .92rem;
    letter-spacing: 1px;
  }
  &Cont{
    display: flex;
    justify-content: space-between;
    margin: 18px 0 0;
    .c--link__wrap{
      width: calc(20% - 20px);
    }
    dl{
      dt{
        padding: 0 0 4px;
        border-bottom: 1px solid #f0f0f0;
      }
      dd{
        margin: 6px 0 0;
        font-size: .75rem;
        a{
          position: relative;
          padding: 0 0 0 14px;
          &:before{
            content: "";
            position: absolute;
            top: 48%;
            left: 0;
            width: 7px;
            height: 7px;
            border: solid #e92d82;
            border-width: 0 2px 2px 0;
            z-index: 10;
            transform: translateY(-50%) rotate(-45deg);
          }
        }
      }
    }
  }

  #privacy{
    width: 50px;
    margin: 0 0 2px;
  }
}




@media screen and (max-width: 767px){
  .c--footer{
    min-width: auto;
    .c--footer__txt,
    .c--dot__line,
    .c--footerNav__link dl,
    .c--footerNav__ttl,
    .c--subFooter__list,
    #FooterServiceHead,
    #FooterService dl{
      display: none;
    }
    &--nolink{
      .c--pageTop__box{
        min-width: auto;
        width: calc(100% - 30px);
        margin: 13px 10px 0 20px;
      }
    }
  }
  .c--footerNav,
  #FooterService{
    width: 100%;
    margin: 13px auto 0;
  }
  .c--subFooter__inner{
    width: 100%;
    padding: 12px 18px;
  }
  .c--footerInfo{
    padding: 97px 0 0;
  }
  .c--footerSns{
    margin: 23px 0 0;
  }
  .c--footerNav__link,
  #FooterServiceCont{
    margin: 0 10px 0 20px;
    .c--link__wrap{
      width: 100%;
    }
  
  }

  .c--footerLogo{
    width: 160px;
  }
  .c--footerInfo__link{
    flex-wrap: wrap;
    margin: 23px 0 0;
    li{
      width: 50%;
      padding: 10px;
      font-size: .75rem;
      &:nth-child(1),
      &:nth-child(2){
        padding-top: 16px;
      }
      &:nth-child(5),
      &:nth-child(6){
        padding-bottom: 16px;
      }
    
      &:not(:nth-child(even)){
        border: none;
      }
    }
  }
  .c--pageTop__box{
    justify-content: flex-start; 
  }
  .c--subFooter{
    margin: 23px 0 0;
  }

}


@media screen and (max-width: 480px){
  .c--box__linkArea{
    top: -15px;
    right: -5px;
  }
  .c--footerNav__link{
    margin: 0 10px 0 20px;
  }
  .c--linkArea__btn{
    width: 50px;
    height: 50px;
    background-size: 50px;
  }


}



