/*! 
 * 各要素（繰り返して使うもの）
 */

/** ========================================
 * @only
 * 出し分け
 * ====================================== */
.c--sp__only{
  display: none!important;
}

.c--dsp--no{
  display: none;
}
.c--dsp--block{
  display: block!important;
}
.c--dsp--inlineBlock{
  display: inline-block;
}

@media screen and (max-width: 767px){
  .c--pc__only{
    display: none!important;
  }
  .c--sp__only{
    display: block!important;
  }

  .c--sp__inlineBlock{
    display: inline-block!important;
  }
}

// 余白
@media screen and (max-width: 767px){
  .c--singleTxt{
    margin: 20px 20px 0!important;
  }
}

/** ========================================
 * @color
 * 文字色
 * ====================================== */
.c--color{
  //  ピンク
  &--p80{
    color: $clr-p80!important;
  }
  // 赤
  &--red{
    color: $clr-red!important;
  }
  // 青
  &--lb80{
    color: $clr-lb80!important;
  }
  // 黒
  &--b{
    color: $clr-b!important;
  }

  // グレー
  &--g70{
    color: $clr-g70!important;
  }
}


// 文字装飾
.c--marker--y{
  background: linear-gradient(transparent 70%,$clr-y20 70%);
}


@media screen and (min-width: 768px) {
  /** ========================================
   * @hover
   * hover時アクション
   * ====================================== */
  a:hover{
    cursor: pointer;
  }
  .c--no__pointer{
    pointer-events: none;
  }
  button:hover{
    cursor: pointer;
  }
  button:disabled:hover{
    opacity: 1;
    cursor: default;
  }
  .c--hover__fade{
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    overflow: hidden;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
    &:hover,
    &:focus,
    &:active{
      background-color: #2098D1;
      color: $clr-w;
    }
  }
}


@media screen and (max-width: 767px) {
  input,
  textarea,
  select,
  option {
    font-size: remSize(16)!important;
  }
  a{
    -webkit-tap-highlight-color: rgba(0,0,0,.2);
  }
}


/** ========================================
 * @Headings
 * 見出し
 * ====================================== */
.c--primary__heading{
  color: $clr-p80;
  font-size: remSize(32);
  font-weight: bold;
  text-align: center;
  + p{
    margin: 23px 0 0;
    font-size: remSize(16);
    text-align: center;
  }
  &--bk{
    color: $clr-b;
  }
}
.c--secondary__heading{
  font-size: 1.75rem;
  font-weight: bold;
}
.c--third__heading{
  font-size: remSize(26);
  font-weight: bold;
}

@media screen and (max-width: 767px){
  .c--primary__heading{
    font-size: remSize(24);
    padding: 0 20px;
    + p{
      margin: 18px 0 0;
      font-size: remSize(14);
      line-height: 1.7;
    }
  }

  .c--secondary__heading{
    font-size: remSize(20);
  }

}



/** ========================================
 * @TABLE
 * テーブル
 * ====================================== */
.c--tableHeading{
  font-size: remSize(16);
  font-weight: bold;
  + .c--primaryTable{
    margin: 15px 0 0;
  }
}

.c--primaryTable{
  width:100%;
  table-layout:auto;
  border-collapse: collapse;
  &--fixed{
    table-layout:fixed;
  }
  th{
    padding: 10px 20px;
    border-top:1px solid $clr-g40;
    border-bottom:1px solid $clr-g40;
    background:$clr-p20;
    color:$clr-b;
    text-align:left;
    vertical-align:middle;
    .c--require{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      &:after{
        content: "必須";
        min-width: 26px;
        margin: 3px 0 0 5px;
        color: $clr-w;
        font-weight: 400;
        font-size: remSize(10);
        background: $clr-red;
        padding: 3px;
        line-height: 1;
        display: inline-block;
      }
    }
    .c--required {
      background-color: #B00000;
      padding: 0.1rem 0.5rem;
      white-space: nowrap;
      float:right;
      color: $clr-w;
      font-size: remSize(10);
      text-align: center;
    }
    .c--noneTxt{
      font-weight:normal;
      font-size:.75rem;
    }
  }
  &.c--colType th{
    width:15%;
    font-size: remSize(14);
  }

  td{
    background:$clr-w;
    border-top:1px solid $clr-g40;
    border-bottom:1px solid $clr-g40;
    padding: 10px 20px;
    vertical-align: middle;
    &.c--brno{
      border-right:none;
    }
    &.c--blno{
      border-left:none;
    }
    &.c--btno{
      border-top:none;
    }
    &.c--bbno{
      border-bottom:none;
    }
    &.c--guide{
      padding:5px 20px;
      background:#f7f7f7;
      border-top:none;
    }
  }
  &--btm__editbtn{
    text-align: right;
    margin-top: 20px;
    button{
      margin: 0;
    }
  }
  + .c--tableHeading{
    margin: 35px 0 0;
  }
  td.p--inputArea--col2{
    input{
      + span{
        margin: 0 5px 0 15px;
      }
    }
  }

  &--clr__lpk{
    th{
      background: #FFF7F6;
    }
  }

  &--thHigh{
    th{
      padding: 24px 20px;
    }
  }

}

.c--innerTable{
  margin: 10px 0;
  th{
    background: #F5F5F5;
  }
  th,td{
    padding: 3px 10px;
    border: 1px solid #D9D9DB;
  }
}


@media screen and (max-width: 767px) {
  .c--tableHeading{
    padding: 10px 20px;
    background: #F1F6FA;
    + .c--primaryTable{
      margin: 0;
    }
  }

  .c--primaryTable{
    th,td{
      display: block;
      width: 100%;
      border: none;
    }
    th{
      padding: 5px 20px;
      .c--require{
        display: block;
      }
    }
    + .c--tableHeading{
      margin: 15px 0 0;
    }
    td.p--inputArea--col2{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 10px 20px;
      input + a{
        margin: 10px 0 0;
      }
    }
    &--thHigh{
      th{
        padding: 5px 20px;
      }
    }
  }

  .c--innerTable{
    th,td{
      display: table-cell;
      border: 1px solid #D9D9DB;
    }
  }

}




/** ========================================
 * @
 * フォーム要素のスタイル
 * ====================================== */
 input {
  width: auto;
  height: 45px;
  padding: 5px 10px;
  border: 2px solid $clr-g40;
  border-radius: 4px;
  &:disabled{
    &:hover{
      cursor: auto;
    }
  }
  &[type=checkbox]{
    &:disabled~.c--control__txt{
      color: $clr-g50;
    }
  }
  &[type=radio]{
    &:disabled~.c--control__txt{
      color: $clr-g50;
    }
  }
}
.c--control__txt a{
  display: inline-block;
  margin: 0 5px 0 0;
  color: $clr-p80;
  text-decoration: underline;
}


textarea{
  padding: 5px 10px;
  border: 2px solid $clr-g40;
  border-radius: 4px;
}
.stretchTextarea{
  min-height:45px;
}
select{
  border: 2px solid $clr-g40;
  background: $clr-w;
  outline: none;
  &:focus{
    background: $clr-w;
  }
}

::-webkit-input-placeholder {
  color:#ccc;
}
:-ms-input-placeholder {
  color:#ccc;
}
::placeholder{
  color:#ccc
}

.c--input__name{
  width: 160px;
}

.c--text{
  &__cntWrap{
    display: flex;
    align-items: center;
  }
  &__cntBlock{
    display: flex;
    align-items: center;
    .c--cntBlock__info{
      margin: 0 0 0 10px;
      min-width: calc(20% - 10px);
      font-size: remSize(12);
    }
  }
}

// 文字カウント
input[type="text"],
input[type="password"],
input[type="number"],
input[type="radio"],
select,
textarea{
  &.is--over{
    border: 2px solid $clr-red;
  }
}
.c--text__print.is--over{
  color: $clr-red;
}

@media screen and (max-width: 767px){
  .c--input__name{
    width: 48%;
    + .c--input__name{
      margin: 0 0 0 10px;
    }
    input{
      width: 100%;
    }
    + .c--arrow--circle{
      margin: 5px 0 0;
    }
  }
  .c--errorTxt{
    font-size: remSize(13);
  }
  .c--text__cntWrap{
    display: block;
  }
}





// チェック・ラジオ基本設定
.c--control{
  display: inline-block;
  position: relative;
  margin: 5px 15px 5px 0;
  padding-left: 26px;
  cursor: pointer;
  min-width: 100px;
  vertical-align: middle;
  /*チェックボックス、またはラジオボタンだけ*/
  &.checkbox__only,
  &.radio__only{
    min-width:20px;
    padding-left: 20px;
    margin-bottom: 0;
  }
  // 中のinputボタン
  input{
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  // ボタンサイズが大きい場合の見た目
  &.c--control--l{
    margin-right: 20px;
  }
}

// チェック・ラジオの枠設定
.c--control__indicator{
  position: absolute;
  top: 50%;
  left: 0;
  height: 18px;
  width: 18px;
  background: $clr-w;
  border: 1px solid $clr-g60;
  border-radius: 2px;
  transform: translateY(-50%);
 
  .c--control--radio &{
    border-radius: 50%;
  }
  // Hover and focus
  .c--control:hover input[type="checkbox"] ~ &,
  .c--control input[type="checkbox"]:focus ~ &,
  .c--control:hover input[type="radio"] ~ &,
  .c--control input[type="radio"]:focus ~ &{
    background: $clr-g30;
  }
  // Checked
  .c--control input[type="checkbox"]:checked ~ &{
    // background: $clr-p80;
    background: $clr-lb80;
  }
  // Hover when checked
  .c--control:hover input[type="checkbox"]:not([disabled]):checked ~ &,
  .c--control input[type="checkbox"]:checked:focus ~ &,
  .c--control:hover input[type="radio"]:not([disabled]):checked ~ &,
  .c--control input[type="radio"]:checked:focus ~ &{
    // background: #9e1955;
    background: #0084bf;
  }
  // Hide default browser input[type="checkbox"]
  .c--control input[type="checkbox"]:disabled ~ &,
  .c--control input[type="radio"]:disabled ~ &{
    background: $clr-g40;
    pointer-events: none;
  }

  &:after{
    content: '';
    position: absolute;
    display: none; // Hide check

    .c--control input[type="checkbox"]:checked ~ &{
      display: block; // Show check
    }

    // Checkbox tick
    .c--control--checkbox &{
      top: 50%;
      left: 50%;
      width: 7px;
      height: 10px;
      border: solid $clr-w;
      border-width: 0 2px 2px 0;
      transform: translate(-50%, -70%) rotate(45deg);
    }
    // Disabled tick colour
    .c--control--checkbox input[type="checkbox"]:disabled ~ &{
      border-color: $clr-g80;
    }
    // Radio button inner circle
    .c--control--radio &{
      top: 50%;
      left: 50%;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background: $clr-w;
      transform: translate(-50% ,-50%);
    }
    // Disabled circle colour
    .c--control--radio input[type="checkbox"]:disabled ~ &{
      background: $clr-g80;
    }
  }
  .c--control input:checked ~ &{
    // background: $clr-p80;
    background: $clr-lb80;
    border: none;
    &:after{
      display: block;
    }
  }

}




// セレクト設定 
.c--select{
  position: relative;
  display: inline-block;
  width: 100%;
  &__only{
    margin-bottom: 0;
  }
  &.w400{
    width: 400px;
  }
  &.w300{
    width: 300px;
  }
  &.w200{
    width: 200px;
  }
  &.w100{
    width: 100px;
  }
  select{
    display: inline-block;
    width: 100%;
    height: 45px;
    cursor: pointer;
    padding:10px 30px 10px 10px;
    outline: 0;
    border: 2px solid $clr-g40;
    border-radius: 0;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    line-height: 1.2;
    font-size: remSize(14);
    border-radius: 6px;
    &::-ms-expand{
      display: none;
    }

    &:disabled{
      opacity: .5;
      pointer-events: none;
    }
  }
  
  &__multiple{
    height: auto!important;
  }
  &__multiple--row5{
    height: 133px!important;
  }
}
 
.c--select__arrow{
  position: absolute;
  top: 50%;
  right: 15px;
  width: 8px;
  height: 8px;
  border: solid $clr-g70;
  border-width: 0 2px 2px 0;
  transform: translateY(-50%) rotate(45deg);
  pointer-events: none;

  .c--select select:hover ~ &
  .c--select select:focus ~ &{
    border-top-color: $clr-b;
  }

  .c--select select:disabled ~ &{
    border-top-color: $clr-g40;
  }

}


// テキスト中央寄せのセレクトボックス
.c--select__tcArea {
  position: relative;
  display: inline-block;
  width: auto;
  height: 37px;
  margin: 0;
  border: 2px solid $clr-g40;
  border-radius: 4px;
  background: $clr-w;
  text-align: center;
  cursor: pointer;
  .c--tc__label {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 7px 30px 7px 10px;
    color: $clr-p80;
    font-size: remSize(13);
    font-weight: bold;
    z-index: 1;
  }
  .c--tc__select {
    position: relative;
    display: block;
    width: 100%;
    padding: 8px 30px 8px 10px;
    border: none;
    opacity: 0;
    appearance: none;
    cursor: pointer;
    z-index: 2;
    &:focus {
      z-index: -1;
      opacity: 1;
    }
    /* IE10以上で矢印を消す */
    &::-ms-expand {
      display: none; 
    }
  }
}


.c--select__arrow--pk{
  position: absolute;
  width: 18px;
  height: 100%;
  top: 0;
  right: 0;
  border-left: 1px solid $clr-g30;
  i{
    position: absolute;
    top: 50%;
    right: 6px;
    width: 6px;
    height: 6px;
    border: solid $clr-p80;
    border-width: 0 2px 2px 0;
    -webkit-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg);
    pointer-events: none;
  }
}




/** ========================================
 * @
 * フォームの枠色を変更するクラス
 * ====================================== */
/** ====================
 * 一括変換
 * ==================== */
// 背景青
.c--input--bgLb{
  input,
  select,
  textarea{
    background: #e8f0fe;
  }
}

//  必須
.c--input--required{
  // input・txtarea
  input,
  textarea,
  select,
  .c--control__indicator{
    border: 2px solid $clr-lb80;
  }
}

// エラー
.c--input--error{
  input,
  textarea{
    background: #FFD6E4;
    color: #BDBDC1;
  }
  // チェック・ラジオ
  .c--control__indicator{
    background: #FFD6E4;
  }
  // セレクト
  select{
    background: #FFD6E4;
    &.no__select{
      color: #BDBDC1;
      option:not(:first-child) {
        color: $clr-b;
      }
    }
  }
}
// チェック・ラジオ
.c--input--required2{
  color: $clr-red;
  .c--control__indicator{
    border: solid 1px $clr-red;
    outline: solid 3px #fb9dab;
  }
}
.c--input--disabled{
  color:#c1c1c1;
  &:hover{
    cursor: default;
  }
}

/** ====================
 * 個々変換
 * ==================== */
// 背景青
input,
select,
textarea{
  &.c--input--bgLb{
    background: #e8f0fe;
  }
}

//  必須
// input・txtarea・select
input,
textarea,
select{
  &.c--input--required{
    border: 2px solid $clr-lb80;
  }
}
// チェック・ラジオ
.c--control__indicator{
  &.c--input--required{
    border-color: $clr-lb80;
  }
}


// エラー
// input・txtarea
input,
textarea{
  &.c--input--error{
    background: #FFD6E4;
    color: #BDBDC1;
  }
}
// チェック・ラジオ
.c--control__indicator{
  &.c--input--error{
    background: #FFD6E4;
  }
}
// セレクト
select{
  &.c--input--error{
    background: #FFD6E4;
    &.no__select{
      color: #BDBDC1;
      option:not(:first-child) {
        color: $clr-b;
      }
    }
  }
}

@media screen and (max-width: 767px){
  .c--inputSp__per--100{
    td{
      padding: 0;
      .c--control{
        width: 100%;
        padding: 12px 0 12px 49px;
        margin: 0;
        &__indicator{
          left: 20px;
          height: 16px;
          width: 16px;
        }
        + .c--control{
          border-top: 1px solid $clr-g40;
        }
      }
      .p--strong__wrap{
        padding: 0;
      }
    }
  }
  .c--select{
    &__multiple--row5{
      height: 45px!important;
    }
  }
}


 /** ========================================
 * セレクトテーブル(先頭にチェックボックスがあるテーブル)
 * ====================================== */
@for $i from 1 through 20 {
  $val : ($i*5);
  .selectTable__w__per--#{$val} {
    width: #{$val}% !important;
  }
}


/** ========================================
 * エラー
 * ====================================== */
 .c--error__list{
  margin: 0 0 20px;
  color: $clr-red;
  li{
    color: $clr-red;
    &:before{
      content:"・ ";
    }
  }
}
.c--error__txt{
  color: $clr-red;
  margin-top: 10px;
}


/** ========================================
 * パスワード強度
 * ====================================== */
.p--pass_strength{
  display: flex;
  align-items: center;
  margin: 7px 0 0;
  font-size: remSize(12);
  span{
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 0 0 7px;
    background: $clr-g40;
    &.active:nth-child(1){
      background: $clr-red;
    }
    &.active:nth-child(2){
      background: $clr-red;
    }
    &.active:nth-child(3){
      background: $clr-red;
    }
  }
  i {
    margin-left: .5em;
  }
}



/** ========================================
 * @
 * jQuery UI Datepicker
 * ====================================== */
// datepickerエリア見た目
input[type=text].c--ui__date{
  padding-right: 40px;
  background: url(../images/icon_calendar.png) right 7px center no-repeat !important;
  background-size: 23px!important;
  font-size: .9rem;
  &:focus{
    background-color: #e2f8ff!important;
  }
  &.error{
    background: url(../images/icon_calendar.png) right 7px center no-repeat #fcdcdb !important;
  }
}
 // datepicker上書き
 .ui-widget-header {
  border: none !important;
  background: none !important;
 }
 .ui-state-default {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 30px !important;
  height: 30px !important;
 }
 .ui-datepicker-week-end span[title=日曜日],
 .ui--sunday .ui-state-default,
 .ui--sunday,
 .ui--holiday {
  color: $clr-red !important;
 }
 .ui-datepicker-week-end span[title=土曜日],
 .ui--saturday .ui-state-default,
 .ui--saturday {
  color: #00abeb !important;
 }
 
 .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
  border: none !important;
  background: none !important;
  color: inherit!important;
 }
 
 .ui-widget-content .ui-datepicker-today span.ui-state-default,
 .ui-widget-content .ui-datepicker-today a.ui-state-default{
  background: #ccc !important;
  border-radius: 50%;
  color: #fff!important;
 }
 
.ui-datepicker .ui-state-active,
.ui-datepicker .ui-widget-content .ui-state-active{
    border: none !important;
    border-radius: 50%;
    background: $clr-p80 !important;
    color: $clr-w !important;
 }
 .ui-datepicker .ui-datepicker-title{
  display: flex;
  justify-content: center;
  align-content: center;
 }
 select.ui-datepicker-month {
  border: none !important;
 }
 .ui-datepicker th{
  font-weight: normal!important;
 }
 .ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year{
  width: 40% !important;
  font-weight: bold;
 }
 .ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next{
  display: inline-block;
 }
 .ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span{
  position: absolute;
  background: transparent;
  top: 50%;
  width: 11px;
  height: 11px;
  margin-top: -4px !important;
  margin-left: -6px !important;
  border: solid $clr-b;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
 }
 .ui-datepicker .ui-datepicker-next span{
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
 }
 .ui-datepicker .ui-datepicker-next{
  right:1px !important;
  top:1px !important;
 }
 .ui-datepicker .ui-datepicker-prev{
  left:1px !important;
  top:1px !important;
 }




/** ========================================
 * @
 * パスワード
 * ====================================== */
/** ========================================
 * パスワードの目
 * ====================================== */
.hideShowPassword-toggle {
  background-color: transparent;
  background-image: url(../images/wink.png); /* fallback */
  background-image: url(../images/wink.svg), none;
  background-position: 0 center;
  background-repeat: no-repeat;
  border: 2px solid transparent;
  border-radius: 0.25em;
  cursor: pointer;
  font-size: 100%;
  height: 44px;
  margin: 0;
  max-height: 100%;
  padding: 0;
  overflow: 'hidden';
  text-indent: -999em;
  width: 46px;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.hideShowPassword-toggle-hide {
  background-position: -44px center;
}

.hideShowPassword-toggle:hover,
.hideShowPassword-toggle:focus {
  outline: transparent;
}
::-ms-clear { display: none; }
::-ms-reveal { display: none; }


/** ========================================
 * @
 * // コンテンツ
 * ====================================== */
main{
  min-width: 1200px;
  min-height: calc(100vh - 103px - 64px); //高さ - ヘッダー - シンプルフッター
  background: #fff7f6;
}
.c--contents{
  background: #fff7f6;
  padding: 25px 0;
  &--noBg{
    background: $clr-w;
    padding: 25px 0;
  }
  &__inner{
    width: 1200px;
    margin: 0 auto;
  }
  &--lastYear{
    background: #ececec;
  }
  &--nextYear{
    background: #ffd;
  }

}


@media screen and (max-width: 767px){
  main{
    min-width: auto;
    min-height: calc(100vh - 450px);
  }
  .c--contents{
    padding-bottom: 0;
  }
  .c--contents__inner{
    width: 100%;
  }
}

/** ========================================
 * @
 * // 線関係
 * ====================================== */
// ドットの破線
.c--dot__line {
  height: 3px;
  position: relative;
}
.c--dot__line::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(to right, $clr-g40, $clr-g40 3px, transparent 3px, transparent 12px);
  background-size: 12px 3px;
  background-repeat: repeat-x;
}


/** ========================================
 * @
 * // 矢印
 * ====================================== */
// ピンク矢印
.c--arrow{
  position: relative;
  &:before{
    content: "";
    position: absolute;
    top: 49%;
    width: 7px;
    height: 7px;
    border: solid $clr-p80;
    border-width: 0 2px 2px 0;
    z-index: 10;
  }
  &--up:before{
    transform: translateY(-50%) rotate(-135deg);
    top: 50%;
  }
  &--down:before{
    transform: translateY(-50%) rotate(45deg);
  }
  &--right:before{
    transform: translateY(-50%) rotate(-45deg);
  }
  &--left:before{
    transform: translateY(-50%) rotate(135deg);
    top: 50%;
  }

  &--circle{
    position: relative;
    display: inline-block;
    padding: 0 0 0 20px;
    &:before{
      content: "";
      position: absolute;
      display: inline-block;
      top: 50%;
      left: 0;
      width: 16px;
      height: 16px;
      background-size: contain;
      transform: translateY(-50%);
      background: url(../images/arrow_circle_r.png) 50%/16px no-repeat;
    }
    &--down:before{
      transform: translateY(-50%) rotate(90deg);
    }
  }

  &--p__left{
    padding: 0 0 0 14px;
    &:before{
      left: 0;
    }
  }
  &--p__right{
    padding: 0 14px 0 0;
    &:before{
      right: 1px;
    }
  }
  &__box{
    &:before{
      top: auto;
      right: 10px;
      bottom: 4px;
    }
  }
}


/** ========================================
 * @
 * // 編集アイコン付きリンク
 * ====================================== */
.c--link__edit{
  position: relative;
  padding: 0 0 0 22px;
  font-size: remSize(13);
  font-weight: normal;
  &:before{
    content: "";
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 0;
    width: 16px;
    height: 16px;
    background-size: contain;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: url(../images/icon_pencil.png) 50%/16px no-repeat;
  }
}



/** ========================================
 * @
 * // ホバーでシャドウが付く
 * ====================================== */
 .c--hoverShadow{
  &__item{
    display: inline-block;
    background: $clr-w;
  }

  &--border{
    .c--hoverShadow__item{
      border: 1px solid $clr-g40;
    }
  }
  &--shadow{
    .c--hoverShadow__item{
      box-shadow: 0 2px 2px rgba(97, 97, 106, 0.2), 0 1px 5px rgba(97, 97, 106, 0.1);
    }
  }
}
@media screen and (min-width: 768px){
  /** ========================================
  * @
  * // ホバーでシャドウが付く
  * ====================================== */
  .c--hoverShadow{
    &__item{
      &:hover{
        cursor: pointer;
        opacity: 1;
        box-shadow: 4px 4px 8px rgba(97, 97, 106, 0.2), 1px 1px 20px rgba(97, 97, 106, 0.2);
        transition: all .25s;
        z-index: 5;
      }
    }

    &--border{
      .c--hoverShadow__item{
        &:hover{
          border-color: transparent;
        }
      }
    }
    &--shadow{
      .c--hoverShadow__item{
        &:hover{
          box-shadow: 0 4px 8px rgba(97, 97, 106, 0.2), 0 1px 20px rgba(97, 97, 106, 0.2);
        }
      }
    }
  }
}


/** ========================================
 * @
 * // 要素の装飾
 * ====================================== */

.c--boxWht{
  background: $clr-w;
}
.c--boxlp{
  background: $clr-p20;
}
.c--boxBdg{
  border: 1px solid $clr-g40;
  &--pc{
    border: 1px solid $clr-g40;
  }
}


@media screen and (max-width: 767px){
  .c--boxBdg{
    &--pc{
      border: none;
    }
    &--sp{
      border: 1px solid $clr-g40;
    }
    &--spNolr{
      border-right: none;
      border-left: none;
    }
  }
}


// 要素にシャドウをつける
.c--boxShadow{
  box-shadow: 0 2px 2px rgba(97,97,106,.2), 0 1px 5px rgba(97,97,106,.1);
}

.c--bdt--p{
  border-top: 2px solid $clr-p80;
}
.c--bdt--lb{
  border-top: 2px solid $clr-lb60;
}
.c--bdt--g{
  border-top: 1px solid $clr-g40;
}


/** ========================================
 * @
 * // 数字バッジ
 * ====================================== */
.c--notice__num{
  display: inline-block;
  min-width: 20px;
  height: 20px;
  margin-left: 5px;
  padding: 0 5px;
  border-radius: 100px;
  background: $clr-red;
  color: $clr-w;
  font-size: .6rem;
  font-weight: 400;
  text-align: center;
  line-height: 18px;
}


/** ========================================
 * @
 * // 角丸長方形
 * ====================================== */
.c--oval--pk{
  display: inline-block;
  border: 1px solid $clr-p80;
  border-radius: 100px;
  color: $clr-p80;
}

.c--oval--m{
  padding: 4px 13px;
  font-size: .7rem;
  text-align: center;
}

@media screen and (max-width: 767px){
  .c--oval--m{
    padding: 2px;
  }
}


/** ========================================
 * @
 * // 画像付きリスト
 * ====================================== */
// リストの上に乗るタグ
.c--card{
  display: flex;
  width: 100%;
  &--col3{
    .c--card__item{
      width: calc((100% - 48px) / 3);
      &:nth-child(3n){
        margin: 0;
      }
    }
  }
  &--col4{
    .c--card__item{
      width: calc((100% - 72px) / 4);
      &:nth-child(4n){
        margin: 0;
      }
    }
  }
  a{
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__item{
    display: block;
    margin: 0 24px 0 0;
    padding: 0 0 20px;
    white-space: normal;
    border-bottom: 1px solid $clr-g30;
    a:hover{
      color: $clr-p80;
      .c--item__txt{
        color: $clr-b;
      }
    }
    &.c--arrow__box:before{
      right: 5px;
    }
    .c--item__img{
      position: relative;
      img{
        height: 171px;
      }
      .c--list__tag{
        position: absolute;
        left: 4px;
        top: 4px;
      }
    }
    .c--item__ttl{
      flex-grow: 1;
      padding: 15px 15px 0;
      font-size: remSize(16);
      font-weight: bold;
      .c--item__catchcopy{
        overflow: hidden;
        width: 100%;
        font-size: remSize(12);
        font-weight: normal;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .c--item__txt{
      display: flex;
      flex-wrap: wrap;
      margin: 12px 0 0;
      padding: 0 15px;
      font-size: remSize(12);
      font-weight: normal;
      span{
        display: inline-block;
        width: 50%;
        &:nth-child(n + 3){
          margin: 5px 0 0;
        }
        i{
          color: $clr-g70;
        }
      }
    }
  }
}
.c--list__tag{
  display: inline-block;
  padding: 3px 7px;
  border: 1px solid $clr-p80;
  border-radius: 100px;
  background: $clr-w;
  color: $clr-p80;
  font-size: remSize(12);
}

@media screen and (max-width: 767px){
  .c--list__tag{
    padding: 1px 7px;
  }
  .c--card{
    &__item{
      padding: 0 0 25px;
      .c--item__img .c--list__tag{
        position: relative;
        left: 0;
        top: 0;
        margin: 7px 0 0;
      }
      .c--item__ttl{
        padding: 7px 0 0;
        font-size: remSize(12);
      }
    }
    &--spCol1{
      display: block;
      .c--card__item{
        width: 100%;
      }
    }
    &--spCol2{
      flex-wrap: wrap;
      .c--card__item{
        width: calc(50% - 8px);
        margin: 0 16px 0 0;
        &:nth-child(even){
          margin-right: 0;
        }
        &:nth-child(n + 3){
          margin-top: 17px;
        }
        .c--item__img{
          .c--list__tag{
            margin: 8px 0 0;
          }
          img{
            height: 103px;
          }
        }
      }
    }
    &--scrl{
      overflow: hidden;
      overflow-x: auto;
      padding: 0 0 10px 20px;
      .c--card__item{
        min-width: 187px;
        .c--item__img{
          img{
            height: 120px;
          }
        }
        .c--item__ttl{
          padding: 18px 12px 0;
        }
        .c--item__txt{
          display: block;
          span{
            width: 100%;
            &:not(:first-child){
              margin: 6px 0 0;
            }
          }
        }
      }
    }
  }
}



/** ========================================
 * @
 * // 背景グレーの画像が乗ったアイコン
 * ====================================== */
.p--btn{
  position: relative;
  display: inline-block;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;

  .p--btn__icon{
    position: relative;
    &:after{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      content: "";
      display: block;
      background-size: contain;
    }
    &:before{
      content: "";
      background-color: $clr-g20;
      border-radius: 50%;
      display: block;
      margin: 0 auto 7px;
    }
  }
}


/** ========================================
 * @
 * // トグル関係
 * ====================================== */
@media screen and (max-width: 767px){
  .js--tglSwitch--sp{
    position: relative;
    &:after{
      content: "";
      position: absolute;
      top: 48%;
      right: 17px;
      width: 7px;
      height: 7px;
      border: solid $clr-p80;
      border-width: 0 2px 2px 0;
      z-index: 10;
      transform: translateY(-50%) rotate(45deg);
    }
    &.c--tglSwitch--open{
      &:after{
        transform: translateY(-50%) rotate(-135deg);
      }
    }
  }

  .c--spNoTgl{
    &:before{
      background: none!important;
    }
  }

}


/** ========================================
 * @
 * // 画像
 * ====================================== */
 .c--cover{
  object-fit: cover;
  font-family: "object-fit: cover;";
}
.c--contain{
  object-fit: contain;
  font-family: 'object-fit: contain;'
}



/** ========================================
 * @c--impNotices
 * お知らせ関係
 * ====================================== */
.c--impNotices {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: $clr-red;
  box-shadow: 0 2px 2px rgba(0,0,0,.2), 0 1px 5px rgba(0,0,0,.1);
}
.c--impNotices__heading{
  position: relative;
  width: 134px;
  padding: 0 0 0 32px;
  color: $clr-w;
  font-size: .8rem;
  &:before{
    content: "";
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 12px;
    width: 16px;
    height: 16px;
    background-size: contain;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background: url(../images/icon_imp.png) 50%/16px no-repeat;
  }
}
.c--notices__list{
  width: 100%;
  background: $clr-w;
  .c--list__item{
    &--new{
      .c--item__txt{
        &:after{
          content: 'NEW';
          display: inline-block;
          color: $clr-red;
          font-weight: bold;
        }
      }
    }
    &:not(:first-child){
      border-top: 1px solid $clr-g30;
    }
    a,div{
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 12px 25px 11px 20px;
      font-size: remSize(13);
      &:hover{
        color: $clr-p80;
      }
      .c--oval--m{
        margin: 0 13px 0 0;
        width: 120px;
      }
      &.c--arrow--p__right:before{
        right: 10px;
      }
    }
    .c--item__txt{
      width: calc(100% - 120px);
    }
  }
}

@media screen and (max-width: 767px){
  .c--impNotices{
    display: block;
    border-radius: unset;
    &__heading{
      width: calc(100% - 20px);
      padding: 5px 34px;
      margin: 0 10px;
    }
  }

  .c--notices__list{
    .c--list__item{
      a,div{
        display: flex;
        align-items: flex-start;
        padding: 7px 20px 11px;
        font-size: remSize(12);
        .c--oval--m{
          width: 104px;
          margin: 8px 8px 0 0;
        }
      }
      .c--item__txt{
        width: calc(100% - 112px);
      }
    }
  }
}


/** ========================================
 * @c--recommended
 * オススメPR
 * ====================================== */
 .c--recommended {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: $clr-p80;
  box-shadow: 0 2px 2px rgba(0,0,0,.2), 0 1px 5px rgba(0,0,0,.1);
}
.c--recommended__heading{
  position: relative;
  width: 134px;
  padding: 0 0 0 10px;
  color: $clr-w;
  font-size: .8rem;
}


@media screen and (max-width: 767px){
  .c--recommended{
    display: block;
    border-radius: unset;
    &__heading{
      width: 100%;
      padding: 5px 20px;
    }
    .c--notices__list .c--list__item .c--item__txt{
      width: 100%;
    }
  }
}

/** ========================================
 * @
 * // お知らせポップアップ
 * ====================================== */
 .c--message {
  transform: translateX(-50%);
  position: fixed;
  top: 90px;
  left: 50%;
  min-width: 300px;
  padding: 30px 10px 22px;
  border-radius: 10px;
  background-color: #ffe4ed;
  background-image: url(../images/point_border_top.png), url(../images/point_border_bottom.png);
  background-position: center top, center bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: auto, auto;
  box-shadow: 1px 0 20px rgba(97,97,106,.2), 4px 0px 8px rgba(97,97,106,.2);
  text-align: center;
  z-index: 1000;
  &__heading{
    display: flex;
    align-items: center;
    justify-content: center;
    color: $clr-p80;
    font-size: remSize(20);
    font-weight: bold;
    &:before{
      content: "";
      display: inline-block;
      width: 50px;
      height: 43px;
      background: url(../images/point_icon.png) 50% no-repeat;
      background-size: 50px;
    }
  }
  &__txt{
    margin: 10px 0 15px;
    font-size: remSize(13);
    line-height: 1.7;
  }
  &__atten{
    margin: 10px 0 0;
    font-size: remSize(10);
    line-height: 1.6;
  }
  .c--btn--m{
    padding: 11px 20px;
  }
}




// フロー
.c--contents__flow{
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  .c--flow__item{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 200px;
    position: relative;
    .c--item__num{
      color: $clr-b;
      display: inline-block;
      width: 27px;
      height: 27px;
      border-radius: 50%;
      border: 1px solid $clr-g40;
      background: $clr-w;
      text-align:center;
      line-height: 27px;
      vertical-align: middle;
    }
    .c--item__txt{
      width: 100%;
      margin-top: 5px;
      text-align: center;
    }
    .c--item__num{
        position: relative;
    }
    &--active .c--item__num{
      color: $clr-w;
      background: $clr-p80;
      border: 1px solid $clr-p80;
    }
    + .c--flow__item .c--item__num::before{
      content: "";
      width: 173px;
      height: 4px;
      background: #eceff0;
      position: absolute;
      top: 50%;
      right: 26px;
      transform: translateY(-50%);
    }
    &--active + .c--flow__item.c--flow__item--active .c--item__num::before{
      background: $clr-p80;
    }
  }
}
@media screen and (max-width: 768px) {
  .c--contents__flow{
    .c--flow__item{
      width: calc(100vw / 6);
    }
    .c--flow__item + .c--flow__item .c--item__num::before{
      width: calc(100vw / 6 - 27px);
    }
  }
}

@media screen and (max-width: 480px) { 
  .c--contents__flow{
    .c--flow__item .c--item__txt{
      display: none;
    }
  }
}

/*必須ラベルアイコン*/
.c--require__label{
  min-width: 26px;
  margin: 0 5px;
  color: #fff;
  font-weight: 400;
  font-size: remSize(10);
  background: #f84361;
  padding: 3px;
  line-height: 1;
  display: inline-block;
}



// ボタン横並び
.c--col__btn2{
  .c--btn{
    + .c--btn{
      margin-left: 10px;
    }
  }
}
// 230pxの横並び
.c--col__btn--230{
  .c--btn{
    width: 230px;
    + .c--btn{
      margin-left: 10px;
    }
  }
}
@media screen and (max-width: 768px) {
  .c--col__btn--230{
    .c--btn{
      width: 150px;
      padding: 18px 10px;
    }
  }
}



/** ========================================
 * @
 * 左側にメニューのある２カラム
 * ====================================== */
// 見出し
.c--contHeading--gr{
  padding: 8px 20px;
  border-radius: 4px 4px 0 0;
  background: $clr-g70;
  color: #fff;
  font-size: remSize(16);
  font-weight: bold;
}
.c--contHeading--gr.c--contHeading--form{
  margin: 50px 0 0;
}
div + .c--contHeading--gr{
  margin: 20px 0 0;
}
// 上部2カラム
.c--menuBlock{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 40px 0 0;
  .c--menu__side{
    width: 283px;
    box-shadow: 2px 2px 2px rgba(97,97,106,.2), 1px 1px 5px rgba(97,97,106,.1);
    &--noShadow{
      box-shadow: none;
      .c--side__inner{
        box-shadow: 2px 2px 2px rgba(97,97,106,.2), 1px 1px 5px rgba(97,97,106,.1);
        + .c--side__inner{
          margin: 20px 0 0;
        }
      }
    }
  }
  .c--menu__main{
    width: 894px;
  }
  .c--menu__setting{
    padding: 15px;
    border-top: 1px solid $clr-g30;
    border-radius: 0 0 4px 4px;
    background: #fff;
    li:not(:first-child){
      margin: 10px 0 0;
    }
  }
  &--col1{
    display: block;
    .c--menu__main{
      width: 100%;
      margin: 0;
    }
  }

  // メニュー
  .c--point__main{
    padding: 23px 20px;
    background: $clr-w;
  }
  .c--point__txt{
    text-align: center;
    i{
      display: inline-block;
      margin: 0 3px;
      color: $clr-p80;
      font-size: remSize(32);
      font-weight: bold;
    }
  }
  .c--point__link{
    margin: 20px 0 0;
  }

  // 検索結果
  .c--search__main{
    padding: 16px 20px;
    background: $clr-w;
    border-radius: 0 0 4px 4px;
    &--nopd{
      padding: 0;
    }
    .c--main__item{
      padding: 16px 20px;
      &:not(:first-child){
        border-top: 1px solid $clr-g40;
      }
    }
  }

  // 先頭アイコン付き
  .c--link__item{
    border-top: 1px solid $clr-g30;
    a{
      position: relative;
      display: inline-block;
      width: 100%;
      padding: 17px 20px 17px 42px;
      background: $clr-w;
      line-height: 1;
      cursor: pointer;
      &.c--arrow--p__right:before {
        right: 20px;
      }
      &:after{
        content: "";
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        background-size: contain!important;
        background-repeat: no-repeat;
      }
    }
    &--fav a:after{
      background-image: url(../images/icon_fav.png);
    }
    &--comparison a:after{
      background-image: url(../images/icon_graph.png);
    }
    &--msg a:after{
      background-image: url(../images/icon_msg.png);
    }
    &--scout a:after{
      background-image: url(../images/icon_scout.png);
    }
    &--history a:after{
      background-image: url(../images/icon_history.png);
    }
    &--setting a:after{
      background-image: url(../images/icon_setting.png);
    }
  }
  .c--link__item--active{
    a{
      background: $clr-p80;
      color: $clr-w;
      &.c--arrow:before {
        border-color: $clr-w;
      }
    }
    .c--notice__num{
      background: $clr-w;
      color: $clr-red;
    }
    &.c--link__item{
      &--fav a:after{
        background-image: url(../images/icon_fav-w.png);
      }
      &--comparison a:after{
        background-image: url(../images/icon_graph-w.png);
      }
      &--msg a:after{
        background-image: url(../images/icon_msg-w.png);
      }
      &--scout a:after{
        background-image: url(../images/icon_scout-w.png);
      }
      &--history a:after{
        background-image: url(../images/icon_history-w.png);
      }
      &--setting a:after{
        background-image: url(../images/icon_setting-w.png);
      }
    }
  }

}

@media screen and (max-width: 767px){
  // 見出し
  .c--contHeading--gr{
    padding: 5px 20px;
    border-radius: 0;
    font-size: remSize(13);
  }
  .c--contHeading--gr.c--contHeading--form{
    margin: 0;
  }
  // 上部2カラム右側(左は基本非表示)
  .c--menuBlock{
    display: block;
    margin: 20px 0 0;
    .c--menu__side{
      width: 100%;
      &:not(.c--pc__only){
        margin: 25px 0;
      }
    }
    .c--menu__main{
      width: 100%;
      margin: 0;
    }
  }
  // 設定
  .c--fav__setting{
    background: $clr-g30;
    .c--setting{
      padding: 0 20px;
      li{
        &:not(:first-child){
          margin: 10px 0 0;
        }
        a{
          font-size: remSize(16);
          font-weight: bold;
        }
      }
    }
  }

}

/** ========================================
 * @
 * // マップ付き都道府県チェックボックスエリア
 * ====================================== */
.c--areaWrap{
  // チェックボックス装飾
  .c--control{
    width: 100%;
    margin: 0;
    padding: 5px 0 5px 26px;
    &:hover,
    &:focus{
      color: $clr-p80;
    }
  }

  // マップ
  .c--main__map{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    height: 680px;
    padding: 0;
    background-size: 100%;
    background-image: url(../images/map.png);
    background-repeat: no-repeat;
    background-position: top;
  }

  // チェックボックスの位置
  .c--area{
    display: flex;
    flex-direction: column;
    width: calc(25% - 18px);

    &--col3{
      position: relative;
      width: calc(50% - 12px);
      .c--area__item{
        position: absolute;
        width: calc(50% - 12px);
        &--pref04{
          left: 0;
          top: 0;
        }
        &--pref05{
          right: 0;
          bottom: 36px;
          &.c--area__item--details__pref05{
            bottom: 24px;
          }
        }
        &--pref06{
          left: 0;
          bottom: 0;
        }
      }
    }

    &--justEnd{
      justify-content: flex-end;
      .c--area__item{
        margin: 0 0 36px!important;
      }
    }
    &--between{
      justify-content: space-between;
    }
    &:last-child{
      .c--area__item:last-child{
        margin: 45px 0 0!important;
      }
    }
    &__item{
      width: 100%;
      padding: 0;
      border: 1px solid $clr-g40;
      border-radius: 4px;
      background: $clr-w;
      &:nth-child(n + 2){
        margin: 25px 0 0;
      }
      .c--control__indicator{
        width: 16px;
        height: 16px;
      }
    }
    .c--item{
      &__heading{
        width: 100%;
        font-size: remSize(16);
        .c--control{
          width: 100%;
          margin: 0;
          padding: 13px 20px 13px 46px;
        }
        .c--control__indicator{
          width: 16px;
          height: 16px;
          left: 16px;
        }
      }
      &__body{
        display: flex;
        flex-wrap: wrap;
        margin: 0 7px;
        padding: 9px 4px 12px;
        border-top: 1px solid $clr-g30;
        font-size: remSize(13);
        li{
          width: 50%;
          padding: 0 0 0 8px;
          &:nth-child(n + 3){
            margin: 7px 0 0;
          }
        }
        .c--hoverShadow__item{
          border-radius: 4px;
        }
      }
    }
  }


}


@media screen and (max-width: 767px) {
  .c--areaWrap{
    // チェックボックス装飾
    .c--control{
      input{
        font-size: remSize(13);
      }
    }
    .c--hoverShadow__item:hover{
      box-shadow: none;
    }

    .c--area__item--pref01{
      .c--item__heading{
        &:after,
        &:before{
          display: none;
        }
      }
    }
    .c--main__map{
      flex-direction: column;
      height: auto;
      background: none;
    }
    .c--area{
      width: 100%;
      display: block;
      &--col3{
        width: 100%;
        .c--area__item{
          position: unset;
          width: 100%;
        }
      }
      &__item{
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: unset;
        &:nth-child(n+2){
          margin: 0;
        }
      }
      &:first-child .c--area__item:first-child{
        border-top: 1px solid $clr-g40;
      }
      &--justEnd .c--area__item,
      &:last-child .c--area__item:last-child{
        margin: 0!important;
      }

      .c--item__heading{
        padding: 11px 20px;
        font-size: remSize(14);
        .c--control{
          padding: 0 0 0 26px;
          width: auto;
          &__indicator{
            left: 0;
          }
        }
      }
      .c--item__heading{
        position: relative;
        &:before{
          content: "";
          position: absolute;
          top: 0;
          right: 45px;
          width: 1px;
          height: 100%;
          background: $clr-g40;
        }
        &:after{
          right: 17px;
        }
      }


      .c--item__body{
        padding: 0 0 0 38px;
        border-top: none;
        border-top: 1px solid $clr-g40;
        li{
          width: 100%;
          margin: 0!important;
          padding: 8px 0;
          &:nth-child(n + 2){
            border-top: 1px solid $clr-g40;
          }
        }
        .c--hoverShadow__item{
          border-radius: unset;
        }
      }
    }


  }
}




/** ========================================
 * @
 * 楕円形タグ
 * ====================================== */
.c--ovalTag{
  display: inline-block;
  width: 128px;
  background: $clr-w;
  margin: 0 5px 5px 0;
  padding: 1px 0;
  border: 1px solid ;
  border-radius: 100px;
  font-size: remSize(12);
  text-align: center;
  &--gr{
    border-color: #2CB200;
    color: #2CB200;
  }
  &--pl{
    border-color: #9979DF;
    color: #9979DF;
  }
  &--bl{
    border-color: #40C4FF;
    color: #40C4FF;
  }
  &--og{
    border-color: #FF7F00;
    color: #FF7F00;
  }
  &--pk{
    border-color: #E4267E;
    color: #E4267E;
  }
  &--yl{
    border-color: #ffcc00;
    color: #D3A900;
    &.c--ovalTag--fill{
      border-color: #FFF79D;
      background: #FFF79D;
      color: #333;
    }
  }
  &--gy{
    border-color: $clr-g70;
    color: $clr-g70;
    &.c--ovalTag--fill{
      border-color: #c5c5c6;
      background: #c5c5c6;
      color: #808084;
    }
  }
  &--w__auto{
    width: auto;
    padding: 4px 15px;
  }
  &--sml{
    width: 80px;
    font-size: remSize(10);
  }
}
.c--taggedBlock{
  .c--boxHeading{
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px){
  .c--ovalTag{
    &--w__auto{
      margin: 4px;//指摘されたため4pxにしてプレビューに合わせました
      padding: 1px 8px;
      font-size: .625rem;
    }
  }
}


.c--textCol1{
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.c--link__pdf{
  position: relative;
  display: inline-block;
  margin: 10px 0 0;
  padding: 0 0 0 26px;
  color: $clr-p80;
  font-size: remSize(13);
  font-weight: normal;
  &:before{
    content: "";
    position: absolute;
    display: inline-block;
    top: 50%;
    left: 0;
    width: 18px;
    height: 23px;
    background-size: contain;
    transform: translateY(-50%);
    background: url(../images/icon_pdf.png) no-repeat;
  }
}

// 背景ピンクのトグルエリア
.c--tgl__block--pk{
  .c--tgl__head{
    padding: 10px 15px;
    background: #FFEFF4;
    font-weight: bold;
    &.c--arrow{
      &--p__right:before{
        right: 17px;
        width: 6px;
        height: 6px;
      }
      &.p--tgl--open{
        &:before{
          transform: translateY(-50%) rotate(-135deg);
        }
      }
    }
  }
}
.c--tgl__head{
  &:hover{
    cursor: pointer;
  }
}



/** ========================================
 * jQuery UI autocompleteの上書き
 * @SENI-001
 * 2020.07.06
 * ====================================== */
.ui-autocomplete .ui-state-active,
.ui-autocomplete .ui-widget-content .ui-state-active{
    border: none !important;
    background: #ccc !important;
}

