/*! 
 * お気に入り
 */

 /** ========================================
 * @p--mypage
 * マイページエリア
 * ====================================== */
// TOP
.p--fav__top{
  padding: 25px 0 60px;
}
// おすすめコンテンツ
.p--fav__rmd{
  padding: 55px 0 60px;
  background: $clr-w;
  .c--card{
    margin: 40px 0 0;
  }
}
// あなたにおすすめの病院
.p--fav__hospital{
  padding: 55px 0 115px;
  .c--card{
    margin: 40px 0 0;
  }
  .c--hoverShadow__item{
    background: $clr-p10;
  }
}

.p--show__btn{
  &:hover{
    cursor: pointer;
  }
  &--close,
  &--open{
    position: relative;
    height: 30px;
    &:before,
    &:before{
      content: "";
      position: absolute;
      top: 48%;
      width: 12px;
      height: 12px;
      border: solid #e92d82;
      border-width: 0 2px 2px 0;
      z-index: 10;
      left: 50%;
    }
  }
  &--close{
    &:before{
      transform: translateY(-50%) rotate(45deg);
    }
  }
  &--open{
    &:before{
      margin: 5px 0 0;
      transform: translateY(-50%) rotate(-135deg);
    }
  }

}
.p--show__block{
  overflow: hidden;
}


// メイン
// 新着のお知らせ
.p--infoBlock{
  box-shadow: 2px 2px 2px rgba(97,97,106,.2), 1px 1px 5px rgba(97,97,106,.1);
}





/** ========================================
 * @p--reg
 * 登録情報一覧エリア
 * ====================================== */

// 件数・ボタン
.p--reg{
  margin: 40px 0 0;
  &__top{
    display: flex;
    justify-content: space-between;
    .p--top__num{
      font-size: remSize(16);
      font-weight: bold;
      i{
        color: $clr-p80;
        font-size: remSize(24);
      }
    }
    .p--top__btn{
      display: flex;
      align-items: center;
      .c--btn{
        height: 38px;
        padding: 7px;
        font-size: remSize(13);
        vertical-align: middle;
      }
      .c--select__tcArea{
        width: 230px;
        margin: 0 0 0 8px;
      }
    }
  }

  // リスト
  &__block{
    margin: 25px 0 0;
  }
  &__item{
    box-shadow: 2px 2px 2px rgba(97,97,106,.2), 1px 1px 5px rgba(97,97,106,.1);
    &:not(:first-of-type){
      margin: 33px 0 0;
    }
  }
  &__head{
    position: relative;
    padding: 12px 55px 14px 25px;
    border-top: 2px solid $clr-p80;
    border-bottom: 1px solid $clr-g40;
    background: $clr-w;
    font-size: remSize(20);
    font-weight: bold;
    &:hover{
      cursor: pointer;
    }
    &:before{
      content: "";
      position: absolute;
      top: 0;
      right: 54px;
      display: inline-block;
      width: 1px;
      height: 100%;
      background: $clr-g40;
    }
    &:after{
      content: "";
      position: absolute;
      top: 50%;
      width: 12px;
      height: 12px;
      border: solid $clr-p80;
      border-width: 0 2px 2px 0;
      z-index: 10;
      transform: translateY(-50%) rotate(45deg);
      right: 20px;
    }
    &.p--tgl--open:after{
      transform: translateY(-50%) rotate(-135deg);
    }
    .c--ovalTag{
      width: auto;
      padding: 0 10px;
    }
    &--finish{
      background: $clr-g40;
      border-bottom-color: $clr-g50;
      &:before{
        background: $clr-g50;
      }
      + .p--reg__body{
        background: $clr-g40;
        .p--body__btm{
          .p--favAnime{
            background: $clr-g40;
            border: 1px solid $clr-g50;
          }
        }
      }
    }
  }
  &__body{
    // margin: -5px 0 0;
    padding: 21px 24px 16px 24px;
    background: $clr-w;
    &--open{
      display: block;
    }
    .p--body__list{
      display: flex;
      .p--list__item{
        width: calc(25% - 10px);
        padding: 4px 4px 8px;
        background: $clr-g10;
        text-align: center;
        &:not(:first-child){
          margin: 0 0 0 10px;
        }
        &--on{
          background: $clr-lb10;
        }
        .p--item__heading{
          padding: 5px;
          background: $clr-w;
        }
        .c--btn{
          width: calc(100% - 4px);
          padding: 11px 5px;
          &--disabled{
            border: 2px solid $clr-g40;
            background: $clr-g40;
            color: $clr-g70;
          }
        }
        .p--item__icon{
          position: relative;
          width: 36px;
          height: 36px;
          margin: 16px auto;
          &:before{
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: 100%;
            height: 100%;
            background-size: contain!important;
            background-repeat: no-repeat;
          }
          &.p--item__icon--on:before{
            background-image: url(../images/icon_mypage.png);
          }
          &.p--item__icon--on__event:before{
            background-image: url(../images/icon_mypage_event.png);
          }
          &.p--item__icon--off:before{
            background-image: url(../images/icon_mypage_gray.png);
          }
          &.p--item__icon--off__event:before{
            background-image: url(../images/icon_mypage_gray_event.png);
          }
          &.p--item__icon--noSelect:before{
            background: $clr-g40;
            height: 1px;
          }
        }
      }
    }
    
    .p--body__btm{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 13px 0 0;
      font-size: remSize(12);
      .p--btm__date{
        display: flex;
        p:not(:first-child){
          margin: 0 0 0 20px;
        }
        a{
          color: $clr-p80;
          text-decoration: underline;
        }
      }
      .p--fav__btn{
        position: relative;
        padding: 11px 9px 11px 30px;
      }
    
    }
    &--nopd{
      padding: 0 0 16px;
      .p--body__btm{
        padding-right: 24px;
        padding-left: 24px;
      }
    }
    .p--reg__txt__bgGy{
      background: #fafafa;
      padding: 10px;
      font-size: remSize(12);
    }
  }

}



@media screen and (max-width: 767px){
  // TOP
  .p--fav__top{
    padding-bottom: 30px;
  }
  // あなたにおすすめの病院
  .p--fav__hospital{
    padding: 30px 0 55px;
    background: $clr-w;
    .c--card{
      margin-top: 20px;
    }
    .c--hoverShadow__item{
      background: $clr-w;
    }
  }


  /** ========================================
   * @p--reg
   * 登録情報一覧エリア
   * ====================================== */

  // 件数・ボタン
  .p--reg{
    margin: 10px 0 0;
    &__top{
      flex-direction: column;
      padding: 0 20px;
      .p--top__num{
        font-size: remSize(14);
        i{
          font-size: remSize(20);
        }
      }
      .p--top__btn{
        margin: 10px 0 0;
        .c--btn{
          width: calc(50% - 4px);
        }
        .c--select__tcArea{
          width: calc(50% - 4px);
        }
      }
    }
    // リスト
    &__head{
      padding: 15px 55px 15px 21px;
      font-size: remSize(16);
      .c--ovalTag{
        width: auto;
        margin: 0;
        padding: 5px;
        font-size: .625rem;
        font-weight: 400;
        line-height: 1;
      }
    }
    &__body{
      margin: 0;
      padding: 0;
      > P{
        padding: 10px 20px 0;
      }
      .p--body__list{
        .p--list__item{
          width: calc(25% - 1px);
          &:not(:first-child){
            margin: 0 0 0 1px;
          }
          .p--item__heading{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 48px;
          }
          .p--item__icon{
            margin: 11px auto;
          }
        }
      }

      .p--body__btm{
        margin: 0;
        padding: 10px 19px 15px 21px;
        .p--btm__date{
          display: block;
          font-size: remSize(10);
          p:not(:first-child){
            margin: 0;
          }
        }
        .p--fav__btn{
          width: 38px;
          height: 38px;
          padding: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 320px){
  .p--reg__body{
    .p--body__list{
      .p--list__item{
        .p--item__heading{
          font-size: .7rem;
        }
      }
    }
  }
}

/** ========================================
 * @p--history
 * 活動履歴
 * ====================================== */
.p--history{
  .p--reg{
    margin-top: 15px;
    .p--top__narrowDown{
      display: flex;
      align-items: flex-start;
      li:not(:first-child){
        margin: 0 0 0 20px;
        padding: 0 0 0 20px;
        border-left: 1px solid $clr-g40;
        a{
          color: $clr-p80;
        }
      }
    }
  }
  .p--topBdBox{
    margin: 30px 0 0;
    &__heading{
      padding: 0;
      &.p--topBdBox__link{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        padding: 12px 21px 12px 26px;
        .c--arrow--circle:before{
          top: .3rem;
          left: 0;
          transform: none;
        }
        .p--moreMsg{
          display: block;
          margin: 0 0 0 20px;
          color: $clr-p80;
          font-size: remSize(14);
          font-weight: normal;
          line-height: 1;
        }
        .p--date{
          font-size: remSize(12);
          font-weight: normal;
        }
      }
    }
  }

  .p--contBox{
    padding: 20px 25px;
  }
}

.p--headLpBox{
  &:not(:first-child){
    margin: 10px 0 0;
  }
  &--finished{
    .p--headLp__ttl{
      background: #e5d7db;
      .c--ovalTag{
        background: #e5e5e5;
      }
    }
    .p--headLp__body{
      background: #e5e5e5;
    }
  }
}
.p--headLp__ttl{
  display: flex;
  justify-content: space-between;
  padding: 7px 13px;
  background: #ffeff4;
  color: $clr-p80;
  font-weight: bold;
  &--off{
    color: $clr-b;
    font-weight: normal;
  }
  .p--ttl__date{
    color: $clr-b;
    font-size: remSize(12);
    font-weight: normal;
  }
  .c--ovalTag{
    margin: 0 12px 0 0;
    font-weight: normal;
  }
  &.c--arrow--p__right::before{
    right: 10px;
  }
}
.p--headLp__body{
  padding: 10px 13px;
  font-size: remSize(12);
  .p--headLp__list{
    p{
      margin: 5px 0 0;
      span{
        display: inline-block;
        margin: 0 10px 0 0;
        color: #818187;
      }
    }
  }
  .p--headLp__steteBox{
    margin: 14px 0 0;
    .c--ovalTag{
      width: auto;
      padding: 2px 10px;
      font-size: remSize(10);
    }
  }
}

@media screen and (max-width: 767px){
  .p--history{
    .c--select__tcArea{
      width: 100%;
    }
    .p--contBox{
      padding: 10px 20px 15px;
    }
  }
  .p--headLp__ttl{
    display: block;
    .c--ovalTag{
      width: auto;
      min-width: 96px;
      padding: 1px 12px;
      font-size: remSize(10);
    }
    i{
      display: block;
      margin: 5px 0 0;
    }
    p + p{
      margin: 3px 0 0;
    }

  }
  .p--headLp__body{
    .p--headLp__steteBox{
      .c--btn{
        width: 100%;
        font-size: remSize(12);
      }
    }
  }
}


/** ========================================
 * @p--rsv
 * 活動履歴
 * ====================================== */
.p--rsv{
  .c--primary__heading + .p--topBdBox{
    margin: 35px 0 0;
  }
  .p--contBox__inner{
    width: 847px;
    margin: 0 auto;
  }
  .p--contBox{
    padding: 38px 20px;
  }
  .p--headLpBox{
    &:not(:first-child){
      margin: 25px 0 0;
    }
    + .p--topBdBox__infoTxt{
      margin: 32px 0 0;
      padding: 32px 0 0;
      border-top: 1px dotted $clr-g40;
    }
  }
  .p--headLp__body{
    padding: 15px 13px;
  }
}

@media screen and (max-width: 767px){
  .p--rsv{
    .p--contBox__inner{
      width: 100%;
    }
    .p--contBox{
      padding: 13px 0;
    }
    .p--topBdBox__infoTxt{
      padding: 0 20px;
    }
    .p--headLpBox{
      padding: 0 20px;
      &:not(:first-child){
        margin: 13px 0 0;
      }
      .p--headLp__ttl{
        padding: 10px 13px;
      }
      + .p--topBdBox__infoTxt{
        margin: 22px 0 0;
        padding: 22px 20px 0;
      }
    }
    .p--headLp__body{
      padding: 10px 0;
      font-size: remSize(14);
    }
  }
}

/** ========================================
 * @p--rsv
 * 各種予約・応募
 * ====================================== */
.p--rsv__detail{
  .p--headLp__body{
    padding: 15px 0;
    font-size: remSize(14);
  }
}

.p--rsvTable{
  width: 100%;
  height: 1px;
  margin: 20px 0 0;
  tr{
    border-bottom: 1px solid #DADADB;
  }
  th,td{
    height: 100%;
    padding: 10px 15px;
    font-size: remSize(13);
    vertical-align: middle;
    &:not(:first-child){
      border-left: 1px solid #DADADB;
    }
  }
  th{
    background: #F5F5F5;
    text-align: left;
  }
  .c--btn{
    width: 100%;
    + .c--btn{
      margin-top: 5px;
    }
  }
  .p--col2{
    padding: 0;
    > div{
      display: flex;
      align-items: center;
      height: 100%;
      span{
        display: inline-block;
        width: 50%;
        height: 100%;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        + span{
          border-left: 1px solid #DADADB;
        }
      }
    }
  }
}

.p--guideLink{
  margin: 12px 5px 0;
  text-align: right;
  a{
    color: $clr-p80;
    font-size: remSize(14);
  }
}
.p--icon__route{
  &:before{
    content: "";
    display: inline-block;
    width: 16px;
    height: 18px;
    background: url(../images/icon_train.png) no-repeat;
    margin: 0 3px 0 0;
    vertical-align: middle;
  }
}

@media screen and (max-width: 767px){
  .p--tag__finish--sp{
    display: inline-block;
    padding: 3px 15px;
    border-color: $clr-g30;
    border-radius: 100px;
    background: $clr-g30;
    color: $clr-g50;
    font-size: remSize(10);
  }
  .p--rsvTable{
    height: auto;
    tr:nth-child(2){
      border-top: 1px solid $clr-g40;
    }
    th{
      display: none;
    }
    td{
      display: block;
      padding: 7px 0 0;
      &:not(:first-child){
        border: none;
      }
      &:last-child,
      &.p--sp__lastItem{
        padding-bottom: 15px;
      }
    }
    .c--btn{
      + .c--btn{
        margin-top: 10px;
      }
    }
    .p--col2{
      > div{
        display: flex;
        align-items: center;
        height: 100%;
        span{
          width: auto;
          margin: 5px 0 0;
          padding: 0 30px 0 0;
          + span{
            border-left: none;
          }
        }
      }
    }

  }
}


/** ========================================
 * @p--rsv
 * 各種予約・応募
 * ====================================== */
.p--qrArea{
  width: 140px;
  margin: 30px auto 0;
}
@media screen and (max-width: 767px){
  .p--qrArea{
    margin: 20px auto 0;
  }
}



/** ========================================
 * @p--checkArea
 * 「忘れずにこちらもチェック」エリア
 * ====================================== */
.p--checkArea{
  margin: 25px 0 0;
  padding: 13px 25px;
  .p--ttl{
    font-size: remSize(14);
  }
  .c--btn{
    min-width: auto;
    margin: 13px 0 0;
    padding: 10px 20px;
    font-size: .875rem;
    + p{
      margin: 7px 0 10px;
    }
  }
}




/** ========================================
 * お気に入りボタンアニメ
 * ====================================== */
.p--favAnime{
  position: relative;
  padding: 0 0 0 10px!important;
  &:before{
    display: none;
  }
  .p--favAnime__img {
    position: absolute;
    top: calc(50% - 10px);
    right: 0;
    width: 122px;
    height: 20px;
  }
  &.c--btn{
    width: 147px;
    height: 40px;
    .p--favAnime__img {
      right: 12px;
    }
  }
}


.p--favAnime--img{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px!important;
  height: 70px!important;
  padding: 0!important;
  &.c--sp__only{
    display: none;
  }
  span{
    width: 42px;
    height: 30px;
    background: url(../images/fav_anime_sp.png) no-repeat;
  }
  &.on span{
    background-position: -2348px 0;
  }
  &.off span{
    background-position: 0 0;
  }
  &.move{
    &.on,
    &.off{
      span{
        -webkit-transition: background 1s steps(55);
        transition: background 1s steps(55);
      }
    }
  }
}
@media screen and (max-width: 767px){
  .p--favAnime--img{
    &.c--sp__only{
      display: flex!important;
    }
    width: 44px!important;
    height: 44px!important;
  }
}