/*! 
 * ログイン関連
 */

/** ========================================
 * @p--login
 * ログイン
======================================== */
.p--login{
  /*全体*/
  &__area{
    width:600px;
    margin:0 auto;
  }
  &__box{
    width:600px;
    margin:30px auto 0;
    /*ピンク*/
    &--p{
      border-top:2px solid $clr-p80;
    }
    /*青*/
    &--b{
      border-top:2px solid $clr-lb60;
    }
    .p--signup__title{
      margin:20px 0 10px;
    }
  }
  /*タイトル*/
  &__heading{
    font-size: remSize(20);
    font-weight: bold;
    text-align: center;
    padding:15px 0;
    border-bottom: 1px solid #dbdbdc;
  }
  /*下のブロック*/
  &__block{
    padding:25px 40px;
  }
  &__txt{
    font-size: remSize(16);
  }
  &__btn{
    width: 300px;
  }
}
/*パスワード*/
.p--pswd{
  &__btn{
    width: 300px;
  }
}
/*画像認証*/
.p--authentication{
  display: flex;
  &__btn{
    margin-left: 20px;
    p{
      margin-top:5px;
    }
  }
}
@media screen and (max-width: 767px){
  .p--login{
    &__area{
      width:100%;
      padding:0 20px;
    }
    &__box{
      width:100%;
    }
    /*タイトル*/
    &__heading{
      font-size: remSize(16);
      text-align: left;
      padding:15px;
    }
    /*下のブロック*/
    &__block{
      padding:25px 20px;
    }
    &__txt{
      font-size: remSize(14);
    }
    &__btn{
      width: 100%;
    }
  }
  /*パスワード*/
  .p--pswd{
    &__btn{
      width: calc(100% - 100px );
    }
  }
  /*画像認証*/
  .p--authentication{
    display: block;
    &__img{
      img{
        width:auto;
      }
    }
    &__btn{
      margin-left: 0;
      margin-top:10px;
      p{
        margin-top:5px;
      }
    }
  }
}


/** ========================================
 * @p--rule
 * 利用規約
======================================== */
.p--rule{
  .c--contents__inner{
    width: 896px;
  }
  .c--boxWht{
    margin: 37px 0 0;
    padding: 27px 25px;
  }
  .c--btnArea{
    margin: 40px 0 30px;
    text-align: center;
    .c--btn--pk{
      width: 296px;
      padding: 20px 22px;
    }
  }
}

.p--ruleHeading{
  &:not(:first-child){
    margin: 48px 0 0;
  }
  + p{
    margin: 25px 0 0;
  }
}
.p--ruleList{
  margin: 25px 0 0;
  dt{
    margin-bottom: 10px;
    font-weight: bold;
    &:not(:first-child){
      margin-top: 20px;
    }
  }
  dd{
    line-height: 1.65;
  }
  + p{
    margin: 23px 0 0;
  }
  ul{
    li{
      a{
        color: $clr-p80;
      }
    }
    + p{
      margin: 25px 0 0;
    }
  }
  .p--rulrList--normal{
    > li{
      padding-left: .9em;
      text-indent: -.9em;
      p > a{
        padding-left: .9em;
      }
    }
    .p--rulrList--brackets{
      padding-left: 0;
      > li{
        padding-left: 1.5em;
        text-indent: -1.5em;
      }
    }
  }
  .p--rulrList--brackets{
    > li{
      padding-left: 1.9em;
      text-indent: -2.5em;
    }
    .p--rulrList--hyphen{
      padding: 0;
      > li{
        padding-left: .35em;
        text-indent: -.35em;
      }
    }
  }
  .p--rulrList--hyphen{
    padding-left: 1em;
  }
}


@media screen and (max-width: 767px){
  .p--rule{
    .c--contents__inner{
      width: 100%;
    }
    .c--boxWht {
      margin: 37px 0 0;
      padding: 17px 20px;
    }
    .c--btnArea{
      padding: 0;
      .c--btn--pk{
        width: 100%;
      }
    }
    
    &Box *{
      word-break: normal !important;
      word-wrap: break-word;
    }

  }
}