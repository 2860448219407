/** ================================================================================
 * 学生友達紹介 友達紹介
================================================================================ */

// 共通
.p--contBox{
  padding: 15px;
  background: $clr-w;
  border-radius: 4px;
  .p--memberAdd{
    padding: 15px 22px;
    text-align: center;
    .c--btn{
      min-width: 236px;
    }
  }
  .c--step__main{
    .c--btn{
      min-width: auto;
      font-size: remSize(13);
    }
  }
}
.c--contHeading--gr{
  + .p--contBox{
    padding: 30px 20px;
    border-radius: 0 0 4px 4px;
  }
}

@media screen and (max-width: 767px){
  .p--contBox{
    padding: 15px 0;
    border-radius: 0;
    .p--memberAdd{
      padding: 20px 20px 0;
      .c--btn{
        min-width: 100%;
      }
    }
    .c--step__main{
      .c--btn{
        width: auto;
      }
    }
  }
}

 /** ========================================
 * @p--referFriend
 * 友だち紹介トップ
======================================== */
.p--referFriend{
  &__imgArea{
    margin: 40px 0 0;
  }
  &__link{
    margin: 40px 0 70px;
  }
}
.p--body__img--1200__340{
  width: 1200px;
  img{
    height: 340px;
  }
}
@media screen and (max-width: 767px){
  .p--referFriend{
    &__imgArea{
      margin: 20px 0 0;
    }
    &__link{
      margin: 20px 0 30px;
      padding: 0 20px;
    }
  }
  .p--body__img--1200__340{
    max-width: 375px;
    width: 100%;
    margin: 0 auto;
    img{
      height: 120px;
    }
  }
}

 /** ========================================
 * @p--groupManage
 * グループ登録・解散ページ
======================================== */
.p--groupManage{
  text-align: center;
  .c--primary__heading + p{
    margin: 36px 0 0;
    font-size: remSize(14);
    + p{
      margin: 14px 0 0;
    }
  }
  .c--btnArea__formBox{
    margin: 50px 0 0;
  }
}

@media screen and (max-width: 767px){
  .p--groupManage{
    .c--contents__inner{
      padding: 0 20px;
    }
    .c--primary__heading{
      padding: 0;
      + p{
        margin: 17px 0 0;
      }
    }
    .c--btnArea__formBox{
      margin: 30px 0 25px;
    }
  }
}




/** ========================================
 * @p--groupParticipation
 * グループ参加特典
======================================== */
.p--groupBox__inner{
  width: 846px;
  margin: 0 auto;
  padding: 40px 0 70px;
}
// TOPがボーダー付きのボックス
.p--topBdBox{
  border-radius: 0 0 4px 4px;
  background: $clr-w;
  box-shadow: 0 2px 2px rgba(97,97,106,.2), 0 1px 5px rgba(97,97,106,.1);
  &--pk{
    border-top: 2px solid $clr-p80;
  }
  &__heading{
    padding: 12px 42px;
    border-bottom: 1px solid #dbdbdc;
    font-size: remSize(20);
    font-weight: 700;
    .c--ovalTag{
      width: auto;
      margin: 0;
      padding: 0 10px;
    }
  }
  &__body{
    border-radius: 0 0 4px 4px;
  }
}
.p--groupParticipation{
  .p--topBdBox{
    margin: 38px 0 0;
  }
  .c--contHeading--gr{
    margin: 40px 0 0;
    &.p--groupName__box{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .p--contBox{
    padding: 30px 85px;
  }
  .p--contBox__inputArea{
    display: flex;
    align-items: center;
    margin: 10px 0 0;
    input{
      width: 75%;
      height: 52px;
      margin: 0 7px 0 0;
    }
    .c--btn{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 52px;
      font-size: 1rem;
    }
  }
}

.p--atten__ttl{
  margin: 40px 0 0;
  font-weight: bold;
}
.p--atten__list{
  margin: 10px 0 0;
  li{
    margin: 5px 0 0 1rem;
    text-indent: -1rem;
    &:before{
      content: "●";
      margin: 0 3px 0 0;
    }
  }
}

.p--faq{
  margin: 65px 0 0;
  &__item{
    position: relative;
    &:before{
      content: "";
      position: absolute;
      display: inline-block;
      left: 17px;
      width: 24px;
      height: 26px;
      background-size: contain!important;
    }
    &--q{
      padding: 20px 35px 0 55px;
      font-weight: bold;
      &:before{
        top: 20px;
        background: url(../images/icon_question.png) no-repeat;
      }
    }
    &--a{
      padding: 12px 35px 16px 55px;;
      border-bottom: 1px solid $clr-g40;
      &:before{
        top: 15px;
        background: url(../images/icon_answer.png) no-repeat;
      }
    }
  }
}

@media screen and (max-width: 767px){
  .p--groupBox__inner{
    width: 100%;
    padding: 0 0 20px;
  }
  .p--topBdBox{
    &__heading{
      padding: 12px 22px;
      font-size: 1rem;
      .c--ovalTag{
        margin: 0;
        padding: 5px;
        font-size: .625rem;
        font-weight: 400;
        line-height: 1;
      }
    }
  }
  .p--groupParticipation{
    .p--topBdBox{
      margin: 22px 0 0;
    }
    .c--contHeading--gr{
      margin: 20px 0 0;
    }
    .p--contBox{
      padding: 15px 20px;
    }
    .p--contBox__inputArea{
      input{
        width: calc(100% - 100px);
      }
      .c--btn{
        min-width: auto;
        width: 100px;
        padding: 5px 12px;
        font-size: remSize(14);
        line-height: 1.4;
      }
    }
  }
  
  .p--atten__ttl{
    margin: 20px 0 0;
    padding: 0 20px;
    font-size: remSize(12);
  }
  .p--atten__list{
    margin: 5px 0 0;
    padding: 0 20px;
    font-size: remSize(12);
  }
  
  .p--faq{
    margin: 35px 0 0;
    &__ttl{
      padding: 7px 22px;
    }
  
    &__item{
      &:before{
        left: 24px;
      }
      &--q{
        padding: 15px 30px 0 62px;
        &:before{
          top: 17px;
        }
      }
      &--a{
        padding: 7px 30px 12px 62px;
        &:before{
          top: 10px;
        }
      }
    }
  }
}


/** ====================
 * グループ参加特典（招待者） 参加前
==================== */
.p--groupParticipation{
  &.p--groupMember{
    .p--contBox{
      padding: 30px;
    }
    .c--btnArea__formBox{
      .c--btn{
        min-width: 296px;
      }
    }
  }
}
.p--memberTotal{
  margin: 25px 0 0;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  span{
    display: inline-block;
    margin: 0 5px;
    color: $clr-p80;
    font-size: remSize(22);
  }
}
.p--memberList{
  display: flex;
  flex-wrap: wrap;
  margin: 27px 0 0;
  &__item{
    width: calc(50% - 3px);
    padding: 16px;
    border: 1px solid $clr-g40;
    background: $clr-g40;
    color: $clr-g70;
    &:nth-child(even){
      margin: 0 0 0 6px;
    }
    &:nth-child(n + 3){
      margin-top: 6px;
    }
    .p--itemNum{
      position: relative;
      padding: 0 0 0 23px;
      font-size: remSize(13);
      &:before{
        content: "";
        position: absolute;
        display: inline-block;
        top: 50%;
        left: 0;
        width: 16px;
        height: 16px;
        background-size: contain!important;
        background: url(../images/icon_member_off.png) no-repeat;
        transform: translateY(-50%);
      }
      .p--itemMsg{
        position: relative;
        display: inline-block;
        margin: 0 0 0 20px;
        padding: 3px 10px;
        border-radius: 3px;
        border: 1px solid $clr-g70;
        color: $clr-g70;
        font-size: .75rem;
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: -13px;
          border: 6px solid transparent;
          border-right: 8px solid $clr-g40;
          z-index: 2;
          transform: translateY(-50%);
        }
        
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: -16px;
          border: 7px solid transparent;
          border-right: 9px solid $clr-g70;
          z-index: 1;
          transform: translateY(-50%);
        }

      }
    }
    &--active{
      background: $clr-w;
      color: $clr-b;
      .p--itemNum{
        color: $clr-p80;
        &:before{
          background: url(../images/icon_member_on.png) no-repeat;
        }
        .p--itemMsg{
          position: relative;
          display: inline-block;
          margin: 0 0 0 20px;
          padding: 3px 10px;
          border-radius: 3px;
          border: none;
          background: $clr-p80;
          color: $clr-w;
          font-size: .75rem;
          &:before{
            content: "";
            position: absolute;
            top: 50%;
            left: -12px;
            border: 5px solid transparent;
            border-right: 7px solid $clr-p80;
            transform: translateY(-50%);
          }
          &:after {
            display: none;
          }
        }
      }
    }
    &--comp{
      background: #FFF79D;
    }
    &--pk{
      background: #FFF0F5;
    }
    .p--itemName{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 12px 3px 0;
      font-size: 1rem;
      .p--cancelBtn{
        display: inline-block;
        border: none;
        border-radius: 3px;
        padding: 3px 10px 6px;
        background: $clr-g70;
        color: $clr-w;
        font-size: .75rem;
      }
      .p--representative{
        display: inline-block;
        border-radius: 3px;
        padding: 3px 10px;
        border:1px solid $clr-p80;
        background: $clr-w;
        color: $clr-p80;
        font-size: .75rem;
      }
    }
  }
  
}



@media screen and (max-width: 767px){

  .p--groupParticipation{
    &.p--groupMember{
      .p--contBox{
        padding: 20px 0 0;
      }
      .c--btnArea__formBox{
        .c--btn{
          min-width: 100%;
        }
      }
      .p--groupMember__box{
        padding: 20px 20px 33px;
        border-bottom: 1px solid #ccc;
      }
      .p--lpWrap{
        + .p--groupMember__box{
          padding-top: 0;
        }
      }
    }
    .p--groupName{
      font-size: remSize(18);
    }
  }
  .p--memberTotal{
    margin: 10px 0 0 5px;
    padding: 0 20px;
    text-align: left;
    font-size: remSize(14);
  }

  .p--memberList{
    margin: 15px 0 0;
    display: block;
    border-top: 1px solid $clr-g40;
    &__item{
      width: 100%;
      border-color: transparent transparent $clr-w transparent;
      &:nth-child(even){
        margin-left: 0;
      }
      &:nth-child(n + 3){
        margin-top: 0;
      }
      &:last-child{
        border-bottom: none;
      }
      &--active{
        border-color: transparent transparent $clr-g40 transparent;
        .p--itemNum{
          .p--itemMsg{
            font-size: remSize(10);
          }
        }
      }
    }
  }
}

/** ====================
 * グループ参加特典（招待者） 参加後
==================== */
.p--groupParticipation{
  &.p--groupMember{
    .p--member__info{
      background: #fff79d;
      text-align: center;
      padding: 10px;
    }
    .p--memberInv{
      margin: 20px 100px;
      padding: 23px 50px;
      background: #FFF0F5;
      font-weight: bold;
      text-align: center;
      &__heading{
        position: relative;
        display: inline-block;
        margin: 0 0 16px 0;
        padding: 0 15px 0 20px;
        color: $clr-p80;
        font-size: remSize(18);
        &:before{
          content: "";
          position: absolute;
          display: inline-block;
          top: 50%;
          left: 0;
          width: 16px;
          height: 16px;
          background-size: contain!important;
          background: url(../images/icon_member_on.png) no-repeat;
          transform: translateY(-50%);
        }
      }
      &__btnArea{
        display: flex;
        justify-content: space-between;
        margin: 18px 0 0;
        .c--btn{
          min-width: 238px;
        }
      }
    }

    .p--memberComp{
      margin: 25px 0 0;
      padding: 25px;
      background-color: #FFF0F5;
      background-image: url(../images/point_border_top.png),url(../images/point_border_bottom.png);
      background-position: top,bottom;
      background-repeat: repeat-x, repeat-x;
      background-size: auto,auto;
      font-weight: bold;
      text-align: center;
      &__heading{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 5px;
        color: $clr-p80;
        font-size: remSize(18);
        &:before,
        &:after{
          content: "";
          display: inline-block;
          width: 18px;
          height: 21px;
          background-size: 18px;
        }
        &:before{
          margin: 0 7px 0 0;
          background: url(../images/icon_comp_left.png) 50% no-repeat;
        }
        &:after{
          content: "";
          margin: 0 0 0 7px;
          background: url(../images/icon_comp_right.png) 50% no-repeat;
        }
      }
    }
  }
}

@media screen and (max-width: 767px){
  .p--groupParticipation.p--groupMember{
    &.p--groupMember--join{
      .p--contBox{
        padding: 0 0 20px;
      }
    }
    .p--member__info{
      text-align: left;
    }
    .p--memberInv{
      margin: 20px 0;
      padding: 20px;
      &__heading{
        margin: 0 0 10px;
        padding: 0 5px 0 20px;
        font-size: remSize(13);
        + p{
          text-align: left;
        }
      }
      &__btnArea{
        margin: 10px 0 0;
        display: block;
        .c--btn{
          margin: 0;
          padding: 13px 5px;
          &--line{
            margin: 0 0 12px;
            padding: 13px 22px 13px 5px;
            i{
              padding: 0 0 0 30px;
              &:before{
                width: 30px;
                height: 30px;
                background-size: 30px;
              }
            }
          }
        }
      }
    }

    .p--memberComp{
      margin: 17px 0 0;
      padding: 25px 20px;
      &__heading{
        margin: 0 0 10px;
        font-size: 1rem;
      }
    }
  }
}


/** ====================
 * グループ参加特典ログインフォーム
==================== */
.p--groupParticipation.p--groupMember{
  &.p--groupMember__form{
    &--narrow{
      .c--contents__inner{
        width: 895px;
      }
      .c--contHeading--gr+.p--contBox{
        padding: 30px 20px;
      }
      .c--btnArea__formBox{
        padding: 40px 0 20px;
      }
    }
    .p--groupBox__inner{
      padding: 0;
      .c--primaryTable{
        th,td{
          height: 66px;
        }
      }
    }
    .c--primary__heading + .p--contBox{
      margin: 36px 0 0;
      padding: 24px;
    }
    .p--contBox + .p--contBox{
      margin: 40px 0 0;
    }
    .p--groupBox__infoArea{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .c--btn{
        min-width: 156px;
      }
      + .p--form__table{
        margin: 25px 0 0;
      }
    }
  }
}


@media screen and (max-width: 767px){
  .p--groupParticipation.p--groupMember{
    &.p--groupMember__form{
      &--narrow{
        .c--contents__inner{
          width: 100%;
        }
        .c--contHeading--gr+.p--contBox{
          padding: 20px 0!important;
        }
        .c--btnArea__formBox{
          padding-right: 20px;
          padding-left: 20px;
          p{
            padding: 0!important;
            text-align: center!important;
          }
        }
        .c--primaryTable+.c--control{
          margin: 5px 25px;
        }
      }
      .p--groupBox__inner{
        .c--primaryTable{
          th,td{
            height: auto;
          }
        }
      }
      .p--contBox{
        padding: 0!important;
      }
      .p--groupBox__login{
        padding-top: 20px!important;
        .c--primaryTable{
          th{
            background: transparent;
          }
          + .c--control{
            margin: 5px 25px 5px 25px;
            + .c--btnArea__formBox{
              .c--btn{
                margin: 0;
              }
            }
          }
        }
      }
      .c--btnArea__formBox{
        p{
          padding: 15px 0 35px;
          text-align: left;
        }
      }
      .p--groupBox__infoArea{
        display: block;
        padding: 10px 20px;
        + .p--form__table{
          margin: 0;
        }
      }

    }
  }
}





/** ================================================================================
 * 学生友達紹介 資料請求
================================================================================ */
 /** ========================================
 * @p--docReq
 * 資料請求関係
======================================== */
// 共通
.p--form__edit{
  display: flex;
  justify-content: space-between;
}
.p--docReq{
  .c--contents__inner{
    width: 895px;
    .c--primary__heading{
      + .p--topBdBox{
        margin: 40px 0 0;
      }
    }
    .c--error__list{
      margin: 35px 0;
    }
    .p--topBdBox{
      &__heading{
        padding: 12px 25px;
      }
      + .p--contBox{
        margin: 50px 0 0;
      }
    }
    .c--contHeading--gr{
      margin: 50px 0 0;
    }
    p + .p--form__infoTxt{
      margin: 17px 0 0;
      + .p--form__table{
        margin: 25px 0 0;
      }
    }
    .c--btnArea{
      padding-top: 40px;
      padding-bottom: 20px;
    }
    .c--primaryTable{
      th,td{
        height: 66px;
      }
    }
    .c--btnArea__formBox{
      .c--btn{
        min-width: 296px;
      }
    }
  } //.c--contents__inner
}

@media screen and (max-width: 767px){
  // 共通
  .p--form__edit{
    display: block;
    text-align: right;
    .p--form__infoTxt{
      margin: 0 0 25px;
      text-align: left;
    }
  }
  .p--docReq{
    .c--contents__inner{
      width: 100%;
      .c--error__list{
        padding: 0 20px;
      }
      .p--contBox{
        padding: 20px 0;
        &.c--boxShadow{
          box-shadow: none;
        }
        .p--finTxt{
          padding:0 25px;
        }
      }
      .p--form__infoWrap{
        padding: 0 20px;
      }
      .c--primaryTable{
        th,td{
          height: auto;
        }
      }
      .c--primaryTable + .c--control{
        margin: 5px 25px;
      }
      .p--topBdBox{
        box-shadow: none;
        + .p--contBox{
          margin: 20px 0 0;
        }
      }
      .c--btnArea__formBox{
        .c--btn{
          min-width: 100%;
        }
      }
    } //.c--contents__inner
  }
}

/** ====================
 * 資料請求一括申込
==================== */
.p--docReq__target{
  padding: 18px 23px;
  .p--target__num{
    font-size: remSize(16);
    font-weight: bold;
    span{
      display: inline-block;
      margin: 0 5px 0 0;
      color: $clr-p80;
      font-size: remSize(23);
    }
  }
  .p--target__list{
    margin: 8px 0 0;
    font-weight: bold;
  }
  .p--target__already{
    margin: 10px 0 0;
    display: inline-block;
  }
}

@media screen and (max-width: 767px){
  .p--docReq__target{
    margin: 0 20px;
  }
}


/** ====================
 * 資料請求完了
==================== */
.p--docReq{
  &.p--docReq__finish{
    .c--contents__inner{
      .p--topBdBox{
        .p--docReq__finishTxt{
          font-size: remSize(18);
          font-weight: bold;
          text-align: center;
        }
      }
      .c--btnArea{
        .c--btn{
          min-width: auto;
          + .c--btn{
            margin: 0 0 0 10px;
          }
        }
      }
      .c--secondary__heading{
        margin: 45px 0 0;
        text-align: center;
      }
    } //.c--contents__inner
  } //.p--docReq__finish
} //.p--docReq

.c--primary__heading{
  + .p--docReq__finishTxt{
    margin: 32px 0;
    color: $clr-p80;
    font-size: remSize(24);
    font-weight: bold;
  }
}
.p--docReq__finishInfoTxt{
  margin: 25px 0 0;
  text-align: center;
}

@media screen and (max-width: 767px){
  .p--docReq__finishInfoTxt{
    padding: 0 20px;
  }

  .p--docReq{
    &.p--docReq__finish{
      .c--contents__inner{

        .p--topBdBox{
          .p--docReq__finishTxt{
            padding: 0 20px;
            font-size: remSize(14);
            text-align: left;
          }
        }

        .c--btnArea{
          padding-top: 20px;
          .c--btn{
            width: 100%;
            + .c--btn{
              margin: 10px 0 0;
            }
          }
        }
        .c--secondary__heading{
          margin: 30px 0 0;
          padding: 0 20px;
          font-size: remSize(16);
        }
      } //.c--contents__inner
    } //.p--docReq__finish
  } //.p--docReq
  .c--primary__heading{
    + .p--docReq__finishTxt{
      font-size: remSize(18);
    }
  }

}