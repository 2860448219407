/*! 
 * ヘッダー
 */

/** ========================================
 * @HEADER
 * ヘッダー
 * ====================================== */
header{
  min-width: 1200px;
}
.c--mainHeader{
  position: relative;
  display: block;
  box-shadow: 0 2px 8px rgba(97, 97, 106, 0.1), 0 1px 10px rgba(97, 97, 106, 0.1);
}
.c--mainHeader__inner{
  display:flex;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  min-height: 103px;
  margin: 0 auto;
  background: $clr-w;
}
.c--header__logo{
  margin: 0 0 5px;
  p{
    margin: 0 0 14px;
    padding: 0 0 0 1px;
    color: #7f7f7f;
    font-size: .69rem;
    letter-spacing: .1rem;
  }
  img{
    width: 300px;
  }
}

.c--subHeader{
  display: none;
}

@media screen and (max-width: 767px){
  header{
    min-width: auto;
  }
  .c--mainHeader{
    box-shadow: none;
    &__inner{
      width: 100%;
      min-height: 60px;
    }
  }
  .c--header__logo{
    p{
      display: none;
    }
    img{
      width: 42.5vw;
      margin: 0 0 0 19px;
    }
  }

  .c--subHeader{
    display: block;
    box-shadow: 0 8px 3px -3px rgba(97,97,106,.1) inset;
    &__head{
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 44px;
      padding: 0 20px;
      font-size: remSize(12);
      line-height: 1;
      .c--head__item{
        display: flex;
        align-items: center;
        height: 100%;
        .c--item__inner{
          display: flex;
          align-items: center;
          height: 100%;
          &--myqr{
            margin: 0 0 0 10px;
            padding: 0 0 0 10px;
            border-left: 1px solid $clr-g40;
          }
        }
        .c--item__txt{
          color: $clr-g70;
          font-size: remSize(10);
        }
        .c--item__icon{
          margin: 0 0 0 5px;
          a{
            display: flex;
            width: 24px;
            height: 24px;
            border: 1px solid $clr-g40;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
          }
          img{
            width: 14px;
            height: 14px;
          }
        }
        .c--item__point{
          margin: 0 0 0 5px;
          i{
            color: $clr-p80;
            font-size: remSize(16);
            font-weight: bold;
          }
        }
      }
    }

    &__mypageList{
      display: flex;
      border-top: 1px solid $clr-g40;
      li{
        width: 20%;
        a{
          display: inline-block;
          width: 100%;
          text-align: center;
          color: $clr-g70;
          font-size: remSize(10);
        }
      }



      // 先頭アイコン付き
      .c--list__item{
        border-top: 1px solid $clr-g30;
        .c--item{
          display: block;
          width: 100%;
          height: 100%;
          padding: 13px 0;
          background: #fff;
          line-height: 1;
          &__icon{
            span{
              position: relative;
              display: inline-block;
              &:before{
                content: "";
                display: block;
                width: 16px;
                height: 16px;
                margin: 0 auto 8px;
                background-size: contain!important;
                background-repeat: no-repeat;
              }
              .c--notice__num{
                position: absolute;
                right: -22px;
                top: -9px;
                height: 18px;
                border: 1px solid $clr-w;
                line-height: 16px;
              }
            }
            &--fav span:before{
              background-image: url(../images/icon_fav.png);
            }
            &--comparison span:before{
              background-image: url(../images/icon_graph.png);
            }
            &--msg span:before{
              background-image: url(../images/icon_msg.png);
            }
            &--scout span:before{
              background-image: url(../images/icon_scout.png);
            }
            &--history span:before{
              background-image: url(../images/icon_history.png);
            }
          }
        }



        &--active{
          .c--item{
            background: $clr-p80;
            color:$clr-w;
            &__icon{
              span{
                .c--notice__num{
                  background: $clr-w;
                  border: 1px solid $clr-p80!important;
                  color: $clr-red;
                }
              }
              &--fav span:before{
                background-image: url(../images/icon_fav-w.png);
              }
              &--comparison span:before{
                background-image: url(../images/icon_graph-w.png);
              }
              &--msg span:before{
                background-image: url(../images/icon_msg-w.png);
              }
              &--scout span:before{
                background-image: url(../images/icon_scout-w.png);
              }
              &--history span:before{
                background-image: url(../images/icon_history-w.png);
              }
            }
          }
        }
      }
    }

    &__defList{
      display: flex;
      padding: 4px 0;
      .c--list__item{
        width: calc(100% / 3);
        &:not(:first-child){
          border-left: 1px solid $clr-g40;
        }
        .c--item{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 9px 0;
          font-size: remSize(10);
          &__icon{
            display: inline-block;
            span{
              display: flex;
              align-items: center;
              &:before{
                content: "";
                display: inline-block;
                width: 17px;
                height: 17px;
                margin: 0 7px 0 0;
                background-size: contain!important;
              }
            }
            &--msg span:before{
              background: url(../images/icon_msg.png) 50% no-repeat;
            }
            &--scout span:before{
              background: url(../images/icon_scout.png) 50% no-repeat;
            }
            &--fav span:before{
              background: url(../images/icon_fav.png) 50% no-repeat;
            }
          }
        }
      }
    }
  }


}