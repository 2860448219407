@charset "UTF-8";
/*! 
 * front
 * (C)Mynavi Corporation
 */


/*! ============================================================
 * File        : Foundation/
 * DESCRIPTION : 定数・変数や関数、Mixin
 * AUTHOR      : 
 * -------------------------------------------------------------
 * (C)Mynavi Corporation
 * ========================================================== */
@import 'Foundation/_define';

/*! ============================================================
 * File        : /common/scss/Foundation/reset
 * DESCRIPTION : 共通のリセット
 * AUTHOR      : 
 * -------------------------------------------------------------
 * (C)Mynavi Corporation
 * ========================================================== */
@import '../../../common/scss/Foundation/reset';


/*! ============================================================
 * File        : Layout/
 * DESCRIPTION : 基本のレイアウト
 * AUTHOR      : 
 * -------------------------------------------------------------
 * (C)Mynavi Corporation
 * ========================================================== */
@import 'Layout/_base';



/*! ============================================================
 * File        : Object/
 * DESCRIPTION : 要素のスタイル
 * AUTHOR      : 
 * -------------------------------------------------------------
 * (C)Mynavi Corporation
 * ========================================================== */

/** ------------------------------
 * Component
 * DESCRIPTION : コンポーネント
 * ---------------------------- */
@import 'Object/Component/_common';
@import 'Object/Component/_buttons';
@import 'Object/Component/_header';
@import 'Object/Component/_footer';
@import 'Object/Component/_navi';
@import 'Object/Component/_breadcrumbs';
@import 'Object/Component/_modal';
@import 'Object/Component/_behavior';



/** ------------------------------
 * Project
 * DESCRIPTION : 共通化しないページごとのもの
 * ---------------------------- */
@import 'Object/Project/_index';
@import 'Object/Project/_favorite';
@import 'Object/Project/_comparison';
@import 'Object/Project/_login';
@import 'Object/Project/_form';
@import 'Object/Project/_drwl';
@import 'Object/Project/_edit';
@import 'Object/Project/_inpt';
@import 'Object/Project/_hosp';
@import 'Object/Project/_frnd';
@import 'Object/Project/_aply';
@import 'Object/Project/_schl';
@import 'Object/Project/_mesg';
@import 'Object/Project/_cnst';



/** ------------------------------
 * Utility
 * DESCRIPTION : ユーティリティ・補足
 * ---------------------------- */
@import '../../../common/scss/Object/Utility/_helper';
@import '../../node_modules/jquery-ui/themes/base/all.css';
